import React, { useCallback, useState } from "react";
import { FiFileText } from "react-icons/fi";

import { MESSAGE_COLlECTION } from "@fb/const";
import { db } from "@fb/db";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import {
  Avatar,
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { deleteDoc, doc } from "firebase/firestore";

import { useAppSelector } from "@app/hooks";

import ViewImageDialog from "@components/Dialog/ViewImageDialog";
import EllipsisContent from "@components/EllipsisContent";

import { MessageType } from "@pages/chat/Conversation/type";

import { humanFileSize } from "@utils/format/fileSize";

const ActivityItem = ({
  title,
  text,
  position,
  avatar,
  fileUrls,
  date,
  ...rest
}: MessageType) => {
  const { userId, imageUrls, id } = rest;
  const user = useAppSelector((state) => state.auth.userInfo.user);
  const isMine = userId == user.id;
  const [isOpenedModal, setIsOpenedModal] = useState("");
  const [embedLink, setEmbedLink] = useState({
    url: "",
    displayText: "",
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleToggleModal = useCallback(
    (modalSection?: string) => {
      setIsOpenedModal(modalSection);
    },
    [isOpenedModal]
  );

  const rednerTime = (date?: any) => {
    if (!date) {
      return null;
    }
    const msgDate = dayjs(typeof date === "number" ? date : date?.seconds * 1000);
    const format = "h:mm A";

    let formatedDate = msgDate.format(format);
    const todayStart = dayjs().startOf("day");
    if (msgDate.diff(todayStart, "second") > 0) {
      formatedDate = msgDate.fromNow();
    }

    return (
      <Typography color={"#0000005C"} fontSize={"10px"}>
        {formatedDate}
      </Typography>
    );
  };
  const onDeleteChat = async () => {
    setAnchorEl(null);
    await deleteDoc(doc(db, MESSAGE_COLlECTION, id))
      .then(() => {
        console.log("Entire Document has been deleted successfully.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Stack className="message-item" gap={1} p={2} bgcolor={"white"} borderRadius={"8px"}>
      <Stack flex={1} flexDirection={"column"} gap={2}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Stack display={"flex"} alignItems={"center"} flexDirection={"row"} gap={1}>
            <Avatar src={avatar}>{title}</Avatar>
            <Typography component={"span"} fontSize={"12px"} fontWeight={"600"}>
              {title}
            </Typography>
          </Stack>
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            {rednerTime(date)}
            {isMine && (
              <>
                <IconButton
                  onClick={(e) => {
                    handleOpenMenu(e);
                  }}
                  sx={{
                    width: 28,
                    height: 28,
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  sx={{
                    ".MuiPaper-root": {
                      boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.10)",
                    },
                  }}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteChat();
                      setAnchorEl(null);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
        </Stack>
        {!!(text || fileUrls?.length) && (
          <MessageMentionContainer
            fontSize={14}
            alignSelf={"flex-start"}
            display="flex"
            flexDirection="column"
            {...(!!(text && fileUrls?.length) && { gap: 1 })}
          >
            {!!text && (
              <Typography
                component={"p"}
                fontSize={14}
                sx={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                }}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            {fileUrls && (
              <FileContainer gap={1}>
                {fileUrls?.map?.((f) => {
                  return (
                    <Link
                      href={f.url}
                      color="#000"
                      fontWeight="bold"
                      target="_blank"
                      rel="noreferrer"
                      underline="none"
                      key={f.url}
                      display="flex"
                      alignItems="center"
                      gap={1}
                      sx={{
                        ":hover": {
                          p: 0.5,
                          m: -0.5,
                          backgroundColor:
                            position === "left" ? "#c3d4eb" : "common.wePeep",
                        },
                        borderRadius: 1,
                      }}
                    >
                      <Box
                        borderRadius="100%"
                        p={1}
                        bgcolor="primary.main"
                        width={40}
                        height={40}
                        color="white"
                      >
                        <FiFileText size={24} />
                      </Box>
                      <Box>
                        <Box>{f.name}</Box>
                        {!!f.size && (
                          <Typography color="#0000007A" fontSize={10}>
                            {humanFileSize(f.size)}
                          </Typography>
                        )}
                      </Box>
                    </Link>
                  );
                })}
              </FileContainer>
            )}
          </MessageMentionContainer>
        )}
        {rest.imageUrls?.length > 0 && (
          <FileContainer flexDirection={"column"} gap={1}>
            {rest.imageUrls?.map?.((image) => {
              return image.type?.startsWith("video/") ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <Box
                  key={image.url}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <OndemandVideoIcon />
                  <Link
                    target="_parent"
                    href={image.url}
                    style={{ color: "#000", textDecorationColor: "#000" }}
                  >
                    <EllipsisContent
                      numberOfLines={1}
                      sx={{ fontSize: 16, marginTop: 0 }}
                      content={image.name}
                    />
                  </Link>
                </Box>
              ) : (
                //   <video controls autoPlay>
                //   <source src={image.url} type="video/mp4" />
                //   Your browser does not support the video tag.
                // </video>
                <Image
                  key={image.url}
                  src={image.url}
                  alt={image.name}
                  onClick={() => {
                    setEmbedLink({ url: image.url, displayText: "" });
                    handleToggleModal("image");
                  }}
                />
              );
            })}
          </FileContainer>
        )}
      </Stack>
      {!!isOpenedModal && (
        <ViewImageDialog {...{ embedLink, isOpenedModal, handleToggleModal }} />
      )}
    </Stack>
  );
};
export default ActivityItem;

const FileContainer = styled(Stack)`
  display: flex;
`;

const Image = styled("img")`
  max-width: 300px;
`;

const MessageMentionContainer = styled(Box)`
  max-width: 100%;

  textarea:disabled {
    border: 0;
    color: #000;
    line-height: 1.4;
  }
  width: fit-content;
  span[data-lexical-beautiful-mention="true"] {
    font-family: Poppins;
    font-weight: 500;
    color: #3182ce;
    padding: 2px 4px;
    border-radius: 4px;
  }
`;
