import { useCallback } from "react";

import { DELIVERY_REALTIME } from "@fb/const";
import { realtimeDb } from "@fb/db";
import { ref, serverTimestamp, child, get, update, remove } from "firebase/database";

import { useAppSelector } from "@app/hooks";

import { DeliveryDto } from "@dto/job";

const useReadDelivery = (projectId: string) => {
  const { user } = useAppSelector((state) => state.auth.userInfo);
  const userId = user?.id;

  const hasReadDelivery = useCallback(
    async (deliveryId: string) => {
      const readDeliveryRef = ref(
        realtimeDb,
        `${DELIVERY_REALTIME}/${projectId}/${deliveryId}/`
      );
      return get(child(readDeliveryRef, userId))
        .then((snapshot) => {
          return snapshot.exists();
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    },
    [ref]
  );

  const addUserAsRead = useCallback(
    async (deliveryId: string) => {
      const exists = await hasReadDelivery(userId);
      if (!exists) {
        try {
          const databaseRef = ref(
            realtimeDb,
            `${DELIVERY_REALTIME}/${projectId}/${deliveryId}/${userId}`
          );
          await update(databaseRef, {
            readAt: serverTimestamp(),
          });
        } catch (error) {
          console.error("Error adding user to delivery:", error);
        }
      }
    },
    [hasReadDelivery, projectId, userId]
  );

  const readAllDelivery = async (deliveries: DeliveryDto[]) => {
    try {
      const deliveryIds = deliveries.map((item) => item.id);
      const updatePromises = deliveryIds.map((deliveryId) => {
        const userRef = ref(
          realtimeDb,
          `${DELIVERY_REALTIME}/${projectId}/${deliveryId}/${userId}`
        );
        return update(userRef, {
          readAt: new Date().getTime(),
        });
      });

      await Promise.all(updatePromises);
    } catch (error) {
      console.error(error);
    }
  };

  const resetReadDelivery = async (deliveryId: string) => {
    const deliveryRef = ref(
      realtimeDb,
      `${DELIVERY_REALTIME}/${projectId}/${deliveryId}`
    );

    await remove(deliveryRef)
      .then(() => {
        console.log("Delivery deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting delivery:", error);
      });
  };

  return { readAllDelivery, resetReadDelivery, hasReadDelivery, addUserAsRead };
};

export default useReadDelivery;
