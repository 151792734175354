import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

export type Request = {
  roundTableId: string;
  proposalId: string;
  voteOptionId: string;
  reason?: string;
};

const submitVote = async (payload: Request) => {
  const response = await axios.post("/submit-vote", payload);

  return response.data.data;
};

const useSubmitVote = () => {
  return useMutation(submitVote);
};

export default useSubmitVote;
