import { axios, handleApiError } from "@features/config";

import { Application } from "@dto/application";
import { User } from "@dto/user";

import { Delivery_Status } from "./type";

type HiringResponseType = {
  success: boolean;
  data?: {
    data: boolean;
  };
  message?: string;
};

type HiringRequestType = {
  jobId: string;
  deliveryId: string;
  status: Delivery_Status;
  note?: string;
};

export const reviewDelivery = async ({
  jobId,
  deliveryId,
  status,
  note,
}: HiringRequestType) => {
  try {
    const data: HiringResponseType = await axios.post(
      `/job/${jobId}/deliveries/${deliveryId}/review`,
      {
        status,
        note,
      }
    );

    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return handleApiError(error);
  }
};
