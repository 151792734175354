export const getBrandIcon = (brand: string) => {
  switch (brand) {
    case "visa":
      return require("@assets/images/payment/Visa.png"); // You need to store these icons in your public folder
    case "mastercard":
      return require("@assets/images/payment/MasterCard.png");
    case "amex":
      return require("@assets/images/payment/Amex.png");
    case "discover":
      return require("@assets/images/payment/Discover.png");
    case "diners":
      return require("@assets/images/payment/Diners.png");
    case "jcb":
      return require("@assets/images/payment/JCB.png");
    case "unionpay":
      return require("@assets/images/payment/UnionPay.png");
    default:
      return; // Fallback icon
  }
};
