import HistoryIcon from "@mui/icons-material/History";
import { Stack, Typography } from "@mui/material";

interface IProps<T> {
  data: T;
  field: keyof T;
  currentValue: unknown;
  suffix?: string;
  formatContent?: (value: any) => any;
}
const HelperText = <T,>({
  data,
  field,
  currentValue,
  suffix,
  formatContent,
}: IProps<T>) => {
  if (
    !data ||
    !Object.prototype.hasOwnProperty.call(data, field) ||
    currentValue === data[field]
  ) {
    return null;
  }

  const pendingValue = formatContent ? formatContent(data[field]) : data[field];

  return (
    <Stack
      component="p"
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ color: "common.partyTime" }}
    >
      <HistoryIcon sx={{ width: 20, height: 20 }} />
      <Typography sx={{ fontSize: 14 }}>
        Pending Value: {pendingValue}{" "}
        <Typography component="span" sx={{ fontSize: 14 }}>
          {suffix}
        </Typography>
      </Typography>
    </Stack>
  );
};

export default HelperText;
