import { JobStatusColor } from "@pages/jobs-management/constants";

import { Order_Status } from "./types";

export const formatOrderStatus = (status: string) => {
  // enum Order_Status {
  //   TO_DO = "TO_DO",
  //   IN_PROGRESS = "IN_PROGRESS",
  //   COMPLETED = "COMPLETED",
  //   DELIVERED = "DELIVERED",
  //   DONE = "DONE",
  //   CANCELLED = "CANCELLED",
  // }
  const res = {
    label: "Unknow",
    color: "",
    bgcolor: "#ebebeb",
    itemStatus: status,
  };
  switch (status) {
    case Order_Status.TO_DO:
      res.label = "Todo";
      break;
    case Order_Status.IN_PROGRESS:
      res.label = "In progress";
      res.color = "primary";
      res.bgcolor = "#FEEBCB";
      break;
    case Order_Status.DELIVERED:
      res.label = "Delivered";
      res.color = "primary";
      res.bgcolor = JobStatusColor.DELIVERED;
      break;
    case Order_Status.DONE:
      res.label = "Done";
      res.color = "#1B5E20";
      res.bgcolor = "#E8F5E9";
      break;
    case Order_Status.CANCELLED:
      res.label = "Canceled";
      break;

    default:
      break;
  }

  return res;
};
