import { axios, handleApiError } from "@features/config";

type Request = {
  roundtableId: string;
  files: string[];
};

type Response = {
  success: boolean;
  data?: { code: number; message: string };
  message?: string;
};

export const uploadRoundtableFiles = async ({
  roundtableId,
  files,
}: Request): Promise<Response> => {
  try {
    // if (files.length === 0) {return}
    const { data } = await axios.post(`/round-table/${roundtableId}/files`, {
      files,
    });
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};
