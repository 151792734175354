import { useAppDispatch } from "@app/hooks";

import { sidebarActions } from "@features/sidebar/slice";

import { addNewConversationForJobActivity } from "@pages/messenger/ConversationList/service";

import { Attendee } from "@dto/roundtable";

import { useCollapseTable } from "./CollapseContext";
import { useSelectJob } from "./SelectJobContext";
import { JOB_STATUS } from "./constants";
import { TRowType } from "./types";

const useCommonEvent = () => {
  const dispatch = useAppDispatch();
  const { setJobSelected } = useSelectJob();
  const { toggleCollapse } = useCollapseTable();

  const onClickQuestRow = async (row: TRowType, roundtableMembers: Attendee[]) => {
    if (
      [JOB_STATUS.IN_PROGRESS, JOB_STATUS.DONE, JOB_STATUS.DELIVERED].includes(
        row?.status
      )
    ) {
      await addNewConversationForJobActivity(row.id, roundtableMembers);
    }
    dispatch(sidebarActions.setRightBarOpen({ open: "quest" }));

    setJobSelected(row);
    toggleCollapse(true);
  };

  return {
    onClickQuestRow,
  };
};

export default useCommonEvent;
