/* eslint-disable indent */
import { FC, useCallback, useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";

import { applyToJoin } from "@api/roundtable/applyToJoin";
import useGetRoundtable from "@api/roundtable/useGetRoundtable";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";

import { useAppSelector } from "@app/hooks";

import EllipsisContent from "@components/EllipsisContent";
import CustomSnackbar, { ISnackbar } from "@components/Snackbar";

import { Attendee } from "@dto/roundtable";

import { useViewport } from "@contexts/ViewportContext";

import ApplyToJoinDialog, { ApplyToJoinForm } from "../ApplyToJoinDialog";
import MemberDetails from "./MemberDetails";
import Members from "./Members";
import RoundtableDetails from "./RoundtableDetails";
import Table from "./Table";

const labelStyles: SxProps = {
  fontSize: 18,
  fontWeight: 700,
};

interface IProps {
  roundtableId: string;
  open?: boolean;
  showApplyToJoinBtn?: boolean;
  onClose: () => void;
  displayAsProject?: boolean;
}

const RoundtableDetailsDialog: FC<IProps> = ({
  roundtableId,
  open = false,
  showApplyToJoinBtn = false,
  onClose,
  displayAsProject,
}) => {
  const { isMobile, isDesktop } = useViewport();
  const { userInfo } = useAppSelector((state) => state.auth);
  const {
    palette: { common },
  } = useTheme();

  const {
    data: roundtable,
    isError,
    error,
    isLoading,
  } = useGetRoundtable({ id: roundtableId });

  const [memberDetails, setMemberDetails] = useState<Attendee>(null);
  const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false });
  const [loadingApplyToJoin, setLoadingApplyToJoin] = useState<boolean>(false);
  const [openApplyToJoinDialog, setOpenApplyToJoinDialog] = useState<boolean>(false);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar({ open: false });
  }, []);

  useEffect(() => {
    if (isError && error instanceof Error) {
      setSnackbar({
        message: error.message,
        open: true,
        severity: "error",
      });
    }
  }, [isError, error]);

  useEffect(() => {
    const currentAttendee = roundtable?.attendees?.find(
      (a) => a.userId === userInfo?.user?.id
    );

    setMemberDetails(currentAttendee);
  }, [roundtable, userInfo?.user?.id]);

  const handleApplyToJoin = useCallback(
    async ({ reason }: ApplyToJoinForm) => {
      setLoadingApplyToJoin(true);
      const { success, message } = await applyToJoin({
        roundtableId,
        reason,
      }).finally(() => {
        setLoadingApplyToJoin(false);
      });

      if (!success) {
        setSnackbar({
          message,
          open: true,
          severity: "error",
        });
      } else {
        setSnackbar({
          message: "Submitted successfully!",
          open: true,
          severity: "success",
        });
        setOpenApplyToJoinDialog(false);
        onClose();
      }
    },
    [setOpenApplyToJoinDialog, roundtableId]
  );

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth="lg"
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            m: 1,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
            {roundtable?.title}
          </Typography> */}
          <EllipsisContent
            fontSize={20}
            lineHeight={1.5}
            fontWeight={700}
            numberOfLines={1}
            sx={{ wordBreak: "break-word", m: 0 }}
            content={roundtable?.title}
          />

          <CloseIcon
            sx={{
              width: 24,
              height: 24,
              color: "common.black",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </DialogTitle>

        {isLoading && (
          <DialogContent sx={{ backgroundColor: "common.mainBg" }}>
            <LinearProgress />
          </DialogContent>
        )}

        {!isLoading && roundtable && (
          <DialogContent sx={{ p: 2, backgroundColor: "common.white" }}>
            <Box sx={{ mt: 3, border: common.border, borderRadius: 3 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px={isDesktop ? 2 : 1}
                py={3}
              >
                <Typography variant="h5" sx={labelStyles}>
                  {displayAsProject ? "Project" : "Teamspace"} Details
                </Typography>

                {((showApplyToJoinBtn && !roundtable?.alreadyJoined) ||
                  (roundtable?.isPublic && !roundtable?.alreadyJoined)) && (
                  <Button
                    variant="contained"
                    startIcon={<AiOutlinePlusSquare />}
                    onClick={() => setOpenApplyToJoinDialog(true)}
                    sx={{
                      fontSize: isMobile ? 12 : 14,
                      p: isMobile ? "8px 12px" : "8px 16px",
                    }}
                  >
                    Apply To Join
                  </Button>
                )}
              </Box>
              <RoundtableDetails
                roundtable={roundtable}
                displayAsProject={displayAsProject}
              />
            </Box>

            {/* {roundtable && roundtable?.alreadyJoined ? (
              <Box sx={{ mt: 3, px: 2, py: 3, border: common.border, borderRadius: 3 }}>
                <Typography variant="h5" sx={labelStyles}>
                  Member Details
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <MemberDetails info={memberDetails} />
                </Box>
              </Box>
            ) : null} */}

            <Box sx={{ mt: 3, px: 3, py: 2, border: common.border, borderRadius: 3 }}>
              <Box sx={{ mb: 5 }}>
                <Typography variant="h5" sx={labelStyles}>
                  {displayAsProject ? "Project" : "Teamspace"} Members
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} md={7} id="rt-visual">
                  <Table
                    members={roundtable?.attendees}
                    roundtableAvatar={roundtable?.avatar}
                    centerTitle={roundtable?.title}
                    displayAsProject={displayAsProject}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Members
                    members={roundtable?.attendees}
                    managementStyle={roundtable?.managementStyle}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        )}
      </Dialog>

      <CustomSnackbar onClose={handleCloseSnackbar} {...snackbar} />

      {openApplyToJoinDialog && (
        <ApplyToJoinDialog
          loading={loadingApplyToJoin}
          onCancel={() => setOpenApplyToJoinDialog(false)}
          onApply={handleApplyToJoin}
        />
      )}
    </>
  );
};

export default RoundtableDetailsDialog;
