/* eslint-disable react/jsx-no-bind */
import { Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from "react";

import useExitParentRoundtable from "@api/roundtable/useExitParentRoundtable";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";

import { handleApiError } from "@features/config";

import { ISnackbar } from "@components/Snackbar";

import { Roundtable } from "@dto/roundtable";

import queryClient from "@config/queryClient";

const FieldWrapper: FC<{ label: string; row?: boolean; children: ReactNode }> = ({
  label,
  children,
  row,
}) => {
  return (
    <Stack gap={2} direction={row ? "row" : "column"}>
      <Typography fontSize={16} fontWeight={700}>
        {label}
      </Typography>
      {children}
    </Stack>
  );
};

interface IProps {
  isOpened: boolean;
  onClose: () => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  roundtable: Roundtable;
}

const ExitParentRoundtableDialog: FC<IProps> = ({
  isOpened,
  setSnackbar,
  onClose,
  roundtable,
}) => {
  const {
    palette: { primary, common },
  } = useTheme();

  const { mutate: exitParentRoundtable, isLoading: isLoadingExit } =
    useExitParentRoundtable();
  const [reason, setReason] = useState("");

  const representatives = useMemo(() => {
    return roundtable.attendees.filter((attendee) => !!attendee.userId) || [];
  }, [roundtable]);

  const handleSave = () => {
    exitParentRoundtable(
      {
        id: roundtable.id,
        reason,
      },
      {
        onSuccess: () => {
          setSnackbar({
            message: "Your proposal was created successfully",
            open: true,
            severity: "success",
          });

          queryClient.invalidateQueries(["getEventLogs"]);
        },
        onError: (error) => {
          const { message } = handleApiError(error);

          setSnackbar({
            message,
            open: true,
            severity: "error",
          });
        },
        onSettled: () => {
          onClose();
        },
      }
    );
  };

  const handleChangeReason = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setReason(value);
  };

  const isDisabledSaveBtn = isLoadingExit || !reason;

  return (
    <Dialog fullWidth open={isOpened} maxWidth="sm">
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontSize: 20, fontWeight: 700, borderBottom: common.border, mb: 3 }}
      >
        Exit Parent-Teamspace
      </DialogTitle>

      <DialogContent>
        <Stack gap={3}>
          <FieldWrapper label="Parent-Teamspace name" row>
            <Typography color={primary.main} fontWeight="bold">
              {roundtable.parent.title}
            </Typography>
          </FieldWrapper>
          <FieldWrapper label="Respentative" row>
            {representatives.map((representative, index) => {
              return (
                <Typography
                  key={`${index}-${
                    representative.userId ||
                    representative.roundTableId ||
                    representative.attendeeId
                  }-representative-section`}
                >
                  {representative.name}
                </Typography>
              );
            })}
          </FieldWrapper>
          <TextField
            fullWidth
            multiline
            rows={5}
            name="reason"
            label="Reason"
            value={reason}
            onChange={handleChangeReason}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <Button variant="contained" disabled={isDisabledSaveBtn} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExitParentRoundtableDialog;
