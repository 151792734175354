import { axios } from "@features/config";

type Response = {
  data: any;
};

type Request = {
  roundtableId: string,
  type: string,
  delegatee: string,
}

const voteDelegate = async ({ roundtableId, ...payload }: Request): Promise<Response> => {
  const response = await axios.post(`/round-tables/${roundtableId}/delegations`, payload);

  return response.data?.data;
};

export default voteDelegate;
