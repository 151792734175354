import React, { Dispatch, ReactNode, SetStateAction } from "react";

import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import classNames from "classnames";

interface CustomButtonProps {
  label: string;
  backgroundColor?: string;
  textColor?: string;
  variant?: ButtonProps["variant"];
  preventDialogClose?: boolean;
  handleOnClickButton?: () => void;
  isLoading?: boolean;
}

interface IProps {
  title: string;
  content?: ReactNode;
  buttons: CustomButtonProps[];
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
}

const CustomConfirmationDialog: React.FC<IProps> = ({
  title,
  content,
  buttons,
  openDialog,
  setOpenDialog,
  children,
}) => {
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOnClick = (
    func: (() => void) | undefined,
    preventDialogClose?: boolean
  ) => {
    func?.();

    if (!preventDialogClose) {
      handleClose();
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiPaper-rounded": { borderRadius: "12px" },
        "& .MuiDialog-paper": {
          minWidth: { md: 560 },
          minHeight: 100,
        },
      }}
      open={openDialog}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography fontWeight="bold">{title}</Typography>
      </DialogTitle>
      {content && (
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
      )}
      {children}
      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Box display="flex" gap={2}>
          {buttons.map((btn, index) => {
            const btnClass = classNames({
              [`${btn.backgroundColor}`]: btn.backgroundColor,
            });

            const textClass = classNames({
              [`${btn.textColor}`]: btn.textColor,
            });

            return (
              <div key={`${index}-dialog-btn`}>
                <Button
                  variant={btn.variant || "contained"}
                  sx={{
                    height: 40,
                    minWidth: 80,
                  }}
                  onClick={() =>
                    handleOnClick(btn.handleOnClickButton, btn.preventDialogClose)
                  }
                  className={btnClass}
                  disabled={!!btn.isLoading}
                >
                  <Typography fontSize={14} fontWeight="bold" className={textClass}>
                    {btn.label}
                  </Typography>
                </Button>
              </div>
            );
          })}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmationDialog;
