import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import useGetProposal from "@api/proposal/useGetProposal";
import useGetCurrentVoteWeight from "@api/vote/useGetCurrentVoteWeight";
import useSubmitVote from "@api/vote/useSubmitVote";
import { DATE_TIME_FORMAT } from "@constants/datetime";
import { NOT_APPLICABLE } from "@constants/index";
import {
  EVENT_HEADING_TITLE,
  EVENT_KEYS,
  getEventDescription,
} from "@constants/proposal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton, // LinearProgress,
  Radio,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";

import { useAppSelector } from "@app/hooks";

import { handleApiError } from "@features/config";

import UserDetailAvatarButton from "@components/Button/userAvatarButton";
import DeterminateProgress from "@components/DeterminateProgress";
import EditRoundtableDialog from "@components/Dialog/AddEditRoundtableDialog/EditRoundtableDialog";
import ViewCreateEventProposalDialog from "@components/Dialog/CreateEventProposalDialog";
import CreateUpdateProjectDialog from "@components/Dialog/CreateUpdateProjectDialog";
import PublishProjectDialog from "@components/Dialog/PublishProjectDialog";
import UpdateProjectProfileDialog from "@components/Dialog/UpdateProjectProfileDialog";
import ViewUpsertRoundtableDialog from "@components/Dialog/UpsertRoundtableDialog";
import ViewApplyToJoinDialog from "@components/Dialog/ViewApplyToJoinDialog";
import ViewBecomeSubRoundtableDialog from "@components/Dialog/ViewBecomeSubRoundtableDialog";
import ViewCloseProjectDialog from "@components/Dialog/ViewCloseProjectDialog";
import ViewExitParentRoundtableDialog from "@components/Dialog/ViewExitParentRoundtableDialog";
import ViewKickoutMemberDialog from "@components/Dialog/ViewKickoutMemberDialog";
import ViewPositionSettingsDialog from "@components/Dialog/ViewPositionSettingsDialog";
import ViewUpdatedSettingsDialog from "@components/Dialog/ViewUpdatedSettingsDialog";
import VoteHistoryDialog from "@components/Dialog/VoteHistoryDialog";

import { mappingProposalType } from "@utils/format/proposalType";
import { capitalizeFirstChar } from "@utils/helpers/stringHelper";
import { isProject, value2Percent } from "@utils/index";

import { KickoutEventProposalForm, ProposalStatus, ProposalType } from "@dto/proposal";
import { Attendee, ManagementEnum, Roundtable, VoteModeEnum } from "@dto/roundtable";

import { useSnackbar } from "@contexts/SnackbarContext";
import { useViewport } from "@contexts/ViewportContext";

import queryClient from "@config/queryClient";

interface EventDetailProps {
  proposalId: string;
  roundtableId: string;
  roundtable: Roundtable;
  currentAttendee?: Attendee;
  refetchRoundtable: () => void;
  onBack?: () => void;
}

const FirstVotingResult: FC<{ text: string }> = ({ text }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" columnGap={0.5}>
        <FactCheckOutlinedIcon sx={{ width: 14, height: 14 }} />
        <Typography fontSize={12} fontWeight={400} sx={{ opacity: "64%" }}>
          Result:
        </Typography>
      </Box>
      <Typography fontSize={12} fontWeight="bold">
        {text}
      </Typography>
    </Box>
  );
};

const EventDetails: FC<EventDetailProps> = ({
  proposalId,
  roundtableId,
  roundtable,
  currentAttendee,
  refetchRoundtable,
  onBack,
}) => {
  const { pathname } = useLocation();
  const {
    palette: { common, primary },
  } = useTheme();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { user } = userInfo;
  const { setSnackbar } = useSnackbar();
  const { isDesktop } = useViewport();

  const isRoundtable = !isProject(roundtable);
  const {
    data: proposal,
    isLoading: isLoadingProposal,
    isFetching: isFetchingProposal,
    handleChangeParams,
    refetch,
  } = useGetProposal({ roundtableId, proposalId });
  const { mutate: submitVote, isLoading: isLoadingVoteSubmission } = useSubmitVote();
  const { data: currentVoteWeightData, isFetched: isFetchCurrentVoteWeight } =
    useGetCurrentVoteWeight({ roundtableId });

  const currentVoteWeight = useMemo(() => {
    if (!isFetchCurrentVoteWeight || !proposal?.type || !currentVoteWeightData?.length) {
      return 0;
    }

    const currentProposalType = currentVoteWeightData?.find(
      (type) => type.proposalType === mappingProposalType(proposal.type)
    );
    // return 0
    return +currentProposalType?.currentVoteWeight ?? 0;
  }, [
    proposal?.type,
    currentVoteWeightData,
    isFetchCurrentVoteWeight,
    mappingProposalType,
  ]);

  const isDashboard = !pathname || pathname === "/";
  const votes = proposal?.voteOptions?.reduce(
    (a, { votes }) => a + (votes?.length || 0),
    0
  );

  const reasonInputRef = useRef<HTMLInputElement>(null);
  const [selectedOptionId, setSelectedOptionId] = useState("");
  const [openConfirmSubmitDialog, setOpenConfirmSubmitDialog] = useState(false);
  const [openViewUpdatedSettingsDialog, setOpenViewUpdatedSettingsDialog] =
    useState(false);
  const [openViewRoundtableMng, setToggleViewUpdatedRoundtableMng] = useState(false);
  const [openViewRoundtableProfile, setToggleViewUpdatedRoundtableProfile] =
    useState(false);
  const [openViewProjectProfileDialog, setOpenViewProjectProfileDialog] = useState(false);
  const [openViewProjectManagementDialog, setOpenViewProjectManagementDialog] =
    useState(false);
  const [openViewUpdatedPositionDialog, setOpenViewUpdatedPositionDialog] =
    useState(false);
  const [openViewApplyToJoinDialog, setOpenViewApplyToJoinDialog] = useState(false);
  const [openKickoutMemberDialog, setOpenViewKickoutMemberDialog] = useState(false);
  const [openViewBecomeSubRtDialog, setOpenViewBecomeSubRtDialog] = useState(false);
  const [openViewCreateProposalProject, setOpenViewCreateProposalProject] =
    useState(false);
  const [openViewUpdateRoundtable, setOpenViewUpdateRoundtable] = useState(false);
  const [openViewExitParentRoundtable, setOpenViewExitParentRoundtable] = useState(false);
  const [openViewCreateEventProposal, setOpenViewCreateEventProposal] = useState(false);
  const [openViewCloseProject, setOpenViewCloseProject] = useState(false);
  const [openViewVoteHistory, setOpenViewVoteHistory] = useState(false);
  const [openViewUpdatePublishProject, setOpenViewUpdatePublishProject] = useState(false);

  useEffect(() => {
    const selectedOption = proposal?.voteOptions?.find((o) =>
      o.votes.find((v) => v.userId === user?.id)
    );
    setSelectedOptionId(selectedOption?.id);
  }, [user?.id, proposal?.voteOptions]);

  useEffect(() => {
    handleChangeParams({ roundtableId, proposalId });
  }, [proposalId, roundtableId, handleChangeParams]);

  const handleOpenConfirmSubmitDialog = useCallback(() => {
    setOpenConfirmSubmitDialog(true);
  }, []);

  const handleCloseConfirmSubmitDialog = useCallback(() => {
    setOpenConfirmSubmitDialog(false);
  }, []);

  const handleOpenViewApplyToJoinDialog = useCallback(() => {
    setOpenViewApplyToJoinDialog(true);
  }, []);

  const handleCloseViewApplyToJoinDialog = useCallback(() => {
    setOpenViewApplyToJoinDialog(false);
  }, []);
  const handleOpenKickoutDialog = useCallback(() => {
    setOpenViewKickoutMemberDialog(true);
  }, []);

  const handleCloseKickoutDialog = useCallback(() => {
    setOpenViewKickoutMemberDialog(false);
  }, []);

  const handleOpenViewUpdatedSettingsDialog = useCallback(() => {
    setOpenViewUpdatedSettingsDialog(true);
  }, []);
  const handleToggleViewUpdatedRoundtableMngDialog = useCallback(() => {
    setToggleViewUpdatedRoundtableMng(!openViewRoundtableMng);
  }, [openViewRoundtableMng]);
  const handleToggleViewUpdatedRoundtableProfileDialog = useCallback(() => {
    setToggleViewUpdatedRoundtableProfile(!openViewRoundtableProfile);
  }, [openViewRoundtableProfile]);

  const handleCloseViewUpdatedSettingsDialog = useCallback(() => {
    setOpenViewUpdatedSettingsDialog(false);
  }, []);

  const handleOpenViewProjectProfileDialog = useCallback(() => {
    setOpenViewProjectProfileDialog(true);
  }, []);

  const handleCloseViewProjectProfileDialog = useCallback(() => {
    setOpenViewProjectProfileDialog(false);
  }, []);

  const handleOpenViewProjectManagementDialog = useCallback(() => {
    setOpenViewProjectManagementDialog(true);
  }, []);

  const handleCloseViewProjectManagementDialog = useCallback(() => {
    setOpenViewProjectManagementDialog(false);
  }, []);

  const handleOpenViewUpdatedPositionDialog = useCallback(() => {
    setOpenViewUpdatedPositionDialog(true);
  }, []);

  const handleCloseViewUpdatedPositionDialog = useCallback(() => {
    setOpenViewUpdatedPositionDialog(false);
  }, []);

  const handleOpenViewBecomeSubRTDialog = useCallback(() => {
    setOpenViewBecomeSubRtDialog(true);
  }, []);

  const handleCloseViewBecomeSubRTDialog = useCallback(() => {
    setOpenViewBecomeSubRtDialog(false);
  }, []);

  const handleOpenViewCreateProposalProject = useCallback(() => {
    setOpenViewCreateProposalProject(true);
  }, []);

  const handleCloseViewCreateProposalProject = useCallback(() => {
    setOpenViewCreateProposalProject(false);
  }, []);

  const handleOpenViewUpdateRoundtable = useCallback(() => {
    setOpenViewUpdateRoundtable(true);
  }, []);

  const handleCloseViewUpdateRoundtable = useCallback(() => {
    setOpenViewUpdateRoundtable(false);
  }, []);

  const handleOpenViewExitParentRoundtable = useCallback(() => {
    setOpenViewExitParentRoundtable(true);
  }, []);

  const handleCloseViewExitParentRoundtable = useCallback(() => {
    setOpenViewExitParentRoundtable(false);
  }, []);

  const handleToggleViewCreateEventProposal = useCallback(() => {
    setOpenViewCreateEventProposal(!openViewCreateEventProposal);
  }, [openViewCreateEventProposal]);

  const handleOpenViewCloseProjectDialog = () => {
    setOpenViewCloseProject(true);
  };

  const handleCloseViewCloseProjectDialog = () => {
    setOpenViewCloseProject(false);
  };

  const handleToggleViewUpdatePublishProjectDialog = useCallback(() => {
    setOpenViewUpdatePublishProject(!openViewUpdatePublishProject);
  }, [openViewUpdatePublishProject]);

  const handleConfirmSubmitVote = useCallback(async () => {
    setOpenConfirmSubmitDialog(false);

    submitVote(
      {
        roundTableId: roundtableId,
        proposalId,
        voteOptionId: selectedOptionId,
        reason: reasonInputRef?.current?.value || "",
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getEventLogs"]);
          queryClient.invalidateQueries(["getNotifications"]);
          queryClient.invalidateQueries(["getOngoingProposal"]);
          refetchRoundtable();
        },
        onError: (error) => {
          setSnackbar({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
        onSettled: () => {
          refetch();
        },
      }
    );
  }, [proposalId, roundtableId, selectedOptionId, refetch, submitVote]);

  const handleSelectOption = useCallback(
    (optionId: string) => () => {
      setSelectedOptionId(optionId);
    },
    []
  );

  const hasViewDetails = [
    ProposalType.APPLY_TO_JOIN,
    ProposalType.UPDATE_SETTINGS,
    ProposalType.UPDATE_ROUND_TABLE_MANAGEMENT,
    ProposalType.UPDATE_ROUND_TABLE_PROFILE,
    ProposalType.PROJECT_PROFILE,
    ProposalType.PROJECT_MANAGEMENT,
    ProposalType.REQUEST_VOTE_WEIGHT,
    ProposalType.SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT,
    ProposalType.BECOME_SUB_ROUND_TABLE,
    ProposalType.APPROVE_SUB_ROUND_TABLE,
    ProposalType.APPROVE_SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT,
    ProposalType.CREATE_PROPOSAL_PROJECT,
    ProposalType.UPDATE_REPRESENTATIVES,
    ProposalType.EVENT,
    ProposalType.APPLY_TO_JOIN_EXTERNAL,
    ProposalType.CLOSE_PROJECT,
    ProposalType.PUBLISH_PROJECT,
    ProposalType.KICKOUT_MEMBER,
  ].includes(proposal?.type);

  const getViewDetailsDialogFn = () => {
    switch (proposal?.type) {
      case ProposalType.APPLY_TO_JOIN:
        return handleOpenViewApplyToJoinDialog;
      case ProposalType.UPDATE_SETTINGS:
        return handleOpenViewUpdatedSettingsDialog;
      case ProposalType.UPDATE_ROUND_TABLE_MANAGEMENT:
        return handleToggleViewUpdatedRoundtableMngDialog;
      case ProposalType.UPDATE_ROUND_TABLE_PROFILE:
        return handleToggleViewUpdatedRoundtableProfileDialog;
      case ProposalType.PROJECT_PROFILE:
        return handleOpenViewProjectProfileDialog;
      case ProposalType.PROJECT_MANAGEMENT:
        return handleOpenViewProjectManagementDialog;
      case ProposalType.REQUEST_VOTE_WEIGHT:
      case ProposalType.APPROVE_SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT:
      case ProposalType.SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT:
        return handleOpenViewUpdatedPositionDialog;
      case ProposalType.BECOME_SUB_ROUND_TABLE:
        return handleOpenViewBecomeSubRTDialog;
      case ProposalType.APPROVE_SUB_ROUND_TABLE:
        return handleOpenViewBecomeSubRTDialog;
      case ProposalType.KICKOUT_MEMBER:
        return handleOpenKickoutDialog;
      case ProposalType.CREATE_PROPOSAL_PROJECT:
        return handleOpenViewCreateProposalProject;
      case ProposalType.UPDATE_REPRESENTATIVES:
        return handleOpenViewUpdateRoundtable;
      case ProposalType.EXIT_PARENT_ROUNDTABLE:
        return handleOpenViewExitParentRoundtable;
      case ProposalType.EVENT:
        return handleToggleViewCreateEventProposal;
      case ProposalType.APPLY_TO_JOIN_EXTERNAL:
        return handleOpenViewApplyToJoinDialog;
      case ProposalType.CLOSE_PROJECT:
        return handleOpenViewCloseProjectDialog;
      case ProposalType.PUBLISH_PROJECT:
        return handleToggleViewUpdatePublishProjectDialog;
    }
  };

  const highestTotalVoteWeight = useMemo(() => {
    if (proposal?.voteOptions) {
      return proposal.voteOptions.reduce((maxVoteWeight, option) => {
        const optionTotalVoteWeight = option.totalVoteWeight || 0;
        return Math.max(maxVoteWeight, optionTotalVoteWeight);
      }, 0);
    }
    return 0;
  }, [proposal?.voteOptions]);

  const currentVote = Number(value2Percent(votes, proposal?.maxVotes));

  const isPollTraditional = !!(
    proposal?.type === ProposalType.POLL &&
    roundtable?.managementStyle === ManagementEnum.TRADITIONAL
  );
  const isVoteDisabled = useMemo(() => {
    return proposal?.isVoted ||
      proposal?.status === ProposalStatus.COMPLETED ||
      isPollTraditional
      ? false
      : !(roundtable?.voteMode === VoteModeEnum.SIMPLE_VOTE
          ? currentAttendee?.isSubmittable
          : +currentAttendee?.voteWeight > 0 || currentVoteWeight > 0);
  }, [
    proposal?.isVoted,
    proposal?.status,
    roundtable?.voteMode,
    currentAttendee?.isSubmittable,
    currentAttendee?.voteWeight,
    currentVoteWeight,
  ]);

  const [firstResult, ...results] = useMemo(() => {
    return proposal?.result?.split("\n") ?? [];
  }, [proposal?.result]);

  if (isLoadingProposal || isFetchingProposal || isLoadingVoteSubmission) {
    return (
      <Box pt={isDashboard ? 0 : 4} px={2}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </Box>
    );
  }

  return proposal ? (
    <Stack bgcolor={common.deutziaWhite} borderRadius={2}>
      {isDashboard && (
        <Box
          display="flex"
          alignItems="center"
          borderBottom={common.border}
          gap={1}
          p="24px 16px"
        >
          <Avatar src={roundtable.avatar} />
          <Stack gap={0.5}>
            <Typography fontWeight={700} fontSize={16}>
              {roundtable.title}
            </Typography>
            <Typography fontSize={12} color={common.doverGrey}>
              {roundtable.attendees?.length || 0} members
            </Typography>
          </Stack>
        </Box>
      )}
      <Stack
        rowGap={2}
        pt={3}
        pl={2}
        pr={2}
        borderTop={!!onBack && !isDashboard && !isDesktop ? common.border : "none"}
      >
        <Box display="flex" alignItems="center" columnGap={1}>
          {!!onBack && !isDashboard && !isDesktop && (
            <IconButton onClick={() => onBack()}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Box display="flex" columnGap={1}>
            <Typography fontWeight="bold">
              {EVENT_HEADING_TITLE[proposal.type as EVENT_KEYS]}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" columnGap={1}>
          <UserDetailAvatarButton
            src={proposal.createdByUser?.avatar}
            userId={proposal.createdByUser?.id}
          />
          <Stack justifyContent={"center"}>
            <Typography fontWeight="bold" fontSize={14}>
              {proposal.createdByUser?.name}
            </Typography>
            {/* <Typography
              fontSize={10}
              fontWeight={400}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {proposal.createdByUser.email}
            </Typography> */}
          </Stack>
        </Box>

        <Typography fontSize={14} sx={{ opacity: "64%" }}>
          {capitalizeFirstChar(
            getEventDescription(
              proposal.type,
              (proposal.data as KickoutEventProposalForm)?.user?.name,
              isRoundtable
            )
          )}
        </Typography>

        {hasViewDetails && (
          <Button
            onClick={getViewDetailsDialogFn()}
            sx={{
              fontSize: 12,
              fontWeight: 600,
              width: "fit-content",
              p: 0,
            }}
          >
            View Details
          </Button>
        )}

        <Divider />

        {proposal.type === ProposalType.POLL && (
          <Typography fontSize={14}>{proposal.question}</Typography>
        )}

        {proposal.voteOptions?.map(({ id, label, totalVoteWeight }) => {
          return (
            <Stack key={id} rowGap={1} position="relative">
              <Box
                onClick={isVoteDisabled ? () => null : handleSelectOption(id)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: proposal.isVoted ? "transparent" : common.white,
                  p: proposal?.isVoted ? 0 : 1.5,
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <Radio
                  checked={selectedOptionId === id}
                  value={id}
                  name="radio-buttons"
                  // disabled={isVoteDisabled}
                  sx={{ p: 0 }}
                />
                <Typography sx={{ ml: 1, fontSize: 14, fontWeight: 400 }}>
                  {label}
                </Typography>
              </Box>

              {selectedOptionId === id &&
                !proposal.isVoted &&
                proposal.status !== ProposalStatus.COMPLETED && (
                  <TextField
                    inputRef={reasonInputRef}
                    multiline
                    rows={4}
                    size="small"
                    placeholder="Note"
                    sx={{
                      backgroundColor: common.white,
                      "& fieldset": { border: "none", borderRadius: 2 },
                    }}
                  />
                )}
              {(proposal.isVoted || proposal.status === ProposalStatus.COMPLETED) && (
                // <>
                //   <Box color={common.black}>
                //     <LinearProgress
                //       variant="determinate"
                //       value={
                //         (proposal.resultDetail?.find((_) => _.vote_option_id === id)
                //           ?.vote_weight ?? 0) * 100
                //       }
                //       sx={{
                //         height: 40,
                //         width: "100%",
                //         borderRadius: 2,
                //         "&.MuiLinearProgress-root::before": {
                //           backgroundColor: common.wolfram,
                //         },
                //       }}
                //       color={
                //         totalVoteWeight === highestTotalVoteWeight &&
                //         totalVoteWeight !== 0 &&
                //         highestTotalVoteWeight !== 0
                //           ? "primary"
                //           : "inherit"
                //       }
                //     />
                //   </Box>

                //   <Typography
                //     sx={{
                //       position: "absolute",
                //       bottom: 0,
                //       right: 0,
                //       transform: "translate(-50%, -50%)",
                //       color: common.black,
                //       fontSize: 12,
                //       fontWeight: 600,
                //       zIndex: 1,
                //     }}
                //   >
                //     {`${(totalVoteWeight * 100).toFixed(2)}%`}
                //   </Typography>
                // </>
                <DeterminateProgress
                  height={44}
                  borderRadius={2}
                  value={Number((totalVoteWeight * 100).toFixed(2))}
                  valueLabel={`${parseFloat((totalVoteWeight * 100).toFixed(2))}%`}
                  progressStyle="normal"
                  progressColor={
                    totalVoteWeight === highestTotalVoteWeight &&
                    totalVoteWeight !== 0 &&
                    highestTotalVoteWeight !== 0
                      ? primary.main
                      : common.wolfram
                  }
                  bgcolor={"transparent"}
                />
              )}
            </Stack>
          );
        })}

        {!proposal.isVoted &&
          !proposal.result &&
          proposal.status !== ProposalStatus.COMPLETED && (
            <Button
              variant="contained"
              disabled={!selectedOptionId || isLoadingProposal || isLoadingVoteSubmission}
              onClick={handleOpenConfirmSubmitDialog}
              sx={{ borderRadius: "8px" }}
            >
              Submit
            </Button>
          )}

        {proposal.status === ProposalStatus.COMPLETED &&
          !isLoadingProposal &&
          !isLoadingVoteSubmission && (
            <Button
              variant="outlined"
              onClick={() => setOpenViewVoteHistory(true)}
              sx={{ borderRadius: "8px" }}
            >
              View vote history
            </Button>
          )}

        <Divider />
        {/* <Box color="#0000000F">
          <LinearProgress
            variant="determinate"
            value={currentVote}
            sx={{
              height: 8,
              borderRadius: 2,
              "&.MuiLinearProgress-root": {
                backgroundColor: "#0000000F",
              },
            }}
            color={isNaN(currentVote) ? "inherit" : "primary"}
          />
        </Box> */}
        <DeterminateProgress value={currentVote} progressColor={primary.main} />
        <Stack>
          <Stack>
            <FirstVotingResult
              text={
                firstResult ??
                (proposal.status === ProposalStatus.COMPLETED ? "Failed" : NOT_APPLICABLE)
              }
            />
            {results?.map((result, index) => (
              <Typography
                key={`${index}-${result}`}
                fontSize={12}
                fontWeight="bold"
                textAlign="end"
              >
                {result}
              </Typography>
            ))}
          </Stack>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Stack rowGap={0.5}>
            <Box
              display="flex"
              justifyContent="space-between"
              rowGap={0.5}
              flexWrap="wrap"
            >
              <Box display="flex" alignItems="center" columnGap={0.5} width={80}>
                <CheckCircleOutlineIcon sx={{ width: 14, height: 14 }} />
                <Typography fontSize={12} fontWeight={400} sx={{ opacity: "64%" }}>
                  Voted by:
                </Typography>
              </Box>
              <Typography fontSize={12} fontWeight="bold">
                {votes}/{proposal.maxVotes}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              mb={1}
              flexWrap="wrap"
              rowGap={0.5}
            >
              <Box display="flex" alignItems="center" columnGap={0.5} width={80}>
                <InfoOutlinedIcon sx={{ width: 14, height: 14 }} />
                <Typography fontSize={12} fontWeight={400} sx={{ opacity: "64%" }}>
                  Expire by:
                </Typography>
              </Box>
              <Typography fontSize={12} fontWeight="bold">
                {proposal.endAt
                  ? dayjs(dayjs(proposal.endAt)).format(DATE_TIME_FORMAT)
                  : NOT_APPLICABLE}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>

      {openConfirmSubmitDialog && (
        <Dialog fullWidth open maxWidth="sm">
          <DialogTitle
            id="alert-dialog-title"
            sx={{ fontSize: 20, fontWeight: 700, textAlign: "center" }}
          >
            Confirm
          </DialogTitle>

          <DialogContent>
            <Typography>Are you sure?</Typography>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleCloseConfirmSubmitDialog}>
              Cancel
            </Button>

            <Button variant="contained" onClick={handleConfirmSubmitVote}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openViewUpdatedSettingsDialog && (
        <ViewUpdatedSettingsDialog
          isOpen={openViewUpdatedSettingsDialog}
          roundtable={roundtable}
          proposal={proposal}
          onClose={handleCloseViewUpdatedSettingsDialog}
        />
      )}

      {(openViewRoundtableMng || openViewRoundtableProfile) && (
        <ViewUpsertRoundtableDialog
          isOpen={openViewRoundtableMng || openViewRoundtableProfile}
          isViewOnly
          data={roundtable}
          proposal={proposal}
          onClose={
            openViewRoundtableMng
              ? () => handleToggleViewUpdatedRoundtableMngDialog()
              : () => handleToggleViewUpdatedRoundtableProfileDialog()
          }
          type={openViewRoundtableMng ? "ROUNDTABLE_MANAGEMENT" : "ROUNDTABLE_PROFILE"}
        />
      )}

      {openViewProjectProfileDialog && (
        <UpdateProjectProfileDialog
          id={roundtable.id}
          initData={proposal.data as any}
          isOpen
          onClose={handleCloseViewProjectProfileDialog}
          isViewOnly
          attendees={roundtable.attendees}
        />
      )}

      {openViewProjectManagementDialog && (
        <EditRoundtableDialog
          isViewOnly
          id={roundtable.id}
          isOpen={openViewProjectManagementDialog}
          initData={proposal.data as any}
          onClose={handleCloseViewProjectManagementDialog}
        />
      )}

      {openViewUpdatedPositionDialog && (
        <ViewPositionSettingsDialog
          isOpen
          proposal={proposal}
          onClose={handleCloseViewUpdatedPositionDialog}
        />
      )}

      {openViewApplyToJoinDialog && (
        <ViewApplyToJoinDialog
          isOpen={openViewApplyToJoinDialog}
          proposal={proposal}
          onClose={handleCloseViewApplyToJoinDialog}
        />
      )}

      {openKickoutMemberDialog && (
        <ViewKickoutMemberDialog
          isOpen={openKickoutMemberDialog}
          proposal={proposal}
          onClose={handleCloseKickoutDialog}
        />
      )}

      {(openViewBecomeSubRtDialog || openViewUpdateRoundtable) && (
        <ViewBecomeSubRoundtableDialog
          isOpen={openViewBecomeSubRtDialog || openViewUpdateRoundtable}
          proposal={proposal}
          roundtable={roundtable}
          onClose={() => {
            handleCloseViewBecomeSubRTDialog();
            handleCloseViewUpdateRoundtable();
          }}
        />
      )}

      {openViewExitParentRoundtable && (
        <ViewExitParentRoundtableDialog
          isOpen={openViewExitParentRoundtable}
          proposal={proposal}
          onClose={handleCloseViewExitParentRoundtable}
        />
      )}

      {openViewCloseProject && (
        <ViewCloseProjectDialog
          isOpen={openViewCloseProject}
          proposal={proposal}
          onClose={handleCloseViewCloseProjectDialog}
        />
      )}

      {openViewCreateProposalProject && (
        <CreateUpdateProjectDialog
          isOpen={openViewCreateProposalProject}
          isViewOnly
          project={roundtable}
          onClose={handleCloseViewCreateProposalProject}
          proposal={proposal}
          setSnackbar={setSnackbar}
        />
      )}

      <ViewCreateEventProposalDialog
        isViewOnly
        proposal={proposal}
        isOpen={openViewCreateEventProposal}
        onClose={handleToggleViewCreateEventProposal}
      />

      {openViewVoteHistory && (
        <VoteHistoryDialog
          onClose={() => setOpenViewVoteHistory(false)}
          proposal={proposal}
        />
      )}

      {openViewUpdatePublishProject && (
        <PublishProjectDialog
          isViewOnly
          isOpened={openViewUpdatePublishProject}
          projectId={roundtable.id}
          onClose={handleToggleViewUpdatePublishProjectDialog}
          setSnackbar={setSnackbar}
        />
      )}
    </Stack>
  ) : (
    <></>
  );
};

export default EventDetails;
