import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import useGetRoundtable from "@api/roundtable/useGetRoundtable";
import { MemberManagementType } from "@constants/attendee";
import { DATE_FORMAT } from "@constants/index";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import dayjs from "dayjs";

import { useAppSelector } from "@app/hooks";

import AppChip from "@components/AppChip";
import Button from "@components/Button";
import InviteMemberDialog from "@components/InviteMemberDialog";

import { renderAccountType } from "@utils/format";
import { isProject } from "@utils/roundtable.utils";

import { Attendee, ManagementEnum } from "@dto/roundtable";

import { useSnackbar } from "@contexts/SnackbarContext";
import { useViewport } from "@contexts/ViewportContext";

import MenuManagementList from "./MenuList";

export type LoadingType = "wave" | "circular";

interface PropsType {
  roundTableId: string;
}

const MeberList: React.FC<PropsType> = ({ roundTableId }) => {
  const { data: roundtable, isLoading } = useGetRoundtable({
    id: roundTableId,
  });

  const { isDesktop } = useViewport();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const { user } = useAppSelector((state) => state.auth.userInfo);
  const [type, setType] = useState();
  const [value, setValue] = useState("");
  const [openInviteMember, setOpenInviteMember] = useState(false);
  const isRoundtable = !isProject(roundtable);
  const currentUser = roundtable.attendees.find(
    (attendee) => attendee.userId === user.id
  );
  const freelancer = currentUser.isFreelancer;

  const responsiveSize = useMemo(() => {
    return {
      avatar: isDesktop ? 88 : 64,
      font12: isDesktop ? 12 : 10,
      font14: isDesktop ? 14 : 10,
      font16: isDesktop ? 16 : 14,
      chipSize: isDesktop ? 24 : 16,
      closeIcon: isDesktop ? 32 : 24,
      imageStyles: {
        width: isDesktop ? "24.5%" : "30%",
        aspectRatio: "16 / 9",
        objectFit: "cover",
        borderRadius: "4px",
      } as React.CSSProperties,
    };
  }, [isDesktop]);

  const handleSearch = useCallback(() => {
    const trimmedValue = value.trim();

    navigate(`/search/${type}?q=${trimmedValue}`);
    setValue(trimmedValue);
  }, [navigate, type, value]);

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setValue(value);
    },
    []
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // if (e.key === "Enter") {
      //   handleSearch();
      // }
    },
    [handleSearch]
  );

  return isLoading ? (
    <Box p={4} sx={{ width: "100%" }}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  ) : (
    <>
      <Stack direction={"row"} mb={3}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            border: (theme) => `1px solid ${theme.palette.common.bellflowerBlue}`,
            borderRadius: 1,
            backgroundColor: "common.white",
          }}
        >
          <InputBase
            placeholder="Search user..."
            value={value}
            onChange={handleValueChange}
            onKeyDown={handleKeyDown}
            sx={{ ml: 1, flex: 1 }}
          />
          <IconButton type="button" onClick={handleSearch} sx={{ p: 1.25 }}>
            <SearchIcon />
          </IconButton>
        </Box>
        {!freelancer && (
          <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                maxWidth: isDesktop ? 180 : 156,
                fontSize: isDesktop ? 14 : 12,
                border: "1px solid #E2E8F0",
                color: "common.gray7",
                bgcolor: "white",
                ":hover": { border: "1px solid #E2E8F0" },
              }}
              onClick={() => setOpenInviteMember(true)}
            >
              Invite member
            </Button>
          </Box>
        )}
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={700} variant="subtitle1">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={700} variant="subtitle1">
                  Join date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={700} variant="subtitle1">
                  Account type
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {roundtable.attendees?.length > 0 ? (
              roundtable.attendees
                ?.filter((member) => {
                  if (value.length) {
                    return (
                      member.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
                      member.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
                      member.title?.toLowerCase()?.includes(value?.toLowerCase())
                    );
                  }
                  return member;
                })
                .map((member) => {
                  return (
                    <TableRow
                      key={member.userId}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Stack direction={"row"} alignItems={"center"} gap={2}>
                          <Avatar src={member.avatar} />
                          <Stack direction={"column"}>
                            <Typography fontWeight={600} variant="subtitle2">
                              {member.name || member.title}
                            </Typography>
                            {member.email}
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>{dayjs(member.createdAt).format(DATE_FORMAT)}</TableCell>
                      <TableCell>
                        <Typography mb={1}>
                          {renderAccountType(member, roundtable.managementStyle)}{" "}
                        </Typography>
                        {member.isFreelancer && <AppChip label={"Freelancer"} />}
                      </TableCell>
                      <TableCell align="right">
                        <MenuManagementList
                          member={member}
                          roundtable={roundtable}
                          currentUser={currentUser}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <Typography fontSize={responsiveSize.font14}>No members</Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {openInviteMember && (
        <InviteMemberDialog
          isOpen={openInviteMember}
          onClose={() => setOpenInviteMember(false)}
          roundtableId={roundTableId}
          isRoundtable={isRoundtable}
          setSnackbar={setSnackbar}
          fetchEventLogs={() => console.log("fetchEventLogs placeholder")}
        />
      )}
    </>
  );
};

export default MeberList;
