import { FC, ReactNode } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Avatar,
  Alert,
  Stack,
} from "@mui/material";
import dayjs from "dayjs";

import { ExitParentRoundtableForm, Proposal } from "@dto/proposal";

interface IProps {
  isOpen: boolean;
  proposal: Proposal;
  onClose: () => void;
}

const FieldWrapper: FC<{ label: string; row?: boolean; children: ReactNode }> = ({
  label,
  children,
  row,
}) => {
  return (
    <Stack gap={2} direction={row ? "row" : "column"}>
      <Typography fontSize={16} fontWeight={700}>
        {label}
      </Typography>
      {children}
    </Stack>
  );
};

const ViewExitParentRoundtableDialog: FC<IProps> = ({ isOpen, proposal, onClose }) => {
  const { endAt, startAt, createdByUser } = proposal;
  const endDate = dayjs(endAt);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
          Exit Parent-Teamspace (View Only)
        </Typography>

        <CloseIcon
          sx={{
            width: 24,
            height: 24,
            color: "common.black",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </DialogTitle>

      <DialogContent>
        <Alert
          severity="info"
          icon={<InfoIcon fontSize="inherit" />}
          sx={{ alignItems: "center", color: "common.black", px: { xs: 1.5, sm: 2 } }}
        >
          <Typography sx={{ fontSize: 14 }}>
            The Teamspace is under approval. Expired by:{" "}
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              {dayjs.duration(endDate.diff(dayjs())).humanize(true)}
            </Typography>
          </Typography>

          <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              Last edited by:
            </Typography>

            <Stack direction="row" spacing={0.5} alignItems="center" mx={1}>
              <Avatar
                alt="Avatar"
                src={createdByUser?.avatar}
                sx={{ width: 20, height: 20 }}
              />

              <Typography
                component="span"
                sx={{ fontSize: 14, fontWeight: 600, color: "common.partyTime" }}
              >
                {createdByUser?.name}
              </Typography>
            </Stack>

            <Typography component="span" sx={{ fontSize: 14 }}>
              {dayjs(startAt).format("hh:mm A, YYYY MMM DD")}
            </Typography>
          </Stack>
        </Alert>

        <Stack rowGap={2} mt={2}>
          <FieldWrapper label="Parent-Teamspace name" row>
            {proposal?.parentRoundTableTitle}
          </FieldWrapper>
          <FieldWrapper label="Parent-Teamspace name" row>
            {proposal?.representatives.map((representative, index) => (
              <Typography key={`${index}-representative-in-view-detail`}>
                {representative.name}
              </Typography>
            ))}
          </FieldWrapper>
          <TextField
            disabled
            fullWidth
            multiline
            rows={5}
            label="Reason"
            defaultValue={(proposal?.data as ExitParentRoundtableForm)?.reason}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ pr: 2.5 }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewExitParentRoundtableDialog;
