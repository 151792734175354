import { ChangeEvent, useEffect, useState } from "react";

import useUpdateVirtualValues from "@api/admin/useUpdateVirtualValues";
import useGetPublishedProjects from "@api/roundtable/useGetPublishedProjects";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  KeyboardArrowLeft as BackIcon,
} from "@mui/icons-material";
import {
  IconButton,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Popover,
  debounce,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";

import LoadingButton from "@components/Button";
import Button from "@components/Button";
import Loading from "@components/Loading";

import { PublishedProject } from "@dto/publishedProject";

import { useSnackbar } from "@contexts/SnackbarContext";

const headCells: readonly any[] = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "project",
    label: "Project name",
  },
  {
    id: "rtb",
    label: "Teamspace name",
  },
  // {
  //   id: "published_at",
  //   label: "Published date",
  // },
  {
    id: "virtualLike",
    label: "Virtual like",
  },
  {
    id: "virtualMember",
    label: "Virtual member",
  },
  {
    id: "action",
    label: "Action",
  },
];

const Action = ({
  project,
  refetch,
}: {
  project: PublishedProject;
  refetch?: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedOpt, setSelectedOpt] = useState("");
  const [virtualValues, setVirtualValue] = useState({ virtualLike: 0, virtualMember: 0 });
  const { setSnackbar } = useSnackbar();

  const { mutate: updateVirtualValue, isLoading: isLoadingUpdateVirtualValue } =
    useUpdateVirtualValues();

  useEffect(() => {
    setVirtualValue({
      virtualLike: project.virtualLike,
      virtualMember: project.virtualMember,
    });
  }, [project]);

  const optionContents = {
    update_virtual_value: "update_virtual_value",
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setVirtualValue({
      virtualLike: project.virtualLike,
      virtualMember: project.virtualMember,
    });
  };

  const onSave = () => {
    updateVirtualValue(
      { payload: { ...virtualValues, roundtableId: project.id } },
      {
        onSuccess: () => {
          refetch?.();
          setSnackbar({
            message: "Update successfully.",
            open: true,
            severity: "success",
          });
          handleClose();
        },
        onError: () => {
          setSnackbar({
            message: "Update error.",
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  const open = !!anchorEl;

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!selectedOpt && (
          <Button
            variant="text"
            sx={{ color: "black", fontWeight: "normal" }}
            onClick={() => setSelectedOpt(optionContents.update_virtual_value)}
          >
            Update virtual values
          </Button>
        )}
        {selectedOpt && (
          <Stack mx={2} my={1.2} gap={2}>
            <IconButton sx={{ width: "fit-content" }} onClick={() => setSelectedOpt("")}>
              <BackIcon />
            </IconButton>
            <TextField
              size="small"
              label="Virtual likes"
              disabled={isLoadingUpdateVirtualValue}
              value={virtualValues.virtualLike}
              onChange={(e) =>
                setVirtualValue({ ...virtualValues, virtualLike: Number(e.target.value) })
              }
            />
            <TextField
              size="small"
              label="Virtual members"
              disabled={isLoadingUpdateVirtualValue}
              value={virtualValues.virtualMember}
              onChange={(e) =>
                setVirtualValue({
                  ...virtualValues,
                  virtualMember: Number(e.target.value),
                })
              }
            />
            <LoadingButton
              sx={{ mb: 1 }}
              loading={isLoadingUpdateVirtualValue}
              disabled={isLoadingUpdateVirtualValue}
              onClick={onSave}
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </Popover>
    </>
  );
};

const AllProjects: any = () => {
  const {
    data: projects,
    isLoading,
    params,
    totalItems,
    refetch,
    handleChangeParams,
  } = useGetPublishedProjects({
    page: 0,
    pageSize: 10,
    orderByTime: "DESC",
    title: "",
  });

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");

  const handleSearchUser = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const title = event.target?.value?.trim() || "";

    handleChangeParams({
      title,
      page: 0,
      pageSize: params.pageSize,
    });
  }, 300);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property?: string) => {
    let isAsc = orderBy === property && order === "asc";
    let propertyName = property;
    if (!property) {
      isAsc = true;
      propertyName = "virtualLike";
    }
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(propertyName);

    handleChangeParams({
      title: params.title,
      page: 0,
      order: {
        field: propertyName,
        by: isAsc ? "desc" : "asc",
      },
      pageSize: params.pageSize,
    });
  };

  useEffect(() => {
    if (!orderBy) {
      handleRequestSort(null);
    }
  }, [orderBy]);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handleChangeParams({
      page: newPage,
    });
  };

  return (
    <Stack>
      <Stack>
        <TextField
          sx={{
            width: 400,
            "& .MuiInputBase-input": {
              height: "48px",
              borderRadius: "8px",
              padding: "0 16px",
              fontSize: "14px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="Search user ..."
          onChange={handleSearchUser}
        />
      </Stack>
      <TableContainer sx={{ maxWidth: "100%" }}>
        <Table sx={{ overflow: "auto" }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell key={`Table-head-${index}`} align="left">
                  <Typography fontWeight="bold" fontSize="12px">
                    {headCell.label}
                    {["virtualLike", "virtualMember"].includes(headCell.id) && (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={(e) => handleRequestSort(e, headCell.id)}
                      >
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              projects.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`project-title-${index}`}
                    sx={{
                      "& .MuiTableCell-root": {
                        fontSize: "14px",
                        paddingTop: 2,
                        paddingBottom: 2,
                      },
                    }}
                  >
                    <TableCell align="left">{row.shortId}</TableCell>
                    <TableCell align="left" sx={{ maxWidth: 340, fontWeight: "bold" }}>
                      {row.title}
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: 340 }}>
                      {row.roundTableName}
                    </TableCell>
                    {/* <TableCell align="left" sx={{ minWidth: 132 }}>
                      {row.publishedAt
                        ? dayjs(new Date(row.publishedAt))?.format("YYYY, MMM DD")
                        : "-/-"}
                    </TableCell> */}
                    <TableCell align="left">{row.virtualLike}</TableCell>
                    <TableCell align="left">{row.virtualMember}</TableCell>
                    <TableCell>
                      <Action project={row} refetch={refetch} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && (
        <Stack mt={2}>
          <Loading />
        </Stack>
      )}

      <TablePagination
        // rowsPerPageOptions={[10, 20, 30, 40]}
        rowsPerPageOptions={[]}
        component="div"
        count={totalItems}
        rowsPerPage={params.pageSize}
        page={params.page}
        onPageChange={handleChangePage}
        sx={{ visibility: isLoading && "hidden" }}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

export default AllProjects;
