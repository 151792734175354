import { FC } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

import CloseIcon from "@mui/icons-material/Close";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  SxProps,
  TextField,
  Avatar,
  Alert,
  Stack,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";

import { isProject } from "@utils/roundtable.utils";

import { Proposal } from "@dto/proposal";

const titleSxProps: SxProps = { fontSize: 16, fontWeight: 700, mb: 1 };
const helperTextSxProps: SxProps = {
  "& .MuiFormHelperText-root": {
    mx: 0,
    mt: 1,
  },
};

interface IProps {
  isOpen: boolean;
  proposal: Proposal;
  onClose: () => void;
}

const ViewApplyToJoinDialog: FC<IProps> = ({ isOpen, proposal, onClose }) => {
  const { endAt, startAt, createdByUser } = proposal;
  const endDate = dayjs(endAt);

  const isRoundtable = !isProject(proposal.roundTable);

  const onDownloadFile = (fileUrl: string) => {
    const win = window.open(fileUrl, "_blank");
    win.focus();
  };
  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
          Apply To Join (View Only)
        </Typography>

        <CloseIcon
          sx={{
            width: 24,
            height: 24,
            color: "common.black",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </DialogTitle>

      <DialogContent>
        <Alert
          severity="info"
          icon={<InfoIcon fontSize="inherit" />}
          sx={{ alignItems: "center", color: "common.black", px: { xs: 1.5, sm: 2 } }}
        >
          <Typography sx={{ fontSize: 14 }}>
            The {`${isRoundtable ? "Teamspace" : "project"}`} is under approval. Expired
            by:{" "}
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              {dayjs.duration(endDate.diff(dayjs())).humanize(true)}
            </Typography>
          </Typography>

          <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              Last edited by:
            </Typography>

            <Stack direction="row" spacing={0.5} alignItems="center" mx={1}>
              <Avatar
                alt="Avatar"
                src={createdByUser?.avatar}
                sx={{ width: 20, height: 20 }}
              />

              <Typography
                component="span"
                sx={{ fontSize: 14, fontWeight: 600, color: "common.partyTime" }}
              >
                {createdByUser?.name}
              </Typography>
            </Stack>

            <Typography component="span" sx={{ fontSize: 14 }}>
              {dayjs(startAt).format("hh:mm A, YYYY MMM DD")}
            </Typography>
          </Stack>
        </Alert>

        <Box mt={1}>
          <Typography variant="h6" sx={titleSxProps}>
            Reason
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={5}
                fullWidth
                disabled
                size="small"
                value={proposal?.joinRequest?.reason ?? "There is no reason."}
                sx={helperTextSxProps}
                InputProps={{
                  style: { overflowY: "scroll" },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {proposal?.joinRequest?.attachment && (
          <Box mt={1}>
            <Typography variant="h6" sx={titleSxProps}>
              Attachments
            </Typography>
            <Grid container>
              {proposal?.joinRequest?.attachment.map((file, index) => (
                <Grid item key={index} xs={6} md={3} p={0.5}>
                  <Box
                    sx={{ position: "relative", cursor: "pointer" }}
                    overflow={"hidden"}
                    onClick={() => onDownloadFile(file.url)}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 10,
                      }}
                      color="default"
                    >
                      <FileDownloadIcon />
                    </IconButton>
                    <Avatar
                      variant="rounded"
                      src={!file.type.startsWith("data:application/") && file.name}
                      sx={{ width: "100%", height: 120 }}
                    >
                      <DocumentScannerIcon fontSize="large" />
                    </Avatar>
                    <Typography
                      sx={{
                        ...titleSxProps,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      variant="subtitle2"
                    >
                      {file.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ pr: 2.5 }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewApplyToJoinDialog;
