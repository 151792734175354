import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

import { UpdatePositionForm } from "@components/Dialog/UpdatePositionDialog";

import { convertPercentageToBetween0And1 } from "@utils/numberHelper";

type Request = {
  id: string;
  payload: UpdatePositionForm;
};

const requestVoteWeight = async ({ id, payload }: Request) => {
  const response = await axios.post(`/round-tables/${id}/request-vote-weight`, {
    ...payload,
    voteWeight: convertPercentageToBetween0And1(payload.voteWeight),
  });

  return response.data.data;
};

const useRequestVoteWeight = () => {
  return useMutation(requestVoteWeight);
};

export default useRequestVoteWeight;
