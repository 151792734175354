import { useEffect } from "react";

import { PRESENCE_REALTIME, UNREAD_REALTIME } from "@fb/const";
import { authInstance, realtimeDb } from "@fb/db";
import {
  ref,
  onValue,
  onDisconnect,
  set,
  serverTimestamp,
  get,
  child,
} from "firebase/database";

import { useAppSelector } from "@app/hooks";

const usePresence = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const userId = userInfo?.user.id;
  const updateDisconnectActiveProject = async () => {
    const unreadUserStatusDatabaseRef = ref(realtimeDb, `${UNREAD_REALTIME}/${userId}`);
    const unreadUser = await get(unreadUserStatusDatabaseRef);

    const unreadData = unreadUser?.val() || {};
    const inactiveProjectData = {
      ...unreadData,
      active: "",
    };
    console.log("updateDisconnectActiveProject", inactiveProjectData);
    // onDisconnect(unreadUserStatusDatabaseRef).update(inactiveProjectData);
  };
  useEffect(() => {
    const unsubscribeAuth = authInstance.onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid;

        const connectedRef = ref(realtimeDb, ".info/connected");
        onValue(connectedRef, (snapshot) => {
          if (snapshot.val() === false) {
            return;
          }

          // update active status project
          updateDisconnectActiveProject();

          // update realtime precense
          const userStatusDatabaseRef = ref(realtimeDb, `${PRESENCE_REALTIME}/${userId}`);
          const isOfflineForDatabase = {
            state: "offline",
            last_changed: serverTimestamp(),
          };

          const isOnlineForDatabase = {
            state: "online",
            last_changed: serverTimestamp(),
          };

          onDisconnect(userStatusDatabaseRef)
            .set(isOfflineForDatabase)
            .then(() => {
              set(userStatusDatabaseRef, isOnlineForDatabase);
            });
        });
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, [userInfo]);
};

export default usePresence;
