import { forwardRef, useImperativeHandle, useMemo, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { EventLogRequest, useGetEventLogs } from "@api/event-logs/useGetEventLogs";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ProposalStatus } from "@dto/proposal";
import { Attendee, Roundtable } from "@dto/roundtable";

import { useViewport } from "@contexts/ViewportContext";

import EventDetails from "../EventDetails";
import LogItem from "./LogItem";
import formatLogs from "./formatLogs";

const DEFAULT_LOG_STATUS = ProposalStatus.ONGOING;

interface EventLogsProps {
  onClick: (id: string) => void;
  roundtable: Roundtable;
  currentAttendee: Attendee;
  refetchRoundtable: () => void;
}

interface FilterButtonProps {
  label: string;
  value: ProposalStatus;
  isSelected?: boolean;
  handleChangeParams: (params: Partial<EventLogRequest>) => void;
  selectedStatus?: ProposalStatus;
}

const FilterButton = ({
  label,
  value,
  isSelected,
  handleChangeParams,
  selectedStatus,
}: FilterButtonProps) => {
  const _onClick = () => {
    if (value !== selectedStatus) {
      handleChangeParams({ status: value });
    }
  };

  const selectedItemColor = isSelected ? "common.partyTime" : "common.featherSoftBlue";

  return (
    <Button
      variant="outlined"
      sx={{
        height: 20,
        borderRadius: "16px",
        color: selectedItemColor,
        borderColor: selectedItemColor,
        fontSize: 14,
        fontWeight: 500,
        textTransform: "none",
      }}
      onClick={_onClick}
    >
      {mappingProposalStatus(label as ProposalStatus)}
    </Button>
  );
};

type EventLogRef = {
  refetchEventLog: () => void;
};

const EventLogs = forwardRef<EventLogRef, EventLogsProps>(
  ({ onClick, roundtable, currentAttendee, refetchRoundtable }, ref) => {
    const {
      palette: { primary, common },
    } = useTheme();
    const { isMobile, isDesktop } = useViewport();
    const isUnder1280 = useMediaQuery("(max-width:1280px)");
    const isDesktopUnder1280 = isDesktop && isUnder1280;

    const { roundtableId, projectId } = useParams();
    const conversationId = projectId ? projectId : roundtableId;
    const [searchParams, setSearchParams] = useSearchParams();

    const selectedProposalId = searchParams.get("proposal");

    const {
      data: events,
      isLoading,
      params,
      handleChangeParams,
      refetch,
    } = useGetEventLogs({ roundtableId: conversationId, status: DEFAULT_LOG_STATUS });

    useImperativeHandle(
      ref,
      () => ({
        refetchEventLog: () => refetch(),
      }),
      [roundtableId]
    );

    useEffect(() => {
      handleChangeParams({ roundtableId: conversationId });
    }, [conversationId]);

    const logs = useMemo(() => {
      return formatLogs(events || [], !projectId);
    }, [events]);

    return (
      <Stack rowGap={1} sx={{ overflowX: "auto" }}>
        <Box
          display="flex"
          pl={3}
          pr={3}
          pt={1}
          pb={1}
          alignItems="center"
          columnGap={1}
          height={40}
        >
          {!isDesktopUnder1280 && (
            <FilterListIcon sx={{ color: "common.featherSoftBlue" }} />
          )}
          <Select
            defaultValue={DEFAULT_LOG_STATUS}
            sx={{
              height: 24,
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              "fieldset": {
                borderColor: primary.main,
              },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: primary.main,
              },
            }}
            renderValue={(selectedStatus) => (
              <Typography fontSize={14} textAlign="center" color={primary.main}>
                {mappingProposalStatus(selectedStatus)}
              </Typography>
            )}
          >
            {[
              ProposalStatus.ONGOING,
              ProposalStatus.COMPLETED,
              ProposalStatus.UNVOTABLE,
              ProposalStatus.ALL,
            ].map((value, index) => (
              <MenuItem key={`${index}-${value}`} value={value}>
                <FilterButton
                  value={value}
                  label={value}
                  isSelected={params.status === value}
                  handleChangeParams={handleChangeParams}
                  selectedStatus={params.status}
                />
              </MenuItem>
            ))}
          </Select>
        </Box>

        {selectedProposalId && !isDesktop ? (
          <EventDetails
            proposalId={selectedProposalId}
            roundtableId={conversationId}
            roundtable={roundtable}
            currentAttendee={currentAttendee}
            refetchRoundtable={refetchRoundtable}
            onBack={() => setSearchParams({ proposal: "" })}
          />
        ) : (
          <Box sx={{ overflowX: "scroll" }}>
            {isLoading ? (
              <Skeleton width="100%" height={65} />
            ) : logs.length ? (
              logs.map((log) => (
                <LogItem
                  key={log.id}
                  log={log}
                  active={log.proposal?.id === selectedProposalId}
                  onClick={onClick}
                />
              ))
            ) : (
              <Box>
                <Divider />
                <Typography fontSize={12} pt={1} sx={{ textAlign: "center" }}>
                  No events.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Stack>
    );
  }
);

const mappingProposalStatus = (
  status: (typeof ProposalStatus)[keyof typeof ProposalStatus]
) => {
  switch (status) {
    case ProposalStatus.ONGOING:
      return "On-going";
    case ProposalStatus.COMPLETED:
      return "Completed";
    case ProposalStatus.UNVOTABLE:
      return "Unvotable";
    default:
      return "All";
  }
};

EventLogs.displayName = "EventLogs";

export default EventLogs;
