import { Attendee } from "@dto/roundtable";

interface IFile {
  name: string;
  url: string;
  size?: number;
  type?: string;
}

export type MessageType = {
  id: string;
  type?: string;
  title: string;
  text?: string;
  date: number;
  avatar: string;
  fileUrls?: IFile[];
  imageUrls?: IFile[];
  position?: "left" | "right";
  representatives?: Attendee[];
  userId: string;
  groupDate?: string;
};

export enum Missed_Message_Type {
  MESSAGE = "message",
  QUEST = "quest",
}
