import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetAttachmentsChat } from "@api/chat/useGetAttachmentsChat";
import { Box, Button, Skeleton, Stack } from "@mui/material";

import ViewImageDialog from "@components/Dialog/ViewImageDialog";

import AttachmentItem from "./AttachmentItem";

type DocFileType = {
  name: string;
  size: number;
  type: string;
  url: string;
};

enum AttachmentType {
  MEDIA = "MEDIA",
  FILE = "FILE",
}

interface FilterButtonProps {
  label: string;
  value: AttachmentType;
  handleChangeParams: (value: AttachmentType) => void;
  selectedStatus?: AttachmentType;
}

const FilterButton = ({
  label,
  value,
  handleChangeParams,
  selectedStatus,
}: FilterButtonProps) => {
  const _onClick = () => {
    if (value !== selectedStatus) {
      handleChangeParams(value);
    }
  };

  const selectedItemColor =
    selectedStatus === value ? "common.partyTime" : "common.featherSoftBlue";

  return (
    <Button
      variant="outlined"
      sx={{
        height: 20,
        borderRadius: "16px",
        color: selectedItemColor,
        borderColor: selectedItemColor,
        fontSize: 14,
        fontWeight: 500,
        textTransform: "none",
      }}
      onClick={_onClick}
    >
      {mappingAttachmentLabel(label as AttachmentType)}
    </Button>
  );
};

const attachmentType = [
  { value: "MEDIA", label: "MEDIA" },
  { value: "FILE", label: "FILE" },
];
const AttachmentsChat = () => {
  const { roundtableId, projectId } = useParams();
  const conversationId = projectId ? projectId : roundtableId;

  const [attachmentList, setAttachmentList] = useState([]);
  const [attType, setAttType] = useState(AttachmentType.MEDIA);
  const [attachmentDoc = [], loading] = useGetAttachmentsChat(conversationId, attType);

  const [isOpenedModal, setIsOpenedModal] = useState("");
  const [imageLink, setImgeLink] = useState("");

  const handleToggleModal = useCallback(
    (modalSection?: string) => {
      setIsOpenedModal(modalSection);
    },
    [isOpenedModal]
  );

  useEffect(() => {
    setAttachmentList(
      attachmentDoc
        .map((item: any) =>
          attType === AttachmentType.MEDIA ? item.imageUrls : item.fileUrls
        )
        .flat()
        .reverse()
    );
  }, [attType, attachmentDoc]);

  const onClickImage = (url: string) => {
    setImgeLink(url);
    handleToggleModal("image");
  };
  return (
    <Stack rowGap={1} sx={{ overflowX: "auto" }}>
      <Box
        display="flex"
        pl={3}
        pr={3}
        pt={1}
        pb={1}
        alignItems="center"
        columnGap={1}
        height={40}
      >
        {attachmentType.map((item) => (
          <FilterButton
            key={item.value}
            value={item.value as AttachmentType}
            label={item.label}
            handleChangeParams={setAttType}
            selectedStatus={attType}
          />
        ))}
      </Box>
      <Box sx={{ overflowX: "scroll" }}>
        {loading ? (
          <Skeleton width="100%" height={65} />
        ) : (
          attachmentList.map((item, i) => (
            <AttachmentItem onClickImage={onClickImage} {...item} key={i} />
          ))
        )}
      </Box>
      {!!isOpenedModal && (
        <ViewImageDialog
          {...{ embedLink: { url: imageLink }, isOpenedModal, handleToggleModal }}
        />
      )}
    </Stack>
  );
};
const mappingAttachmentLabel = (
  status: (typeof AttachmentType)[keyof typeof AttachmentType]
) => {
  switch (status) {
    case AttachmentType.FILE:
      return "Files";
    case AttachmentType.MEDIA:
      return "Photos & Video";
    default:
      return "Photos & Video";
  }
};

export default AttachmentsChat;
