import { FC } from "react";

import Picker from "@emoji-mart/react";
import { Popover } from "@mui/material";

export const popOverAnchorDivId = `emoji-popover-chat-editor-new`;
type PropsType = {
  popOverAnchorDiv: string;
  showPicker: boolean;
  closePicker: () => void;
  onAddReaction: (emoji: string) => void;
};

export const AppEmojiPicker: FC<PropsType> = (props) => {
  return (
    <Popover
      id={`popover-${popOverAnchorDivId}`}
      open={props.showPicker}
      anchorEl={document.getElementById(popOverAnchorDivId)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        backgroundColor: "transparent",
        borderRadius: 0,
        border: "1px solid",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={props.closePicker}
      elevation={0}
      transitionDuration={0}
      disableRestoreFocus
    >
      <Picker
        theme="light"
        previewPosition={"none"}
        skinTonePosition={"none"}
        onEmojiSelect={(emoji: { native: string }) => {
          props.onAddReaction(emoji.native as string);
        }}
      />
    </Popover>
  );
};
