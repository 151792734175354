import { axios } from "@features/config";

export type Request = {
  jobId: string;
  expectBudget: string;
  estimation: string;
  duration?: string;
  description?: string;
  attachments?: {
    name: string;
    url: string;
  };
};

export const applyJob = async ({ jobId, ...restPayload }: Request): Promise<any> => {
  const response = await axios.post(`/job/${jobId}/apply`, restPayload);
  return response?.data;
};
