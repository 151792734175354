export const convertBetween0And1ToPercentage = (
  number: number | string
): number | null => {
  const formattedNumber = +number;

  if (typeof formattedNumber !== "number" || isNaN(formattedNumber)) {
    return null;
  }

  const percentage = (formattedNumber * 100).toFixed(2);
  return +percentage;
};

export const roundedEarningNumber = (number: number) => {
  if (number < 50000) {
    return "Under $50k";
  }
  const range = Math.floor(number / 50000) * 50;
  return `$${range}k+`;
};

export const convertPercentageToBetween0And1 = (
  percentage: number | string
): number | null => {
  const formattedPercentage =
    typeof percentage === "string" ? parseFloat(percentage) : +percentage;

  if (
    typeof formattedPercentage !== "number" ||
    isNaN(formattedPercentage) ||
    formattedPercentage < 0 ||
    formattedPercentage > 100
  ) {
    return null;
  }

  return formattedPercentage / 100;
};
