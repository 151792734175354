import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";

import useCreateProposal from "@api/proposal/useCreateProposal";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import * as yup from "yup";

import { handleApiError } from "@features/config";

import CustomButton from "@components/Button";
import FormInput from "@components/FormInput";
import { ISnackbar } from "@components/Snackbar";

import { useViewport } from "@contexts/ViewportContext";

import queryClient from "@config/queryClient";

const formSchema = yup.object().shape({
  // title: yup.string().trim().required("Title is required."),
  question: yup.string().trim().required("Question is required."),
  answers: yup
    .array()
    .min(2)
    .of(yup.object().shape({ value: yup.string().trim().required() })),
});

export type PollForm = {
  title: string;
  question: string;
  answers: { value?: string }[];
};

const defaultValues: PollForm = {
  title: "",
  question: "",
  answers: [],
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  conversationId: string;
}

const CreateUpdatePollDialog: FC<IProps> = ({
  isOpen,
  onClose,
  conversationId,
  setSnackbar,
}) => {
  const { isDesktop } = useViewport();
  const { mutate: createProposal, isLoading: isLoadingProposalCreation } =
    useCreateProposal();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<PollForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });
  const {
    fields: answers,
    append,
    remove,
  } = useFieldArray({
    name: "answers",
    control,
  });

  const handleAddOption = useCallback(() => {
    append({ value: "" });
  }, [append]);

  const handleRemoveOption = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );

  const _onSave = async (formData: PollForm) => {
    const { title, question, answers } = formData;
    const options = answers?.filter((a) => a.value.trim()).map((a) => a.value);

    createProposal(
      {
        roundtableId: conversationId,
        payload: { title, question, options },
      },
      {
        onSuccess: () => {
          setSnackbar({
            message: "Your proposal was created successfully!",
            open: true,
            severity: "success",
          });

          queryClient.invalidateQueries(["getEventLogs"]);
          onClose();
        },
        onError: (error) => {
          setSnackbar({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        open={isOpen}
        maxWidth={isDesktop ? "sm" : "lg"}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            m: 2,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
          New Vote
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <FormInput
                  required
                  control={control}
                  name="title"
                  label="Title"
                  size="small"
                  error={!!errors?.title?.message}
                  helperText={errors?.title?.message}
                />
              </Grid> */}

              <Grid item xs={12}>
                <FormInput
                  required
                  control={control}
                  name="question"
                  label="Question"
                  size="small"
                  error={!!errors?.question?.message}
                  helperText={errors?.question?.message}
                />
              </Grid>
            </Grid>
          </Box>

          <Stack rowGap={2} mt={3}>
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>Answers</Typography>
            {answers?.map((field, index) => {
              return (
                <Grid
                  key={field.id}
                  container
                  justifyContent="space-between"
                  columnSpacing={{ xs: 1, sm: 4 }}
                >
                  <Grid item xs={10} sm={10}>
                    <FormInput
                      control={control}
                      name={`answers.${index}.value`}
                      label={`Option ${index + 1}`}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <Button
                      variant="contained"
                      onClick={handleRemoveOption(index)}
                      sx={{ height: "100%" }}
                    >
                      <AiOutlineClose />
                    </Button>
                  </Grid>
                </Grid>
              );
            })}

            <Button
              variant="outlined"
              startIcon={<AiOutlinePlus />}
              onClick={handleAddOption}
              sx={{
                width: "fit-content",
              }}
            >
              Add option
            </Button>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            disabled={isLoadingProposalCreation}
            onClick={onClose}
          >
            Cancel
          </Button>

          <CustomButton
            disabled={!isValid || isLoadingProposalCreation}
            loading={isLoadingProposalCreation}
            onClick={handleSubmit(_onSave)}
          >
            Create
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUpdatePollDialog;
