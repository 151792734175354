import { FC, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { uploadRoundtableFiles } from "@api/roundtable/uploadRoundtableFiles";
import InfoIcon from "@mui/icons-material/Info";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  Alert,
  Avatar,
  Typography,
  SxProps,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";

import Button from "@components/Button";
import FormInput from "@components/FormInput";
import HelperText from "@components/HelperText";
import ImageUploader, { ImageUploaderType } from "@components/ImageUploader";
import MultiFileUploader from "@components/MultiFileUploader";

import { Proposal } from "@dto/proposal";
import {
  ManagementEnum,
  Roundtable as RoundtableType,
  VoteModeEnum,
} from "@dto/roundtable";

const helperTextSxProps: SxProps = {
  "& .MuiFormHelperText-root": {
    mx: 0,
    mt: 1,
  },
};

enum VoteModeOption {
  SIMPLE_VOTE = "SIMPLE_VOTE",
  WEIGHT_COMMON_VOTE = "WEIGHT_COMMON_VOTE",
  ELECTORS_VOTE = "ELECTORS_VOTE",
}
type VoteMode = {
  value: VoteModeOption;
  text: string;
};
const voteModes: VoteMode[] = [
  {
    value: VoteModeOption.SIMPLE_VOTE,
    text: "Simple",
  },
  {
    value: VoteModeOption.WEIGHT_COMMON_VOTE,
    text: "Weight Common",
  },
  {
    value: VoteModeOption.ELECTORS_VOTE,
    text: "Electors",
  },
];

enum VisibilityOption {
  PRIVATE,
  PUBLIC,
}
type Visibility = {
  value: VisibilityOption;
  text: string;
};
const visibilities: Visibility[] = [
  {
    value: VisibilityOption.PUBLIC,
    text: "Public",
  },
  {
    value: VisibilityOption.PRIVATE,
    text: "Private",
  },
];

const INPUT_COMPONENT_TYPE = {
  AVATAR_UPLOAD: "avatarUpload",
  INPUT_TEXT: "inputText",
  INPUT_NUMBER: "inputNumber",
  SELECT: "select",
  FILE_UPLOAD: "fileUpload",
  CHECKBOX: "checkbox",
} as const;
export const ROUNDTABLE_FORM_TYPE = {
  MANAGEMENT: "ROUNDTABLE_MANAGEMENT",
  PROFILE: "ROUNDTABLE_PROFILE",
} as const;

export type { Roundtable } from "@dto/roundtable";

export type RoundtableForm = {
  avatar: any;
  title: string;
  type: string;
  language: string;
  institution: string;
  location: string;
  description: string;
  visibility: number;
  passedPercentage: number;
  voteMode: VoteModeEnum;
  voteDuration: number;
  originalValues?: any;
  requireApplication?: boolean;
};

type InputComponentProps = {
  label?: string;
  // type?: "avatarUpload" | "inputText" | "inputNumber" | "select"
  component?: (typeof INPUT_COMPONENT_TYPE)[keyof typeof INPUT_COMPONENT_TYPE];
  componentProps?: any;
  // options?: { text: string, value: string }[];
  options?: any[];
  name?: string;
  sx?: SxProps;
  helperText?: ReactNode;
};
type FieldSchemas = {
  [x: string]: InputComponentProps & {
    gridCol?: any;
    section: (typeof ROUNDTABLE_FORM_TYPE)[keyof typeof ROUNDTABLE_FORM_TYPE];
    key: string;
  };
};

interface IProps {
  isOpen?: boolean;
  isLoading?: boolean;
  isActionLoading?: boolean;
  title?: string;
  onClose: () => void;
  onSave?: (data: any) => void;
  data: RoundtableType;
  proposal?: Proposal;
  type?: (typeof ROUNDTABLE_FORM_TYPE)[keyof typeof ROUNDTABLE_FORM_TYPE];
  // type?: keyof typeof ROUNDTABLE_FORM_TYPE;
  isViewOnly?: boolean;
  removeDialog?: boolean;
}

const RoundtableFormDialog: FC<IProps> = ({
  isOpen,
  isActionLoading,
  isViewOnly,
  title,
  onClose,
  onSave,
  type,
  data,
  proposal,
  removeDialog,
}) => {
  const { endAt, startAt, createdByUser } = proposal || {};
  const { originalValues = {}, ...pendingValues } =
    (proposal?.data as RoundtableForm) || {};
  const endDate = dayjs(endAt);

  const imageUploaderRef = useRef(null);
  const [isInternalLoading, setInternalLoading] = useState(false);

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useFormContext();

  const avatar = watch("avatar");
  const watchedrequireApplication = watch("requireApplication") || false;

  const disableSubmit = !isDirty || isActionLoading || isInternalLoading;
  const disableForm = isActionLoading || isInternalLoading || isViewOnly;
  const isTraditionalRoundtable = data.managementStyle === ManagementEnum.TRADITIONAL;
  const handleUploadImage = useCallback(
    (file: File) => {
      setValue("avatar", file, {
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const fieldSchemas = useMemo<FieldSchemas>(
    () => ({
      avatar: {
        key: "avatar",
        component: "avatarUpload",
        label: "",
        section: ROUNDTABLE_FORM_TYPE.PROFILE,
        gridCol: { xs: 12, md: 4 },
        componentProps: {
          initAvatar: avatar,
          onSetValue: handleUploadImage,
          disabled: disableForm,
        },
      },
      title: {
        key: "title",
        component: "inputText",
        label: "Teamspace Title",
        section: ROUNDTABLE_FORM_TYPE.PROFILE,
        gridCol: { xs: 12, md: 8 },
        componentProps: { required: true, disabled: disableForm },
        sx: helperTextSxProps,
        helperText: isViewOnly && (
          <HelperText
            data={proposal?.data as RoundtableType}
            field="title"
            currentValue={originalValues.title}
          />
        ),
      },

      // management
      passedPercentage: {
        key: "passedPercentage",
        component: "inputText",
        label: "Pass Percentage (%)",
        section: isTraditionalRoundtable ? null : ROUNDTABLE_FORM_TYPE.MANAGEMENT,
        gridCol: { xs: 12, md: 9 },
        componentProps: { required: true, type: "number", disabled: disableForm },
        sx: helperTextSxProps,
        helperText: isViewOnly && (
          <HelperText
            data={proposal?.data as RoundtableType}
            field="passedPercentage"
            currentValue={+originalValues.passedPercentage}
            suffix="(%)"
          />
        ),
      },
      voteMode: {
        key: "voteMode",
        component: "select",
        label: "Vote Mode",
        section: isTraditionalRoundtable ? null : ROUNDTABLE_FORM_TYPE.MANAGEMENT,
        gridCol: { xs: 12, md: 9 },
        componentProps: { required: true, disabled: disableForm },
        options: voteModes,
        sx: helperTextSxProps,
        helperText: isViewOnly && (
          <HelperText
            data={proposal?.data as RoundtableType}
            field="voteMode"
            currentValue={originalValues.voteMode}
            formatContent={(voteMode) => {
              if (voteMode === VoteModeOption.SIMPLE_VOTE) {
                return "Simple";
              }

              if (voteMode === VoteModeOption.WEIGHT_COMMON_VOTE) {
                return "Weight Common";
              }

              return "Electors";
            }}
          />
        ),
      },
      voteDuration: {
        key: "voteDuration",
        component: "inputText",
        label: "Vote Duration (hrs)",
        section: isTraditionalRoundtable ? null : ROUNDTABLE_FORM_TYPE.MANAGEMENT,
        gridCol: { xs: 12, md: 9 },
        componentProps: { required: true, type: "number", disabled: disableForm },
        sx: helperTextSxProps,
        helperText: isViewOnly && (
          <HelperText
            data={proposal?.data as RoundtableType}
            field="voteDuration"
            currentValue={+originalValues.voteDuration}
            suffix="(hrs)"
          />
        ),
      },
      visibility: {
        key: "visibility",
        component: "select",
        label: "Visibility",
        section: ROUNDTABLE_FORM_TYPE.MANAGEMENT,
        gridCol: { xs: 12, md: 9 },
        componentProps: { required: true, disabled: disableForm },
        options: visibilities,
        sx: helperTextSxProps,
        helperText: isViewOnly && (
          <HelperText
            data={{ isPublic: +(pendingValues as RoundtableType).isPublic }}
            field="isPublic"
            currentValue={+(originalValues as RoundtableType).isPublic}
            formatContent={(isPublic) => (isPublic ? "Public" : "Private")}
          />
        ),
      },
      requireApplication: {
        key: "requireApplication",
        component: "checkbox",
        label: "Application required",
        section: ROUNDTABLE_FORM_TYPE.MANAGEMENT,
        gridCol: { xs: 12, md: isTraditionalRoundtable ? 12 : 6 },
        componentProps: {
          disabled: disableForm,
          checked: watchedrequireApplication,
          onChange: () =>
            setValue("requireApplication", !watchedrequireApplication, {
              shouldDirty: true,
            }),
        },
        helperText: isViewOnly && (
          <HelperText
            data={proposal?.data as RoundtableType}
            // data={{ requireApplication: +(proposal?.data as RoundtableType).requireApplication }}
            field="requireApplication"
            currentValue={originalValues.requireApplication}
            formatContent={(requireApplication) => (requireApplication ? "Yes" : "No")}
          />
        ),
      },
    }),
    [data, avatar, watchedrequireApplication, proposal, disableForm]
  );

  const handleSave = async (formData: RoundtableForm) => {
    // console.log("imageUploaderRef", imageUploaderRef?.current?.files);
    if (!!imageUploaderRef.current?.files?.length) {
      setInternalLoading(true);
      const files = await imageUploaderRef.current?.processingImages();
      await uploadRoundtableFiles({ roundtableId: data?.id, files });
      setInternalLoading(false);
    }
    onSave(formData);
  };

  const renderInputComponent = ({
    name,
    label,
    component,
    sx,
    componentProps,
    options,
    helperText,
  }: InputComponentProps) => {
    switch (component) {
      case INPUT_COMPONENT_TYPE.AVATAR_UPLOAD:
        return <ImageUploader type={ImageUploaderType.ROUNDED} {...componentProps} />;
      case INPUT_COMPONENT_TYPE.INPUT_TEXT:
        return (
          <FormInput
            {...componentProps}
            sx={{ ...sx, "& input": { background: "white" } }}
            control={control}
            name={name}
            label={label}
            size="small"
            error={!!errors?.[name]?.message}
            helperText={helperText || errors?.[name]?.message}
          />
        );
      case INPUT_COMPONENT_TYPE.SELECT:
        return (
          <FormInput
            select
            {...componentProps}
            sx={{ ...sx, background: "white" }}
            control={control}
            name={name}
            label={label}
            size="small"
            error={!!errors?.[name]?.message}
            helperText={helperText || errors?.[name]?.message}
          >
            {options?.map(({ value, text }, index) => (
              <MenuItem key={`${index}-${value}`} value={value}>
                {text}
              </MenuItem>
            ))}
          </FormInput>
        );
      case INPUT_COMPONENT_TYPE.FILE_UPLOAD:
        return (
          <Stack gap={1.5}>
            {label && (
              <Typography fontSize={16} fontWeight={700}>
                {label}
              </Typography>
            )}
            <MultiFileUploader {...componentProps} />
          </Stack>
        );
      case INPUT_COMPONENT_TYPE.CHECKBOX:
        return (
          <Stack gap={0.5}>
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  {...componentProps}
                  // sx={{ color: "common.bellflowerBlue" }}
                />
              }
              labelPlacement="end"
              sx={{
                "& span.MuiCheckbox-root": { p: 0, mx: "9px" },
                // "& span.MuiTypography-root": { color: "common.gray5" },
              }}
            />
            {helperText}
          </Stack>
        );
      default:
        return <>DEFAULT</>;
    }
  };

  const renderContent = () => {
    return (
      <>
        <Grid container spacing={2} mt={1}>
          {Object.values(fieldSchemas)?.map(
            (
              {
                section,
                gridCol,
                key,
                label,
                component,
                componentProps,
                options,
                helperText,
                sx,
              },
              i
            ) => {
              if (section !== type) {
                return;
              }
              const styles = {
                display: key === "title" && "flex",
                alignItems: key === "title" && "center",
              };
              return (
                <Grid item key={i} {...gridCol} {...styles}>
                  {renderInputComponent({
                    name: key,
                    label,
                    component,
                    componentProps,
                    options,
                    helperText,
                    sx,
                  })}
                </Grid>
              );
            }
          )}
        </Grid>
      </>
    );
  };

  if (removeDialog) {
    return (
      <Stack>
        {renderContent()}
        <Stack flexDirection={"row"} justifyContent={"right"} gap={1} mt={2}>
          <Button variant="outlined" disabled={disableForm} onClick={onClose}>
            Cancel
          </Button>

          <Button
            loading={disableForm}
            disabled={disableSubmit}
            onClick={handleSubmit(handleSave)}
          >
            Update
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle sx={{ fontSize: 20, fontWeight: 700 }}>
        {title || "Teamspace form"}
      </DialogTitle>

      <DialogContent>
        {isViewOnly && (
          <Alert
            severity="info"
            icon={<InfoIcon fontSize="inherit" />}
            sx={{ alignItems: "center", color: "common.black", px: { xs: 1.5, sm: 2 } }}
          >
            <Typography sx={{ fontSize: 14 }}>
              The Teamspace is under approval. Expired by:{" "}
              <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
                {dayjs.duration(endDate.diff(dayjs())).humanize(true)}
              </Typography>
            </Typography>

            <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
              <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
                Last edited by:
              </Typography>

              <Stack direction="row" spacing={0.5} alignItems="center" mx={1}>
                <Avatar
                  alt="Avatar"
                  src={createdByUser?.avatar}
                  sx={{ width: 20, height: 20 }}
                />

                <Typography
                  component="span"
                  sx={{ fontSize: 14, fontWeight: 600, color: "common.partyTime" }}
                >
                  {createdByUser?.name}
                </Typography>
              </Stack>

              <Typography component="span" sx={{ fontSize: 14 }}>
                {dayjs(startAt).format("hh:mm A, YYYY MMM DD")}
              </Typography>
            </Stack>
          </Alert>
        )}
        {renderContent()}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={disableForm && !isViewOnly}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        {!isViewOnly && (
          <Button
            disabled={disableSubmit}
            loading={disableForm}
            onClick={handleSubmit(handleSave)}
          >
            Update
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RoundtableFormDialog;
