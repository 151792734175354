import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";

import Button from "@components/Button";
import FormInput from "@components/FormInput";

type ReviewDialogProps = {
  content?: JSX.Element;
  isOpen?: boolean;
  title?: string;
  submitBtnLabel?: string;
  handleClose?: () => void;
  submitReview?: (star: number, review: string) => void;
};

const InputStyle = {
  backgroundColor: "white",
  "fieldset": { borderColor: "common.bellflowerBlue" },
};

const formSchema = yup.object().shape({
  star: yup.number().min(1, "Please provide a rating score"),
});

const ReviewDialog = (props: ReviewDialogProps) => {
  const { content, isOpen, title, submitBtnLabel, handleClose, submitReview } = props;

  const [loading, setLoading] = useState(false);
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      star: 0,
      review: "",
    },
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const starWatch = watch("star");

  const onStarClick = (rating: number) => {
    setValue("star", rating, { shouldValidate: true });
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    const { star, review } = data;
    if (submitReview) {
      submitReview(star, review);
    }
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: 712,
          m: 2,
        },
      }}
    >
      <DialogContent sx={{ pb: 6 }}>
        <Stack rowGap={1}>
          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
            {title || "Write a review"}
          </Typography>
        </Stack>
        {content}
        <Stack spacing={3} mt={1}>
          <FormControl error={Boolean(errors.star)}>
            <Stack direction="row">
              {[...Array(5)].map((_, i) =>
                i < starWatch ? (
                  <StarIcon
                    key={i}
                    sx={{ cursor: "pointer", fontSize: 36, color: "#FEC84B" }}
                    onClick={() => onStarClick(i + 1)}
                  />
                ) : (
                  <StarBorderIcon
                    key={i}
                    sx={{ fontSize: 36, cursor: "pointer" }}
                    onClick={() => onStarClick(i + 1)}
                  />
                )
              )}
            </Stack>
            <FormHelperText>{errors.star?.message}</FormHelperText>
          </FormControl>
          <FormInput
            multiline
            maxRows={5}
            minRows={5}
            control={control}
            name="review"
            label="Your review"
            sx={InputStyle}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={handleClose}>
          {"Cancel"}
        </Button>
        <Button disabled={loading} variant="contained" onClick={handleSubmit(onSubmit)}>
          {submitBtnLabel || "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ReviewDialog;
