import { JobStatus } from "@@types/type";

export const JOB_STATUS: Record<string, JobStatus> = {
  TODO: "TO_DO",
  HIRING: "HIRING",
  IN_PROGRESS: "IN_PROGRESS",
  DELIVERED: "DELIVERED",
  DONE: "DONE",
};

export enum JobStatusColor {
  TODO = "#718096",
  HIRING = "#FF2D55",
  IN_PROGRESS = "#DD6B20",
  DELIVERED = "#3182CE",
  DONE = "#38A169",
  CANCELLED = "#718096",
}

export const Priority = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  URGENT: "Urgent",
};

export const QuestVisibility = {
  PLATFORM: "platform",
  PROJECT: "project",
} as const;

export const SERVICE_CHARGE = {
  CLIENT: 0.015,
  FREELANCER: 0.029,
};

export const VIEW = {
  1: {
    id: 1,
    key: "QUEST_LIST",
  },
  2: {
    id: 2,
    key: "QUEST_TOOLS",
  },
  3: {
    id: 3,
    key: "QUEST_DETAILS",
  },
  4: {
    id: 4,
    key: "APPLICATION_DETAILS",
  },
  5: {
    id: 5,
    key: "QUEST_ACTIVITY",
  },
  6: {
    id: 6,
    key: "QUEST_ESTIMATION_DETAILS",
  },
  7: {
    id: 7,
    key: "QUEST_DETAILS_READ_ONLY",
  },
  8: {
    id: 8,
    key: "APPLICATION_LIST",
  },
  9: {
    id: 9,
    key: "EMPTY_QUEST_DETAILS",
  },
  10: {
    id: 10,
    key: "DELIVERY_LIST",
  },
};

export const ACTION = {
  0: {
    id: 0,
    key: "INITIAL",
  },
  1: {
    id: 1,
    key: "VIEW_QUEST_DETAILS",
  },
};
