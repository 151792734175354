import { FC, useState } from "react";

import { usePublishHiringQuest } from "@api/job/useEditJob";
import { Stack, useTheme } from "@mui/material";

import Button from "@components/Button";
import ConfirmActionDialog from "@components/Dialog/ConfirmDialog/ConfirmAction";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

import { useSelectJob } from "./SelectJobContext";

type PropTypes = {
  onSubmit?: (cb?: () => void) => void;
};

const UpdateHiringQuestBtn: FC<PropTypes> = ({ onSubmit }) => {
  const {
    palette: { common },
  } = useTheme();
  const [openConfirm, setOpenConfirm] = useState(false);
  const { mutate: publishHiring, isLoading } = usePublishHiringQuest();
  const { setSnackbar } = useSnackbar();
  const { jobSelected } = useSelectJob();

  const handleSubmit = () => {
    publishHiring(jobSelected.id, {
      onSuccess: (_apiData) => {
        // setSnackbar({
        //   message: "Call API success",
        //   open: true,
        //   severity: "success",
        // });
        queryClient.invalidateQueries(["getJobsByProject", jobSelected.projectId]);
        // queryClient.invalidateQueries(["getQuestDraft", jobSelected.id]);
      },
      onError: (_error) => {
        setSnackbar({
          message: "Update hiring-quest failed.",
          open: true,
          severity: "error",
        });
        queryClient.invalidateQueries(["getJobsByProject", jobSelected.projectId]);
        queryClient.invalidateQueries(["getQuestDraft", jobSelected.id]);
      },
    });
  };

  return (
    <Stack>
      <Button
        onClick={() => setOpenConfirm(true)}
        sx={{
          height: 36,
          borderRadius: 2,
          bgcolor: "black",
          color: "white",
          border: common.border,
          ":hover": { bgcolor: "black" },
          "&.Mui-disabled": { bgcolor: "#0000001f" },
          width: "max-content",
        }}
      >
        Update quest
      </Button>
      <ConfirmActionDialog
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onSubmit={() => onSubmit(handleSubmit)}
        title="Update quest"
        description="Updating quest will require a new estimate from the freelancer, and some may not submit. Are you sure you want to proceed?"
        submitBtnLabel="Yes"
        cancelBtnLabel="No"
      />
    </Stack>
  );
};

export default UpdateHiringQuestBtn;
