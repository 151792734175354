import { createContext, useState, useContext } from "react";

import { TRowType } from "./types";

const JobSelectedContext = createContext<{
  jobSelected: TRowType;
  setJobSelected: React.Dispatch<React.SetStateAction<TRowType>>;
}>(null);

export const SelectJobProvider = ({ children }: any) => {
  const [jobSelected, setJobSelected] = useState<TRowType>();
  return (
    <JobSelectedContext.Provider value={{ jobSelected, setJobSelected }}>
      {children}
    </JobSelectedContext.Provider>
  );
};

export const useSelectJob = () => {
  return useContext(JobSelectedContext);
};
