import { CHAT_TYPES } from "@constants/chat";
import { CONVERSATION_COLlECTION, USER_COLlECTION } from "@fb/const";
import { db } from "@fb/db";
import md5 from "crypto-js/md5";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";

import { Attendee } from "@dto/roundtable";
import { User } from "@dto/user";

const useUpdateReadStatus = async ({
  user,
  participants,
  conversationId,
}: {
  user: User;
  participants: Attendee[];
  conversationId: string;
}) => {
  try {
    const conversationDocRef = doc(db, CONVERSATION_COLlECTION, conversationId);
    const conversationDoc = await getDoc(conversationDocRef);

    const userDocRef = doc(db, USER_COLlECTION, user.id);
    const userDoc = await getDoc(userDocRef);

    if (!conversationDoc.exists()) {
      const _p = [...participants.map((item) => item.userId)].sort().join("-");
      const pHash = md5(_p).toString();

      await updateDoc(doc(db, CONVERSATION_COLlECTION, conversationId), {
        date: serverTimestamp(),
        type: CHAT_TYPES.ROUNDTABLE,
        pHash,
        lastMessageAt: +new Date(),
      });
    }
    const userData = userDoc.data();
    let updateData: any = {};

    if (conversationDoc.data().type === CHAT_TYPES.ROUNDTABLE) {
      updateData = {
        unreadRoundTableIds: userData.unreadRoundTableIds?.filter(
          (id: string) => id !== conversationId
        ),
      };
      if (!updateData?.unreadRoundTableIds) {
        updateData = {};
      }
    } else {
      updateData = {
        unreadP2PIds: userData?.unreadP2PIds.filter(
          (id: string) => id !== conversationId
        ),
      };
    }

    await updateDoc(userDocRef, updateData);

    return userDocRef;
  } catch (e) {
    console.error("Error update read status: ", e);
  }
};

export default useUpdateReadStatus;
