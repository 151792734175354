import { ChangeEvent, FC, ReactNode, useState } from "react";
import { isEmpty } from "lodash";
import { debounce, Stack, Box, Button, Avatar, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, TextField, ListSubheader, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, Padding } from "@mui/icons-material";
import ConfirmActionDialog from "@components/Dialog/ConfirmDialog/ConfirmAction";
import FormInput from "@components/FormInput";
import ReadMoreText from "@components/ReadMoreText.tsx";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import DefaultThumbnail from "@assets/images/defaultThumbnail.png";

import useGetPublishedProjects from "@api/roundtable/useGetPublishedProjects";
import useRedirectTo from "@hooks/useRedirectTo";
import { PublicPaths } from "@routers/path/PublicPath";

type Action = 'add' | 'update' | 'delete' | ''

interface IProject {
  projectId: string;
  project?: any;
  description?: string;
}
export interface IDataChangeProps {
  action: Action;
  index: number;
  data?: IProject;
}
interface IProps {
  title?: ReactNode;
  isEdit?: boolean;
  projects?: IProject[];
  onDataChange?: (props: IDataChangeProps) => void;
  userIds?: string;
}

const formSchema = yup.object().shape({
  projectId: yup.string(),
  description: yup.string(),
  project: yup.mixed()
})

const defaultValues = {
  projectId: "",
  description: "",
  project: {}
}

const UserProjects: FC<IProps> = ({
  title,
  isEdit,
  projects,
  onDataChange,
  userIds
}) => {
  const [selectedItem, setSelectedItem] = useState<{ action: Action, itemIndex: number }>({ action: '', itemIndex: -1 });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues
  });

  const { getRedirectRoute } = useRedirectTo();

  const {
    data: publishedProjects,
    isLoading,
    // params,
    handleChangeParams,
    // fetchNextPage,
    // hasNextPage,
  } = useGetPublishedProjects({
    page: 0,
    pageSize: 20,
    orderByTime: "DESC",
    title: "",
    users: userIds
    // category: searchParams.get("category") ? [searchParams.get("category")] : [],
    // excludeCategory: searchParams.get("category")
    //   ? undefined
    //   : Category_List.ComputerProduct,
  });

  const handleStartModifyAction = (action: Action, index: number, e: React.MouseEvent<any>) => {
    e.stopPropagation();
    
    if (action === 'add') {
      reset(defaultValues);
    } else if (action === 'update') {
      reset(projects?.[index]);
    }
    setSelectedItem({ action, itemIndex: index });
  }

  const handleSave = (formData: IProject) => {
    if (onDataChange) {
      onDataChange({
        action: selectedItem.action,
        index: selectedItem.itemIndex,
        data: formData
      })
      setSelectedItem({ action: "", itemIndex: -1 })
    }
  }

  const handleDelete = () => {
    if (onDataChange) {
      onDataChange({
        action: 'delete',
        index: selectedItem.itemIndex,
      })
      setSelectedItem({ action: "", itemIndex: -1 })
    }
  }

  const handleChangeSearchInput = debounce(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target?.value?.trim() || "";

      handleChangeParams({ title: value })
    },
    300
  );

  const handleOpenProjectDetail = (shortId: string) => {
    const projectDetailPath = getRedirectRoute(PublicPaths.publishedProjectDetail, [shortId]);
    window.open(`${location.origin}${projectDetailPath}`, '_blank');
  }

  return (
    <Stack gap={2}>
      {title}
      {projects?.length < 1 && !isEdit && <Typography>-</Typography>}
      <Stack>
        {projects?.map((prj, i) => (
          <Stack 
            key={i} 
            flexDirection={"row"} 
            flexWrap={{ xs: "wrap", md: "nowrap" }} 
            gap={2} 
            py={2} 
            borderBottom={"1px solid #E2E8F0"}
            sx={{ cursor: 'pointer' }} 
            onClick={() => handleOpenProjectDetail(prj?.project?.shortId)}
          >
            <Box
              component="img"
              src={
                prj?.project?.projectAvatar ||
                prj?.project?.avatar ||
                DefaultThumbnail
              }
              sx={{
                width: { md: 184, xs: '100%' },
                minWidth: { md: 184, xs: "unset" },
                aspectRatio: "16/9",
                borderRadius: 3,
                objectFit: "cover",
              }}
            />
            <Stack width={"100%"} gap={1.2}>
              <Typography 
                fontSize={18} 
                fontWeight={"bold"}
              >
                {prj?.project?.title}
              </Typography>
              <Typography fontSize={16}>
                <ReadMoreText
                  text={prj?.description}
                  fontSize="16px"
                  maxLength={165}
                  // maxLength={isDesktop ? 500 : 260}
                />
              </Typography>
            </Stack>
            {isEdit && (
              <Stack>
                <Stack flexDirection={"row"} gap={0.5}>
                  <IconButton sx={{ color: "common.gray5" }} onClick={(e) => handleStartModifyAction("update", i, e)}><EditIcon /></IconButton>
                  <IconButton sx={{ color: "common.gray5" }} onClick={(e) => handleStartModifyAction("delete", i, e)}><DeleteIcon /></IconButton>
                </Stack>
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
      {isEdit && (
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ width: "fit-content" }}
          onClick={(e) => handleStartModifyAction("add", projects?.length, e)}
        >
          Add Project
        </Button>
      )}
      <Dialog
        fullWidth
        open={['add', 'update'].includes(selectedItem.action)}
        maxWidth="sm"
        onClose={() => setSelectedItem({ ...selectedItem, action: "" })}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
          {selectedItem.action && (selectedItem.action === "add" ? "Add project" : "Edit project")}
        </DialogTitle>

        <DialogContent sx={{ py: 3 }}>
          <Stack gap={2.5} pt={3}>
            {/* <Controller
              name="projectId"
              control={control}
              render={({ field }) => ( */}
                <Select
                  // {...field}
                  fullWidth
                  displayEmpty
                  // IconComponent={KeyboardArrowDown}
                  // renderValue={
                  //   !isEmpty(field?.value)
                  //     ? undefined
                  //     : () => <Typography color="common.hintText">{placeholder}</Typography>
                  // }
                  // defaultValue={getValues("project")?.title}
                  value={getValues("project")?.title}
                  renderValue={(value) => {
                    const selectedProject = publishedProjects?.find(({ id }) =>
                      // field?.value?.includes(id)
                      value?.includes(id)
                    );
                    return <Typography>{selectedProject?.title || getValues("project")?.title}</Typography>
                  }}
                  onChange={(e) => {
                    const selectedProject = publishedProjects?.find(({ id }) => id === e?.target?.value)
                    if (selectedProject) {
                      setValue("project", selectedProject);
                      setValue("projectId", e?.target?.value)
                    }
                  }}
                  onClose={() => handleChangeParams({ title: "" })}
                  MenuProps={{
                    autoFocus: false,
                    PaperProps: {
                      style: { maxHeight: 420 },
                    },
                  }}
                >
                  <ListSubheader sx={{ py: 2 }}>
                    <TextField
                      sx={{
                        width: "100%",
                        "& fieldset": {
                          // borderRadius: 9,
                        },
                        "svg": {
                          // color: common.doverGrey, pr: 1,
                        },
                      }}
                      InputProps={{
                        endAdornment: <SearchIcon />,
                      }}
                      placeholder="Search project"
                      onChange={handleChangeSearchInput}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {isLoading ? (
                    <Stack pb={2} px={2}>Loading...</Stack>
                  ) : (
                    publishedProjects?.map(({ title, roundTableName, id }: any, i: number) => (
                      <MenuItem key={i} value={id}>
                        <Stack>
                          <Typography fontSize={14} fontWeight={600}>{title}</Typography>
                          <Typography fontSize={12} color="primary">{roundTableName}</Typography>
                        </Stack>
                      </MenuItem>
                    ))
                  )}
                </Select>
              {/* )}
            /> */}
            {/* <FormInput
              autoComplete="off"
              select
              control={control}
              fullWidth
              name="projectId"
              label="Select project"
              SelectProps={{
                renderValue: (value: any) => {
                  const selectedProject = publishedProjects?.find(({ id }) =>
                    value?.includes(id)
                  );
                  return selectedProject?.title
                },
              }}
            >
              <ListSubheader>
              <TextField
                sx={{
                  width: "100%",
                  "& fieldset": {
                    // borderRadius: 9,
                  },
                  "svg": {
                    // color: common.doverGrey,
                    // pr: 1,
                  },
                }}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                placeholder="Search project"
                onChange={handleChangeSearchInput}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
              </ListSubheader>
              {isLoading ? (
                <>Loading...</>
              ) : (
                publishedProjects?.map(({ title, roundTableName, id }: any, i: number) => (
                  <MenuItem key={i} value={id}>
                    <Stack>
                      <Typography fontSize={14} fontWeight={600}>{title}</Typography>
                      <Typography fontSize={12} color="primary">{roundTableName}</Typography>
                    </Stack>
                  </MenuItem>
                ))
              )}
            </FormInput> */}
            <FormInput
              multiline
              minRows={5}
              maxRows={5}
              control={control}
              name="description"
              label="Description"
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ pt: 1.5, pb: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedItem({ ...selectedItem, action: "" });
              // reset(defaultValues);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(handleSave)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmActionDialog
        title="Delete project"
        description="Are you sure you want to delete this project?"
        isOpen={selectedItem.action === 'delete'}
        onClose={() => setSelectedItem({ ...selectedItem, action: "" })}
        onSubmit={handleDelete}
        submitBtnLabel="Delete"
      />
    </Stack>
  )
}

export default UserProjects;
