import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";

import { axios } from "@features/config";

import { JobDto } from "@dto/job";

export type Request = {
  title: string;
  isQuest?: boolean;
  description?: string;
  attachment?: string[];
  deliverables?: string;
  skills?: { id: string; name: string }[];
  budget?: number;
  projectId: string;
  regional?: string;
};

type Response = {
  jobId: string;
  projectId: string;
  shortId: string;
};

const createJob = async (payload: Request): Promise<Response> => {
  const response = await axios.post(`/jobs`, payload);
  return response?.data?.data;
};

const useCreateJob = () => {
  return useMutation(createJob);
};

export default useCreateJob;
