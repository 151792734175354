import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import { UNREAD_REALTIME } from "@fb/const";
import { realtimeDb } from "@fb/db";
import { styled } from "@mui/material";
import { TreeItem, TreeItemProps } from "@mui/x-tree-view";
import { SidebarKeys } from "@routers/const";
import { AppRoute } from "@routers/type";
import { ref, onValue } from "firebase/database";

import { useAppSelector } from "@app/hooks";

import {
  useGetUnreadP2PIds,
  useGetUnreadRoundTables,
} from "@pages/messenger/ConversationList/hooks";

import useControlSidebar from "../../hooks/useControlSidebar";
import AppTreeItemLabel from "./AppTreeItemLabel";

interface AppTreeItemProps extends TreeItemProps {
  label: string | ReactNode;
  icon?: ReactNode;
  roundtablePaths?: AppRoute[];
}

const TREE_ITEM_RADIUS = 4;

const ExpandedColor = "#060606";

const getRadius = (sidebarOpen: boolean) => (sidebarOpen ? TREE_ITEM_RADIUS : 0);

const CustomTreeItem = styled(TreeItem)<{ sidebarOpen: boolean }>(
  ({ theme, sidebarOpen }) => ({
    ".MuiTreeItem-root": {
      color: theme.palette.common.gray6,
    },
    "& .MuiTreeItem-content.Mui-expanded": {
      "& .tree-item-expand-icon,.tree-item-icon": {
        "svg": {
          fill: ExpandedColor,
        },
      },
      "& .tree-item-label": {
        color: ExpandedColor,
      },
      "& .tree-item-label-container": {
        background: "transparent",
      },
    },

    "& .MuiTreeItem-content.Mui-selected": {
      "& .MuiTreeItem-label": {
        "& .tree-item-label-container": {
          background: ExpandedColor,
        },
        "& .tree-item-label": {
          color: "white",
        },
        "& .tree-item-expand-icon, .tree-item-icon": {
          "svg": {
            fill: "white",
          },
        },
      },
    },
    ".MuiTreeItem-root.roundTable-item": {
      "& .MuiTreeItem-content.Mui-expanded.Mui-selected": {
        "& .MuiTreeItem-label": {
          "& .tree-item-label-container": {
            background: theme.palette.primary.main,
          },
        },
      },
      "& .MuiTreeItem-content.Mui-expanded": {
        "& .MuiTreeItem-content": {
          padding: 0,
          "&:hover": {
            borderRadius: getRadius(sidebarOpen),
          },
        },
        // "&:hover": {
        //   background: "#FFF5F5",
        // },
        // "& .MuiTreeItem-label": {
        //   "& .tree-item-label-container": {
        //     padding: 0,
        //     "& .tree-item-icon": {
        //       "svg": {
        //         fill: theme.palette.common.gray6,
        //       },
        //       padding: "8px 12px",
        //     },
        //     "& .tree-item-label": {
        //       padding: "8px 12px",
        //       boxSizing: "border-box",
        //       background: "#FFF5F5",
        //       color: theme.palette.common.gray6,
        //       marginLeft: 0,
        //     },
        //   },
        // },
      },
    },
    ".MuiCollapse-root[role=group]": {
      marginLeft: 8,
      // "& .tree-item-label": {
      //   paddingLeft: 12,
      // },
      '& .MuiCollapse-root[role="group"]': {
        marginLeft: 20,

        "& .tree-item-label": {
          marginLeft: 6,
        },
      },
    },

    ".MuiTreeItem-content": {
      padding: 0,
      "&:hover": {
        borderRadius: getRadius(sidebarOpen),
      },
      "& .MuiTreeItem-iconContainer": {
        display: "none",
      },
      "& .MuiTreeItem-label": {
        padding: 0,
        "& .tree-item-label-container": {
          borderRadius: getRadius(sidebarOpen),
        },
      },
    },
  })
);

const AppTreeItem: FC<AppTreeItemProps> = ({
  nodeId,
  label,
  icon,
  collapseIcon,
  expandIcon,
  roundtablePaths,
  ...rest
}) => {
  const [open] = useControlSidebar();
  const [showNotificationDot, setShowNotificationDot] = useState(false);

  const unreadRoundtables = useGetUnreadRoundTables();
  const unreadP2PChats = useGetUnreadP2PIds();

  const userId = useAppSelector((state) => state.auth.userInfo?.user.id);

  useEffect(() => {
    const unreadRef = ref(realtimeDb, `${UNREAD_REALTIME}/${userId}`);

    const unsubscribe = onValue(unreadRef, (snapshot) => {
      const data = snapshot.val();
      const splittedNodeId = nodeId?.split?.(":");
      setShowNotificationDot(
        !!data?.project?.includes(splittedNodeId[splittedNodeId.length - 1])
      );
    });

    return () => {
      unsubscribe();
    };
  }, [nodeId, userId]);

  const showNotificationDotMemo = useMemo(() => {
    const splittedNodeId = nodeId?.split?.(":");

    if (nodeId?.includes?.(SidebarKeys.roundtableItem)) {
      return unreadRoundtables?.includes?.(splittedNodeId[splittedNodeId.length - 1]);
    }

    if ("Teamspaces" === label.toString()) {
      const hasUnreadTeamSpaces = roundtablePaths.some((path) => {
        const [_, splittedTeamspaceId] = path.key ? path.key.split(":") : [];

        return unreadRoundtables?.includes?.(splittedTeamspaceId);
      });

      return hasUnreadTeamSpaces;
    }

    if ("Projects" === label.toString()) {
      const projectPaths = roundtablePaths.flatMap(
        (roundtable) => roundtable?.nestedPaths || []
      );
      const hasUnreadProjects = projectPaths.some((path) => {
        const [, , splittedProjectId] = path.key ? path.key.split(":") : [];

        return unreadRoundtables?.includes?.(splittedProjectId);
      });

      return hasUnreadProjects;
    }

    if (nodeId?.includes?.(SidebarKeys.P2PChat)) {
      return unreadP2PChats.length > 0;
    }

    return showNotificationDot;
  }, [nodeId, showNotificationDot, unreadRoundtables, unreadP2PChats.length]);

  return (
    <CustomTreeItem
      {...rest}
      sidebarOpen={open}
      nodeId={nodeId}
      label={
        <AppTreeItemLabel
          icon={icon}
          text={label}
          expandIcon={open ? expandIcon : null}
          showNotificationDot={showNotificationDotMemo || showNotificationDot}
        />
      }
    />
  );
};

export default AppTreeItem;
