import React from "react";

import { Box, Typography } from "@mui/material";

type LineSeparateTextProps = {
  startText: string;
  endText: string;
};

const LineSeparateText = (props: LineSeparateTextProps) => {
  const { startText, endText } = props;

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Typography>{startText}</Typography>
      <Typography fontWeight={600}>{endText}</Typography>
    </Box>
  );
};
export default LineSeparateText;
