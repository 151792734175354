import { MutableRefObject, Ref, useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Edit as EditIcon, Save as SaveIcon } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import * as yup from "yup";

import { useAppDispatch } from "@app/hooks";

import { updateUserInfo } from "@features/auth/actions";

import Button from "@components/Button";

import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { User } from "@dto/user";

import MainUserInfo from "./MainInfo";

export type UserProfileCommonProps = {
  userData?: User;
  isEdit?: boolean;
  imageUploaderRef?: MutableRefObject<any>;
};

const formSchema = yup.object().shape({
  avatar: yup.mixed().nullable(),
  name: yup.string(),
  bio: yup.string(),
  gallery: yup.array(),
  hourRateTo: yup.number().min(0).nullable(),
  skillTag: yup.array().of(yup.string()).nullable(),
  location: yup.string(),
  level: yup.string(),
  languages: yup.array().of(yup.string()).nullable(),
  institution: yup.string(),
  workingSchedule: yup.string(),
  description: yup.string(),
  links: yup.array().of(
    yup.object().shape({
      url: yup.string(),
      displayText: yup.string(),
    })
  ),
  experiences: yup.array().of(
    yup.object().shape({
      type: yup.string(),
      institutionName: yup.string(),
      position: yup.string(),
      location: yup.string(),
      start: yup.string(),
      to: yup.string().nullable(),
      description: yup.string(),
      institution: yup.object().shape({
        website: yup.string(),
        name: yup.string(),
        logo: yup.string(),
      }),
    })
  ),
  projects: yup.array().of(
    yup.object().shape({
      projectId: yup.string(),
      description: yup.string(),
      project: yup.mixed().nullable(),
    })
  ),
});

const UserProfile = ({ userData, isEdit }: UserProfileCommonProps) => {
  // console.log({
  //   'User-Data-Prop': userData,
  //   isEdit
  // })
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const userProfileData = { ...userData };
  const [edit, setEdit] = useState(false);
  const imageUploaderRef = useRef(null);
  const formMethods = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });
  const { handleSubmit, reset } = formMethods;

  const resetData = () => {
    reset({
      avatar: userProfileData.avatar,
      name: userProfileData.name || "",
      location: userProfileData.location || "",
      level: userProfileData.level || "",
      institution: userProfileData.institution || "",
      gallery: userProfileData.files || [],
      workingSchedule:
        !userProfileData.workingSchedule ||
        userProfileData.workingSchedule.includes("day")
          ? ""
          : userProfileData.workingSchedule,
      languages: userProfileData?.userLanguages?.map((lg) => lg?.language?.name) || [],
      bio: userProfileData.bio || "",
      hourRateTo: userProfileData.hourRateTo,
      skillTag: userProfileData?.skillTag
        ? userProfileData?.skillTag?.split(",")?.filter((s) => s)
        : [],
      links: userProfileData.link?.map((l) => ({ url: l.url, displayText: l.label })),
      description: userProfileData.description || "",
      experiences: userProfileData.experiences || [],
      projects: userProfileData.profileProjects || [],
    });
  };

  useEffect(() => {
    resetData();
  }, [reset, userData]);

  const handleSave = async (data: any) => {
    // console.log('Form-Data', data);
    setLoading(true);
    let avatar = null;
    if (data?.avatar instanceof File) {
      const result = await uploadImagesToServer([data.avatar]);
      avatar = result[0] || null;
    } else {
      avatar = data?.avatar;
    }

    const galleries = await imageUploaderRef.current?.processingImages();

    if (galleries?.length) {
      data.gallery = galleries;
    }

    const sanitizedPayload = {
      ...data,
      avatar,
      skillTag: data?.skillTag?.join(","),
      link: data?.links?.map((link: any) => ({
        url: link?.url,
        label: link?.displayText,
      })),
      experiences: data?.experiences?.map((exp: any) => ({
        "type": exp.type,
        "position": exp.position,
        "location": exp.location,
        "start": exp.start,
        // "to": exp.to || (new Date()).toISOString(),
        "to": exp.to,
        "description": exp.description,
        "institutionName": exp.institutionName,
        "isCurrent": exp.isCurrent,
        "institution": {
          "website": exp?.institution?.website,
          "name": exp?.institution?.name,
          "logo": exp?.institution?.logo,
        },
      })),
      projects: data?.projects?.map((prj: any) => ({
        projectId: prj?.projectId,
        description: prj?.description,
      })),
    };
    // console.log("SanitizedPayload", sanitizedPayload);

    await dispatch(updateUserInfo({ userId: userData?.id, payload: sanitizedPayload }));
    setEdit(false);
    setLoading(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Stack
        sx={{
          position: { xs: "initial", md: "absolute" },
          top: 0,
          right: 0,
          flexDirection: "row",
          gap: 2,
          display: isEdit ? "flex" : "none",
          pb: 3,
        }}
      >
        {edit && (
          <Button
            variant="outlined"
            onClick={() => {
              resetData();
              setEdit(false);
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        )}

        <Button
          startIcon={edit ? <SaveIcon /> : <EditIcon />}
          onClick={edit ? handleSubmit(handleSave) : () => setEdit(true)}
          disabled={loading}
        >
          {edit ? "Save change" : "Edit profile"}
        </Button>
      </Stack>
      <FormProvider {...formMethods}>
        <MainUserInfo
          userData={{
            ...userProfileData,
            userLanguages:
              userProfileData?.userLanguages?.map((lg) => lg?.language?.name) || [],
            link: userProfileData.link?.map((l) => ({
              url: l.url,
              displayText: l.label,
            })),
          }}
          isEdit={edit}
          imageUploaderRef={imageUploaderRef}
        />
      </FormProvider>
    </Box>
  );
};

export default UserProfile;
