import { useMutation } from "@tanstack/react-query";

import { axios, handleApiError } from "@features/config";

import { Application } from "@dto/application";
import { User } from "@dto/user";

type HiringResponseType = {
  success: boolean;
  data?: {
    client_secret: string;
  };
  message?: string;
};

type HiringRequestType = {
  application: Application;
  user: User;
  paymentIntentId?: string;
  transactionId?: string;
};

export const hireMember = async ({
  application,
  user,
  paymentIntentId,
  transactionId,
}: HiringRequestType) => {
  try {
    const data: HiringResponseType = await axios.post(`/job/${application.id}/hired`, {
      appDetails: application,
      userInfo: user,
      paymentIntentId,
      transactionId,
      freelancer: application.createdByUser,
    });

    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return handleApiError(error);
  }
};

const startHiringFreelancerForQuest = async (questId: string): Promise<any> => {
  const res = await axios.patch(`jobs/${questId}/hiring`);
  return res?.data?.data;
};

export const useStartHiringFreelancerForQuest = () => {
  return useMutation(startHiringFreelancerForQuest);
};
