import { useCallback } from "react";

import useMentionUsers from "@api/chat/useMentionUsers";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";

import { useAppSelector } from "@app/hooks";

import MessageInput from "@pages/chat/Conversation/MessageInput";
import { addNewMessage } from "@pages/chat/Conversation/service";
import { MessageType } from "@pages/chat/Conversation/type";

import { isProject } from "@utils/roundtable.utils";

import { Roundtable } from "@dto/roundtable";

import ActivityChat from "./ActivityChat";
import { useQuestNotificationsManagement } from "./QuestNotificationManagement";

type QuestActivityProps = {
  jobId: string;
  roundtableData: Roundtable;
};

export const quesActivityElementId = "quest-activity-chat-area";
const QuestActivity = (props: QuestActivityProps) => {
  const { jobId, roundtableData } = props;
  const { user } = useAppSelector((state) => state.auth.userInfo);
  const {
    palette: { common },
  } = useTheme();
  const { mutate: mentionUsers } = useMentionUsers();
  const projectId = roundtableData?.id;
  const { doSendNotificationToUsers, updateUnreadQuestStatus } =
    useQuestNotificationsManagement();
  const userForMentions = roundtableData?.attendees;
  const handleAddNewMessage = useCallback(
    async ({
      text,
      imageUrls,
      fileUrls,
      mentionedUsers,
    }: {
      text: string;
      imageUrls: { name: string; url: string }[];
      fileUrls: { name: string; url: string }[];
      mentionedUsers: string[];
    }) => {
      if (!text && !imageUrls.length && !fileUrls.length) {
        return;
      }

      const newMessage = {
        text,
        title: user.name || user.username,
        avatar: user.avatar,
        imageUrls,
        fileUrls,
      } as MessageType;
      const messageDoc = await addNewMessage({
        message: newMessage,
        conversationId: jobId,
        userId: user.id,
      });

      if (messageDoc) {
        const formatMessage = `${text} </br>
        ${imageUrls
          ?.map(
            (i) =>
              `<i class="fa-regular fa-file" style="color: #3182ce" ></i><a href="${i.url}">Attachment</a>`
          )
          .join("</br>")}
          ${fileUrls
            ?.map(
              (i) =>
                `<i class="fa-regular fa-file" style="color: #3182ce" ></i><a href="${i.url}">Attachment</a>`
            )
            .join("</br>")}`;
        await doSendNotificationToUsers(jobId, projectId, {
          message: formatMessage,
          sender: {
            name: user.name,
            avatar: user.avatar,
          },
          sendingTime: dayjs(new Date()).format("HH:mm:ss DD MMM YYYY"),
        });
        await updateUnreadQuestStatus(jobId);
      }

      if (mentionedUsers?.length) {
        mentionUsers({
          roundtableId: jobId,
          userIds: mentionedUsers,
        });
      }
    },
    [jobId, user.avatar, user.id, user.name, user.username]
  );

  return (
    <Stack
      height={"calc(100vh - 205px)"}
      overflow={"hidden"}
      flex={1}
      id={quesActivityElementId}
      width={"100%"}
      sx={{
        borderRadius: 4,
        border: (theme) => `1px solid ${theme.palette.common.bellflowerBlue}`,
      }}
    >
      <Stack p={2} borderBottom={"1px solid #e2e8f0"}>
        <Typography fontWeight={700} fontSize={20}>
          Quest Activity
        </Typography>
      </Stack>
      <Stack
        id="chat-editor-area"
        height="calc(100% - 56px)"
        overflow={"hidden"}
        mt={0}
        sx={{ "& .MuiGrid-item": { pt: 0 } }}
      >
        {/* <Conversation
          conversationId={jobId}
          user={user}
          userForMentions={roundtableData?.attendees}
          isRoundtableConversation
        /> */}
        <Box
          flex={1}
          height={"100%"}
          overflow={"auto"}
          p={2}
          bgcolor={common.deutziaWhite}
        >
          <ActivityChat conversationId={jobId} />
        </Box>
        <Box
          sx={{
            bgcolor: "#F7FAFC",
            ".editor-box-area": {
              bgcolor: common.white,
            },
          }}
        >
          <MessageInput
            key={jobId}
            onSubmit={handleAddNewMessage}
            userForMentions={userForMentions}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
export default QuestActivity;
