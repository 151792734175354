import { useState, useRef, memo, useEffect, MouseEvent } from "react";

import useGetApplicationsByJob from "@api/job/useGetApplicationsByJob";
import useUpdateApplicationStatus from "@api/job/useUpdateApplicationStatus";
import {
  Sort as SortIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Stack,
  Box,
  Skeleton,
  Typography,
  Divider,
  Avatar,
  Button,
  IconButton,
  Popover,
  MenuItem,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { applicationActions } from "@features/application/slice";

import { CircleLoading } from "@components/Loading/CircleLoading";

import { formatDurationTimeFromNow } from "@utils/format/dateTime";
import { roundedEarningNumber } from "@utils/numberHelper";

import { ReactComponent as BookmarkIcon } from "@assets/icons/bookmark.svg";
import { ReactComponent as BookmarkFulFillIcon } from "@assets/icons/bookmark_fulfill.svg";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

const statusFilter = [
  { label: "New", value: "" },
  { label: "Saved", value: "saved" },
  { label: "Archived", value: "archived" },
];
const orderByFilter = [
  { label: "Date", value: "createdAt" },
  { label: "Price", value: "expectBudget" },
];

const PanelList = ({ jobId }: { jobId?: string }) => {
  const [mouseOnApp, setMouseOnApp] = useState("");
  const [anchorSort, setAnchorSort] = useState<HTMLButtonElement | null>(null);
  const { filters, applicationId } = useAppSelector((state) => state.application);
  const dispatch = useAppDispatch();
  const { isLoading, data: apps } = useGetApplicationsByJob(jobId, filters);
  const { mutateAsync: updateAppStatus, isLoading: isUpdateAppLoading } =
    useUpdateApplicationStatus();
  const { setSnackbar } = useSnackbar();
  // const renderCount = useRef(0);
  // console.log('app list', { jobId, isLoading, apps });

  const [_, sort] = (filters.order ?? "").split(",");
  useEffect(() => {
    if (apps?.length && !isLoading) {
      dispatch(applicationActions.setApplicationIds(apps.map((item) => item.id)));
    }
  }, [apps]);

  const handleUpdateAppStatus = async (
    e: MouseEvent<HTMLElement>,
    action: "save" | "close",
    statuses: { saved?: boolean; archived?: boolean },
    appId: string
  ) => {
    e?.stopPropagation();
    setMouseOnApp(appId);
    const itemStatuses = { ...statuses };

    if (action === "save") {
      itemStatuses.saved = !itemStatuses.saved;
      if (filters.type === "archived") {
        itemStatuses.archived = false;
      }
    } else if (action === "close") {
      if (!filters.type) {
        itemStatuses.archived = true;
      } else {
        itemStatuses[filters.type] = false;
      }
    }
    try {
      await updateAppStatus({ jobId, applicationId: appId, ...itemStatuses });
      queryClient.invalidateQueries(["getApplicationDetails", jobId, appId]);
      queryClient.invalidateQueries(["getApplicationsByJob", jobId, filters]);
    } catch (error: any) {
      console.log("ERR", error);
      setSnackbar({
        message: error?.response?.data?.message,
        open: true,
        severity: "error",
      });
    }
  };

  return (
    <Stack rowGap={1} sx={{ overflowX: "auto", height: "100%" }}>
      <Box
        display="flex"
        flexDirection={"row"}
        px={2}
        py={1}
        alignItems="center"
        columnGap={1}
        height={40}
      >
        {statusFilter?.map((s, i) => (
          <Button
            key={i}
            variant="outlined"
            sx={{
              height: 24,
              borderRadius: "16px",
              color:
                filters?.type === s.value ? "common.partyTime" : "common.featherSoftBlue",
              borderColor:
                filters?.type === s.value ? "common.partyTime" : "common.featherSoftBlue",
              fontSize: 14,
              fontWeight: 500,
              textTransform: "none",
              p: 1,
            }}
            onClick={() => dispatch(applicationActions.setFilter({ type: s.value }))}
          >
            {s.label}
          </Button>
        ))}
        <Box>
          <IconButton onClick={(e) => setAnchorSort(e.currentTarget)}>
            <SortIcon sx={{ color: "common.featherSoftBlue" }} />
          </IconButton>
          <Popover
            open={!!anchorSort}
            anchorEl={anchorSort}
            onClose={() => setAnchorSort(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{ ".MuiPopover-paper": { p: 1 } }}
          >
            {orderByFilter?.map((opt, i) => (
              <MenuItem
                key={i}
                sx={{ bgcolor: opt.value === filters.order && "common.deutziaWhite" }}
                onClick={() => {
                  const toggleSort = sort === "asc" ? "desc" : "asc";
                  dispatch(
                    applicationActions.setFilter({
                      order: `${opt.value},${toggleSort}`,
                    })
                  );
                  setAnchorSort(null);
                }}
              >
                <Typography width={80} textAlign={"right"}>
                  {opt.label}
                </Typography>
              </MenuItem>
            ))}
          </Popover>
        </Box>
      </Box>
      <Box sx={{ overflowY: "scroll" }}>
        {/* <p>renderCount: {(renderCount.current ++)}</p> */}
        {isLoading ? (
          <Skeleton width="100%" height={65} />
        ) : apps?.length ? (
          // [1,2,3,4,5,6,67,7,8,8,6,7,8,9,0,8,6,4,3,35,6,7,6].map((app, i) => (
          apps.map((app, i) => (
            <Stack key={i} position={"relative"}>
              <Stack
                p={2}
                gap={1.4}
                sx={{
                  cursor: "pointer",
                  ":hover": { bgcolor: "common.deutziaWhite" },
                  position: "relative",
                }}
                bgcolor={
                  (applicationId === app.id || !app.isRead) && "common.deutziaWhite"
                }
                onClick={() => dispatch(applicationActions.setApplicationId(app.id))}
              >
                <IconButton
                  sx={{ position: "absolute", top: 8, right: 10 }}
                  onClick={(e) =>
                    handleUpdateAppStatus(
                      e,
                      "close",
                      { saved: app?.saved, archived: app?.archived },
                      app.id
                    )
                  }
                >
                  <CloseIcon sx={{ width: 18, height: 18 }} />
                </IconButton>
                <Stack flexDirection={"row"} gap={1.2} width={"calc(100% - 26px)"}>
                  <Avatar
                    src={app?.createdByUser?.avatar}
                    sx={{ width: 58, height: 58 }}
                  />
                  <Stack gap={0.4}>
                    <Typography color={"#3182CE"} fontWeight={700}>
                      {app?.createdByUser?.name}
                    </Typography>
                    <Stack flexDirection={"row"} color={"primary.main"}>
                      <StarIcon sx={{ height: 16, width: 16 }} />
                      <Typography fontSize={12} color={"primary"} fontWeight={700}>
                        {Number(app.createdByUser.averageRating).toFixed(1)}/5.0
                        <Typography
                          component={"span"}
                          fontSize={12}
                          color={"common.gray5"}
                        >
                          {" "}
                          (
                          {+app.createdByUser.virtualOrderNumber +
                            +app.createdByUser.completedOrderCount}{" "}
                          orders)
                        </Typography>
                      </Typography>
                    </Stack>
                    <Typography fontSize={12}>
                      Total earnings:{" "}
                      {roundedEarningNumber(app?.createdByUser?.totalEarnings)}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography fontSize={14}>
                  {/* {app?.applyToJob?.title} */}
                  Deliver by:{" "}
                  {`${formatDurationTimeFromNow(+app?.duration)} (if start now)`}
                </Typography>
                <Typography fontSize={14} fontWeight={700}>
                  Hourly rate: ${app?.expectBudget}/h
                </Typography>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={14} fontWeight={700} color="primary">
                    Effort needed: {+app.estimation}h
                  </Typography>
                </Stack>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    color="primary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {app?.expectBudget && "$" + +app.expectBudget * +app.estimation}
                    {app?.hasNewUpdate && (
                      <Typography
                        component={"span"}
                        sx={{
                          m: 1,
                          fontSize: 10,
                          py: 0.5,
                          px: 1,
                          borderRadius: 4,
                          fontWeight: 500,
                          backgroundColor: "common.partyTime",
                          color: "common.white",
                        }}
                      >
                        Updated
                      </Typography>
                    )}
                  </Typography>
                  <IconButton
                    sx={{
                      alignSelf: "flex-end",
                    }}
                    onClick={(e) =>
                      handleUpdateAppStatus(
                        e,
                        "save",
                        { saved: app?.saved, archived: app?.archived },
                        app.id
                      )
                    }
                  >
                    {app?.saved ? <BookmarkFulFillIcon /> : <BookmarkIcon />}
                  </IconButton>
                </Stack>
              </Stack>

              {mouseOnApp === app.id && isUpdateAppLoading && (
                <CircleLoading
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bgcolor: "#ffffff99",
                    opacity: 0.4,
                  }}
                />
              )}
            </Stack>
          ))
        ) : (
          <Box>
            {/* <Divider /> */}
            <Typography fontSize={12} pt={1} sx={{ textAlign: "center" }}>
              No applications found.
            </Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default memo(PanelList);
