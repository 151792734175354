import { GridPaginationModel } from "@mui/x-data-grid";

import { axios, handleApiError } from "@features/config";

import { Roundtable } from "@dto/roundtable";

import { RoundtableType } from "@@types/type";

type Response = {
  success: boolean;
  data?: { data: Roundtable[]; totalItems: number };
  message?: string;
};

interface Request extends Partial<GridPaginationModel> {
  title?: string;
  type?: RoundtableType[];
  parentId?: string;
}

export const getRoundtables = async ({
  title,
  type,
  parentId,
  page = 0,
  pageSize = 5,
}: Request): Promise<Response> => {
  try {
    const { data } = await axios.get(`/round-tables`, {
      params: {
        title,
        type: type.join(","),
        pageSize,
        page: page + 1,
        parentId,
      },
    });
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};
