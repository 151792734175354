import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

interface IRequest {
  id: string;
}

const useDeleteRoundtable = () => {
  return useMutation(async ({ id }: IRequest) => {
    const url = `/round-table/${id}`;
    const response = await axios.delete(url);

    return response.data.data;
  });
};

export default useDeleteRoundtable;
