import { axios } from "@features/config";

const sendMissedActivity = async (jobId: string, activityData: any) => {
  try {
    const response = await axios.post(`/job/${jobId}/missed`, activityData);
    return response.data;
  } catch (error) {
    console.error("Error sending missed activity:", error);
  }
};

export default sendMissedActivity;
