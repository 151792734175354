import { FC } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  SxProps,
  TextField,
  Avatar,
  Alert,
  Stack,
} from "@mui/material";
import dayjs from "dayjs";

import { KickoutEventProposalForm, Proposal } from "@dto/proposal";

const titleSxProps: SxProps = { fontSize: 16, fontWeight: 700, mb: 1 };

interface IProps {
  isOpen: boolean;
  proposal: Proposal;
  onClose: () => void;
}

const ViewKickoutMemberDialog: FC<IProps> = ({ isOpen, proposal, onClose }) => {
  const { createdByUser, data } = proposal;

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
          Kickout (View Only)
        </Typography>

        <CloseIcon
          sx={{
            width: 24,
            height: 24,
            color: "common.black",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" flexWrap={"wrap"}>
          <Typography variant="h6" sx={titleSxProps}>
            Member
          </Typography>

          <Stack direction="row" spacing={0.5} alignItems="center" mx={1} gap={1}>
            <Avatar
              alt="Avatar"
              src={(data as KickoutEventProposalForm)?.user?.avatar}
              sx={{ width: 40, height: 40 }}
            />

            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              {(data as KickoutEventProposalForm)?.user?.name}
              <Typography sx={{ color: "common.partyTime" }}>
                {(data as KickoutEventProposalForm)?.type}
              </Typography>
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" flexWrap={"wrap"} my={3}>
          <Typography variant="h6" sx={titleSxProps}>
            Proposed by
          </Typography>

          <Stack direction="row" spacing={0.5} alignItems="center" mx={1} gap={1}>
            <Avatar
              alt="Avatar"
              src={createdByUser?.avatar}
              sx={{ width: 40, height: 40 }}
            />

            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              {createdByUser?.name}
              <Typography sx={{ color: "common.partyTime" }}>
                {(createdByUser as any)?.attendees?.[0].type}
              </Typography>
            </Typography>
          </Stack>
        </Stack>

        <Box mt={1}>
          <Typography variant="h6" sx={titleSxProps}>
            Reason
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {(proposal?.data as KickoutEventProposalForm)?.reason ||
                  "There is no reason."}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions sx={{ pr: 2.5 }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewKickoutMemberDialog;
