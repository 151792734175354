import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Member } from "@api/proposal/createRoundTableProject";
import useUpdateRoundtableOrProject from "@api/roundtable/useUpdateRoundtableOrProject";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { handleApiError } from "@features/config";

import { ISnackbar } from "@components/Snackbar";

import { isProject as isAProject } from "@utils/roundtable.utils";
import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { Roundtable, VoteModeEnum } from "@dto/roundtable";

import { RoundtableType } from "@@types/type";

import queryClient from "@config/queryClient";

import RoundtableDialog, { ROUNDTABLE_DIALOG_TYPE } from "./RoundtableDialog";

const transformFn = (value: any) => (isNaN(value) ? null : value);
const formSchema = yup.object().shape({
  avatar: yup.mixed().nullable(),
  title: yup.string().required("Title is required."),
  type: yup.string(),
  passedPercentage: yup
    .number()
    .nullable()
    .transform(transformFn)
    .required("Pass Percentage is required."),
  voteMode: yup
    .mixed<VoteModeEnum>()
    .oneOf(Object.values(VoteModeEnum))
    .required("Vote Mode is required."),
  voteDuration: yup
    .number()
    .nullable()
    .transform(transformFn)
    .required("Vote Duration is required."),
  language: yup.string().nullable(),
  institution: yup.string().nullable(),
  location: yup.string().nullable(),
  description: yup.string(),
  requireInstitution: yup.boolean(),
});

export type EditRoundtableForm = {
  avatar: any;
  title: string;
  type: string;
  passedPercentage: number;
  voteMode: VoteModeEnum;
  voteDuration: number;
  language: string;
  institution: string;
  location: string;
  description: string;
  fund?: number;
  members?: string[];
  memberList?: {
    userId?: string;
    voteWeight?: number;
    percentageProfit?: number;
    subRoundTableId?: string;
  }[];
  category?: string;
  tags?: string;
  originalValues?: any;
  requireInstitution?: boolean;
};

interface IProps {
  isOpen?: boolean;
  initData: Roundtable;
  onClose: () => void;
  setSnackbar?: Dispatch<SetStateAction<ISnackbar>>;
  id: string;
  isViewOnly?: boolean;
  removeDialog?: boolean;
}

const EditRoundtableDialog: FC<IProps> = ({
  isOpen,
  initData,
  onClose,
  setSnackbar,
  id,
  isViewOnly,
  removeDialog,
}) => {
  const isProject = isAProject(initData);
  const [imageUploading, setImageUploading] = useState(false);
  const updateRoundtableOrProject = useUpdateRoundtableOrProject();

  const defaultValues: EditRoundtableForm = useMemo(() => {
    return {
      avatar: null,
      title: "",
      type: "Team",
      passedPercentage: null,
      voteMode: VoteModeEnum.SIMPLE_VOTE,
      voteDuration: null,
      language: "",
      institution: "",
      location: "",
      description: "",
      //for project only
      fund: null,
      members: [],
      memberList: [],
      requireInstitution: false,
    };
  }, [initData, isProject]);

  const formMethods = useForm<EditRoundtableForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const {
    reset,
    trigger,
    formState: { isDirty },
  } = formMethods;
  useEffect(() => {
    if (!initData) {
      return;
    }

    const data = initData.originalValues || initData;

    const {
      avatar = "",
      title,
      type,
      passedPercentage,
      voteDuration,
      voteMode,
      language,
      institution,
      location,
      description,
      category,
      tags,
      requireInstitution = false,
    } = data;

    reset({
      avatar,
      title,
      type: type || "Team",
      passedPercentage: +passedPercentage,
      voteDuration: +voteDuration,
      voteMode,
      language,
      institution,
      location,
      description: description ?? "",
      category,
      tags: tags?.join(",") || "",
      fund: isProject ? initData.fund : null,
      members: isProject ? initData.attendees?.map((attendee) => attendee.userId) : [],
      memberList: isProject
        ? initData.attendees?.map((attendee) => ({
            userId: attendee.userId,
            voteWeight: +attendee.voteWeight,
            percentageProfit: +attendee.percentageProfit,
            subRoundTableId: attendee.subRoundTable?.id,
          }))
        : [],
      requireInstitution,
    });

    trigger();
  }, [initData, reset, trigger, isProject]);

  const handleClickOnSave = async (formData: EditRoundtableForm) => {
    let avatar = null;
    if (formData?.avatar instanceof File) {
      setImageUploading(true);
      const result = await uploadImagesToServer([formData.avatar]).finally(() =>
        setImageUploading(false)
      );
      avatar = result[0] || null;
    } else {
      avatar = formData?.avatar;
    }

    const {
      type,
      title,
      voteMode,
      description,
      voteDuration,
      passedPercentage,
      location,
      institution,
      language,
      tags,
      category,
      fund,
      memberList,
      requireInstitution,
    } = formData;

    const _memberList = memberList.map((member) => {
      const obj: Member = {
        percentageProfit: member.percentageProfit,
        voteWeight: member.voteWeight,
      };
      const attendee = initData?.attendees.find(
        (attendee) =>
          attendee.userId === member.userId || attendee.roundTableId === member.userId
      );
      if (attendee.userId) {
        obj.userId = attendee.userId;
      } else if (attendee.roundTableId) {
        obj.subRoundTableId = attendee.roundTableId;
      }

      return obj;
    });

    const payload = {
      type: type as RoundtableType,
      title,
      avatar,
      voteMode,
      description,
      passedPercentage,
      voteDuration,
      location,
      institution,
      language,
      tags: tags ? [tags] : [],
      category,
      fund,
      memberList: _memberList,
      requireInstitution,
    };

    updateRoundtableOrProject.mutate(
      {
        id,
        payload,
        proposalType: isProject ? ROUNDTABLE_DIALOG_TYPE.PROJECT_MANAGEMENT : undefined,
      },
      {
        onSuccess: () => {
          setSnackbar?.({
            message: "Your proposal was created successfully!",
            open: true,
            severity: "success",
          });

          queryClient.invalidateQueries(["getRoundTableDetail", id]);
          queryClient.invalidateQueries(["getEventLogs"]);
          onClose();
        },
        onError: (error) => {
          setSnackbar?.({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <FormProvider {...formMethods}>
      <RoundtableDialog
        isEdit
        isOpen={isOpen}
        isLoading={updateRoundtableOrProject.isLoading || imageUploading}
        isDisabledSubmitBtn={!isDirty}
        dialogTitle={`${isProject ? "Update Project Management" : "Edit Teamspace"} ${
          isViewOnly ? "(View Only)" : ""
        }`}
        roundtableId={initData?.id}
        onClose={onClose}
        onSave={handleClickOnSave}
        project={isProject ? initData : null}
        isViewOnly={isViewOnly}
        type={isProject ? "UPDATE_PROJECT_MANAGEMENT" : "ROUNDTABLE"}
        removeDialog={removeDialog}
      />
    </FormProvider>
  );
};

export default EditRoundtableDialog;
