import { string } from "yup";

import { axios, handleApiError } from "@features/config";

import { DeliveryDto } from "@dto/job";

import { Delivery_Status } from "./type";

type DeliveryHistoryResponseType = {
  data?: DeliveryDto[];
  success?: boolean;
};

type DeliveryHistoryRequestType = {
  jobId: string;
  userId: string;
};

export const getDeliveryHistory = async ({
  jobId,
  userId,
}: DeliveryHistoryRequestType): Promise<DeliveryHistoryResponseType> => {
  try {
    const response = await axios.get(`/job/${jobId}/freelancers/${userId}/deliveries`);

    return { data: response?.data?.data?.deliveries, success: true };
  } catch (error) {
    return handleApiError(error);
  }
};
