/* eslint-disable react/jsx-no-bind */
import { FC } from "react";
import { Controller, Control } from "react-hook-form";

import TextField, { TextFieldProps } from "@mui/material/TextField";

export interface IFormInput {
  name: string;
  control: any | Control;
  label?: string;
  defaultValue?: string;
  [x: string]: unknown;
}
// export type IFormInput = {
//   name: string;
//   control: any | Control;
//   label?: string;
//   defaultValue?: string;
// } & TextFieldProps;

const FormInput: FC<IFormInput> = ({ name, control, label, defaultValue, ...others }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <TextField
            fullWidth
            label={label}
            {...field}
            {...others}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
          />
        );
      }}
    />
  );
};

export default FormInput;
