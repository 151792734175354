import React, { useContext, useState } from "react";

import { reviewDelivery } from "@api/job/reviewDelivery";
import { Delivery_Status } from "@api/job/type";
import { Close as CloseIcon } from "@mui/icons-material";
import { Avatar, Box, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";

import Button from "@components/Button";
import ConfirmDialog from "@components/Dialog/ConfirmDialog/ConfirmAction";
import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";
import FormInput from "@components/FormInput";

import { getMiddleEllipsisText } from "@utils/helpers/stringHelper";

import { ReactComponent as AttactmentIcon } from "@assets/icons/attachments.svg";

import { DeliveryDto } from "@dto/job";

import { DeliveryContext } from "./DeliveryContext";

type ReviewDeliveryProps = {
  delivery?: DeliveryDto;
};

const ReviewDelivery = (props: ReviewDeliveryProps) => {
  const { delivery } = props;
  const {
    palette: { common },
  } = useTheme();

  const { mutate: review, isLoading } = useMutation({
    mutationFn: reviewDelivery,
  });

  const { setDelivery, selectedDelivery } = useContext(DeliveryContext);
  const [reviewType, setReviewType] = useState<Delivery_Status | null>(null);
  const [note, setNote] = useState("");
  const onReviewDelivery = () => {
    review({
      note,
      status: reviewType,
      deliveryId: selectedDelivery,
      jobId: delivery.jobId,
    });
  };

  return (
    <Stack p={2} flex={1} overflow={"auto"} justifyContent={"space-between"}>
      <Stack
        borderRadius={2}
        border={"1px solid #E2E8F0"}
        p={2}
        mb={2}
        flexGrow={1}
        overflow={"auto"}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Avatar src={delivery.freelancer.avatar} />
          <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setDelivery("")} />
        </Stack>
        <Typography mt={2}>
          <b>{delivery.freelancer.name}</b> submitted an attachment
        </Typography>
        <Typography fontSize={14} color={common.gray5} mt={3}>
          {delivery.note}
        </Typography>

        <Stack mt={3}>
          <Typography color={common.gray5} fontSize={14} fontWeight={600}>
            Delivery time
          </Typography>
          <Typography fontSize={14} fontWeight={600}>
            {dayjs(delivery.createdAt).format("MMMM D, YYYY h:mm A") + " (EST)"}
          </Typography>
        </Stack>
        {delivery.attachment.length > 0 && (
          <Stack mt={3} gap={1.5}>
            <Typography color={common.gray5} fontSize={14} fontWeight={600}>
              Attachment
            </Typography>
            {delivery.attachment.map((attachment, i) => (
              <Stack
                key={i}
                px={1.5}
                py={1}
                borderRadius={2}
                onClick={() => window.open(attachment, "_blank")}
                flexDirection={"row"}
                gap={1}
                sx={{
                  cursor: "pointer",
                  border: common.border,
                }}
              >
                <AttactmentIcon />
                <Typography
                  fontSize={14}
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    flex: 1,
                  }}
                >
                  {getMiddleEllipsisText(attachment, 30)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>

      {delivery.status === Delivery_Status.PENDING && (
        <Stack flexDirection={"row"} gap={1}>
          <Button
            variant="outlined"
            sx={{
              flex: 1,
              borderRadius: 2,
              borderColor: common.wolfram,
              color: common.black,
            }}
            onClick={() => setReviewType(Delivery_Status.REJECTED)}
          >
            <Typography>Reject</Typography>
          </Button>

          <Button
            variant="contained"
            sx={{ borderRadius: 2, flex: 1 }}
            onClick={() => setReviewType(Delivery_Status.APPROVED)}
          >
            <Typography>Accept</Typography>
          </Button>
        </Stack>
      )}
      {reviewType && (
        <CustomConfirmationDialog
          title={
            reviewType === Delivery_Status.REJECTED
              ? "Are you sure to reject this delivery?"
              : "Are you sure to accept this delivery?"
          }
          buttons={[
            {
              variant: "outlined",
              label: "Review again",
              isLoading,
            },
            {
              label:
                reviewType === Delivery_Status.REJECTED ? "Reject it!" : "Yes, I like it",
              isLoading,
              handleOnClickButton: onReviewDelivery,
            },
          ]}
          content={
            reviewType === Delivery_Status.REJECTED ? (
              <TextField
                fullWidth
                multiline
                maxRows={10}
                minRows={10}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Note the reason to freelancer"
                size="small"
              />
            ) : (
              "By accepting this delivery, you agree to proceed with payment to the freelancer."
            )
          }
          openDialog={!!reviewType}
          setOpenDialog={() => setReviewType(null)}
        />
      )}
    </Stack>
  );
};
export default ReviewDelivery;
