import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";

import { Member } from "@api/proposal/createRoundTableProject";
import useCreateProjectRoundtable from "@api/proposal/useCreateProjectRoundtable";
import { uploadRoundtableFiles } from "@api/roundtable/uploadRoundtableFiles";
import useGetSearchCriteria from "@api/roundtable/useGetSearchCriteria";
import useUpdateRoundtableOrProject from "@api/roundtable/useUpdateRoundtableOrProject";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoIcon from "@mui/icons-material/Info";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  MenuItem,
  DialogActions,
  Divider,
  Avatar,
  Alert,
  Stack,
  Typography,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import * as yup from "yup";

import { useAppSelector } from "@app/hooks";

import { handleApiError } from "@features/config";

import Button from "@components/Button";
import FormInput from "@components/FormInput";
import ImageUploader from "@components/ImageUploader";
import LinkInput from "@components/LinkInput";
import MultiFileUploader from "@components/MultiFileUploader";
import { ISnackbar } from "@components/Snackbar";

import { capitalizeFirstChar } from "@utils/helpers/stringHelper";
import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { CreateProjectForm, Proposal } from "@dto/proposal";
import { ManagementEnum, Roundtable } from "@dto/roundtable";

import { RoundtableType, RoundtableTypeEnum } from "@@types/type";

import queryClient from "@config/queryClient";

import Members from "./Members";

const DefaultCategory = "Others";

type Type = {
  value: RoundtableType;
  text: string;
};
const types: Type[] = [
  {
    value: RoundtableTypeEnum.SELF,
    text: "Self",
  },
  // {
  //   value: RoundtableTypeEnum.FREELANCER,
  //   text: "Freelancer",
  // },
];

const transformFn = (value: any) => (isNaN(value) ? null : value);
const formSchema = yup.object().shape({
  avatar: yup.mixed().nullable(),
  title: yup.string().required("Title is required."),
  type: yup.string(),
  fund: yup.number().nullable().transform(transformFn),
  description: yup.string().nullable(),
  members: yup.array().of(yup.string()).min(1, "Members is required"),
  memberList: yup.array().of(
    yup.object().shape({
      userId: yup.string().nullable(),
      voteWeight: yup
        .number()
        .min(0, "Vote Weight must be greater than 0")
        .max(100, "Vote Weight must be less than 100")
        .nullable()
        .transform(transformFn)
        .required("Vote Weight is required"),
      percentageProfit: yup
        .number()
        .min(0, "Profit Share must be greater than 0")
        .max(100, "Profit Share must be less than 100")
        .nullable()
        .transform(transformFn),
      // .required("Profit Share is required"),
    })
  ),
  tags: yup.array().of(yup.string()).nullable(),
  category: yup.string(),
  // category: yup.string().when("tags", (tags, schema) => {
  //   if (!!tags?.length && typeof tags[0] === "string" ? !!tags[0] : !!tags[0].length) {
  //     return schema.required("Category is required when Tags are provided.");
  //   }

  //   return schema.nullable();
  // }),
});

export type ProjectForm = {
  avatar: any;
  title: string;
  type?: RoundtableType;
  fund: number;
  description: string;
  members: string[];
  memberList: {
    userId?: string;
    voteWeight?: number;
    percentageProfit?: number;
    subRoundTableId?: string;
  }[];
  category?: string;
  tags?: string[];
  links: { url: string; displayText: string }[];
  videoLinks: { url: string; displayText: string }[];
};

interface IProps {
  id?: string;
  isOpen: boolean;
  isEdit?: boolean;
  isViewOnly?: boolean;
  project: Roundtable & { memberList?: Member[] };
  onClose: () => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  proposal?: Proposal;
}

const _onSuccess = ({
  onClose,
  setSnackbar,
}: {
  onClose: () => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
}) => {
  setSnackbar({
    message: "Your proposal was created successfully!",
    open: true,
    severity: "success",
  });

  onClose();
  queryClient.invalidateQueries(["getEventLogs"]);
  queryClient.invalidateQueries(["getJoinedRoundtables"]);
};

const _onError = ({
  error,
  setSnackbar,
}: {
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  error: unknown;
}) => {
  setSnackbar({
    message: handleApiError(error).message,
    open: true,
    severity: "error",
  });
};

const CreateUpdateProjectDialog: FC<IProps> = ({
  id,
  isOpen,
  isEdit,
  isViewOnly,
  proposal,
  project,
  onClose,
  setSnackbar,
}) => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const projectCreatorId = userInfo?.user?.id;

  const [imageUploading, setImageUploading] = useState(false);
  const imageUploaderRef = useRef(null);

  const {
    mutate: createProjectRoundtable,
    isLoading: isLoadingProjectRoundtableCreation,
  } = useCreateProjectRoundtable();
  const { mutate: updateRoundtableOrProject } = useUpdateRoundtableOrProject();
  const { data: searchCriteria } = useGetSearchCriteria();

  const defaultValues: ProjectForm = useMemo(() => {
    return {
      avatar: project?.avatar || null,
      title: project?.title ? `${project.title} - new project` : "New project",
      // type: RoundtableTypeEnum[project.type] || Roundt ableTypeEnum.SELF,
      type: RoundtableTypeEnum.SELF,
      fund: project?.fund,
      description: project?.description,
      members: !isViewOnly
        ? project.attendees
            ?.filter((a) => a.userId === projectCreatorId)
            .map((attendee) => attendee.userId)
        : [],
      memberList: isEdit
        ? project.attendees?.map((attendee) => ({
            userId: attendee.userId,
            voteWeight: +attendee.voteWeight,
            percentageProfit: +attendee.percentageProfit,
            subRoundTableId: attendee.subRoundTable?.id,
          }))
        : [],
      links: [],
      videoLinks: [],
      tags: !!project?.tags?.length ? project.tags : [],
      category: !isEdit && !isViewOnly && "",
    };
  }, [project]);

  const formMethods = useForm<ProjectForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = formMethods;
  const watchedVideoLinks = watch("videoLinks") || [];
  const watchedLinks = watch("links") || [];
  const members = watch("members");
  const attendees = project?.attendees;

  const handleUploadAvatar = useCallback(
    (file: File) => {
      setValue("avatar", file, {
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const processingAvatar = useCallback(
    async (avatar: any) => {
      let result = null;
      if (avatar instanceof File) {
        const response = await uploadImagesToServer([avatar]);
        avatar = response[0] || null;
      } else {
        result = avatar;
      }
      return result;
    },
    [uploadImagesToServer]
  );

  //temporarily using for hiding member list
  useEffect(() => {
    /**
     * Skip this function if the Dialog is only for view
     */
    if (isViewOnly) {
      return;
    }

    /**
     * Adjust the form data, field memberList
     * Adjust the default value for vote weight by attendee's vote weight
     */
    const currentInfo: any = {};
    const currentMembersInfo = getValues("memberList");
    currentMembersInfo?.forEach(({ userId, voteWeight, percentageProfit }) => {
      currentInfo[userId] = { voteWeight, percentageProfit };
    });

    //  Remove null item in member (with list have Sub-Roundtable)
    const newMember = members.filter((m) => m);
    const newMembersInfo = newMember.map((m) => {
      const curMember = attendees?.find((_) => _.userId === m);
      const defaultVoteWeight = curMember?.userId === projectCreatorId ? 100 : 0;

      return {
        userId: m,
        percentageProfit: null,
        ...(currentInfo[m] || {}),
        voteWeight: projectCreatorId
          ? defaultVoteWeight
          : Number((currentInfo?.[m]?.voteWeight * 100)?.toFixed(2)),
      };
    });

    setValue("memberList", newMembersInfo, { shouldValidate: true });
  }, [isViewOnly, attendees, getValues, members, setValue, projectCreatorId]);

  const handleSave = async (data: ProjectForm) => {
    if (
      data.memberList.reduce(
        (accumulator, currentMem) => accumulator + currentMem.voteWeight,
        0
      ) !== 100
    ) {
      return setSnackbar({
        message: "Total vote weight is not equal to 100%. Please check!",
        open: true,
        severity: "error",
      });
    }

    if (!id) {
      return;
    }

    setImageUploading(true);
    const files = await imageUploaderRef.current?.processingImages();
    await uploadRoundtableFiles({
      roundtableId: project?.id,
      files,
    });

    const avatar = await processingAvatar(data?.avatar).finally(() =>
      setImageUploading(false)
    );

    const {
      description,
      fund,
      title,
      type,
      memberList,
      tags,
      category,
      links,
      videoLinks,
    } = data;

    const _memberList = memberList.map((member) => {
      const obj: Member = {
        percentageProfit: member.percentageProfit,
        voteWeight: member.voteWeight,
      };
      const attendee = project?.attendees.find(
        (attendee) =>
          attendee.userId === member.userId || attendee.roundTableId === member.userId
      );
      if (attendee.userId) {
        obj.userId = attendee.userId;
      } else if (attendee.roundTableId) {
        obj.subRoundTableId = attendee.roundTableId;
      }

      return obj;
    });

    const payload = {
      avatar,
      description,
      fund,
      title,
      videoLinks,
      links,
      type,
      memberList: _memberList,
      managementStyle: ManagementEnum.TRADITIONAL,
      category: category || DefaultCategory,
      tags,
    };

    isEdit
      ? updateRoundtableOrProject(
          { id, payload },
          {
            onSuccess: () => {
              _onSuccess({
                setSnackbar,
                onClose,
              });
            },
            onError: (error) => {
              _onError({ setSnackbar, error });
            },
          }
        )
      : createProjectRoundtable(
          {
            roundtableId: id,
            payload: { ...payload, requireApplication: false },
          },
          {
            onSuccess: () => {
              _onSuccess({
                setSnackbar,
                onClose,
              });
            },
            onError: (error) => {
              _onError({ setSnackbar, error });
            },
          }
        );
  };

  const avatar = watch("avatar");
  const watchedTags = watch("tags") || [];

  useEffect(() => {
    if ((isViewOnly || isEdit) && proposal.data) {
      const { title, type, fund, description, category, tags } =
        proposal.data as Roundtable;

      setValue("title", title);
      setValue("type", type);
      setValue("fund", fund);
      setValue("description", description);
      setValue("category", category);
      setValue("tags", tags ?? []);
    }
  }, [proposal]);

  const handleKeyDownTag = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const input = e.target as HTMLInputElement;
      const value = input.value;
      value && setValue("tags", [...getValues("tags"), value]);
    }
  }, []);

  const handleDeleteTag = (index: number) => {
    const filteredTags = getValues("tags").filter(
      (tag: string, i: number) => i !== index
    );

    setValue("tags", filteredTags);
  };

  const handleClickDeleteLink = (indexItem: number, isVideoLinkMode?: boolean) => {
    if (isVideoLinkMode) {
      watchedVideoLinks.splice(indexItem, 1);
      setValue("videoLinks", watchedVideoLinks);
    } else {
      watchedLinks.splice(indexItem, 1);
      setValue("links", watchedLinks);
    }
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle sx={{ fontSize: 20, fontWeight: 700 }}>
        {`${
          isViewOnly
            ? "Project Detail (View Only)"
            : isEdit
            ? "Update Project"
            : "Create Project"
        }`}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <>
            {proposal && (
              <Alert
                severity="info"
                icon={<InfoIcon fontSize="inherit" />}
                sx={{
                  alignItems: "center",
                  color: "common.black",
                  mb: 2,
                  px: { xs: 1.5, sm: 2 },
                }}
              >
                <Typography sx={{ fontSize: 14 }}>
                  The project is under approval. Expired by:{" "}
                  <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
                    {dayjs.duration(dayjs(proposal.endAt).diff(dayjs())).humanize(true)}
                  </Typography>
                </Typography>

                <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
                  <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
                    Last edited by:
                  </Typography>

                  <Stack direction="row" spacing={0.5} alignItems="center" mx={1}>
                    <Avatar
                      alt="Avatar"
                      src={proposal?.createdByUser?.avatar}
                      sx={{ width: 20, height: 20 }}
                    />

                    <Typography
                      component="span"
                      sx={{ fontSize: 14, fontWeight: 600, color: "common.partyTime" }}
                    >
                      {proposal.createdByUser?.name}
                    </Typography>
                  </Stack>

                  <Typography component="span" sx={{ fontSize: 14 }}>
                    {dayjs(proposal.startAt).format("hh:mm A, YYYY MMM DD")}
                  </Typography>
                </Stack>
              </Alert>
            )}
          </>

          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={4}>
              <ImageUploader
                onSetValue={handleUploadAvatar}
                initAvatar={
                  isViewOnly ? (proposal?.data as CreateProjectForm)?.avatar : avatar
                }
                variant="rounded"
                isViewOnly={isViewOnly}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <FormInput
                required
                control={control}
                name="title"
                label="Project Name"
                size="small"
                error={!!errors?.title?.message}
                helperText={errors?.title?.message}
                defaultValue={project?.title || ""}
                disabled={isViewOnly}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormInput
                select
                control={control}
                name="type"
                label="Type"
                size="small"
                disabled={isViewOnly}
                defaultValue={RoundtableTypeEnum.SELF}
              >
                {types.map(({ value, text }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </FormInput>
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <FormInput
                control={control}
                name="fund"
                label="Fund ($)"
                size="small"
                type="number"
                error={!!errors?.fund?.message}
                helperText={errors?.fund?.message}
                disabled={isViewOnly}
              />
            </Grid> */}

            {/* <Grid item xs={12} md={6}>
              <FormInput
                select
                control={control}
                name="category"
                label="Category"
                size="small"
                type="string"
                error={!!errors?.category?.message}
                helperText={errors?.category?.message}
                disabled={isViewOnly}
              >
                {searchCriteria?.categories.map((category, index) => {
                  return (
                    <MenuItem
                      key={`${index}-${category.name}-category-option-from-proj-creation`}
                      value={category.name}
                    >
                      {capitalizeFirstChar(category.name ?? "")}
                    </MenuItem>
                  );
                })}
              </FormInput>
            </Grid> */}

            <Grid item xs={12} md={6}>
              <Autocomplete
                clearIcon={false}
                options={[]}
                freeSolo
                multiple
                limitTags={2}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Tags"
                    placeholder="Press Enter to add tag"
                    onKeyDown={handleKeyDownTag}
                  />
                )}
                value={watchedTags ?? []}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      key={`${index}-${option}-chip-label`}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      onDelete={(event: any) => handleDeleteTag(index)}
                    />
                  ))
                }
                disabled={isViewOnly}
                sx={{
                  "& > .MuiFormControl-root .MuiInputBase-root": {
                    ".MuiChip-root": {
                      maxHeight: 24,
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                multiline
                maxRows={5}
                minRows={5}
                control={control}
                name="description"
                label="Description"
                disabled={isViewOnly}
              />
            </Grid>
          </Grid>
        </Box>

        {/* <FormProvider {...formMethods}>
          <Members
            attendees={project?.attendees}
            isViewOnly={isViewOnly}
            projectCreatorId={projectCreatorId}
          />
        </FormProvider> */}
        {/* <Divider sx={{ my: 2 }} />
        {!isViewOnly && (
          <>
            <LinkInput
              labelTitle={"Youtube video links"}
              isViewOnly={isViewOnly}
              displayLinks={watchedVideoLinks}
              handleClickDeleteLink={handleClickDeleteLink}
              setSnackbar={setSnackbar}
              isVideoLinkMode
              handleVideoLink={(linkDetail) =>
                setValue("videoLinks", [
                  ...getValues("videoLinks"),
                  {
                    url: linkDetail.url,
                    displayText: linkDetail.displayText || linkDetail.url,
                  },
                ])
              }
            />
            <Divider sx={{ my: 2 }} />

            <Typography fontWeight={"bold"} mb={1}>
              Add attachment - files/images
            </Typography>
            <MultiFileUploader roundtableId={project?.id} ref={imageUploaderRef} />
          </>
        )} */}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          disabled={isLoadingProjectRoundtableCreation || imageUploading}
          onClick={onClose}
        >
          Cancel
        </Button>

        {!isViewOnly && (
          <Button
            loading={isLoadingProjectRoundtableCreation || imageUploading}
            disabled={!isValid || isLoadingProjectRoundtableCreation || imageUploading}
            onClick={handleSubmit(handleSave)}
          >
            {isEdit ? "Update" : "Create"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateUpdateProjectDialog;
