import { CONVERSATION_COLlECTION, USER_COLlECTION } from "@fb/const";
import { db } from "@fb/db";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import uniq from "lodash/uniq";

import { User } from "@dto/user";

const updateLastMessage = async ({
  user,
  conversationId,
  isRoundtableConversation,
}: {
  user: User;
  conversationId: string;
  isRoundtableConversation: boolean;
}) => {
  try {
    const conversationDocRef = doc(db, CONVERSATION_COLlECTION, conversationId);
    const conversationDoc = await getDoc(conversationDocRef);

    if (conversationDoc.exists()) {
      const roundtable = conversationDoc.data();

      Promise.all(
        roundtable.participants
          ?.filter((id: string) => id && id !== user.id)
          .map(async (id: string) => {
            const userDocRef = doc(db, USER_COLlECTION, id);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.data();

            if (isRoundtableConversation) {
              const updatedUnreadRoundtableIds = uniq([
                ...(userData?.unreadRoundTableIds ?? []),
                conversationId,
              ]);

              await updateDoc(userDocRef, {
                unreadRoundTableIds: updatedUnreadRoundtableIds,
              });
            } else {
              const updatedUnreadP2PIds = uniq([
                ...(userData?.unreadP2PIds ?? []),
                conversationId,
              ]);

              await updateDoc(userDocRef, {
                unreadP2PIds: updatedUnreadP2PIds,
              });
            }
          })
      );

      await updateDoc(conversationDocRef, { ...roundtable, lastMessageAt: +new Date() });
    } else {
      throw new Error("Roundtable conversation not found!");
    }

    return conversationDoc;
  } catch (e) {
    console.error("Error updating last message: ", e);
  }
};

export default updateLastMessage;
