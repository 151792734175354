import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

import { JOB_STATUS } from "@pages/jobs-management/constants";

// Adjust the import path as necessary

export type EditJobRequestType = {
  id: string;
  title: string;
  description: string;
  deliverables?: string;
  detailOfWork?: string;
  priority?: string;
  dueDate?: string;
  attachment?: string[];
  skills: { label?: string; value?: number; text: string }[];
  budget: number;
  isPublic: boolean;
  projectId: string;
  status?: (typeof JOB_STATUS)[keyof typeof JOB_STATUS];
};

export const editJob = async (payload: Partial<EditJobRequestType>): Promise<any> => {
  const res = await axios.patch("update-jobs", payload);
  return res?.data?.data;
};

const editQuestDraft = async (payload: Partial<EditJobRequestType>): Promise<any> => {
  const res = await axios.patch("update-job-draft", payload);
  return res?.data?.data;
};

const publishHiringQuest = async (questId: string): Promise<any> => {
  const res = await axios.patch(`jobs/${questId}/public-draft`);
  return res?.data?.data;
};

const useEditJob = () => {
  return useMutation(editJob);
};

export const useEditQuestDraft = () => {
  return useMutation(editQuestDraft);
};

export const usePublishHiringQuest = () => {
  return useMutation(publishHiringQuest);
};

export default useEditJob;
