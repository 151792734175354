import { FC, ReactNode } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

interface IProps {
  title?: string;
  children?: ReactNode;
  defaultExpanded?: boolean;
}

const CollapseSection: FC<IProps> = ({ title, children, defaultExpanded }) => {
  return (
    <Accordion defaultExpanded={defaultExpanded} sx={{ borderRadius: 2, boxShadow: "none", "&.Mui-expanded": { margin: 0 }, "&.MuiAccordion-root:before": { bgcolor: "unset" } }}>
      <AccordionSummary
        expandIcon={<IconButton sx={{ border: "1px solid #E2E8F0", borderRadius: 2, bgcolor: "#F7FAFC", width: 24, height: 24 }}><ArrowDropDownIcon /></IconButton>}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ px: 4 }}
      >
        <Typography fontSize={20} fontWeight={700}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 4, pb: 4 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default CollapseSection;
