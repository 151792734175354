import { FC } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { useViewport } from "@contexts/ViewportContext";

import AuthImage from "../../../assets/images/auth2.png";
import WhiteRoundtableLogo from "../../../assets/images/roundtableHorizontalWhiteout.png";

const AuthLayout: FC = () => {
  const { isDesktop } = useViewport();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isPasswordRecoveryPage = pathname.includes("forget-password");

  const handleClickBack = () => {
    navigate(`/sign-in`);
  };

  return (
    <>
      {isPasswordRecoveryPage && !isDesktop && (
        <Box
          pl={2}
          pr={2}
          height={48}
          sx={{ backgroundColor: "common.partyTime", color: "common.white" }}
          display="flex"
        >
          <IconButton onClick={handleClickBack}>
            <ArrowBackIcon sx={{ color: "common.white" }} />
          </IconButton>
          <Typography variant="h6" margin="auto" fontWeight="400">
            Password Recovery
          </Typography>
        </Box>
      )}
      <Box
        component="div"
        sx={{
          height: { xs: "calc(100vh - 80px)" },
          minHeight: "500px",
          padding: {
            xs: "16px 16px 64px",
            md: "40px",
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={isPasswordRecoveryPage && !isDesktop ? "unset" : "center"}
          sx={{
            height: { xs: "100%", md: "calc(100vh - 128px)" },
            margin: "auto 0",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          {isDesktop && (
            <Grid
              item
              md={6}
              sx={{
                height: "100%",
                width: "50%",
                maxHeight: 864,
                maxWidth: 400,
              }}
              textAlign="right"
            >
              <Box
                position="relative"
                width="min-content"
                height="inherit"
                sx={{ float: "right" }}
              >
                <img
                  src={AuthImage}
                  alt="authImg"
                  style={{
                    aspectRatio: "3/4",
                    objectFit: "contain",
                    maxHeight: "100%",
                    borderRadius: "24px",
                  }}
                />
                <Stack
                  position="absolute"
                  sx={{
                    bottom: 52,
                    left: 48,
                  }}
                  rowGap={5}
                >
                  <Box maxHeight={40} maxWidth={200}>
                    <img
                      src={WhiteRoundtableLogo}
                      alt="rt-logo"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Stack>
              </Box>
            </Grid>
          )}
          <Grid
            container
            xs={12}
            md={6}
            justifyContent="center"
            height="100%"
            alignItems="center"
            maxHeight={864}
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
          >
            <Box
              sx={{
                m: "auto",
                display: "flex",
                flexDirection: "column",
                minWidth: { xs: "100%", sm: "80%" },
                overflow: "auto",
              }}
            >
              {((!isPasswordRecoveryPage && !isDesktop) || isDesktop) && (
                <Box
                  sx={{
                    height: 40,
                    marginBottom: 5,
                  }}
                  mx={!isDesktop ? "auto" : 0}
                >
                  <img src={"/logo.png"} alt="heading-logo" height={50} />
                </Box>
              )}

              <Outlet />
            </Box>

            <Box
              sx={{
                mx: "auto",
                mt: "auto",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                minWidth: { xs: "100%", sm: "80%" },
                minHeight: 40,
              }}
            >
              <Stack maxWidth={{ md: 360 }}>
                <Typography
                  sx={{ bottom: 0, fontSize: 12, textAlign: "center" }}
                  color="common.wolfram"
                >
                  © 2024 Roundtable Corporation. All rights reserved
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AuthLayout;
