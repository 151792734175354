import { FC, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSnackbar } from "@contexts/SnackbarContext";
import revokeVoteDelegate from "@api/delegations/revokeDelegation";
import { useParams } from "react-router-dom";
import useVoteDelegations from "@api/delegations/useVoteDelegations";

interface IProps {
  onClose: () => void;
  id: string;
}

const ConfirmRevokeDelegation: FC<IProps> = ({
  onClose,
  id,
}) => {
  const { roundtableId, projectId } = useParams();
  const conversationId = projectId ?? roundtableId;
  const { setSnackbar } = useSnackbar()
  const { refetch } = useVoteDelegations()
  const [loading, setLoading ]= useState(false)

  const handleClickOnRevoke = () => {
    setLoading(true)
    revokeVoteDelegate({
      roundtableId: conversationId,
      delegationId: id,
    })
      .then(() => {
        setSnackbar({
          message: "Your delegation was revoked successfully!",
          open: true,
          severity: "success",
        })
        onClose()
        return refetch()
      })
      .catch((e) => {
        setSnackbar({
          message: e.data.message,
          open: true,
          severity: "error",
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Dialog fullWidth open maxWidth="sm">
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
        Revoke Delegation
      </DialogTitle>

      <DialogContent>
        <Typography>
          Are you sure you want to revoke vote weight delegated to this person?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={loading}>
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={handleClickOnRevoke}
          disabled={loading}
        >
          Revoke
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRevokeDelegation;
