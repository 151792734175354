import { FC, useCallback, useEffect, useState } from "react";

import { NOT_APPLICABLE } from "@constants/index";
import { Avatar, IconButton, Link, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import CustomTooltip from "@components/CustomTooltip";
import RoundtableDetailsDialog from "@components/Dialog/RoundtableDetailsDialog";
import UserDetailDialog from "@components/Dialog/UserDetailDialog";

import { convertBetween0And1ToPercentage } from "@utils/numberHelper";
import { renderAccountType } from "@utils/format";

import { Attendee, ManagementEnum } from "@dto/roundtable";

import { useViewport } from "@contexts/ViewportContext";

interface RTItem {
  attendee: Attendee;
  handleOpenMemberDetailDialog: (attendee: Attendee) => void;
  isTraditionalAdminStyle?: boolean;
}
const RoundTableItem = ({ attendee, handleOpenMemberDetailDialog, isTraditionalAdminStyle }: RTItem) => {
  const { title, name, avatar, type } = attendee;
  const isMember = type === "MEMBER";
  const titleText = isMember ? name : title;

  const handleClick = () => {
    // if (!isMember) {
    //   return;
    // }

    handleOpenMemberDetailDialog(attendee);
  };

  return (
    <Stack direction="row" alignItems="center" minWidth={200} py={1} spacing={1}>
      <IconButton onClick={handleClick}>
        <Avatar alt={titleText} src={avatar} sx={{ width: 32, height: 32 }} />
      </IconButton>

      <Stack>
        <Typography>
          <Link
            tabIndex={0}
            component="button"
            onClick={handleClick}
            sx={{
              color: "common.black",
              fontSize: 14,
              fontWeight: 600,
              textDecoration: "none",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              // cursor: isMember ? "pointer" : "default",
              cursor: "pointer",
            }}
          >
            <CustomTooltip content={titleText} maxLength={15} />
          </Link>
        </Typography>
        {!isTraditionalAdminStyle && (
          <Typography sx={{ fontSize: 12, color: "common.partyTime", textTransform: "capitalize" }}>
            {type?.toLowerCase()}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

interface IProps {
  members: Attendee[];
  managementStyle?: string;
}
const Members: FC<IProps> = ({ members, managementStyle }) => {
  const { isDesktop } = useViewport();

  const [rows, setRows] = useState<Attendee[]>([]);
  const [openUserDetail, setOpenUserDetail] = useState<Attendee>(null);

  const getRowId = useCallback((row: Attendee) => row.attendeeId, []);

  const getRowHeight = useCallback(() => "auto", []);

  const isTraditional = managementStyle === ManagementEnum.TRADITIONAL;

  useEffect(() => {
    setRows(members || []);
  }, [members]);

  const columns: GridColDef<Attendee>[] = [
    {
      field: "name",
      headerName: "Member",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <RoundTableItem
          attendee={params?.row}
          handleOpenMemberDetailDialog={setOpenUserDetail}
          isTraditionalAdminStyle={isTraditional}
        />
      ),
    },
    {
      field: "voteWeight",
      width: isDesktop ? 120 : 100,
      sortComparator: (v1, v2) => parseInt(v1 || 0) - parseInt(v2 || 0),
      renderHeader: () => (
        <Stack direction={isDesktop ? "row" : "column"} spacing={isDesktop ? 0.5 : 0}>
          {isTraditional ? (
            <Typography>Role</Typography>
          ) : (
            <>
              <Typography>Vote</Typography>
              <Typography>Weight</Typography>
            </>
          )}
        </Stack>
      ),
      renderCell: (params) => {
        const voteWeight = params?.row?.voteWeight;
        if (isTraditional) {
          return <Typography sx={{ fontSize: 14, textTransform: "capitalize" }}>{renderAccountType(params?.row, managementStyle)}</Typography>
        }
        return voteWeight
          ? `${convertBetween0And1ToPercentage(voteWeight)}%`
          : NOT_APPLICABLE;
      },
    },
  ];

  return (
    <>
      <DataGrid
        autoHeight
        disableRowSelectionOnClick
        disableColumnSelector
        hideFooter
        disableColumnMenu
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        paginationMode="server"
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 700,
            // opacity: 0.64,
          },
        }}
      />
      {!!openUserDetail?.userId && (
        <UserDetailDialog
          userId={openUserDetail?.userId}
          open={!!openUserDetail?.userId}
          onClose={() => setOpenUserDetail(null)}
        />
      )}
      <RoundtableDetailsDialog
        roundtableId={openUserDetail?.roundTableId}
        open={!!openUserDetail?.roundTableId}
        onClose={() => setOpenUserDetail(null)}
      />
    </>
  );
};

export default Members;
