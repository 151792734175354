import { FC, useContext, useEffect, useState } from "react";
import { useSearchParams, useLocation, useParams } from "react-router-dom";

import { getDeliveries } from "@api/job/getDeliveries";
import { Delivery_Status } from "@api/job/type";
import { DELIVERY_REALTIME } from "@fb/const";
import { realtimeDb } from "@fb/db";
import {
  Close as CloseIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Event as EventIcon,
  AssignmentOutlined as AssignmentOutlinedIcon,
  FileCopy,
} from "@mui/icons-material";
import { Badge, Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { onValue, ref } from "firebase/database";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { sidebarActions } from "@features/sidebar/slice";

import { TabItem } from "@components/CustomTabs";

import { ReactComponent as DeliveriesIcon } from "@assets/icons/deliveries.svg";
import { ReactComponent as UploadIcon } from "@assets/icons/upload1.svg";

import { DeliveryContext } from "./ListDeliveries/DeliveryContext";
import DeliveryQuickReview from "./ListDeliveries/DeliveryQuickReview";

export const TAB_DATA_KEY = {
  EVENT_LOG: "EVENT_LOG",
  RT_PROJECTS: "RT_PROJECTS",
  ATTACHTMENTS: "ATTACHTMENTS",
  JOBS_MANAGEMENT: "JOBS_MANAGEMENT",
  SUBMIT_DELIVERY: "SUBMIT_DELIVERY",
  FREELANCER_DELIVERIES: "FREELANCER_DELIVERIES",
};

const ItemIconMapping = {
  [TAB_DATA_KEY.EVENT_LOG]: <EventIcon />,
  [TAB_DATA_KEY.RT_PROJECTS]: <AssignmentOutlinedIcon />,
  [TAB_DATA_KEY.ATTACHTMENTS]: <FileCopy />,
  [TAB_DATA_KEY.SUBMIT_DELIVERY]: <UploadIcon />,
  [TAB_DATA_KEY.FREELANCER_DELIVERIES]: <DeliveriesIcon />,
};

interface IRightBar {
  data: TabItem[];
  isRoundtable?: boolean;
  isFreelancer?: boolean;
}

const RightBar: FC<IRightBar> = ({ data = [], isRoundtable, isFreelancer }) => {
  const dispatch = useAppDispatch();
  const [selectedItemIndex, setSelectedIndex] = useState(null);
  const { user } = useAppSelector((state) => state.auth.userInfo);

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { setDelivery } = useContext(DeliveryContext);
  const proposalId = searchParams.get("proposal");
  const { roundtableId, projectId } = useParams();
  const projectIdParam = projectId ?? roundtableId;

  const currentItem = data?.[selectedItemIndex];
  const isSelectedItem = ![null, undefined].includes(selectedItemIndex);
  const tabHeight = 50;

  const deliveryTool = data?.findIndex(
    (item) => item.dataKey === TAB_DATA_KEY.FREELANCER_DELIVERIES
  );

  const [hasUnread, setUnread] = useState(false);
  const { data: deliveries } = useQuery({
    queryFn: () =>
      getDeliveries({ projectId: projectIdParam, status: Delivery_Status.PENDING }),
  });
  // const [pendingDeliveries, setPendingDeliveries] = useState(deliveries?.data);

  useEffect(() => {
    deliveries?.data.map(async (delivery) => {
      const deliveryReadStatusRef = ref(
        realtimeDb,
        `${DELIVERY_REALTIME}/${projectId}/${delivery.id}/${user?.id}`
      );
      onValue(
        deliveryReadStatusRef,
        (snapshot) => {
          const data = snapshot.val();
          setUnread(!data);
          // if (data) {
          //   setPendingDeliveries((prev) =>
          //     prev?.filter((item) => item.id !== delivery.id)
          //   );
          // } else {
          //   setPendingDeliveries((prev) => [...(prev || []), delivery]);
          // }
        },
        (error) => {
          console.error(error);
        }
      );
    });
  }, [deliveries?.data, projectId, user?.id]);

  useEffect(() => {
    // if (data?.length > 0 && projectId) {
    //   setSelectedIndex(data?.findIndex(item => item.dataKey === TAB_DATA_KEY.RT_PROJECTS))
    // }
    if (data?.length > 0 && proposalId) {
      // dispatch(sidebarActions.setRightBarOpen({ open: true }))
      setSelectedIndex(
        data?.findIndex((item) => item.dataKey === TAB_DATA_KEY.EVENT_LOG)
      );
    }
  }, [pathname, data]);

  const onViewDeliveryDetail = (deliveryId: string) => {
    setDelivery(deliveryId);
    setSelectedIndex(deliveryTool);
  };

  return (
    <Box
      height="100%"
      sx={{
        borderRadius: 3,
        border: "0.5px solid #E2E8F0",
        backgroundColor: !isSelectedItem ? "#F7FAFC" : "white",
        overflow: "hidden",
      }}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        px={2}
        py={1.5}
      >
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          {isSelectedItem && (
            <KeyboardArrowLeftIcon
              fontSize="medium"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedIndex(null);
                setDelivery("");
              }}
            />
          )}
          <Typography fontSize={16} fontWeight={700}>
            {currentItem?.label || `${!isRoundtable ? "Project" : "Teamspace"} tools`}
          </Typography>
        </Stack>
        <CloseIcon
          fontSize="medium"
          sx={{ cursor: "pointer" }}
          onClick={() => dispatch(sidebarActions.setRightBarOpen({ open: "" }))}
        />
      </Stack>
      {!isSelectedItem && !!data?.length && (
        <Stack px={2} py={1} gap={1.5}>
          {/* {!isRoundtable && !isFreelancer && !loading && !!pendingDeliveries?.length && (
            <DeliveryQuickReview
              dataDelivery={pendingDeliveries}
              onView={onViewDeliveryDetail}
              projectId={projectIdParam}
            />
          )} */}
          {data?.map((tabData, index) => (
            <Stack
              key={index}
              onClick={() => setSelectedIndex(index)}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
              py={"15px"}
              bgcolor={"white"}
              borderRadius={2}
              border={"1px solid #E2E8F0"}
              sx={{ cursor: "pointer" }}
            >
              <Stack flexDirection={"row"} alignItems={"center"} gap={1.3}>
                {ItemIconMapping[tabData.dataKey] || <></>}
                <Typography fontSize={14} fontWeight={700}>
                  <Badge
                    sx={{
                      "& .MuiBadge-badge": {
                        right: -10,
                        top: 10,
                      },
                    }}
                    invisible={
                      tabData?.dataKey !== TAB_DATA_KEY.FREELANCER_DELIVERIES ||
                      !hasUnread
                    }
                    color={"error"}
                    variant="dot"
                  >
                    {tabData?.label}
                  </Badge>
                </Typography>
              </Stack>
              <KeyboardArrowRightIcon fontSize="small" />
            </Stack>
          ))}
        </Stack>
      )}
      {isSelectedItem && currentItem && (
        <Box pt={1} role="tabpanel" height={tabHeight && `calc(100% - ${tabHeight}px)`}>
          <Box height="100%">{currentItem?.content}</Box>
        </Box>
      )}
    </Box>
  );
};

export default RightBar;
