import { FC } from "react";

import { EventLogStatusLabel } from "@constants/index";
import { Box, Typography, Button, Stack } from "@mui/material";
import dayjs from "dayjs";

interface IProps {
  log: any;
  active: boolean;
  onClick: (id: string) => void;
}

const LogItem: FC<IProps> = ({ log, active, onClick }) => {
  const { color, bgColor, heading, title, status, proposal } = log;
  const formattedEndDate = proposal.endAt ? dayjs(proposal.endAt).fromNow() : "";

  return (
    <Button
      onClick={() => onClick(proposal.id)}
      disabled={!proposal}
      sx={{
        width: "100%",
        p: 0,
        backgroundColor: active ? "#FFF5F5" : "common.white",
        ":hover": {
          backgroundColor: "#FFF5F5"
        }
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: `1px solid #e2e8f0`,
          p: 2
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 1
          }}
        >
          <Box
            sx={{
              color: color,
              backgroundColor: bgColor,
              borderRadius: 2.5,
              pl: 1.5,
              pr: 1.5,
              pt: 0.5,
              pb: 0.5
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 700 }}>{heading}</Typography>
          </Box>
        </Box>
        <Stack textAlign="left">
          <Box color="common.black">{title}</Box>
          <Box display="flex" alignItems="center" columnGap={1}>
            {proposal && (
              <Typography
                fontSize={12}
                fontWeight="bold"
                sx={{
                  color:
                    status === EventLogStatusLabel.ONGOING
                      ? "common.bulmaHair"
                      : "common.black"
                }}
              >
                {status}
              </Typography>
            )}
            {formattedEndDate && (
              <>
                <Typography color="common.featherSoftBlue" fontSize={20}>
                  &bull;
                </Typography>
                <Typography fontSize={12} color="common.black" textTransform="lowercase">
                  {formattedEndDate}
                </Typography>
              </>
            )}
          </Box>
        </Stack>
      </Box>
    </Button>
  );
};

export default LogItem;
