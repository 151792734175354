import { useState, useMemo } from "react";
// import { GiPlainCircle } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

import {
  Close as CloseIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
  FacebookOutlined as FacebookIcon,
  LinkedIn as LinkedInIcon, // Language as LanguageIcon,
} from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import { Stack, Box, Grid, IconButton, Button, useTheme, SvgIcon } from "@mui/material";
import { AuthPath } from "@routers/path/AuthPath";
import { PublicPaths } from "@routers/path/PublicPath";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { signOut } from "@features/auth/slice";

import { addNewUrlParams } from "@utils/helpers/stringHelper";

import { ReactComponent as MediumIcon } from "@assets/images/gatherlance-landing/medium.svg";
import { ReactComponent as UnitedKingdomIcon } from "@assets/images/gatherlance-landing/united-kingdom.svg";

import { useViewport } from "@contexts/ViewportContext";

import Typography from "./GatherlanceTypography";

const JOIN_CLOSE_BETA_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSdb3wlS206_V16D7G8SWFIq-7QuBDW2BDzJJlugiM7DTjTmbQ/viewform";

const aboutLinks = [
  { label: "About us", href: "#" },
  { label: "Press center", href: "#" },
  { label: "How Gatherlance Works", href: "#" },
];

const supportLinks = [
  { label: "Help Center", href: "#" },
  { label: "Term & Policy", href: "#" },
];

const SignInOutBtn = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    palette: { primary, common },
  } = useTheme();
  const { isMobile, isTablet } = useViewport();
  const isMobileDesign = isMobile || isTablet;

  const isLoggedIn = useMemo(() => {
    if (userInfo) {
      return true;
    }

    return false;
  }, [userInfo]);

  const handleClickSignInAndSignOut = () => {
    if (isLoggedIn) {
      dispatch(signOut());
      navigate(AuthPath.signIn);
    }

    return navigate(
      addNewUrlParams(AuthPath.signIn, {
        redirectTo: PublicPaths.indexPage,
      })
    );
  };
  // useEffect(() => {
  //   const link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
  //   link.rel = "icon";
  //   link.setAttribute("href", `${FAVICON_NEW}?v=${Date.now()}`);
  // }, []);

  return (
    <Stack flexDirection={isMobileDesign ? "column" : "row"} gap={2} p={3}>
      {/* {isLoggedIn && <DocumentHeader />} */}
      {isLoggedIn && (
        <Button
          sx={{
            fontWeight: "normal",
            padding: "5px",
            color: "common.black",
            fontSize: 15,
          }}
          onClick={() => (window.location.href = "/dashboard")}
        >
          My dashboard
        </Button>
      )}

      <Button
        variant={isLoggedIn ? "text" : "contained"}
        sx={{
          borderRadius: "50px",
          textTransform: "unset",
          bgcolor: isLoggedIn ? common.white : primary.main,
          color: isLoggedIn ? common.black : common.white,
          // bgcolor:
          //   type === "custom" ? (isLoggedIn ? common.white : primary.main) : "unset",
          // color: type === "custom" ? (isLoggedIn ? primary.main : common.white) : "unset",
          // border: type === "custom" && isLoggedIn ? `1px solid ${primary.main}` : "none",
          boxShadow: "none",
        }}
        onClick={handleClickSignInAndSignOut}
        // startIcon={!isLoggedIn && <LogoutIcon />}
        endIcon={isLoggedIn && <LogoutIcon />}
      >
        {isLoggedIn
          ? `${userInfo?.user?.name || userInfo?.user?.username} - Log out`
          : "Sign in"}
      </Button>
    </Stack>
  );
};

export const Header = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const {
    palette: { common },
  } = useTheme();
  const { isMobile, isTablet, isDesktop } = useViewport();
  const isMobileDesign = isMobile || isTablet;

  const renderPostJobBtn = () => {
    return (
      <Button variant="contained" sx={{ background: "black", textTransform: "unset" }}>
        Post a job
      </Button>
    );
  };

  return (
    <Stack px={{ sm: 3, md: 10 }}>
      <Stack
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        height={"70px"}
        px={isMobile ? 2 : 0}
        borderBottom={isMobileDesign ? "none" : common.border}
      >
        <Stack>
          <img
            src={"/gatherlance_brand_dark.png"}
            alt="heading-logo"
            height={isMobileDesign ? 32 : 48}
          />
        </Stack>

        {/* {isMobileDesign ? (
          <IconButton onClick={() => setOpenMobileMenu(!openMobileMenu)} sx={{ px: 0 }}>
            {openMobileMenu ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        ) : (
          <Stack flexDirection={"row"} gap={2} alignItems="center">
            {renderPostJobBtn()}
          </Stack>
        )} */}
      </Stack>

      {/* {isMobileDesign && openMobileMenu && (
        <Stack rowGap={2} p={3}>
          {renderPostJobBtn()}
        </Stack>
      )} */}
    </Stack>
  );
};

export const Footer = () => {
  const { isMobile, isTablet, isDesktop } = useViewport();
  const isMobileDesign = isMobile || isTablet;

  return (
    <>
      <Box
        display={isMobile ? "block" : "flex"}
        justifyContent="space-between"
        py={{ xs: 5, md: 8 }}
        px={{ xs: 3, md: 10 }}
        bgcolor={"black"}
      >
        <Stack>
          <Box
            component="img"
            src={"/gatherlance_brand_light.png"}
            height={isMobileDesign ? 32 : 40}
            width={"max-content"}
            mb={isMobile ? 3 : 0}
          />
          {/* <Typography color={"common.gray3"}>Lorem ipsum dolor sit amet consectetur. Egestas pretium sit lectus convallis.</Typography> */}
        </Stack>

        <Grid
          container
          columnSpacing={isDesktop ? 10 : 2}
          rowGap={3}
          justifyContent={isMobile ? "start" : "flex-end"}
        >
          <Grid item xs={11} tablet={6} sm={3}>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700} color={"white"}>
                ABOUT
              </Typography>
              <Stack rowGap={1.5}>
                {aboutLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={"common.gray3"}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700} color={"white"}>
                SUPPORT
              </Typography>
              <Stack rowGap={1.5}>
                {supportLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={"common.gray3"}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack py={{ xs: 5, md: 8 }} px={{ xs: 3, md: 10 }} bgcolor={"black"} gap={3}>
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          gap={3}
        >
          <Button
            startIcon={
              <SvgIcon>
                <UnitedKingdomIcon />
              </SvgIcon>
            }
            sx={{
              color: "white",
              fontWeight: 400,
              width: "fit-content",
              border: "1px solid white",
              borderRadius: 2,
            }}
          >
            English
          </Button>
          <Stack flexDirection={"row"} alignItems={"center"} gap={2} color={"white"}>
            <LinkedInIcon />
            <FacebookIcon />
            <MediumIcon />
            <XIcon />
          </Stack>
        </Stack>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          columnGap={6}
          rowGap={1}
        >
          <Typography color={"white"}>
            © {new Date().getFullYear()} Gatherlance
          </Typography>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            columnGap={3}
            rowGap={1}
          >
            <Typography color={"common.bellflowerBlue"}>Terms of Use</Typography>
            <Typography color={"common.bellflowerBlue"}>Cookie Policy</Typography>
            <Typography color={"common.bellflowerBlue"}>Privacy Notice</Typography>
          </Box>
        </Box>
      </Stack>
    </>
  );
};
