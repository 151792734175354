import { FC, memo, useCallback, useState } from "react";

import useGetApplicationDetails from "@api/job/useGetApplicationDetails";
import useUpdateApplicationStatus from "@api/job/useUpdateApplicationStatus";
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {
  Button,
  Stack,
  Grid,
  Avatar,
  Typography,
  Divider,
  useTheme,
  Box,
  Chip,
} from "@mui/material";
import { ProtectedPaths } from "@routers/path/ProtectedPath";

import { useAppSelector, useAppDispatch } from "@app/hooks";

import { applicationActions } from "@features/application/slice";

import CustomButton from "@components/Button";
import ImageViewer from "@components/Dialog/ImageViewer";
import { LinkItem } from "@components/LinkInput";
import { CircleLoading } from "@components/Loading/CircleLoading";
import OderPaymentCheck from "@components/Order/OrderPaymentCheck";

import { addNewConversation } from "@pages/messenger/ConversationList/service";

import { roundedEarningNumber } from "@utils/numberHelper";

import { User } from "@dto/user";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

import Experiences from "../UserProfile/Experiences";
import Projects from "../UserProfile/Projects";

type PropTypes = {
  applicationId?: string;
  onOpenCheckout?: () => void;
  hireButtonText?: string;
};

const WorkingScheduleUnit = "hour(s)/week";

const PanelDetails: FC<PropTypes> = ({ onOpenCheckout, hireButtonText }) => {
  const dispatch = useAppDispatch();
  const {
    jobId,
    applicationId: selectedAppId,
    applicationIdsByJob,
    filters,
    openCheckout,
  } = useAppSelector((state) => state.application);

  const {
    palette: { common },
  } = useTheme();
  const { userInfo } = useAppSelector((state) => state.auth);
  const currentAppId = selectedAppId;
  const {
    isLoading,
    data: app,
    isFetching,
  } = useGetApplicationDetails(jobId, currentAppId);
  const { mutateAsync: updateAppStatus, isLoading: isUpdateAppLoading } =
    useUpdateApplicationStatus();
  const [isOpenedModal, setIsOpenedModal] = useState<number>(null);
  const { setSnackbar } = useSnackbar();
  const prevAppId =
    applicationIdsByJob[applicationIdsByJob.findIndex((id) => id === currentAppId) - 1];
  const nextAppId =
    applicationIdsByJob[applicationIdsByJob.findIndex((id) => id === currentAppId) + 1];

  const images = app?.createdByUser?.files?.map((file) => file.link);
  const handleToggleModal = useCallback(
    (modalSection?: number) => {
      setIsOpenedModal(modalSection);
    },
    [isOpenedModal]
  );
  const handleStartChat = async (targetUser: User) => {
    const res = await addNewConversation(userInfo.user, [targetUser]);
    if (res.pHash) {
      window.open(`${ProtectedPaths.messenger}/${res.pHash}`, "_blank");
    }
  };

  if (openCheckout) {
    return <OderPaymentCheck />;
  }
  const handleGotoOtherApp = (appId?: string) => {
    if (!appId) {
      return;
    }
    dispatch(applicationActions.setApplicationId(appId));
  };

  const handleUpdateAppStatus = async (payload: {
    saved?: boolean;
    archived?: boolean;
  }) => {
    try {
      await updateAppStatus({ jobId, applicationId: currentAppId, ...payload });
      queryClient.invalidateQueries(["getApplicationDetails", jobId, currentAppId]);
      queryClient.invalidateQueries(["getApplicationsByJob", jobId, filters]);
    } catch (error: any) {
      console.log("ERR", error);
      setSnackbar({
        message: error?.response?.data?.message,
        open: true,
        severity: "error",
      });
    }
  };

  const renderGallery = (numberOfImages = 5) => {
    return images?.slice(0, numberOfImages).map((file, index) => (
      <Box
        key={index}
        sx={{ position: "relative", cursor: "pointer" }}
        // width={"8rem"}
        onClick={() => {
          handleToggleModal(index);
        }}
      >
        <Avatar
          variant="rounded"
          src={!file.startsWith("data:application/") && file}
          sx={{ width: 182, height: 138, borderRadius: 2 }}
        >
          <DocumentScannerIcon fontSize="large" />
        </Avatar>
        {images?.length > numberOfImages && index === numberOfImages - 1 && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              top: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.6)",
              position: "absolute",
            }}
          >
            <Box
              sx={{
                color: "common.white",
              }}
              fontSize={24}
              fontWeight={500}
            >
              +{images?.length - numberOfImages}
            </Box>
          </Box>
        )}
      </Box>
    ));
  };

  return (
    <Stack
      sx={{
        overflowX: "auto",
        height: "100%",
        position: "relative",
        justifyContent: "space-between",
      }}
    >
      {!isFetching && !app?.id && (
        <Typography
          fontSize={18}
          color={common.gray5}
          fontStyle={"italic"}
          textAlign={"center"}
          mt={"35%"}
        >
          Your quest has been posted! Applications will come in soon!
        </Typography>
      )}
      {isLoading && jobId && currentAppId && (
        <CircleLoading height={200} width={"100%"} />
      )}
      {!isLoading && app && (
        <Stack p={2.5} gap={{ xs: 1, md: 4 }}>
          <Stack flexDirection={"row"} justifyContent={"space-between"} mb={1.5}>
            <Stack flexDirection={"row"} gap={3} flexWrap={"wrap"}>
              <Avatar src={app?.createdByUser?.avatar} sx={{ width: 84, height: 84 }} />
              <Stack gap={1.5}>
                <Typography fontWeight={700} fontSize={24}>
                  {app?.createdByUser?.name}
                </Typography>
                <Stack flexDirection={"row"} gap={3} flexWrap={"wrap"}>
                  <Stack gap={0.3} textAlign={"center"}>
                    <Stack flexDirection={"row"} gap={0.7} color={"primary.main"}>
                      <StarIcon sx={{ height: 24, width: 24 }} />
                      <Typography fontSize={18} color={"primary"} fontWeight={700}>
                        {Number(app.createdByUser.averageRating || 0).toFixed(1)}/5.0
                      </Typography>
                    </Stack>
                    <Typography fontSize={14} color={"common.gray5"}>
                      (
                      {+app.createdByUser.virtualOrderNumber +
                        +app.createdByUser.completedOrderCount}{" "}
                      orders)
                    </Typography>
                  </Stack>
                  <Stack gap={0.3} textAlign={"center"}>
                    <Typography fontSize={18} fontWeight={700}>
                      {roundedEarningNumber(app?.createdByUser?.totalEarnings || 0)}
                    </Typography>
                    <Typography fontSize={14} color={"common.gray5"}>
                      Total earning
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack flexDirection={"row"} gap={1}>
              <Button
                variant="outlined"
                sx={{
                  fontWeight: "normal",
                  height: "max-content",
                  width: 92,
                  color: "black",
                  borderColor: "common.bellflowerBlue",
                }}
                onClick={() => handleStartChat(app?.createdByUser)}
              >
                <Typography fontWeight={600} fontSize={14}>
                  Message
                </Typography>
              </Button>
              <Button
                variant="contained"
                sx={{ fontWeight: "normal", height: "max-content", width: 92 }}
                onClick={() => {
                  dispatch(applicationActions.setOpenCheckout(true));
                  dispatch(applicationActions.setOpenListPanel(false));
                  onOpenCheckout?.();
                }}
              >
                <Typography fontWeight={600} fontSize={14}>
                  {hireButtonText || "Hire"}
                </Typography>
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ bgcolor: "common.bellflowerBlue", my: 1 }} />
          <Stack gap={2}>
            <Typography
              fontSize={14}
              color={"#0000005C"}
              fontWeight={600}
              textTransform={"uppercase"}
              display={{ xs: "none", md: "initial" }}
            >
              Headline
            </Typography>

            <Typography fontSize={16}>{app?.createdByUser?.bio}</Typography>
          </Stack>
          <Stack gap={2}>
            <Typography
              fontSize={14}
              color={"#0000005C"}
              fontWeight={600}
              textTransform={"uppercase"}
            >
              About me
            </Typography>

            <Typography fontSize={16}>{app?.createdByUser?.description}</Typography>
          </Stack>
          <Stack gap={2}>
            <Typography
              fontSize={14}
              color={"#0000005C"}
              fontWeight={600}
              textTransform={"uppercase"}
            >
              Basic informations
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack flexDirection={"row"}>
                  <Typography
                    fontSize={14}
                    fontWeight={700}
                    width={{ xs: "45%", md: "40%" }}
                  >
                    Location
                  </Typography>
                  <Typography fontSize={14} width={{ xs: "55%", md: "60%" }}>
                    {app?.createdByUser?.location}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack flexDirection={"row"} display="flex">
                  <Typography
                    fontSize={14}
                    fontWeight={700}
                    width={{ xs: "45%", md: "40%" }}
                  >
                    Working Schedule
                  </Typography>
                  <Typography fontSize={14} width={{ xs: "55%", md: "60%" }}>
                    {app?.createdByUser?.workingSchedule} {WorkingScheduleUnit}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={{ bgcolor: "common.bellflowerBlue", my: 3 }} />
          <Stack gap={3.5}>
            <Typography
              fontSize={14}
              color={"#0000005C"}
              fontWeight={600}
              textTransform={"uppercase"}
            >
              {"Gallery"}
            </Typography>
            <Box
              display={"flex"}
              gap={1.5}
              sx={{
                overflowX: "auto",
                maxWidth: "100%",
              }}
            >
              {renderGallery()}
            </Box>
            <Typography
              fontSize={14}
              color={"#0000005C"}
              fontWeight={600}
              textTransform={"uppercase"}
            >
              {"Areas of Expertise"}
            </Typography>
            <Stack flexDirection={"row"} flexWrap={"wrap"} gap={2}>
              {app?.createdByUser?.userSkills?.map((userSkill, i) => (
                <Chip
                  key={i}
                  label={userSkill.skill.name}
                  sx={{
                    height: 24,
                    borderRadius: 1,
                    fontSize: 14,
                    fontWeight: "bold",
                    bgcolor: "#EDF2F7",
                    "& .MuiChip-label": { p: 1 },
                  }}
                />
              ))}
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Typography
              fontSize={14}
              color={"#0000005C"}
              fontWeight={600}
              textTransform={"uppercase"}
            >
              Links
            </Typography>
            <>
              <Stack gap={1} display={"flex"}>
                {app?.createdByUser?.link?.map((link: any, i: number) => (
                  <LinkItem
                    key={i}
                    index={i}
                    data={{ url: link.url, displayText: link.label }}
                  />
                ))}
              </Stack>
              {/* {renderDefaultHint(!(link?.length > 0))} */}
            </>
          </Stack>

          <Experiences
            title={
              <Typography
                fontSize={14}
                color={"#0000005C"}
                fontWeight={600}
                textTransform={"uppercase"}
              >
                Work experience
              </Typography>
            }
            type="WORK"
            experiences={app?.createdByUser?.experiences}
          />

          <Experiences
            title={
              <Typography
                fontSize={14}
                color={"#0000005C"}
                fontWeight={600}
                textTransform={"uppercase"}
              >
                Education experience
              </Typography>
            }
            type="EDUCATION"
            experiences={app?.createdByUser?.experiences}
          />
          <Projects
            title={
              <Typography
                fontSize={14}
                color={"#0000005C"}
                fontWeight={600}
                textTransform={"uppercase"}
              >
                Projects
              </Typography>
            }
            projects={app?.createdByUser?.profileProjects}
            userIds={app?.createdByUser?.id}
          />
        </Stack>
      )}
      {!isLoading && app && (
        <Stack
          sx={{
            borderTop: "1px solid #E2E8F0",
            bgcolor: "white",
            position: "sticky",
            bottom: -1,
            left: 0,
            width: "100%",
          }}
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          p={2}
        >
          <Button
            variant="outlined"
            sx={{
              color: "black",
              borderColor: "common.bellflowerBlue",
              fontWeight: "normal",
              width: 110,
            }}
            disabled={!prevAppId}
            onClick={() => handleGotoOtherApp(prevAppId)}
          >
            <KeyboardArrowLeftIcon /> Previous
          </Button>
          <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
            <CustomButton
              loading={isUpdateAppLoading}
              onClick={() =>
                handleUpdateAppStatus({ saved: !app.saved, archived: false })
              }
              variant="outlined"
              sx={{
                color: "black",
                borderColor: "common.bellflowerBlue",
                fontWeight: "normal",
              }}
            >
              <Stack gap={0.5} flexDirection={"row"} alignItems={"center"}>
                {app?.saved ? (
                  <StarIcon sx={{ width: 18, height: 18, color: "#FDD500" }} />
                ) : (
                  <StarBorderIcon sx={{ width: 18, height: 18 }} />
                )}
                <Typography>Save</Typography>
              </Stack>
            </CustomButton>
            <CustomButton
              loading={isUpdateAppLoading}
              onClick={() => handleUpdateAppStatus({ archived: true, saved: false })}
              disabled={app?.archived}
              variant="outlined"
              sx={{
                color: "primary",
                borderColor: "common.bellflowerBlue",
                fontWeight: "normal",
                width: 89,
              }}
            >
              Archive
            </CustomButton>
          </Stack>
          <Button
            variant="outlined"
            sx={{
              color: "black",
              borderColor: "common.bellflowerBlue",
              fontWeight: "normal",
              width: 110,
            }}
            disabled={!nextAppId}
            onClick={() => handleGotoOtherApp(nextAppId)}
          >
            Next <KeyboardArrowRightIcon />
          </Button>
        </Stack>
      )}
      {isOpenedModal !== null && (
        <ImageViewer
          images={images}
          initialIndex={isOpenedModal}
          open={isOpenedModal !== null}
          onClose={() => setIsOpenedModal(null)}
        />
      )}
    </Stack>
  );
};

export default memo(PanelDetails);
