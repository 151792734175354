export type ToolOptionType = keyof typeof DialogEnum | "isEdit";

export enum DialogEnum {
  DETAIL = "DETAIL",
  POLL = "POLL",
  INVITATION = "INVITATION",
  POSITION = "POSITION",
  SETTING = "SETTING",
  PROJECT_PROFILE = "PROJECT_PROFILE",
  SUB_ROUNDTABLE_BECOMING = "SUB_ROUNDTABLE_BECOMING",
  PROJECT = "PROJECT",
  SUB_PROJECT_UPDATE = "SUB_PROJECT_UPDATE",
  LEAVE = "LEAVE",
  LEAVE_PROJECT = "LEAVE_PROJECT",
  DELETE = "DELETE",
  PROJECT_PUBLISHING = "PROJECT_PUBLISHING",
  VOTE_DELEGATE = "VOTE_DELEGATE",
  EXIT = "EXIT",
  EVENT_PROPOSAL = "EVENT_PROPOSAL",
  ROUNDTABLE_MANAGEMENT = "ROUNDTABLE_MANAGEMENT",
  ROUNDTABLE_PROFILE = "ROUNDTABLE_PROFILE",
}
