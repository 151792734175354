/* eslint-disable react/jsx-no-bind */
import { FC, useState } from "react";
import { Controller } from "react-hook-form";

import { TextFieldProps } from "@mui/material/TextField";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from "dayjs";

interface ITimePickerInput extends TimePickerProps<Date> {
  name: string;
  control: any;
  label?: string;
  // defaultValue?: Dayjs;
  textFieldProps?: TextFieldProps;
  timePickerProps?: TimePickerProps<Date>;
  [x: string]: unknown;
}

const TimePickerInput: FC<ITimePickerInput> = ({ name, control, label, defaultValue, textFieldProps, ...others }) => {
  const [open, setOpen] = useState(false)

  return (
    <Controller
      name={name}
      control={control}
      // defaultValue={defaultValue || dayjs()}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            defaultValue={defaultValue || dayjs()}
            open={open}
            onClose={() => setOpen(false)}
            label={label}
            name={name}
            slotProps={{
              textField: { ...textFieldProps, onClick: () => setOpen(true) },
              // inputAdornment: {
              //   sx: {
              //     position: "absolute",
              //     maxHeight: "unset",
              //     marginLeft: 0,
              //     height: "100%",
              //     width: "100%"
              //   },
              // },
              // openPickerButton: {
              //   sx: {
              //     width: "100%",
              //     height: "100%",
              //     borderRadius: "unset"
              //   }
              // },
              openPickerIcon: {
                sx: { display: "none" }
              },
            }}
            {...field}
            {...others}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default TimePickerInput;
