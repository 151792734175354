import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import useGetRoundtable from "@api/roundtable/useGetRoundtable";
import useRequestVoteWeight from "@api/vote/useRequestVoteWeight";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  SxProps,
  FormControlLabel,
  Radio,
  Stack,
  Chip,
} from "@mui/material";
import * as yup from "yup";

import { useAppSelector } from "@app/hooks";

import { handleApiError } from "@features/config";

import Button from "@components/Button";
import SelfContribution from "@components/Dialog/UpdatePositionDialog/SelfContribution";
import { ISnackbar } from "@components/Snackbar";

import { convertBetween0And1ToPercentage } from "@utils/numberHelper";

import { ProposalType } from "@dto/proposal";
import { Attendee, Roundtable, VoteModeEnum } from "@dto/roundtable";

import queryClient from "@config/queryClient";

export enum UpdateObject {
  individual = "INDIVIDUAL",
  subRoundtable = "SUB_ROUNDTABLE",
}

export enum RequestType {
  REQUEST_VOTE_WEIGHT = "REQUEST_VOTE_WEIGHT",
  REQUEST_FORMAL_MEMBER = "REQUEST_FORMAL_MEMBER",
}

type OriginalValuesUpdatePosition = {
  fundContribution: number;
  hourlyRate: number;
  oneTimeCompensation: number;
  percentageProfit: number;
  voteWeight: string;
  workingHourWeekly: string;
  isSubmittable: boolean;
};

export type UpdatePositionForm = {
  fundContribution: number;
  workingHourWeekly: number;
  voteWeight: number;
  percentageProfit: number;
  oneTimeCompensation: number;
  hourlyRate: number;
  message: string;
  type: string;
  isSubmittable: boolean | BecomeFormalMemberOptions;
  subRoundTableId?: string;
  originalValues?: OriginalValuesUpdatePosition;
};

export enum BecomeFormalMemberOptions {
  NO = "NO",
  YES = "YES",
}

const defaultValues: UpdatePositionForm = {
  fundContribution: null,
  workingHourWeekly: null,
  voteWeight: null,
  percentageProfit: null,
  oneTimeCompensation: null,
  hourlyRate: null,
  message: "",
  type: "",
  isSubmittable: null,
};

const titleSxProps: SxProps = { fontSize: 16, fontWeight: 700, mb: 2 };

const convertToNumber = (value: string) => (value ? Number(value) : null);
const transformFn = (value: any) => (isNaN(value) ? null : value);
const formSchemaShape = {
  fundContribution: yup.number().nullable().transform(transformFn),
  workingHourWeekly: yup.number().nullable().transform(transformFn),
  voteWeight: yup
    .number()
    .nullable()
    .transform(transformFn)
    .when("type", ([type], schema) => {
      return type === RequestType.REQUEST_VOTE_WEIGHT
        ? schema.required("Vote Weight is required.")
        : schema;
    }),
  percentageProfit: yup.number().nullable().transform(transformFn),
  oneTimeCompensation: yup.number().nullable().transform(transformFn),
  hourlyRate: yup.number().nullable().transform(transformFn),
  message: yup.string().nullable(),
  type: yup.string(),
  isSubmittable: yup.mixed().nullable(),
};
let formSchema = yup.object().shape(formSchemaShape);

interface IProps {
  id: string;
  isOpened?: boolean;
  roundtable: Roundtable;
  onClose: () => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  removeDialog?: boolean;
}

const UpdatePositionDialog: FC<IProps> = ({
  id,
  isOpened,
  roundtable,
  onClose,
  setSnackbar,
  removeDialog,
}) => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const [updateObject, setUpdateObject] = useState<UpdateObject>();
  const [currentAttendee, setCurrentAttendee] = useState<Attendee>();

  const form = useForm<UpdatePositionForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
    watch,
  } = form;

  const { data: parentRoundtable } = useGetRoundtable({ id: roundtable?.parentId });
  const { mutate: mutateVoteWeight, isLoading } = useRequestVoteWeight();
  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(true);

  const handleUpdate = useCallback(
    (formData: UpdatePositionForm) => {
      const isIndividual = updateObject === UpdateObject.individual;
      const type = isIndividual
        ? ProposalType.REQUEST_VOTE_WEIGHT
        : ProposalType.SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT;

      const formattedFormData = {
        ...formData,
        isSubmittable:
          !currentAttendee?.isSubmittable &&
          formData.isSubmittable === BecomeFormalMemberOptions.YES,
        type,
      };

      mutateVoteWeight(
        {
          id,
          payload: formattedFormData,
        },
        {
          onSuccess: () => {
            setSnackbar({
              message: "Submitted successfully!",
              open: true,
              severity: "success",
            });

            onClose();
            queryClient.invalidateQueries(["getEventLogs"]);
          },
          onError: (error) => {
            setSnackbar({
              message: handleApiError(error).message,
              open: true,
              severity: "error",
            });
          },
        }
      );
    },
    [
      updateObject,
      currentAttendee?.isSubmittable,
      parentRoundtable?.voteMode,
      roundtable?.voteMode,
    ]
  );

  const handleChangeUpdateObject = useCallback(
    (object: UpdateObject) => () => {
      setUpdateObject(object);
      /**
       * @field Type should be:
       * + request vote weight
       * + request become formal member
       */
      const isIndividual = object === UpdateObject.individual;
      const voteMode = isIndividual ? roundtable.voteMode : parentRoundtable?.voteMode;
      const type =
        voteMode !== VoteModeEnum.SIMPLE_VOTE
          ? RequestType.REQUEST_VOTE_WEIGHT
          : RequestType.REQUEST_FORMAL_MEMBER;

      let currentAttendee: Attendee = null;
      if (isIndividual) {
        currentAttendee = roundtable?.attendees?.find(
          (a) => a.userId === userInfo?.user?.id
        );
        if (!currentAttendee) {
          return;
        }
      } else {
        currentAttendee = parentRoundtable.attendees?.find(
          (a) => a.roundTableId === roundtable?.id
        );
        if (!currentAttendee) {
          return;
        }
      }

      /**
       * Update current attendee base on update object
       */
      setCurrentAttendee(currentAttendee);

      /**
       * Update validation of individual position
       * or sub-roundtable position
       */
      formSchemaShape.voteWeight = yup
        .number()
        .transform(transformFn)
        .when("type", ([type], schema) => {
          const currentVoteWeight = +currentAttendee.voteWeight * 100;
          return type === RequestType.REQUEST_VOTE_WEIGHT
            ? schema
                .required("Vote Weight is required.")
                .min(
                  currentVoteWeight,
                  `Profit Share must be greater than ${currentVoteWeight}`
                )
            : schema.nullable();
        });
      formSchema = yup.object().shape(formSchemaShape);

      const {
        fundContribution,
        workingHourWeekly,
        voteWeight,
        percentageProfit,
        oneTimeCompensation,
        hourlyRate,
        isSubmittable,
      } = currentAttendee;
      reset({
        fundContribution: convertToNumber(fundContribution),
        workingHourWeekly: convertToNumber(workingHourWeekly),
        voteWeight: convertBetween0And1ToPercentage(voteWeight),
        percentageProfit: convertToNumber(percentageProfit),
        oneTimeCompensation: convertToNumber(oneTimeCompensation),
        hourlyRate: convertToNumber(hourlyRate),
        isSubmittable: isSubmittable
          ? BecomeFormalMemberOptions.YES
          : BecomeFormalMemberOptions.NO,
        type,
      });
    },
    [roundtable, setValue, parentRoundtable, reset, updateObject, userInfo?.user?.id]
  );

  const renderContent = () => {
    return (
      <>
        <Box>
          <Typography variant="h6" sx={{ ...titleSxProps, mb: 1 }}>
            Update Object
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                value={UpdateObject.individual}
                control={
                  <Radio
                    checked={updateObject === UpdateObject.individual}
                    onClick={handleChangeUpdateObject(UpdateObject.individual)}
                  />
                }
                label={<Typography>Individual Member (Myself)</Typography>}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                value={UpdateObject.subRoundtable}
                control={
                  <Radio
                    disabled={!roundtable?.parentId || !parentRoundtable}
                    checked={updateObject === UpdateObject.subRoundtable}
                    onClick={handleChangeUpdateObject(UpdateObject.subRoundtable)}
                  />
                }
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Sub-Teamspace</Typography>{" "}
                    {updateObject === UpdateObject.subRoundtable ? (
                      <Chip
                        label={roundtable?.parent?.title}
                        variant="outlined"
                        sx={{
                          color: "common.partyTime",
                          borderColor: "common.partyTime",
                          fontSize: 12,
                          p: 0,
                          borderRadius: 1,
                        }}
                      />
                    ) : null}
                  </Stack>
                }
              />
            </Grid>
          </Grid>
        </Box>
        {updateObject && (
          <Box mt={2}>
            <Typography variant="h6" sx={titleSxProps}>
              Self-Contribution
            </Typography>

            <SelfContribution
              roundtable={roundtable}
              currentAttendee={currentAttendee}
              updateObject={updateObject}
              form={form}
              setIsDisabledSubmitButton={setIsDisabledSubmitButton}
            />
          </Box>
        )}
      </>
    );
  };

  if (removeDialog) {
    return (
      <Stack>
        {renderContent()}
        <Stack flexDirection={"row"} justifyContent={"right"} gap={1} mt={2}>
          <Button variant="outlined" disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>

          <Button
            loading={isLoading}
            disabled={!isValid || isLoading || isDisabledSubmitButton}
            onClick={handleSubmit(handleUpdate)}
          >
            Update
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Dialog
      fullWidth
      open={isOpened}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
        Update My Position
      </DialogTitle>

      <DialogContent>{renderContent()}</DialogContent>

      <DialogActions>
        <Button variant="outlined" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>

        <Button
          loading={isLoading}
          disabled={!isValid || isLoading || isDisabledSubmitButton}
          onClick={handleSubmit(handleUpdate)}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePositionDialog;
