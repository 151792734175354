import React, { ChangeEvent, useCallback } from "react";

import { Box, Checkbox, Grid, Stack, TextField, Typography } from "@mui/material";

import { useVoteShare } from "@components/Dialog/VoteDelegateDialog/NewDelegates/CustomizedVoteShareContext";

import { formatProposalName } from "@utils/format/proposalType";
import { convertBetween0And1ToPercentage } from "@utils/numberHelper";

import { RemainingVoteWeight } from "@dto/delegations";

const VoteShareRow = ({ voteShare }: { voteShare: RemainingVoteWeight }) => {
  const proposalType = voteShare.proposalType;
  const { handleVoteShareChange, setErrors, errors, handleSlectVote, selectVote, delegations } = useVoteShare();

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      handleVoteShareChange(+newValue, proposalType);
      setErrors({
        ...errors,
        [proposalType]:
          newValue !== "" &&
          (+newValue <= 0 || +newValue > +voteShare.shareableVoteWeight * 100),
      });
    },
    [errors]
  );

  return (
    <Grid container alignItems="center" rowSpacing={1}>
      <Grid item xs={12} md={6} display="flex">
        <Box ml={-2}>
          <Checkbox
            checked={!!selectVote[voteShare.proposalType]}
            onChange={handleSlectVote(voteShare.proposalType)}
          />
        </Box>
        <Stack direction="column">
          <Typography fontSize={16} fontWeight={400}>
            {formatProposalName(voteShare.proposalType)}
          </Typography>
          <Typography fontSize={14} fontWeight={400} color="common.gray4">
            Max delegate vote:{" "}
            <Typography component="span" color="common.partyTime">
              {convertBetween0And1ToPercentage(voteShare.shareableVoteWeight)}%
            </Typography>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          onChange={onChange}
          value={delegations[voteShare.proposalType]}
          fullWidth
          type="number"
          error={errors[proposalType]}
          helperText={
            errors[proposalType] &&
            "The value is exceeded the maximum vote weight!"
          }
          placeholder="Vote Weight"
          variant="outlined"
          onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()}
        />
      </Grid>
    </Grid>
  );
};

export default VoteShareRow;
