import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { applyJob } from "@api/job/applyJob";
import useGetApplicationsByJob from "@api/job/useGetApplicationsByJob";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Stack, styled, Typography, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";

import useGetAccountInfo from "@hooks/useGetAccountInfo";
import useHandleJobManagementLayoutByQuery from "@hooks/useHandleJobMngLayoutByQuery";

import Button from "@components/Button";
import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";
import FormInput from "@components/FormInput";
import LineSeparateText from "@components/Order/LineSeparateText";

import { useSnackbar } from "@contexts/SnackbarContext";

import { useSelectJob } from "./SelectJobContext";
import { SERVICE_CHARGE, VIEW } from "./constants";

const formSchema = yup.object().shape({
  estimation: yup.string().required("Please fill in this field"),
  hourlyRate: yup
    .number()
    .typeError("Hourly rate must be a number")
    .required("Please fill in this field")
    .min(5, "Minimum hourly rate is 5 US Dollars"),
  duration: yup
    .number()
    .typeError("This field must be a number")
    .required("Please fill in this field")
    .min(1, "Minimum duration is 1 day"),
});
export type SubmitEstimationForm = {
  estimation: string;
  hourlyRate: number;
  duration: number;
};

const UpdateQuest = () => {
  const { user } = useGetAccountInfo();
  const { jobSelected } = useSelectJob();
  const { setSnackbar } = useSnackbar();
  const { appendQuery } = useHandleJobManagementLayoutByQuery();
  const { mutate: apply, isLoading } = useMutation({
    mutationFn: applyJob,
    onSuccess: () => {
      setSnackbar({
        message: "Submitted successfully!",
        open: true,
        severity: "success",
      });
      appendQuery({ l: VIEW[1].id, m: VIEW[9].id });
    },
    onError: () => {
      setSnackbar({
        message: "This quest is no longer open for hiring!",
        open: true,
        severity: "error",
      });
    },
  });
  const { data: apps } = useGetApplicationsByJob(jobSelected.id);
  const [open, setOpen] = React.useState(false);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<SubmitEstimationForm>({
    defaultValues: {
      estimation: "",
      hourlyRate: user.hourRateTo,
    },
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });
  const ownApp = apps?.find((app) => app.createdBy === user.id);
  const { estimation, hourlyRate } = watch();

  const standardFree = Number(+estimation * hourlyRate);

  useEffect(() => {
    reset({
      estimation: ownApp?.estimation || "",
      hourlyRate: +ownApp?.expectBudget || user.hourRateTo || 0,
      duration: +ownApp?.duration || 0,
    });
  }, [ownApp]);
  const onSubmitEstimation = async (data: SubmitEstimationForm) => {
    const { estimation, hourlyRate, duration } = data;

    const payload = {
      jobId: jobSelected.id,
      estimation,
      expectBudget: `${hourlyRate}`,
      duration: `${duration}`,
    };
    await apply(payload);
  };

  return (
    <Stack
      flex={1}
      width={"100%"}
      sx={{
        p: 2,
        gap: 2,
        borderRadius: 4,
        border: (theme) => `1px solid ${theme.palette.common.bellflowerBlue}`,
      }}
    >
      <Label size={18}>
        Estimation{" "}
        {!!ownApp && (
          <Typography fontSize={14} color={"common.originMain"}>
            {
              "You've already submitted an estimation for this quest. To update it, please resubmit the form."
            }
          </Typography>
        )}
      </Label>
      <Label>Duration (days)</Label>
      <TextField
        fullWidth
        type="number"
        name="duration"
        placeholder="Number of days to deliver your work"
        value={getValues("duration")}
        error={!!errors?.duration?.message}
        helperText={errors?.duration?.message}
        onChange={(event: any) => {
          let inputValue = event.target.value;
          if (/^0[0-9]+/.test(inputValue)) {
            inputValue = inputValue.replace(/^0+/, "");
          }
          const decimalPattern = /^\d+(\.\d{0,1})?$/;
          if (decimalPattern.test(inputValue) || inputValue === "") {
            setValue("duration", inputValue);
          }
          trigger("duration");
        }}
      />
      <Label>Time estimation (Hour)</Label>
      <FormInput
        control={control}
        type="number"
        name="estimation"
        error={!!errors?.estimation?.message}
        helperText={errors?.estimation?.message}
      />
      <Label>Your hourly rate (USD/hour)</Label>
      <FormInput
        control={control}
        type="number"
        name="hourlyRate"
        error={!!errors?.hourlyRate?.message}
        helperText={errors?.hourlyRate?.message}
      />
      <Label>Earnings pending for this quest</Label>
      <Box
        display={"flex"}
        flexDirection={"column"}
        mt={2}
        p={3}
        bgcolor={"#F7FAFC"}
        borderRadius={3}
        gap={2}
      >
        <LineSeparateText startText="Standard fee" endText={standardFree.toFixed(2)} />
        <Divider />
        <LineSeparateText
          startText="Service fee(2.9%)"
          endText={`${Number(standardFree * SERVICE_CHARGE.FREELANCER).toFixed(2)}`}
        />
        <Divider />
        <LineSeparateText
          startText="You will receive"
          endText={`${Number(
            standardFree - standardFree * SERVICE_CHARGE.FREELANCER
          ).toFixed(2)}`}
        />
      </Box>
      <CustomConfirmationDialog
        title="Submit Estimation"
        content={"Are you sure you want to submit this estimation?"}
        buttons={[
          {
            variant: "outlined",
            label: "No",
            isLoading,
          },
          {
            label: "Yes",
            handleOnClickButton: handleSubmit(onSubmitEstimation),
            isLoading,
          },
        ]}
        openDialog={open}
        setOpenDialog={setOpen}
      />
      <Button
        onClick={() => setOpen(true)}
        disabled={isLoading}
        sx={{ mt: 2, maxWidth: 200, alignSelf: "center", borderRadius: 2 }}
      >
        Submit Estimation
      </Button>
    </Stack>
  );
};
export default UpdateQuest;

const Label = styled(Typography)<{ size?: number }>(({ size = 16 }) => ({
  fontWeight: 700,
  fontSize: size,
}));
