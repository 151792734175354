import { FC } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import * as yup from "yup";

import FormInput from "@components/FormInput";

const formSchema = yup.object().shape({
  reason: yup.string().trim().required("Message is required."),
});

export type ApplyToJoinForm = {
  reason: string;
};

const defaultValues: ApplyToJoinForm = {
  reason: "",
};

interface IProps {
  loading: boolean;
  onCancel: () => void;
  onApply: (formData: ApplyToJoinForm) => Promise<void>;
}

const ApplyToJoinDialog: FC<IProps> = ({ loading, onCancel, onApply }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ApplyToJoinForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  return (
    <Dialog fullWidth open maxWidth="md">
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
        Apply To Join
      </DialogTitle>

      <DialogContent>
        <FormInput
          fullWidth
          multiline
          rows={5}
          control={control}
          name="reason"
          label="Message"
          error={!!errors?.reason?.message}
          helperText={errors?.reason?.message}
          sx={{ mt: 1 }}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" disabled={loading} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="contained"
          disabled={!isDirty || !isValid || loading}
          onClick={handleSubmit(onApply)}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyToJoinDialog;
