import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { submitDelivery } from "@api/job/submitDelivery";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";

import { useAppSelector } from "@app/hooks";

import Button from "@components/Button";
import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";
import FileHiddenInput from "@components/FileHiddenInput";

import { useSelectJob } from "@pages/jobs-management/SelectJobContext";

import { getMiddleEllipsisText } from "@utils/helpers/stringHelper";
import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { ReactComponent as AttactmentIcon } from "@assets/icons/attachments.svg";
import { ReactComponent as TrashIcon } from "@assets/icons/trash.svg";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

const formSchema = yup.object().shape({
  files: yup.array().min(1, "Attachtment is required"),
});

export type SubmitDeliveryFormType = {
  files: File[];
};

const defaultValues: SubmitDeliveryFormType = {
  files: [],
};

type WorkSubmissionType = {
  cb?: () => void;
};

const WorkSubmission = ({ cb }: WorkSubmissionType) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SubmitDeliveryFormType>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });
  const { jobSelected } = useSelectJob();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { user } = userInfo;

  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { setSnackbar } = useSnackbar();
  const {
    palette: { common },
  } = useTheme();

  const { mutate: submitWork, isLoading } = useMutation({
    mutationFn: submitDelivery,
    onSuccess: ({ success }) => {
      if (success) {
        queryClient.invalidateQueries(["getJobsByProject", jobSelected.projectId]);
        setSnackbar({
          message: "Work submitted!",
          open: true,
          severity: "success",
        });
        cb && cb();
      }
    },
    onError: () => {
      setSnackbar({
        message: "Delivery submission failed!",
        open: true,
        severity: "error",
      });
    },
  });

  const fileWatch = watch("files");
  const onUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) {
      return;
    }

    const localFiles = e.currentTarget.files;
    const newFiles: File[] = [];

    for (let i = 0; i < localFiles.length; i++) {
      const file = localFiles[i];
      /**
       * Validate file size must less than 5MB
       */
      if (file.size > 1024 * 1024 * 1024) {
        setSnackbar({
          message: "File size exceeds 1GB!",
          open: true,
          severity: "error",
        });
        continue;
      }
      newFiles.push(file);
    }
    setUploadProgress(newFiles.map(() => ({ percentage: 0 })));
    setValue("files", fileWatch?.concat(newFiles));
  };

  const handleRemoveFile = ({ index }: { index: number }) => {
    const newFileList = fileWatch.filter((_, idx) => index !== idx) || [];
    setValue("files", newFileList);
  };

  const onSubmitDelivery = async (data: SubmitDeliveryFormType) => {
    const { files } = data;
    setLoading(true);
    let fileResults: string[] = [];
    if (files.length > 0) {
      fileResults = await uploadImagesToServer(files, null, (percentage, index) =>
        setUploadProgress((prevProgressInfos) => {
          const newProgressInfos = [...prevProgressInfos];
          newProgressInfos[index].percentage = percentage;
          return newProgressInfos;
        })
      );
    }

    const payload = {
      jobId: jobSelected.id,
      body: {
        files: fileResults,
        freelancerId: user.id,
      },
    };
    await submitWork(payload);
    setLoading(false);
  };

  const onOpenConfirm = () => {
    if (!fileWatch.length) {
      setSnackbar({
        message: "Attachtment is required!",
        open: true,
        severity: "error",
      });
      return;
    }
    setOpenConfirm(true);
  };

  return (
    <Stack flex={1} height={"100%"}>
      <Stack overflow={"auto"} flexGrow={1}>
        <Typography variant="subtitle2" p={2}>
          Submit your work for the whole project for a specific milestone
        </Typography>

        <FileHiddenInput
          icon={<AttactmentIcon />}
          onUpload={onUploadFile}
          btnLabel={"Attach file"}
          sx={{
            fontWeight: 600,
            color: common.partyTime,
            borderRadius: 2,
            justifyContent: "center",
            border: `${common.partyTime} 1px solid`,
            m: 2,
            width: "auto",
            ".MuiBox-root": { flexDirection: "row", gap: 1 },
            svg: {
              path: {
                fill: common.partyTime,
              },
            },
          }}
        />
        <Stack px={2}>
          {fileWatch.map((file, index) => (
            <Box
              key={`${file.lastModified}${file.size}`}
              alignItems={"center"}
              flexDirection={"row"}
            >
              <Stack direction={"row"}>
                <Typography
                  fontSize={14}
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    flex: 1,
                  }}
                >
                  {getMiddleEllipsisText(file.name, 30)}
                </Typography>

                <IconButton color="primary" onClick={() => handleRemoveFile({ index })}>
                  <TrashIcon />
                </IconButton>
              </Stack>
              {uploadProgress[index]?.percentage > 0 &&
                uploadProgress[index]?.percentage < 100 && (
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress[index]?.percentage}
                  />
                )}
            </Box>
          ))}
        </Stack>
      </Stack>
      <CustomConfirmationDialog
        title="Submit Delivery"
        content={"You are submitting your work. Are you sure?"}
        buttons={[
          {
            variant: "outlined",
            label: "Not yet",
          },
          {
            label: "Submit now",
            handleOnClickButton: handleSubmit(onSubmitDelivery),
            //prevent unmount before the mutation finishes
          },
        ]}
        openDialog={openConfirm}
        setOpenDialog={setOpenConfirm}
      />
      <Stack flexShrink={0}>
        <Divider />
        <Button
          variant="contained"
          disabled={loading || isLoading}
          loading={loading || isLoading}
          sx={{ fontWeight: "normal", borderRadius: 2, m: 2 }}
          onClick={onOpenConfirm}
        >
          <Typography>Submit</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};
export default WorkSubmission;
