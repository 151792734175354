import { TypographyStyle } from "@mui/material";
import { Priority } from "./constants";

export const formatPriorityStyle = (type: string): TypographyStyle => {
  const styles: TypographyStyle = {
    borderRadius: 4,
    fontSize: 12,
    textAlign: "center",
    p: 0.5,
    px: 1
  };
  switch (type) {
    case Priority.URGENT:
      return {
        ...styles,
        color: "common.partyTime",
        backgroundColor: "common.wePeep",
      }
    case Priority.HIGH:
      return {
        ...styles,
        color: "common.mediumText",
        backgroundColor: "common.mediumPriorty",
      };
    case Priority.MEDIUM:
      return {
        ...styles,
        color: "common.heirloomHydrangea",
        backgroundColor: "#BEE3F8"
      };
    case Priority.LOW:
      return {
        ...styles,
        color: "#1B5E20",
        backgroundColor: "#E8F5E9"
      };
    default:
      return {
        ...styles,
        // color: "common.gray5",
        // backgroundColor: "common.wolfram",
      };
  }
}

// const handleKeyDownList = useCallback((e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>, fieldName?: SelectedField['key'] | any) => {
//   const value = (e.target as HTMLInputElement)?.value;
//   if (e.key === "Enter") {
//     if (getValues(fieldName)?.some((i: any) => i?.name === value)) {
//       return
//     }
//     value &&
//       setValue(fieldName, [...(getValues(fieldName) || []), { name: value }], { shouldDirty: true });
//   }
//   if (e.key === "Backspace" && !value) {
//     const newList = [...getValues(fieldName)?.slice(0, getValues(fieldName)?.length - 1) || []]
//     setValue(fieldName, newList)
//   }
// }, []);
// const handleDeleteList = (index: number, fieldName?: SelectedField['key'] | any) => {
//   if (index < 0) {
//     return setValue(fieldName, [], { shouldDirty: true })
//   }
//   const filteredTags = getValues(fieldName)?.filter(
//     (_tag: string, i: number) => i !== index
//   );
//   setValue(fieldName, filteredTags, { shouldDirty: true });
// };
