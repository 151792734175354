/* eslint-disable react/jsx-no-bind */
import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import useGetContactedP2PUsers from "@api/roundtable/useGetContactP2PUsers";
import useInviteMember from "@api/roundtable/useInviteMember";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField, // debounce,
  Grid,
  Typography,
  Avatar,
  Skeleton,
  InputAdornment,
  IconButton,
  useTheme,
  FormControlLabel,
  Checkbox,
  Stack,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material";

import { useAppSelector } from "@app/hooks";

import { handleApiError } from "@features/config";

import CustomButton from "@components/Button";
import { ISnackbar } from "@components/Snackbar";

import { isValidEmail } from "@utils/helpers/stringHelper";

import { User } from "@dto/user";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  roundtableId: string;
  fetchEventLogs: () => void;
  isRoundtable?: boolean;
}

const filter = createFilterOptions();

const InviteMemberDialog: FC<IProps> = ({
  isOpen,
  setSnackbar,
  onClose,
  roundtableId,
  fetchEventLogs,
  isRoundtable,
}) => {
  const {
    userInfo: { user },
  } = useAppSelector((state) => state.auth);
  const {
    palette: { common },
  } = useTheme();

  const { mutate, isLoading } = useInviteMember();
  const {
    data: userOptions,
    isInitialLoading: contactedP2PUserLoading,
    isLoading: isLoadingContactedP2PUsers,
    handleChangeParams,
  } = useGetContactedP2PUsers({ id: user.id });

  const [selectedUsers, setSelectedUsers] = useState<User[]>(null);
  const [emailInput, setEmailInput] = useState("");
  const [message, setMessage] = useState("");
  const [inviteRoundTable, setInviteRoundTable] = useState(false);
  const [inviteFreelancer, setInviteFreelancer] = useState(false);
  const [isTouchedInSelect, setIsTouchedInSelect] = useState(false);
  const handleSelect = useCallback(
    (e: any, value: User[]) => {
      const element = e.target as HTMLInputElement;
      const input = element.value || "";
      if (isValidEmail(input) || value.length) {
        setSelectedUsers(value);
      }
    },
    [setSelectedUsers]
  );

  const handleMemberInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const input = e.target as HTMLInputElement;
      const value = input.value || "";

      if (isValidEmail(value)) {
        setEmailInput(value);
        setSelectedUsers([]);
      }
    }
  };

  const handleChangeMessage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setMessage(value);
  };

  const handleSave = useCallback(() => {
    mutate(
      {
        roundtableId,
        emails: selectedUsers?.map(({ email }) => email),
        reason: message,
        isBothRtbAndPrj: inviteRoundTable,
        inviteFreelancer,
      },
      {
        onSuccess: () => {
          fetchEventLogs();
          setSnackbar({
            message: emailInput
              ? `An invitation was sent via email to ${emailInput}!`
              : "Invite successfully",
            open: true,
            severity: "success",
          });
        },
        onError: (error) => {
          const { message } = handleApiError(error);

          setSnackbar({
            message,
            open: true,
            severity: "error",
          });
        },
        onSettled: () => {
          onClose();
        },
      }
    );
  }, [
    inviteFreelancer,
    mutate,
    roundtableId,
    emailInput,
    selectedUsers,
    message,
    inviteRoundTable,
    fetchEventLogs,
    setSnackbar,
    onClose,
  ]);
  const isDisabledSaveBtn = isLoadingContactedP2PUsers
    ? true
    : !emailInput && !selectedUsers?.length;
  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
        Invite Member
      </DialogTitle>

      <DialogContent>
        {contactedP2PUserLoading ? (
          <>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </>
        ) : (
          <>
            <Autocomplete
              autoHighlight
              multiple
              options={userOptions}
              getOptionLabel={(user) => user?.email || ""}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    size="small"
                    label="Search member or Enter an email"
                    onKeyDown={handleMemberInputKeyDown}
                  />
                );
              }}
              filterOptions={(options: any[], params: FilterOptionsState<any>) => {
                const filtered: any = filter(options, params);
                const { inputValue } = params;

                const isExisting = options.some((option) => inputValue === option.email);
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    email: `${inputValue}`,
                    // text: inputValue,
                  });
                }
                return filtered;
              }}
              onChange={handleSelect}
              onInputChange={(_, newInputValue) => {
                if (!isTouchedInSelect) {
                  setIsTouchedInSelect(true);
                }

                handleChangeParams({ email: newInputValue });
              }}
              getOptionDisabled={(option) => !isValidEmail(option.email)}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: "flex", width: 44 }}>
                        <Avatar
                          alt="Avatar"
                          src={option.avatar}
                          sx={{ width: 32, height: 32 }}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {option.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
              sx={{ mt: 1 }}
              noOptionsText="User not found"
            />

            <TextField
              fullWidth
              multiline
              rows={5}
              name="message"
              label="Message"
              value={message}
              onChange={handleChangeMessage}
              sx={{ mt: 3 }}
            />
            {!isRoundtable && (
              <Stack>
                <FormControlLabel
                  label={"Invite this member to Team space"}
                  control={
                    <Checkbox
                      value={inviteRoundTable}
                      onChange={() => setInviteRoundTable(!inviteRoundTable)}
                    />
                  }
                  labelPlacement="end"
                  sx={{
                    mt: 1,
                  }}
                />
                <FormControlLabel
                  label={"Invite as a Freelancer"}
                  control={
                    <Checkbox
                      value={inviteFreelancer}
                      onChange={() => setInviteFreelancer(!inviteFreelancer)}
                    />
                  }
                  labelPlacement="end"
                />
              </Stack>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <CustomButton
          variant="outlined"
          disabled={isLoadingContactedP2PUsers || isLoading}
          onClick={onClose}
        >
          Cancel
        </CustomButton>

        <CustomButton
          disabled={isDisabledSaveBtn || isLoading}
          loading={isLoading}
          onClick={handleSave}
        >
          Save
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteMemberDialog;
