import { string } from "yup";

import { axios, handleApiError } from "@features/config";

import { DeliveryDto } from "@dto/job";

import { Delivery_Status } from "./type";

type DeliveriesResponseType = {
  data?: DeliveryDto[];
  success?: boolean;
};

type DeliveriesRequestType = {
  projectId: string;
  status?: Delivery_Status | string;
};

export const getDeliveries = async ({
  projectId,
  status,
}: DeliveriesRequestType): Promise<DeliveriesResponseType> => {
  try {
    const response = await axios.get(`/project/${projectId}/deliveries`, {
      params: {
        status,
      },
    });

    return { data: response?.data?.data, success: true };
  } catch (error) {
    return handleApiError(error);
  }
};
