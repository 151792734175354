import { FC, useEffect, useState, useCallback, memo, ReactNode, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import useEditJob, { useEditQuestDraft, EditJobRequestType } from "@api/job/useEditJob";
import { useGetQuestDraft } from "@api/job/useGetJobDetail";
import useFindSkills from "@api/skill/findSkills";
import useFindUserSkills from "@api/skill/useFindUserSkills";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AttachMoney as AttachMoneyIcon,
  CalendarToday as CalendarIcon,
  Sync as SyncIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TrackChangesSharpIcon from "@mui/icons-material/TrackChangesSharp";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  Avatar,
  Chip,
  Autocomplete,
  useTheme,
  Divider,
  StackProps,
  Grid,
  GridProps,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { debounce, isEmpty, isEqual } from "lodash";
import * as yup from "yup";

import useClientRect from "@hooks/useGetComponentSize";

import FormInput from "@components/FormInput";
import Loading from "@components/Loading";
import { CircleLoading } from "@components/Loading/CircleLoading";
import Uploader from "@components/MultiFileUploader/MultiFileUploader";

import { getMiddleEllipsisText } from "@utils/helpers/stringHelper";
import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { GetJobByProjectDto } from "@dto/job";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

import { useSelectJob } from "./SelectJobContext";
import StartHiringQuestBtn from "./StartHiringQuestBtn";
import UpdateHiringQuestBtn from "./UpdateHiringQuestBtn";
import { Priority, QuestVisibility, JOB_STATUS } from "./constants";
import { formatPriorityStyle } from "./utils";

// import { useCollapseTable } from "./CollapseContext";

type PropTypes = StackProps & {
  data?: GetJobByProjectDto;
  isReadOnly?: boolean;
  onBack?: () => void;
};
type EditableField = Pick<
  GetJobByProjectDto,
  | "title"
  | "description"
  | "deliverables"
  | "detailOfWork"
  | "budget"
  | "priority"
  | "visibility"
  | "dueDate"
  | "jobSkills"
>;
type FieldType = "text" | "number";
type SelectedField = {
  key: keyof EditableField | "attachment" | "";
  type: FieldType | "";
};

const transformFn = (value: any) => (isNaN(value) ? null : value);

const PriorityOptions = [Priority.URGENT, Priority.HIGH, Priority.MEDIUM, Priority.LOW];
const VisibilityOptions = [QuestVisibility.PLATFORM, QuestVisibility.PROJECT];
let timeOutSearch: any = 0;

const EditInlineQuest: FC<PropTypes> = ({ isReadOnly, onBack, ...restProps }) => {
  const [editField, setEditField] = useState<SelectedField>({ key: "", type: "" });
  const [updateDraftAt, setUpdateDraftAt] = useState("");
  const [loading, setLoading] = useState(false);
  const [skillInput, setSkillInput] = useState("");
  // const { isCollapsed } = useCollapseTable();
  const [searchParams] = useSearchParams();
  const { jobSelected } = useSelectJob();
  const {
    data: questDraftData,
    isFetching: isFetchingQuestDraft,
    isLoading: isLoadingQuestDraft,
  } = useGetQuestDraft(jobSelected?.id);
  const { mutate: editQuest, isLoading: isLoadingUpdateDraft } = useEditQuestDraft();
  const { mutate: editJob, isLoading: isLoadingUpdateJob } = useEditJob();
  const {
    skills: userSkills,
    isLoading: loadingSkills,
    handleChangeParams,
  } = useFindSkills({});
  const [rect, ref] = useClientRect([jobSelected.id]);
  const {
    palette: { common },
  } = useTheme();
  const { setSnackbar } = useSnackbar();

  const isLoadingUpdate = isLoadingUpdateJob || isLoadingUpdateDraft || loading;
  const isNarrowWidth = Number(rect?.width || 0) < 450;

  const data = useMemo(() => {
    if (!jobSelected?.id) {
      return;
    }
    // WARN: questDraftData id is different from selectedJob id
    if (
      !isLoadingQuestDraft &&
      !isFetchingQuestDraft &&
      questDraftData?.status === JOB_STATUS.HIRING &&
      !isReadOnly
    ) {
      const { id, ...rest } = questDraftData;
      return { ...jobSelected, ...rest };
    }
    return jobSelected;
  }, [jobSelected?.id, isLoadingQuestDraft, isFetchingQuestDraft]);

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required("This field is required!"),
        description: yup.string().required("This field is required!"),
        deliverables: yup.string(),
        detailOfWork: yup.string(),
        budget: yup.number(),
        priority: yup.string().required("This field is required!"),
        visibility: yup.string().required("This field is required!"),
        dueDate: yup.string().required("This field is required!"),
        jobSkills: yup
          .array()
          .nullable()
          .of(
            yup.object().shape({
              id: yup.string().nullable(),
              name: yup.string(),
            })
          ),
        attachment: yup.array().nullable().of(yup.string()),
      }),
    [jobSelected?.status]
  );

  const {
    control,
    reset,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty, errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const watchedTitle = watch("title");
  const watchedDescription = watch("description");
  const watchedDeliverables = watch("deliverables");
  const watchedDetailOfWork = watch("detailOfWork");
  const watchedPriority = watch("priority");
  const watchedVisibility = watch("visibility");
  const watchedBudget = watch("budget");
  const watchedDueDate = watch("dueDate");
  const watchedJobSkills = watch("jobSkills");
  const watchedAttachments = watch("attachment");
  const havingUpdates = useMemo(() => {
    const originData = {
      title: jobSelected.title,
      priority: jobSelected.priority,
      budget: Number(jobSelected.budget || ""),
      visibility: jobSelected.visibility,
      dueDate: jobSelected.dueDate,
      description: jobSelected.description || "",
      deliverables: jobSelected.deliverables || "",
      detailOfWork: jobSelected.detailOfWork || "",
      attachment: jobSelected.attachment?.map((f) => f.link) || [],
    };
    const draftData = {
      title: watchedTitle,
      priority: watchedPriority,
      budget: watchedBudget || 0,
      visibility: watchedVisibility,
      dueDate: watchedDueDate,
      description: watchedDescription || "",
      deliverables: watchedDeliverables || "",
      detailOfWork: watchedDetailOfWork || "",
      attachment: watchedAttachments || [],
    };

    return !isEqual(originData, draftData);
  }, [jobSelected, data, updateDraftAt]);
  // console.log('havingUpdates | updateDraftAt', havingUpdates, updateDraftAt)

  useEffect(() => {
    handleChangeParams({ searchContent: skillInput });
  }, [skillInput, handleChangeParams]);

  const handleUploadFiles = async (files: File[]) => {
    if (!files || files?.length === 0) {
      return;
    }
    setLoading(true);
    const newFileUris = await uploadImagesToServer(files);
    setLoading(false);
    setValue("attachment", [...watchedAttachments, ...newFileUris], {
      shouldDirty: true,
    });
    handleSave("attachment");
  };
  const handleRemoveFiles = (uri: string) => {
    setValue(
      "attachment",
      watchedAttachments?.filter((f) => f !== uri),
      { shouldDirty: true }
    );
    handleSave("attachment");
  };
  const handleSave = (fieldKey?: any) => {
    const payload: Partial<EditJobRequestType> = {
      id: data.id,
      projectId: data.projectId,
      // isPublic: job.isPublic,
    };
    if (fieldKey === "attachment") {
      (payload as any)["attachment"] = getValues("attachment");
    } else if (editField.key === "jobSkills") {
      (payload as any)["skills"] = getValues("jobSkills").map((s) => ({
        value: s.id,
        label: s.id ? undefined : s.name,
        text: s.name,
      }));
    } else {
      (payload as any)[fieldKey] = getValues(fieldKey as any);
    }

    if (data?.status === JOB_STATUS.TODO) {
      editJob(payload, {
        onSuccess: (_apiData: any) => {
          queryClient.invalidateQueries(["getJobsByProject", data.projectId]);
        },
        onError: (error) => {
          console.log("err", error);
          queryClient.invalidateQueries(["getJobsByProject", data.projectId]);
          setSnackbar({
            message: "Update failed.",
            open: true,
            severity: "error",
          });
        },
      });
    }
    if (data?.status === JOB_STATUS.HIRING) {
      editQuest(payload, {
        onSuccess: (_apiData: any) => {
          // queryClient.invalidateQueries(["getQuestDraft", data.id])
          if (data?.status === JOB_STATUS.HIRING) {
            setUpdateDraftAt(new Date().toISOString());
          }
        },
        onError: (error) => {
          console.log("err", error);
          queryClient.invalidateQueries(["getJobsByProject", data.projectId]);
          setSnackbar({
            message: "Update failed.",
            open: true,
            severity: "error",
          });
        },
      });
    }
  };
  const onChanges = useCallback(
    debounce(() => {
      // console.log('Change field: ', editField.key, getValues(editField.key as any));
      // if (['title', 'budget'].includes(editField.key)) {
      //   setJobSelected({ ...jobSelected, [editField.key]: getValues(editField.key as any) });
      // }
      if (isEmpty(errors)) {
        handleSave(editField.key);
      }
    }, 800),
    [editField.key]
  );
  useEffect(() => {
    if (!editField.key || (!isDirty && !editField.key)) {
      return;
    }
    // not working because of nullable errors ??!
    // if (errors?.[editField.key]?.message) {
    //   return
    // }
    onChanges();
  }, [
    watchedTitle,
    watchedDescription,
    watchedDeliverables,
    watchedDetailOfWork,
    watchedPriority,
    watchedVisibility,
    watchedBudget,
    watchedDueDate,
    watchedJobSkills,
  ]);
  useEffect(() => {
    setEditField({ key: "", type: "" });
  }, [searchParams.get("job")]);

  const resetData = () => {
    reset({
      title: data.title,
      description: data.description || "",
      deliverables: data.deliverables || "",
      detailOfWork: data.detailOfWork || "",
      budget: Number(data.budget || ""),
      priority: data.priority,
      visibility: data.visibility || QuestVisibility.PROJECT,
      dueDate: data.dueDate,
      jobSkills: data.jobSkills?.map((s) => s.skill),
      attachment: data?.attachment?.map((f) => f.link),
    });
    setUpdateDraftAt(data?.updateAt);
  };

  useEffect(() => {
    console.log("RESET DATA");
    resetData();
  }, [data]);

  useEffect(() => {
    if (editField?.key && ["text", "number"].includes(editField.type)) {
      document.getElementById(editField?.key || "")?.focus();
    }
  }, [editField?.key]);

  const handleEditField = (key: SelectedField["key"], type: SelectedField["type"]) => {
    setEditField({ key, type });
  };
  const renderEmptyFieldValue = (defaultText?: string) => {
    return (
      <Typography
        fontStyle={"italic"}
        component={"span"}
        fontSize={14}
        color={"common.gray4"}
      >
        {defaultText}
      </Typography>
    );
  };

  const renderGridField = (
    text?: string,
    startIcon?: ReactNode,
    gridProps?: GridProps
  ) => {
    return (
      <Grid
        item
        xs={3}
        {...gridProps}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          minHeight: "40px",
        }}
      >
        <Stack
          pt={1}
          flexDirection={"row"}
          alignItems={"center"}
          color={"common.gray5"}
          gap={1}
        >
          {startIcon}
          <Typography color={"common.gray5"} fontSize={14}>
            {text}
          </Typography>
        </Stack>
      </Grid>
    );
  };
  const renderGridValueContainer = ({
    children,
    gridCol,
    slotProps,
  }: {
    children: ReactNode;
    gridCol: number;
    slotProps?: StackProps;
  }) => {
    return (
      <Grid item xs={gridCol} alignContent={"flex-start"} sx={{ h: 40 }}>
        <Stack
          borderRadius={1.5}
          overflow={"hidden"}
          sx={{
            ":hover": !isReadOnly && {
              bgcolor: "common.deutziaWhite",
              cursor: "pointer",
            },
          }}
          {...slotProps}
          onClick={isReadOnly ? null : slotProps?.onClick}
        >
          {children}
        </Stack>
      </Grid>
    );
  };

  const handleSubmitAction =
    (successCallback?: () => void) => (nextAction?: () => void) => {
      handleSubmit(() => {
        successCallback?.();
        nextAction?.();
      })();
    };

  if (isLoadingQuestDraft) {
    <Stack border={common.border} borderRadius={4} {...restProps}>
      <CircleLoading height={200} width={"100%"} />
    </Stack>;
  }
  if (!isLoadingQuestDraft && !data) {
    return;
  }

  return (
    <Stack
      component={"div"}
      ref={ref}
      border={common.border}
      borderRadius={4}
      {...restProps}
    >
      <Stack
        p={isNarrowWidth ? 1.5 : 2.5}
        py={1.6}
        flexDirection={"row"}
        justifyContent={"space-between"}
        gap={1.5}
        flexWrap={"wrap"}
      >
        <Stack flexDirection={"row"} gap={1} flex={1}>
          {onBack && (
            <KeyboardArrowLeftIcon
              fontSize="medium"
              sx={{ cursor: "pointer", mt: 1 }}
              onClick={onBack}
            />
          )}
          <FormInput
            id="title"
            control={control}
            name="title"
            placeholder="Title"
            onBlur={() => handleEditField("", "")}
            onFocus={!isReadOnly ? () => handleEditField("title", "text") : null}
            // label="title"
            // size="small"
            multiline
            required
            error={!!errors?.title?.message}
            helperText={errors?.title?.message}
            variant="standard"
            InputProps={{ disableUnderline: true }}
            inputProps={{ readOnly: isReadOnly }}
            sx={{
              ".MuiInput-input": {
                fontSize: isNarrowWidth ? 22 : 28,
                fontWeight: 700,
                lineHeight: "29.5px",
              },
            }}
          />
        </Stack>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1.5}>
          {isLoadingUpdate && (
            <Stack
              sx={{
                color: "common.gray4",
                position: "sticky",
                top: 0,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              gap={0.6}
            >
              <SyncIcon sx={{ width: 20, height: 20 }} />
              <Typography
                sx={{
                  textAlign: "right",
                  color: "common.gray4",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                Saving...
              </Typography>
            </Stack>
          )}
          {!isReadOnly && data?.status === JOB_STATUS.TODO && !isLoadingUpdate && (
            <StartHiringQuestBtn onSubmit={(cb) => handleSubmitAction(cb)()} />
          )}
          {!isReadOnly &&
            data?.status === JOB_STATUS.HIRING &&
            !isLoadingUpdate &&
            havingUpdates &&
            updateDraftAt && (
              <UpdateHiringQuestBtn
                onSubmit={(cb) =>
                  handleSubmitAction(cb)(() => {
                    // setUpdateDraftAt('');
                    setSnackbar({
                      message: "Your changes are notified to Freelancers!",
                      open: true,
                      severity: "success",
                    });
                  })
                }
              />
            )}
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: "common.bellflowerBlue" }} />
      <Stack
        p={isNarrowWidth ? 1.5 : 2.5}
        gap={3}
        height={"calc(100% - 68px)"}
        overflow={"auto"}
      >
        <Grid container columnSpacing={1.5}>
          {renderGridField(
            "Priority",
            <TrackChangesSharpIcon sx={{ width: 18, height: 18 }} />,
            { xs: isNarrowWidth ? 5 : 3 }
          )}
          {renderGridValueContainer({
            gridCol: isNarrowWidth ? 7 : 3,
            slotProps: { onClick: () => handleEditField("priority", "text") },
            children: (
              <FormInput
                control={control}
                select
                key={`priority-${watchedPriority}`}
                id="priority"
                name="priority"
                placeholder="Priority"
                onBlur={() => handleEditField("", "")}
                required
                error={!!errors?.priority?.message}
                helperText={errors?.priority?.message}
                sx={{
                  bgcolor:
                    editField.key === "priority" ? "common.deutziaWhite" : "transparent",
                  ".MuiSelect-select": {
                    px: 1,
                    py: 1,
                    ":focus": { bgcolor: "common.deutziaWhite" },
                  },
                }}
                variant="standard"
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: isReadOnly }}
                SelectProps={{
                  IconComponent: () => <></>,
                  renderValue: (val: unknown) => (
                    <Typography
                      sx={{
                        ...formatPriorityStyle(getValues("priority")),
                        width: "fit-content",
                      }}
                    >
                      {val as string}
                    </Typography>
                  ),
                }}
              >
                {PriorityOptions.map((val) => (
                  <MenuItem key={val} value={val} sx={{ textTransform: "capitalize" }}>
                    {val}
                  </MenuItem>
                ))}
              </FormInput>
            ),
          })}

          {renderGridField("Budget", <AttachMoneyIcon sx={{ width: 18, height: 18 }} />, {
            xs: isNarrowWidth ? 5 : 3,
          })}
          {renderGridValueContainer({
            gridCol: isNarrowWidth ? 7 : 3,
            slotProps: { onClick: () => handleEditField("budget", "number") },
            children: (
              <FormInput
                control={control}
                id="budget"
                name="budget"
                type="number"
                placeholder="Budget"
                onBlur={() => handleEditField("", "")}
                required
                error={!!errors?.budget?.message}
                helperText={errors?.budget?.message}
                sx={{
                  bgcolor:
                    editField.key === "budget" ? "common.deutziaWhite" : "transparent",
                  ".MuiInput-input": { px: 1, py: 1, fontSize: 14 },
                }}
                variant="standard"
                InputProps={{
                  // endAdornment: <AttachMoneyIcon />,
                  disableUnderline: true,
                }}
                inputProps={{ readOnly: isReadOnly }}
              />
            ),
          })}

          {renderGridField(
            "Visibility",
            <RemoveRedEyeOutlinedIcon sx={{ width: 18, height: 18 }} />,
            { xs: isNarrowWidth ? 5 : 3 }
          )}
          {renderGridValueContainer({
            gridCol: isNarrowWidth ? 7 : 3,
            slotProps: { onClick: () => handleEditField("visibility", "text") },
            children: (
              <FormInput
                control={control}
                select
                key={`visibility-${watchedVisibility}`}
                id="visibility"
                name="visibility"
                placeholder="Visibility"
                onBlur={() => handleEditField("", "")}
                required
                error={!!errors?.visibility?.message}
                helperText={errors?.visibility?.message}
                sx={{
                  bgcolor:
                    editField.key === "visibility"
                      ? "common.deutziaWhite"
                      : "transparent",
                  ".MuiSelect-select": {
                    px: 1,
                    py: 1,
                    ":focus": { bgcolor: "common.deutziaWhite" },
                  },
                }}
                variant="standard"
                InputProps={{ disableUnderline: true }}
                inputProps={{ readOnly: isReadOnly }}
                SelectProps={{
                  IconComponent: () => <></>,
                  renderValue: (val: unknown) => (
                    <Typography fontSize={14} textTransform={"capitalize"}>
                      {val as string}
                    </Typography>
                  ),
                }}
              >
                {VisibilityOptions.map((val) => (
                  <MenuItem key={val} value={val} sx={{ textTransform: "capitalize" }}>
                    {val}
                  </MenuItem>
                ))}
              </FormInput>
            ),
          })}

          {renderGridField(
            "Created by",
            <PersonPinOutlinedIcon sx={{ width: 18, height: 18 }} />,
            { xs: isNarrowWidth ? 5 : 3 }
          )}
          <Grid item xs={isNarrowWidth ? 7 : 3} alignContent={"center"}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1} py={0.5}>
              <Avatar
                src={data.createdByUser?.avatar}
                alt="createdUser"
                sx={{ width: 24, height: 24, ml: 1 }}
              />
              <Typography fontSize={14} noWrap>
                {data.createdByUser?.name}
              </Typography>
            </Stack>
          </Grid>

          {renderGridField("Due date", <CalendarIcon sx={{ width: 18, height: 18 }} />, {
            xs: isNarrowWidth ? 5 : 3,
          })}
          {renderGridValueContainer({
            gridCol: isNarrowWidth ? 7 : 9,
            slotProps: { onClick: () => handleEditField("dueDate", "") },
            children: (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  readOnly={isReadOnly}
                  value={dayjs(getValues("dueDate"))}
                  onChange={(selectedDateTime) => {
                    setValue("dueDate", dayjs(selectedDateTime).toISOString(), {
                      shouldDirty: true,
                    });
                  }}
                  slots={{
                    openPickerIcon: () => null,
                  }}
                  onClose={() => handleEditField("", "")}
                  slotProps={{
                    textField: {
                      required: true,
                      error: !!errors?.dueDate?.message,
                      helperText: errors?.dueDate?.message,
                      sx: {
                        ".MuiInput-input": { px: 1, py: 1, fontSize: 14 },
                        width: "100%",
                      },
                      variant: "standard",
                      InputProps: { disableUnderline: true },
                      inputProps: { readOnly: isReadOnly },
                    },
                    openPickerButton: {
                      sx: {
                        height: "100%",
                        width: "100%",
                        borderRadius: "unset",
                        p: 10,
                        ":hover": {},
                      },
                    },
                    inputAdornment: {
                      sx: {
                        position: "absolute",
                        right: 0,
                        height: "100%",
                        width: "100%",
                        borderRadius: "unset",
                        m: 0,
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            ),
          })}

          {renderGridField(
            "Skills",
            <VerifiedOutlinedIcon sx={{ width: 18, height: 18 }} />,
            {
              xs: isNarrowWidth ? 5 : 3,
              sx: { alignContent: "initial", "&.MuiGrid-item": { pt: 1 } },
            }
          )}
          {renderGridValueContainer({
            gridCol: isNarrowWidth ? 7 : 9,
            children:
              editField.key === "jobSkills" ? (
                <Autocomplete
                  id="jobSkills"
                  readOnly={isReadOnly}
                  multiple
                  // hide label: no option, allow enter for creating new
                  freeSolo
                  isOptionEqualToValue={(option: any, value) => {
                    // console.log('isOptionEqualToValue', { option, value })
                    // if result: true: do not allow add new
                    if (typeof option === "string") {
                      return option === value?.value;
                    }
                    return option?.value === value?.value;
                  }}
                  autoHighlight
                  filterSelectedOptions
                  onBlur={() => handleEditField("", "")}
                  options={
                    userSkills
                      ?.filter((s) => s.name)
                      ?.map(({ id, name }) => ({ value: id, label: name || "" })) || []
                  }
                  value={
                    watchedJobSkills?.map((s) => ({ value: s.id, label: s.name })) ?? []
                  }
                  // defaultValue={[{ value: userSkills?.[0]?.id || "", label: userSkills?.[0]?.name || "" }]}
                  onInputChange={(event: any, newInputValue: string) => {
                    if (timeOutSearch) {
                      clearTimeout(timeOutSearch);
                    }
                    timeOutSearch = setTimeout(async () => {
                      setSkillInput(newInputValue);
                    }, 1000);
                  }}
                  onChange={(_e, newValues, reason) => {
                    if (reason === "createOption") {
                      const newInputValue = newValues[newValues.length - 1];
                      if (
                        getValues("jobSkills")?.some(
                          (i: any) => i?.name === newInputValue
                        )
                      ) {
                        return;
                      }
                      const newItem = { name: newInputValue };
                      setValue(
                        "jobSkills",
                        [...(getValues("jobSkills") || []), newItem as any],
                        { shouldDirty: true }
                      );
                      return;
                    }
                    setValue(
                      "jobSkills",
                      (newValues as { value: string; label: string }[])?.map((v) => ({
                        id: v.value,
                        name: v.label,
                      })),
                      { shouldDirty: true }
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Press enter to add new skill" />
                  )}
                  // loading={loadingSkills}
                  {...(loadingSkills && {
                    renderOption: () => (
                      <Stack px={2}>
                        <Loading />
                      </Stack>
                    ),
                  })}
                  renderTags={(value, getTagProps) =>
                    value.map((tag, index) => (
                      <Chip
                        key={`${index}-chip-label`}
                        variant="outlined"
                        label={tag?.label || ""}
                        {...getTagProps({ index })}
                        color="primary"
                        size="small"
                      />
                    ))
                  }
                  sx={{
                    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                      minWidth: "unset",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                  }}
                />
              ) : (
                <Stack
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  alignItems={"center"}
                  gap={1}
                  p={1}
                  onClick={
                    !isReadOnly ? () => handleEditField("jobSkills", "text") : null
                  }
                >
                  {!(getValues("jobSkills")?.length > 0) &&
                    renderEmptyFieldValue("Skills")}
                  {getValues("jobSkills")?.map((s, i) => (
                    <Chip
                      key={i}
                      label={s.name}
                      sx={{
                        fontSize: 14,
                        color: "common.gray7",
                        bgcolor: "common.gray1",
                      }}
                    />
                  ))}
                </Stack>
              ),
          })}
        </Grid>

        <Stack gap={1.5}>
          <Typography fontSize={18} fontWeight={700}>
            Quest overview
          </Typography>
          <FormInput
            id="description"
            control={control}
            name="description"
            placeholder="Description"
            onBlur={() => handleEditField("", "")}
            onFocus={!isReadOnly ? () => handleEditField("description", "text") : null}
            multiline
            minRows={5}
            size="small"
            inputProps={{ style: { fontSize: 14 }, readOnly: isReadOnly }}
            error={!!errors?.description?.message}
            helperText={errors?.description?.message}
          />
        </Stack>
        {/* <Stack gap={1.5}>
          <Typography fontSize={18} fontWeight={700}>
            Deliverables
          </Typography>
          <FormInput
            id="deliverables"
            control={control}
            name="deliverables"
            placeholder="Deliverables"
            onBlur={() => handleEditField("", "")}
            onFocus={!isReadOnly ? () => handleEditField("deliverables", "text") : null}
            multiline
            minRows={5}
            size="small"
            inputProps={{ style: { fontSize: 14 }, readOnly: isReadOnly }}
          />
        </Stack> */}
        <Stack gap={1.5}>
          <Stack flexDirection={"row"} alignItems={"center"} gap={0.7}>
            <Typography fontSize={18} fontWeight={700}>
              Ticket Content
            </Typography>
            <Tooltip title="You can provide additional instructions so once the ticket is assigned, freelancers can start immediately">
              <InfoIcon sx={{ height: 14, width: 14, cursor: "pointer" }} />
            </Tooltip>
          </Stack>
          <FormInput
            id="detailOfWork"
            control={control}
            name="detailOfWork"
            placeholder="Detail of Work"
            onBlur={() => handleEditField("", "")}
            onFocus={!isReadOnly ? () => handleEditField("detailOfWork", "text") : null}
            multiline
            minRows={5}
            size="small"
            inputProps={{ style: { fontSize: 14 }, readOnly: isReadOnly }}
          />
        </Stack>
        <Stack gap={1.5}>
          <Stack flexDirection={"row"} alignItems={"center"} gap={0.7}>
            <Typography fontSize={18} fontWeight={700}>
              Ticket Attachment
            </Typography>
            <Tooltip title="You can upload the required files in advance, so once the ticket is assigned, freelancers can start immediately">
              <InfoIcon sx={{ height: 14, width: 14, cursor: "pointer" }} />
            </Tooltip>
          </Stack>
          {!isReadOnly && (
            <Uploader
              key={`${jobSelected.id}-${JSON.stringify(watchedAttachments)}`}
              onChangeFiles={(files) => handleUploadFiles(files?.files)}
              sx={{ m: 0 }}
              hideThumbnails
            />
          )}
          <Stack gap={1.5}>
            {watchedAttachments?.map((f, i) => {
              const fileName = f?.split("/")?.[f?.split("/")?.length - 1];
              return (
                <Stack
                  key={i}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={0.7}
                  borderRadius={2}
                  py={1.5}
                  px={1.5}
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.common.bellflowerBlue}`,
                  }}
                >
                  <Stack flexDirection={"row"} alignItems={"center"} gap={0.5}>
                    <AttachFileIcon sx={{ color: "common.gray5", height: 17 }} />
                    <Typography
                      fontSize={14}
                      color={"black"}
                      component={"a"}
                      href={f}
                      sx={{ textDecoration: "none" }}
                    >
                      {fileName}
                    </Typography>
                  </Stack>
                  {!isReadOnly && (
                    <Stack onClick={() => handleRemoveFiles(f)}>
                      <DeleteIcon
                        sx={{ color: "common.gray5", cursor: "pointer", height: 18 }}
                      />
                    </Stack>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(EditInlineQuest);
