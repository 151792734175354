import { FC } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Avatar,
  Alert,
  Stack,
  Autocomplete,
  Box,
  Chip,
} from "@mui/material";
import dayjs from "dayjs";

import HelperText from "@components/HelperText";

import { BecomeSubRoundTableForm, Proposal, ProposalType } from "@dto/proposal";
import { Roundtable } from "@dto/roundtable";

interface IProps {
  isOpen: boolean;
  roundtable: Roundtable;
  proposal: Proposal;
  onClose: () => void;
}

const DisplayedRoundTable = ({
  proposal,
  roundtable,
}: {
  proposal: Proposal;
  roundtable: Roundtable;
}) => {
  const isInSubRoundtable =
    roundtable.id === (proposal.data as BecomeSubRoundTableForm)?.subRoundTableId;
  return (
    <Autocomplete
      disabled
      value={
        isInSubRoundtable ? proposal.parentRoundTableTitle : proposal.subRoundTableTitle
      }
      options={[]}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={isInSubRoundtable ? "Parent Teamspace" : "Sub Teamspace"}
          size="small"
          variant="outlined"
        />
      )}
    />
  );
};

const ViewBecomeSubRoundtableDialog: FC<IProps> = ({
  isOpen,
  proposal,
  onClose,
  roundtable,
}) => {
  const { data } = proposal || {};
  const { representatives, originalValues } = (data as BecomeSubRoundTableForm) || {};

  const { endAt, startAt, createdByUser, type } = proposal;
  const isBecomeSubRoundtable = type === ProposalType.BECOME_SUB_ROUND_TABLE;
  const endDate = dayjs(endAt);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
          {isBecomeSubRoundtable ? `Become a Sub-Teamspace` : `Update Sub-Teamspace`}{" "}
          (View Only)
        </Typography>

        <CloseIcon
          sx={{
            width: 24,
            height: 24,
            color: "common.black",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </DialogTitle>

      <DialogContent>
        <Alert
          severity="info"
          icon={<InfoIcon fontSize="inherit" />}
          sx={{ alignItems: "center", color: "common.black", px: { xs: 1.5, sm: 2 } }}
        >
          <Typography sx={{ fontSize: 14 }}>
            The Teamspace is under approval. Expired by:{" "}
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              {dayjs.duration(endDate.diff(dayjs())).humanize(true)}
            </Typography>
          </Typography>

          <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              Last edited by:
            </Typography>

            <Stack direction="row" spacing={0.5} alignItems="center" mx={1}>
              <Avatar
                alt="Avatar"
                src={createdByUser?.avatar}
                sx={{ width: 20, height: 20 }}
              />

              <Typography
                component="span"
                sx={{ fontSize: 14, fontWeight: 600, color: "common.partyTime" }}
              >
                {createdByUser?.name}
              </Typography>
            </Stack>

            <Typography component="span" sx={{ fontSize: 14 }}>
              {dayjs(startAt).format("hh:mm A, YYYY MMM DD")}
            </Typography>
          </Stack>
        </Alert>

        <Stack spacing={3} mt={2}>
          <DisplayedRoundTable proposal={proposal} roundtable={roundtable} />

          {isBecomeSubRoundtable && (
            <TextField
              multiline
              rows={5}
              disabled
              label="Message"
              size="small"
              defaultValue={(proposal?.data as BecomeSubRoundTableForm)?.reason}
            />
          )}

          <TextField
            select
            disabled
            name="representatives"
            label="Representative"
            size="small"
            SelectProps={{
              multiple: true,
              defaultValue: originalValues?.representatives?.map(
                (id: string) =>
                  roundtable.attendees.find((attendee) => attendee.attendeeId === id)
                    ?.name
              ),
              renderValue: (selected: any) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value: string) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              ),
            }}
            helperText={
              isBecomeSubRoundtable ? null : (
                <HelperText
                  data={{ representatives }}
                  field="representatives"
                  currentValue={originalValues?.representatives}
                  formatContent={(representatives: string[]) =>
                    representatives
                      .map(
                        (id) =>
                          roundtable.attendees.find(
                            (attendee) => attendee.attendeeId === id
                          )?.name
                      )
                      .join(", ")
                  }
                />
              )
            }
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ pr: 2.5 }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewBecomeSubRoundtableDialog;
