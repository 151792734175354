import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getDeliveries } from "@api/job/getDeliveries";
import { Delivery_Status } from "@api/job/type";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import useReadDelivery from "@hooks/useReadDelivery";

import { DeliveryContext } from "./DeliveryContext";
import ItemDelivery from "./ItemDelivery";
import ReviewDelivery from "./ReviewDelivery";

enum DeliveryStatusType {
  ALL = "ALL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

interface FilterButtonProps {
  label: string;
  value: DeliveryStatusType;
  handleChangeParams: (value: DeliveryStatusType) => void;
  selectedStatus?: DeliveryStatusType;
}

const FilterButton = ({
  label,
  value,
  handleChangeParams,
  selectedStatus,
}: FilterButtonProps) => {
  const _onClick = () => {
    if (value !== selectedStatus) {
      handleChangeParams(value);
    }
  };

  const selectedItemColor =
    selectedStatus === value ? "common.partyTime" : "common.featherSoftBlue";

  return (
    <Button
      variant="outlined"
      sx={{
        height: 20,
        borderRadius: "16px",
        color: selectedItemColor,
        borderColor: selectedItemColor,
        fontSize: 14,
        fontWeight: 500,
        textTransform: "none",
      }}
      onClick={_onClick}
    >
      {mappingAttachmentLabel(label as DeliveryStatusType)}
    </Button>
  );
};

const attachmentType = [
  { value: "ALL", label: "ALL" },
  { value: "APPROVED", label: "APPROVED" },
  { value: "REJECTED", label: "REJECTED" },
];
const DeliveriesJob = () => {
  const { roundtableId, projectId } = useParams();
  const projectIdParam = projectId ?? roundtableId;

  const { readAllDelivery } = useReadDelivery(projectIdParam);
  const [attType, setAttType] = useState(DeliveryStatusType.ALL);
  const {
    data: deliveries,
    isFetching: loading,
    refetch,
  } = useQuery({
    queryKey: ["getFreelancerWorkInProject"],
    queryFn: () =>
      getDeliveries({
        projectId: projectIdParam,
        status: attType === DeliveryStatusType.ALL ? "" : attType,
      }),
  });

  useEffect(() => {
    const listPending = (deliveries?.data || []).filter(
      (deliveries) => deliveries.status === Delivery_Status.PENDING
    );
    readAllDelivery(listPending);
  }, [deliveries?.data, readAllDelivery]);

  useEffect(() => {
    refetch();
  }, [attType]);

  const { selectedDelivery } = useContext(DeliveryContext);
  return (
    <Stack rowGap={1} sx={{ overflowX: "auto" }} flex={1}>
      {selectedDelivery ? (
        <ReviewDelivery
          delivery={deliveries.data?.find((item) => item.id === selectedDelivery)}
        />
      ) : (
        <>
          <Box
            display="flex"
            pl={3}
            pr={3}
            pt={1}
            pb={1}
            alignItems="center"
            columnGap={1}
            height={40}
          >
            {attachmentType.map((item) => (
              <FilterButton
                key={item.value}
                value={item.value as DeliveryStatusType}
                label={item.label}
                handleChangeParams={setAttType}
                selectedStatus={attType}
              />
            ))}
          </Box>
          <Box sx={{ overflowX: "scroll" }}>
            {loading ? (
              <Skeleton height={65} />
            ) : (
              deliveries.data?.map((item, i) => <ItemDelivery key={i} data={item} />)
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};

const mappingAttachmentLabel = (
  status: (typeof DeliveryStatusType)[keyof typeof DeliveryStatusType]
) => {
  switch (status) {
    case DeliveryStatusType.ALL:
      return "All";
    case DeliveryStatusType.APPROVED:
      return "Accepted";
    case DeliveryStatusType.REJECTED:
      return "Rejected";
    default:
      return "All";
  }
};

export default DeliveriesJob;
