import { PaymentIntent } from "@stripe/stripe-js";

import { axios, handleApiError } from "@features/config";

type GetDepositResponse = {
  success: boolean;
  data?: {
    totalBalance: number;
    lockedBalance: number;
    withdrawableBalance: boolean;
  };
  message?: string;
};
export const getDeposit = async (): Promise<GetDepositResponse> => {
  try {
    const { data } = await axios.get(`/me/balances`);

    return { success: true, data: data?.data?.balance };
  } catch (error) {
    return handleApiError(error);
  }
};

type TopupDepositResponse = {
  success: boolean;
  data?: {
    paymentIntent: PaymentIntent;
    client_secret: string;
  };
  message?: string;
};

export const topUpDeposit = async ({
  amount,
  customerId,
  paymentMethod,
}: {
  amount: number;
  customerId: string;
  paymentMethod: string;
}) => {
  try {
    const { data }: { data: TopupDepositResponse } = await axios.post(
      `/me/deposit/top-up/intent`,
      {
        amount,
        customerId,
        paymentMethod,
      }
    );
    return { success: true, data: data?.data, paymentIntent: data?.data?.paymentIntent };
  } catch (error) {
    return handleApiError(error);
  }
};

export const confirmTopUp = async ({ paymentIntentId }: { paymentIntentId: string }) => {
  try {
    const { data }: { data: TopupDepositResponse } = await axios.post(
      `/me/deposit/top-up/confirm`,
      {
        paymentIntentId,
      }
    );
    return { success: true, data: data?.data, paymentIntent: data?.data?.paymentIntent };
  } catch (error) {
    return handleApiError(error);
  }
};
