export const SidebarKeys = {
  roundtableList: "roundtableList",
  roundtableChat: "roundtableChat",
  roundtableItem: "roundtableItem",
  projectItem: "projectItem",
  roundtableProjectItem: "roundtableProjectItem",
  dashboard: "dashboard",
  P2PChat: "/messenger",
  P2PChatItem: "messengerItem",
  userManagement: "userManagement",
  roundtableManagement: "roundtableManagement",
  frontpageManagement: "frontpageManagement",
  menu: "Menu",
  teamSpaceList: "Teamspaces",
  projectList: "Projects",
} as const;

export type SidebarKey = typeof SidebarKeys;
export type SidebarKeyValue = SidebarKey[keyof SidebarKey];
