import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

export const dayjsGetTimeZone = (date?: Date | Dayjs | string) => {
  const dateWithTz = dayjs(date || new Date()).format("DD/MM/YYYY HH:mm:ss Z");
  const startTzIndex = dateWithTz.lastIndexOf(" ") + 1;
  const tzString = dateWithTz.slice(startTzIndex);
  const tzBrief = tzString.slice(0, tzString.indexOf(":"));

  return "GMT" + tzBrief + ` (${dayjs.tz.guess()})`;
};

export const formatDurationTimeFromNow = (duration: number) => {
  const days = Math.floor(duration);
  const hours = (duration - days) * 24;

  const deliverDate = dayjs().add(days, "day").add(hours, "hour");

  return deliverDate.format("HH:mm A, DD MMM YYYY");
};
