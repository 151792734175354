import { useCallback, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { uploadRoundtableFiles } from "@api/roundtable/uploadRoundtableFiles";
import useGetSearchCriteria from "@api/roundtable/useGetSearchCriteria";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  MenuItem,
  SxProps,
  Divider,
  Autocomplete,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import CustomButton from "@components/Button";
import FormInput from "@components/FormInput";
import HelperText from "@components/HelperText";
import ImageUploader from "@components/ImageUploader";
import MultiFileUploader from "@components/MultiFileUploader";

import { capitalizeFirstChar } from "@utils/helpers/stringHelper";
import { checkImgURL } from "@utils/helpers/urlHelper";

import { Roundtable, VoteModeEnum } from "@dto/roundtable";

import Members from "../CreateUpdateProjectDialog/Members";

export const ROUNDTABLE_DIALOG_TYPE = {
  ROUNDTABLE: "ROUNDTABLE",
  PROJECT_MANAGEMENT: "UPDATE_PROJECT_MANAGEMENT",
  PROJECT_PROFILE: "UPDATE_PROJECT_PROFILE",
} as const;

enum VisibilityOption {
  PRIVATE,
  PUBLIC,
}
type Visibility = {
  value: VisibilityOption;
  text: string;
};
const visibilities: Visibility[] = [
  {
    value: VisibilityOption.PUBLIC,
    text: "Public",
  },
  {
    value: VisibilityOption.PRIVATE,
    text: "Private",
  },
];

type VoteMode = {
  value: VoteModeEnum;
  text: string;
};
const voteModes: VoteMode[] = [
  {
    value: VoteModeEnum.SIMPLE_VOTE,
    text: "Simple",
  },
  {
    value: VoteModeEnum.WEIGHT_COMMON_VOTE,
    text: "Weight Common",
  },
  {
    value: VoteModeEnum.ELECTORS_VOTE,
    text: "Electors",
  },
];

const titleSxProps: SxProps = { fontSize: 16, fontWeight: 700, mb: 1 };

interface IProps<T> {
  isOpen: boolean;
  isLoading: boolean;
  isEdit: boolean;
  isDisabledSubmitBtn?: boolean;
  dialogTitle: string;
  roundtableId?: string;
  onClose: () => void;
  onSave: (data: T) => void;
  project?: Roundtable;
  isViewOnly?: boolean;
  type?: (typeof ROUNDTABLE_DIALOG_TYPE)[keyof typeof ROUNDTABLE_DIALOG_TYPE];
  removeDialog?: boolean;
}

const RoundtableDialog = <T extends object>({
  isOpen,
  isLoading,
  isEdit,
  isDisabledSubmitBtn,
  dialogTitle,
  roundtableId,
  onClose,
  onSave,
  project,
  isViewOnly,
  type = ROUNDTABLE_DIALOG_TYPE.ROUNDTABLE,
  removeDialog,
}: IProps<T>) => {
  const { data: searchCriteria } = useGetSearchCriteria({
    enabled: type === ROUNDTABLE_DIALOG_TYPE.PROJECT_PROFILE,
  });

  const [imageUploading, setImageUploading] = useState(false);
  const imageUploaderRef = useRef(null);
  const documentUploaderRef = useRef(null);
  const [isDirtyFiles, setIsDirtyFiles] = useState(false);
  const initialFiles = useMemo(() => {
    const images: string[] = [],
      documents: string[] = [];
    (project?.files || []).map((f) => {
      if (checkImgURL(f.link)) {
        images.push(f.link);
      } else {
        documents.push(f.link);
      }
    });

    return { images, documents };
  }, [project?.files]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    register,
    watch,
    formState: { errors, isValid },
  } = useFormContext();

  const avatar = watch("avatar");
  const watchedTags = watch("tags");
  const watchedrequireApplication = watch("requireApplication") || false;
  const watchedRequireIntsitution = watch("requireInstitution") || false;

  const handleUploadImage = useCallback(
    (file: File) => {
      setValue("avatar", file, {
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const handleSave = async (data: T) => {
    setImageUploading(true);

    const processAndUpload = async (uploaderRef: any) => {
      if (!!uploaderRef.current?.files) {
        const files = await uploaderRef.current?.processingImages();
        return files;
      }
      return [];
    };

    const [images, documents] = await Promise.all([
      processAndUpload(imageUploaderRef),
      processAndUpload(documentUploaderRef),
    ]);
    await uploadRoundtableFiles({ roundtableId, files: [...images, ...documents] });

    setImageUploading(false);
    onSave(data);
  };

  const handleKeyDownTag = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const input = e.target as HTMLInputElement;
      const value = input.value;
      value &&
        setValue("tags", [...(getValues("tags") || []), value], { shouldDirty: true });
    }
  }, []);

  const handleDeleteTag = (index: number) => {
    const filteredTags = getValues("tags").filter(
      (tag: string, i: number) => i !== index
    );

    setValue("tags", filteredTags);
  };

  const renderContent = () => {
    return (
      <>
        {type !== ROUNDTABLE_DIALOG_TYPE.PROJECT_MANAGEMENT && (
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item xs={12} md={4}>
                <ImageUploader
                  variant={
                    type !== ROUNDTABLE_DIALOG_TYPE.ROUNDTABLE ? "rounded" : "circular"
                  }
                  onSetValue={handleUploadImage}
                  initAvatar={avatar}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <FormInput
                  required
                  control={control}
                  name="title"
                  label={`${
                    type !== ROUNDTABLE_DIALOG_TYPE.ROUNDTABLE ? "Project" : "Teamspace"
                  } Title`}
                  size="small"
                  error={!!errors?.title?.message}
                  helperText={errors?.title?.message}
                  disabled={isViewOnly}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box mt={2}>
          {type === ROUNDTABLE_DIALOG_TYPE.ROUNDTABLE && (
            <Typography variant="h6" sx={titleSxProps}>
              Teamspace Details
            </Typography>
          )}

          <Grid container spacing={2}>
            {type !== ROUNDTABLE_DIALOG_TYPE.PROJECT_PROFILE && (
              <>
                <Grid item xs={12} md={9}>
                  <FormInput
                    control={control}
                    name="type"
                    label="Type"
                    size="small"
                    disabled
                    sx={{ background: "white" }}
                  />
                </Grid>

                <Grid item xs={12} md={9}>
                  <FormInput
                    required
                    control={control}
                    name="passedPercentage"
                    label="Pass Percentage (%)"
                    size="small"
                    type="number"
                    sx={{ background: "white" }}
                    error={!!errors?.passedPercentage?.message}
                    helperText={
                      isViewOnly ? (
                        <HelperText
                          data={{
                            passedPercentage: +project?.passedPercentage,
                          }}
                          field="passedPercentage"
                          currentValue={+project.originalValues?.passedPercentage}
                          suffix="(%)"
                        />
                      ) : (
                        errors?.passedPercentage?.message
                      )
                    }
                    disabled={isViewOnly}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <FormInput
                    required
                    select
                    control={control}
                    name="voteMode"
                    label="Vote Mode"
                    size="small"
                    sx={{ background: "white" }}
                    error={!!errors?.voteMode?.message}
                    helperText={
                      isViewOnly ? (
                        <HelperText
                          data={{
                            voteMode: voteModes.find(
                              (vote) => vote.value === project?.voteMode
                            )?.text,
                          }}
                          field="voteMode"
                          currentValue={project.originalValues?.voteMode}
                        />
                      ) : (
                        errors?.voteMode?.message
                      )
                    }
                    disabled={isViewOnly}
                  >
                    {voteModes.map(({ value, text }) => (
                      <MenuItem key={value} value={value}>
                        {text}
                      </MenuItem>
                    ))}
                  </FormInput>
                </Grid>

                <Grid item xs={12} md={9}>
                  <FormInput
                    required
                    control={control}
                    name="voteDuration"
                    label="Vote Duration (hrs)"
                    size="small"
                    type="number"
                    sx={{ background: "white" }}
                    error={!!errors?.voteDuration?.message}
                    helperText={
                      isViewOnly ? (
                        <HelperText
                          data={{
                            voteDuration: +project?.voteDuration,
                          }}
                          field="voteDuration"
                          currentValue={+project.originalValues?.voteDuration}
                          suffix="(hrs)"
                        />
                      ) : (
                        errors?.voteDuration?.message
                      )
                    }
                    disabled={isViewOnly}
                  />
                </Grid>
              </>
            )}
            {type === ROUNDTABLE_DIALOG_TYPE.PROJECT_MANAGEMENT && (
              <Grid item xs={12} pt={0}>
                <FormControlLabel
                  disabled={isViewOnly}
                  control={
                    <Checkbox
                      {...register("requireInstitution")}
                      checked={watchedRequireIntsitution}
                    />
                  }
                  sx={{
                    "& span.MuiCheckbox-root": { p: 0, mx: "9px" },
                  }}
                  label={"Appear to person within the same institution"}
                />
              </Grid>
            )}
            {project && (
              <>
                {type === ROUNDTABLE_DIALOG_TYPE.PROJECT_PROFILE && (
                  <>
                    {/* <Grid item xs={12} md={6}>
                      <FormInput
                        select
                        control={control}
                        name="category"
                        label="Category"
                        size="small"
                        type="string"
                        error={!!errors?.category?.message}
                        helperText={errors?.category?.message}
                        disabled={isViewOnly}
                      >
                        {searchCriteria?.categories.map((category, index) => {
                          return (
                            <MenuItem
                              key={`${index}-${category.name}-category-option-from-roundtable-dialog`}
                              value={category.name}
                            >
                              {capitalizeFirstChar(category.name ?? "")}
                            </MenuItem>
                          );
                        })}
                      </FormInput>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormInput
                        required
                        select
                        control={control}
                        name="visibility"
                        label="Visibility"
                        size="small"
                        error={!!errors?.visibility?.message}
                        {...(isViewOnly && {
                          value: +project.originalValues?.isPublic,
                        })}
                        helperText={
                          isViewOnly ? (
                            <HelperText
                              data={{
                                visibility: visibilities.find(
                                  (item) => item.value === +project?.isPublic
                                ).text,
                              }}
                              field="visibility"
                              currentValue={
                                visibilities.find(
                                  (item) =>
                                    item.value === +project.originalValues?.isPublic
                                ).text
                              }
                            />
                          ) : (
                            errors?.visibility?.message
                          )
                        }
                        disabled={isViewOnly}
                      >
                        {visibilities.map(({ value, text }) => (
                          <MenuItem key={value} value={value}>
                            {text}
                          </MenuItem>
                        ))}
                      </FormInput>
                    </Grid> */}

                    <Grid item xs={12}>
                      <Autocomplete
                        id="multiple-limit-tags"
                        clearIcon={false}
                        options={[]}
                        freeSolo
                        multiple
                        limitTags={2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Tags"
                            onKeyDown={handleKeyDownTag}
                          />
                        )}
                        value={watchedTags ?? []}
                        renderTags={(value: readonly string[], getTagProps) =>
                          value.map((option: string, index: number) => (
                            <Chip
                              key={`${index}-${option}-chip-label`}
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                              onDelete={(event: any) => handleDeleteTag(index)}
                            />
                          ))
                        }
                        disabled={isViewOnly}
                        sx={{
                          "& > .MuiFormControl-root .MuiInputBase-root": {
                            ".MuiChip-root": {
                              maxHeight: 24,
                            },
                          },
                          "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                            minWidth: "unset",
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            {type !== ROUNDTABLE_DIALOG_TYPE.PROJECT_MANAGEMENT && (
              <>
                {/* <Grid item xs={12} md={6}>
                  <FormInput
                    control={control}
                    name="language"
                    label="Language"
                    size="small"
                    disabled={isViewOnly}
                    {...(isViewOnly && {
                      value: project.originalValues?.language,
                    })}
                    helperText={
                      isViewOnly ? (
                        <HelperText
                          data={project}
                          field="language"
                          currentValue={project.originalValues?.language}
                        />
                      ) : (
                        errors?.language?.message
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    control={control}
                    name="institution"
                    label="Institution"
                    size="small"
                    disabled={isViewOnly}
                    helperText={
                      isViewOnly ? (
                        <HelperText
                          data={{
                            institution: project?.institution,
                          }}
                          field="institution"
                          currentValue={project.originalValues?.institution}
                        />
                      ) : (
                        errors?.institution?.message
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    control={control}
                    name="location"
                    label="Location"
                    size="small"
                    disabled={isViewOnly}
                    {...(isViewOnly && {
                      value: project.originalValues?.location,
                    })}
                    helperText={
                      isViewOnly ? (
                        <HelperText
                          data={project}
                          field="location"
                          currentValue={project.originalValues?.location}
                        />
                      ) : (
                        errors?.location?.message
                      )
                    }
                  /> */}
                {/* {searchCriteria?.countries?.map((location, index) => {
                      return (
                        <MenuItem key={`${index}-${location}`} value={location}>
                          <Typography fontSize={14}>{location}</Typography>
                        </MenuItem>
                      );
                    })}
                  </FormInput> */}
                {/* </Grid> */}
                {/* <Grid item xs={12}>
                  <FormInput
                    multiline
                    maxRows={5}
                    minRows={5}
                    control={control}
                    name="description"
                    label="Description"
                    disabled={isViewOnly}
                    helperText={
                      isViewOnly ? (
                        <HelperText
                          data={{
                            description: project?.description,
                          }}
                          field="description"
                          currentValue={project.originalValues?.description}
                        />
                      ) : (
                        errors?.description?.message
                      )
                    }
                  />
                </Grid> */}
              </>
            )}

            {/* {project && type === ROUNDTABLE_DIALOG_TYPE.PROJECT_PROFILE && (
              <Grid item xs={12}>
                <Members attendees={project.attendees} isViewOnly={isViewOnly} />
                <Divider sx={{ mt: 3, mb: 1 }} />
              </Grid>
            )} */}

            {/* {isEdit &&
              !isViewOnly &&
              type !== ROUNDTABLE_DIALOG_TYPE.PROJECT_MANAGEMENT && (
                <Grid item xs={12} pt={0}>
                  <Typography fontWeight={"bold"} mb={1}>
                    Add images
                  </Typography>
                  <MultiFileUploader
                    roundtableId={roundtableId}
                    ref={imageUploaderRef}
                    acceptedFileTypes="image"
                    initialFiles={initialFiles.images}
                    setIsDirtyFiles={setIsDirtyFiles}
                  />
                </Grid>
              )} */}

            {/* {isEdit && !isViewOnly && type === ROUNDTABLE_DIALOG_TYPE.PROJECT_PROFILE && (
              <Grid item xs={12} pt={0}>
                <Typography fontWeight={"bold"} mb={1}>
                  Add files
                </Typography>
                <MultiFileUploader
                  roundtableId={roundtableId}
                  ref={documentUploaderRef}
                  acceptedFileTypes="document"
                  initialFiles={initialFiles.documents}
                  setIsDirtyFiles={setIsDirtyFiles}
                />
              </Grid>
            )} */}
          </Grid>
        </Box>

        {!isEdit && type === ROUNDTABLE_DIALOG_TYPE.ROUNDTABLE && (
          <Box mt={2}>
            <Typography variant="h6" sx={titleSxProps}>
              Member invitation
            </Typography>
            <FormControlLabel
              label="Application required"
              control={
                <Checkbox
                  checked={watchedrequireApplication}
                  disabled={isViewOnly}
                  onChange={() =>
                    setValue("requireApplication", !watchedrequireApplication)
                  }
                  sx={{ color: "common.bellflowerBlue" }}
                />
              }
              labelPlacement="end"
              sx={{
                "& span.MuiCheckbox-root": { p: 0, mx: "9px" },
                // "& span.MuiTypography-root": { color: "common.gray5" },
              }}
            />
          </Box>
        )}

        {!isEdit ? (
          <Box mt={2}>
            <Typography variant="h6" sx={titleSxProps}>
              Self-Contribution
            </Typography>

            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={6}>
                <FormInput
                  control={control}
                  name="fund"
                  label="Fund ($)"
                  size="small"
                  type="number"
                  error={!!errors?.fund?.message}
                  helperText={errors?.fund?.message}
                />
              </Grid> */}

              <Grid item xs={12} md={12}>
                <FormInput
                  control={control}
                  name="workingHourWeekly"
                  label="Working Hour Weekly (hrs)"
                  size="small"
                  type="number"
                  error={!!errors?.workingHourWeekly?.message}
                  helperText={errors?.workingHourWeekly?.message}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </>
    );
  };

  if (removeDialog) {
    return (
      <Box>
        {renderContent()}
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"right"}
          gap={1}
          mt={3}
        >
          <Button
            variant="outlined"
            disabled={isLoading || imageUploading}
            onClick={onClose}
          >
            Cancel
          </Button>

          <CustomButton
            disabled={
              isDirtyFiles
                ? false
                : !isValid || isLoading || imageUploading || isDisabledSubmitBtn
            }
            loading={isLoading || imageUploading}
            onClick={handleSubmit(handleSave)}
          >
            {isEdit ? "Update" : "Create"}
          </CustomButton>
        </Box>
      </Box>
    );
  }

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
        {dialogTitle}
      </DialogTitle>

      <DialogContent>{renderContent()}</DialogContent>

      <DialogActions sx={{ pt: 1.5, pb: 2 }}>
        <Button
          variant="outlined"
          disabled={isLoading || imageUploading}
          onClick={onClose}
        >
          Cancel
        </Button>

        <CustomButton
          disabled={
            isDirtyFiles
              ? false
              : !isValid || isLoading || imageUploading || isDisabledSubmitBtn
          }
          loading={isLoading || imageUploading}
          onClick={handleSubmit(handleSave)}
        >
          {isEdit ? "Update" : "Create"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default RoundtableDialog;
