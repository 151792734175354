import { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { hireMember } from "@api/job/hireFreelancer";
import { checkoutHiring } from "@api/payment/paymentMethod";
import { getDeposit } from "@api/user/userDeposit";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import { ProtectedPaths } from "@routers/path/ProtectedPath";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";

import useHandleJobManagementLayoutByQuery from "@hooks/useHandleJobMngLayoutByQuery";

import { useAppDispatch } from "@app/hooks";

import { applicationActions } from "@features/application/slice";
import { handleApiError } from "@features/config";
import { setPaymentMethod } from "@features/payment/slice";
import { sidebarActions } from "@features/sidebar/slice";

import Button from "@components/Button";
import ConfirmDialog from "@components/Dialog/ConfirmDialog/ConfirmAction";

import { useSelectJob } from "@pages/jobs-management/SelectJobContext";
import { JOB_STATUS, VIEW } from "@pages/jobs-management/constants";
import PaymentMethod from "@pages/my-account/PaymentMethod";

import { Application } from "@dto/application";
import { User } from "@dto/user";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

type Props = {
  user: User;
  appDetails: Application;
};
export const CheckoutForm = (props: Props) => {
  const { user, appDetails } = props;
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const [selectedCard, setSelectedCard] = useState(null);
  const jobContext = useSelectJob();
  const { jobSelected, setJobSelected } = jobContext || {};
  const { data: balances } = useQuery({
    queryKey: ["getDeposit"],
    queryFn: getDeposit,
  });
  const { pathname } = useLocation();
  const matchChatPage = matchPath({ path: "/chat/*", end: true }, pathname);
  const { appendQuery, regenerateLayoutQueries } = useHandleJobManagementLayoutByQuery();
  const totalPaid = +appDetails?.expectBudget * +appDetails.estimation * 1.015;
  const isEnoughBalance = balances?.data?.lockedBalance >= totalPaid;

  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate: checkout } = useMutation({
    mutationFn: checkoutHiring,
    onSuccess: async (res) => {
      if (res?.client_secret) {
        try {
          const { client_secret: clientSecret } = res;

          const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: selectedCard,
          });

          if (error) {
            // setErrorMessage(error.message);
            throw error.message;
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            // Payment succeeded
            const { success } = await hireMember({
              application: appDetails,
              user,
              paymentIntentId: paymentIntent.id,
            });
            if (success) {
              dispatch(applicationActions.setApplicationId(""));
              dispatch(applicationActions.setOpenCheckout(false));
              dispatch(applicationActions.setOpenListPanel(false));
              dispatch(sidebarActions.setProjectContentView({ view: "list" }));
              queryClient.invalidateQueries(["getJobs"]);
              queryClient.invalidateQueries([
                "getJobsByProject",
                appDetails.applyToJob.project.id,
              ]);
              setSnackbar({
                message: res.message,
                open: true,
                severity: "success",
              });
              if (matchChatPage) {
                appendQuery({
                  ...regenerateLayoutQueries({
                    isFreelancer: false,
                    status: JOB_STATUS.IN_PROGRESS,
                  }),
                });
                setJobSelected({ ...jobSelected, status: JOB_STATUS.INPROGRESS });
              } else {
                navigate(`/chat/${appDetails.applyToJob.project.id}`);
              }
            }
          } else {
            // Payment failed
            // handleOther();
          }
        } catch (error) {
          setErrorMessage(
            "Payment processed fail, please check your card info and try again"
          );
          handleApiError(error);
        } finally {
          setLoading(false);
        }
      } else if (res?.transaction) {
        const { success } = await hireMember({
          application: appDetails,
          user,
          transactionId: res?.transaction?.id,
        });
        if (success) {
          dispatch(applicationActions.setApplicationId(""));
          dispatch(applicationActions.setOpenCheckout(false));
          dispatch(applicationActions.setOpenListPanel(false));
          queryClient.invalidateQueries(["getJobs"]);
          queryClient.invalidateQueries([
            "getJobsByProject",
            appDetails.applyToJob.project.id,
          ]);
          setSnackbar({
            message: res.message,
            open: true,
            severity: "success",
          });
          if (matchChatPage) {
            appendQuery({
              ...regenerateLayoutQueries({
                isFreelancer: false,
                status: JOB_STATUS.IN_PROGRESS,
              }),
            });
            setJobSelected({ ...jobSelected, status: JOB_STATUS.INPROGRESS });
          } else {
            navigate(`/chat/${appDetails.applyToJob.project.id}`);
          }
        }
      }
    },
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!selectedCard) {
      setSnackbar({
        message: "Please select a payment method",
        open: true,
        severity: "info",
      });
      return;
    }
    setLoading(true);

    if (elements == null || stripe == null) {
      return;
    }
    if (!selectedCard) {
      const { error: submitError } = await elements.submit();
      if (submitError?.message) {
        // setErrorMessage(submitError.message);
        setErrorMessage(
          "Payment processed fail, please check your card info and try again"
        );
        setLoading(false);
        return;
      }
    }

    await checkout({
      application: appDetails,
      user,
      paymentMethod: selectedCard,
      customerId: user?.stripeCustomerId,
    });
  };

  const getCardSelected = (selectedCard: string) => {
    setSelectedCard(selectedCard);
    dispatch(setPaymentMethod(selectedCard));
  };

  return (
    <>
      <form className="px-4">
        <Stack display={"flex"}>
          <PaymentMethod selectable getCard={getCardSelected} />
          <Typography variant="caption" mt={2}>
            Your payment will be held securely in Stripe and will only be released once
            you approve the delivery
          </Typography>
          <Button
            onClick={handleSubmit}
            disabled={
              !stripe ||
              !elements ||
              loading ||
              (selectedCard === "deposit" && !isEnoughBalance)
            }
            variant="contained"
            sx={{ fontWeight: "normal", bgcolor: "common.black", borderRadius: 2, my: 1 }}
          >
            <Typography>Confirm and Pay</Typography>
          </Button>
          {/* {errorMessage && <div>{errorMessage}</div>} */}
        </Stack>
      </form>
      {errorMessage && (
        <ConfirmDialog
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage("")}
          title={"Checkout"}
          description={errorMessage}
          cancelBtnLabel="Close"
        />
      )}
    </>
  );
};
