import { useCallback, useEffect, useMemo, useState } from "react";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { VscDiffAdded } from "react-icons/vsc";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useGetJoinedRoundtables from "@api/roundtable/useGetJoinedRoundtables";
import {
  Outbound as OutboundIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import AppRoutes, { AppSectionKey, AppSections } from "@routers/AppRoutes";
import { SidebarKeys } from "@routers/const";
import { ProtectedPaths } from "@routers/path/ProtectedPath";
import { AppRoute } from "@routers/type";

import { useAppSelector } from "@app/hooks";

import StartConversation from "@components/UserSelector";

import ConversationItemView from "@pages/messenger/ConversationList/ConversationItemView";
import {
  useConversation,
  useGetUnreadP2PIds,
} from "@pages/messenger/ConversationList/hooks";
import { addNewConversation } from "@pages/messenger/ConversationList/service";
import { ConversationType } from "@pages/messenger/ConversationList/type";

import {
  getAbbreviation, // generateHSL
} from "@utils/index";

import { Roundtable } from "@dto/roundtable";
import { UserRole, User } from "@dto/user";

import { RoundtableTypeEnum } from "@@types/type";

import { SIDEBAR_KEY_STORAGE } from "../const";
import useControlSidebar from "./useControlSidebar";

export type NavItem = Extract<AppRoute, { inSidebar: true }> & { className?: string };

export const useNavItems = () => {
  const { roundtableId, projectId, conversationId } = useParams();
  const { pathname } = useLocation();
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const isAdminUser = userInfo?.user?.role === UserRole.ADMIN;
  const navigate = useNavigate();
  const [open] = useControlSidebar();
  const {
    data: roundtableList,
    isSuccess,
    isLoading,
  } = useGetJoinedRoundtables({
    userId: userInfo?.user?.id,
    pageSize: 9999,
    type: [RoundtableTypeEnum.TEAM],
  });
  const [conversations = []] = useConversation(userInfo?.user?.id);
  const unreadConversations = useGetUnreadP2PIds();

  const formatedConversations = useMemo(
    () =>
      conversations.map((item) => ({
        ...item,
        // title: item.participantsList.map((item) => item.name).join(", "),
        isUnread: unreadConversations.findIndex((_) => _ === item.id) > -1,
      })),
    [conversations, unreadConversations]
  );

  const [expandItems, setExpandItems] = useState<string[]>(
    sessionStorage.getItem(SIDEBAR_KEY_STORAGE.EXPANDED_ITEMS)
      ? JSON.parse(sessionStorage.getItem(SIDEBAR_KEY_STORAGE.EXPANDED_ITEMS))
      : []
  );

  const [selectedSidebarItem, setSelectedSidebarItem] = useState<string>(
    sessionStorage.getItem(SIDEBAR_KEY_STORAGE.SELECTED_ITEM) || ""
  );

  // new chat
  const renderAddChatBtn = useCallback(({ open }: { open: () => void }) => {
    return (
      <Button
        variant="contained"
        startIcon={<VscDiffAdded />}
        sx={{ width: "100%" }}
        onClick={(e) => {
          e.stopPropagation();
          open();
        }}
      >
        New Chat
      </Button>
    );
  }, []);
  const handleStartNewConversation = useCallback(
    async (users: User[]) => {
      const { pHash } = await addNewConversation(userInfo?.user, users);
      handleFocusSidebarItem(`${SidebarKeys.P2PChatItem}:${pHash}`);
      navigate(`/messenger/${pHash}`);
    },
    [userInfo?.user]
  );
  //

  const generateSidebarItems = useCallback(
    (roundTables?: Roundtable[], p2pChats?: ConversationType[]) => {
      const sideBarItems = Object.keys(AppSections).reduce(
        (acc, key) => {
          const sectionKey = key as AppSectionKey;
          acc[sectionKey] = {
            sectionLabel: "",
            items: [],
          };

          acc[sectionKey]["sectionLabel"] = AppSections[sectionKey as AppSectionKey];

          !isAdminUser &&
            AppRoutes.protected.forEach((appProtectedRoute) => {
              if (!appProtectedRoute.inSidebar) {
                return;
              }

              if (!(appProtectedRoute.section === AppSections[key as AppSectionKey])) {
                return;
              }

              const route = appProtectedRoute;

              if (roundTables?.length && route.key === SidebarKeys.roundtableList) {
                const menuKey = SidebarKeys.roundtableList;
                const checkMenuExpanded = expandItems.findIndex(
                  (item) => item === menuKey
                );
                route.expandIcon =
                  checkMenuExpanded < 0 ? KeyboardArrowRightIcon : KeyboardArrowDownIcon;

                if (isLoading) {
                  return (route.nestedPaths = [
                    {
                      key: key as any,
                      path: "/#",
                      section: AppSections.Communication,
                      sidebarLabel: "",
                      sidebarIcon: null,
                      inSidebar: true,
                      component: null,
                      nestedPaths: [],
                    },
                  ]);
                }

                if (open) {
                  route.nestedPaths = roundTables.map((roundtable) => {
                    const key = `${SidebarKeys.roundtableItem}:${roundtable.id}`;
                    const checkExpanded = expandItems.includes(key);
                    const checkSelected = selectedSidebarItem === key;

                    return {
                      key: key as any,
                      path: ProtectedPaths.chatRoundTableId.replace(
                        ":roundtableId",
                        roundtable.id
                      ) as any,
                      className:
                        checkSelected && checkExpanded
                          ? ""
                          : checkExpanded
                          ? "roundTable-item"
                          : "",
                      section: AppSections.Communication,
                      sidebarLabel: roundtable.title,
                      // sidebarIcon: checkExpanded ? AiFillCaretDown : AiFillCaretRight,
                      sidebarIcon: () => (
                        <Avatar
                          sx={{
                            // bgcolor: generateHSL(getAbbreviation(roundtable.title)),
                            width: 31,
                            height: 31,
                            fontSize: 16,
                          }}
                          alt={roundtable.title}
                          src={roundtable.avatar}
                        >
                          {!roundtable.avatar && getAbbreviation(roundtable.title)}
                        </Avatar>
                      ),
                      expandIcon:
                        roundtable.projects?.length > 0 &&
                        (checkExpanded ? AiFillCaretDown : AiFillCaretRight),
                      inSidebar: true,
                      component: <></>,
                      nestedPaths: roundtable.projects?.map?.((project) => ({
                        key: `${SidebarKeys.roundtableItem}:${roundtable.id}:${project.id}` as any,
                        path: project.id,

                        section: AppSections.Communication,
                        sidebarLabel: project.title,
                        sidebarIcon: project.type?.startsWith("EXTERNAL_")
                          ? OutboundIcon
                          : () => (
                              <Avatar
                                sx={{
                                  // bgcolor: generateHSL(getAbbreviation(roundtable.title)),
                                  width: 31,
                                  height: 31,
                                  fontSize: 16,
                                }}
                                variant="rounded"
                                alt={project.title}
                                src={project.avatar}
                              >
                                {!project.avatar && getAbbreviation(project.title)}
                              </Avatar>
                            ),
                        inSidebar: true,
                        component: <></>,
                        className: "roundTable-item-project",
                      })),
                    };
                  }) as NavItem[];
                } else {
                  route.nestedPaths = [];
                }
              }

              if (open && route.key === SidebarKeys.P2PChat) {
                const menuKey = SidebarKeys.P2PChat;
                const checkMenuExpanded = expandItems.findIndex(
                  (item) => item === menuKey
                );
                route.expandIcon =
                  checkMenuExpanded < 0 ? KeyboardArrowRightIcon : KeyboardArrowDownIcon;

                route.nestedPaths = formatedConversations.map((conversation) => {
                  const key = `${SidebarKeys.P2PChatItem}:${conversation.id}`;
                  // const partner = conversation?.participantsList.find((u) => u.id !== userInfo?.user?.id);

                  return {
                    key: key as any,
                    path: ProtectedPaths.conversationId.replace(
                      ProtectedPaths.conversationId,
                      conversation.id
                    ) as any,
                    section: AppSections.Communication,
                    inSidebar: true,
                    component: <></>,
                    sidebarLabel: (
                      <ConversationItemView
                        data={conversation}
                        isUnread={conversation.isUnread}
                      />
                    ),
                    // sidebarLabel: !partner && conversation?.participantsList?.length > 1 ? ' (You)' : partner?.name,
                    // sidebarIcon: () => (
                    //   <Avatar
                    //     sx={{
                    //       width: 31,
                    //       height: 31,
                    //       fontSize: 16,
                    //     }}
                    //     alt={partner?.name}
                    //     src={partner?.avatar}
                    //   >
                    //     {!partner?.avatar && getAbbreviation(partner?.name)}
                    //   </Avatar>
                    // ),
                    // className:
                    //   checkSelected && checkExpanded
                    //     ? ""
                    //     : checkExpanded
                    //     ? "roundTable-item"
                    //     : "",
                  };
                }) as NavItem[];

                route.nestedPaths.push({
                  path: "nothing",
                  section: AppSections.Communication,
                  sidebarLabel: (
                    <StartConversation
                      trigger={renderAddChatBtn}
                      onSubmit={handleStartNewConversation}
                    />
                  ),
                  inSidebar: true,
                  component: null,
                });
              } else if (!open && route.key === SidebarKeys.P2PChat) {
                route.nestedPaths = [];
              }

              acc[sectionKey]["items"].push(route);
            });

          isAdminUser &&
            AppRoutes.admin.forEach((appAdminRoute) => {
              if (
                !appAdminRoute.inSidebar ||
                !(appAdminRoute.section === AppSections[key as AppSectionKey])
              ) {
                return;
              }

              acc[sectionKey]["items"].push(appAdminRoute);
            });

          return acc;
        },
        {} as Record<
          AppSectionKey,
          {
            sectionLabel: string;
            items: NavItem[];
          }
        >
      );

      return sideBarItems;
    },
    [
      isAdminUser,
      open,
      expandItems,
      isLoading,
      selectedSidebarItem,
      formatedConversations,
      renderAddChatBtn,
      handleStartNewConversation,
    ]
  );

  const sideBarItems = useMemo(() => generateSidebarItems(), [generateSidebarItems]);
  const [navItems, setNavItems] = useState(sideBarItems);
  const [highlightHandler, setHighlightHandler] = useState(true);

  const handleChangeExpandSidebarItems = useCallback((nodeIds: string[]) => {
    setExpandItems(nodeIds);
    sessionStorage.setItem(SIDEBAR_KEY_STORAGE.EXPANDED_ITEMS, JSON.stringify(nodeIds));
  }, []);

  const handleFocusSidebarItem = useCallback((nodeId: string) => {
    setSelectedSidebarItem(nodeId);
    sessionStorage.setItem(SIDEBAR_KEY_STORAGE.SELECTED_ITEM, nodeId);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setNavItems(generateSidebarItems(roundtableList, conversations));
    }
  }, [roundtableList, conversations, isSuccess]);

  useEffect(() => {
    const selectedItem = sessionStorage.getItem(SIDEBAR_KEY_STORAGE.SELECTED_ITEM);

    if ((pathname === "" || pathname === "/") && selectedItem !== "/") {
      // return handleFocusSidebarItem("dashboard");
    }

    if (!roundtableId && !conversationId) {
      return handleFocusSidebarItem(pathname);
    }

    // if (roundtableId && projectId) {
    //   const nodeId = `${SidebarKeys.roundtableItem}:${roundtableId}:${projectId}`;
    //   return handleFocusSidebarItem(nodeId);
    // }

    // if (roundtableId && !projectId) {
    //   handleFocusSidebarItem(`${SidebarKeys.roundtableItem}:${roundtableId}`);
    // }
  }, [conversationId, handleFocusSidebarItem, pathname, roundtableId]);

  // highlight the roundtable/project
  useEffect(() => {
    if (
      !roundtableList?.length ||
      !isSuccess ||
      !conversations?.length ||
      (!roundtableId && !projectId && !conversationId) ||
      !highlightHandler
    ) {
      return;
    }

    const newExpandItems = [...(expandItems || [])];
    // highlight selected menu
    // in Roundtable chat
    if (roundtableId || projectId) {
      !newExpandItems.includes(SidebarKeys.roundtableList) &&
        newExpandItems.push(SidebarKeys.roundtableList);
      !newExpandItems.includes(`${SidebarKeys.roundtableItem}:${roundtableId}`) &&
        roundtableId &&
        newExpandItems.unshift(`${SidebarKeys.roundtableItem}:${roundtableId}`);

      const newSelectedItem = [];
      roundtableId && newSelectedItem.push(roundtableId);
      projectId && newSelectedItem.push(projectId);

      if (newExpandItems?.length > expandItems.length) {
        handleChangeExpandSidebarItems(newExpandItems);
      }
      if (newSelectedItem?.length > 0) {
        handleFocusSidebarItem(
          `${SidebarKeys.roundtableItem}:${newSelectedItem.join(":")}`
        );
      }
    }
    // in P2P Chat
    if (conversationId) {
      !newExpandItems.includes(SidebarKeys.P2PChat) &&
        newExpandItems.push(SidebarKeys.P2PChat);

      handleFocusSidebarItem(`${SidebarKeys.P2PChatItem}:${conversationId}`);
    }

    setHighlightHandler(false);
  }, [pathname, roundtableList, isSuccess, conversations]);

  return [
    navItems,
    {
      handleChangeExpandSidebarItems,
      handleFocusSidebarItem,
      expandItems,
      selectedSidebarItem,
    },
  ] satisfies [
    typeof navItems,
    {
      handleChangeExpandSidebarItems: typeof handleChangeExpandSidebarItems;
      handleFocusSidebarItem: typeof handleFocusSidebarItem;
      expandItems: typeof expandItems;
      selectedSidebarItem: typeof selectedSidebarItem;
    }
  ];
};
