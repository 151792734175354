import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

import { useGetEventLogs } from "@api/event-logs/useGetEventLogs";
import useGetRoundtable from "@api/roundtable/useGetRoundtable";
import { RoundTableAttendeeEnum } from "@constants/index";
import { ToolOptionType } from "@constants/roundtable";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { Box, Paper, Grid, Stack, IconButton, Divider, useTheme } from "@mui/material";

// import { ProtectedPaths } from "@routers/path/ProtectedPath";
import { useAppSelector } from "@app/hooks";

import { applicationActions } from "@features/application/slice";
import { sidebarActions } from "@features/sidebar/slice";

import CheckoutCardList from "@components/CheckoutApplication";
import ContentWrapper from "@components/ContentWrapper";
import CustomTabs, { TabItem } from "@components/CustomTabs";
import { useNavItems } from "@components/Layout/PageLayout/hooks/useNavItems";
import CustomSnackbar, { ISnackbar } from "@components/Snackbar";

import Dialogs from "@pages/chat/Dialogs";
import JobsManagement from "@pages/jobs-management";
import { useQuestNotificationsManagement } from "@pages/jobs-management/QuestActivity/QuestNotificationManagement";
import { SelectJobProvider } from "@pages/jobs-management/SelectJobContext";

import { isFreelancer, isProject } from "@utils/roundtable.utils";

import { useViewport } from "@contexts/ViewportContext";

import AttachmentsChat from "./AttachmentsChat";
import Conversation from "./Conversation";
import EventDetails from "./EventDetails";
import EventLogs from "./EventLogs";
import Header from "./Header";
import MemberToolManagement from "./MemberToolManagement";
import Projects from "./Projects";
import RightBar, { TAB_DATA_KEY } from "./RightBar";
import DeliveriesJob from "./RightBar/ListDeliveries";
import { DeliveryProvider } from "./RightBar/ListDeliveries/DeliveryContext";
import SidebarQuestTool from "./RightBar/SidebarQuestTool";
import SubmitDelivery from "./SubmitDelivery";

const RoundTableDetail: FC = () => {
  const params = useParams();
  const {
    palette: { common },
  } = useTheme();
  const { isDesktop } = useViewport();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { user } = userInfo;
  const { roundtableId, projectId } = params;
  const conversationId = projectId ? projectId : roundtableId;
  const {
    projectContentView,
    openRightBar: rightBarOpen,
    openMemberToolMng: memberToolMngOpen,
  } = useAppSelector((state) => state.sideBar);
  const { openCheckout, jobId } = useAppSelector((state) => state.application);
  const { updateProjectActiveStatus } = useQuestNotificationsManagement();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const proposalId = searchParams.get("proposal");

  const [_, { handleChangeExpandSidebarItems }] = useNavItems();
  const projectNodeId = searchParams.get("activeRoundtable");

  const { handleChangeParams: handleChangeEventLogsParams } = useGetEventLogs({
    roundtableId: conversationId,
  });
  const { data: roundtable, refetch: refetchRoundtable } = useGetRoundtable({
    id: conversationId,
  });

  const isRoundtable = !isProject(roundtable);
  const isFreelancerView = isFreelancer(roundtable, user);

  const eventLogsRef = useRef<React.ElementRef<typeof EventLogs>>();

  useEffect(() => {
    dispatch(sidebarActions.setMemberToolMngOpen({ open: false }));
  }, [roundtableId]);

  useEffect(() => {
    dispatch(
      sidebarActions.setProjectContentView({ view: isRoundtable ? "chat" : "list" })
    );
  }, [isRoundtable]);

  const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false });
  const [eventDetail, setEventDetail] = useState<{ isOpened: boolean; id: string }>({
    isOpened: !!proposalId,
    id: proposalId || "",
  });

  const desktopComponentWidth = {
    xs: { chat: 12, eventDetail: 12, rightBar: 12 },
    lg: {
      // chat: eventDetail.isOpened ? 6 : 9,
      eventDetail: eventDetail.isOpened ? 3 : 0,
      rightBar: rightBarOpen ? 3 : 0,
    },
    xxl: {
      // chat: eventDetail.isOpened ? 8 : 10,
      eventDetail: eventDetail.isOpened ? 2 : 0,
      rightBar: rightBarOpen ? 2 : 0,
    },
  };

  useEffect(() => {
    if (projectId && projectNodeId) {
      handleChangeExpandSidebarItems(projectNodeId.split(","));
    }
  }, [projectId, projectNodeId]);

  useEffect(() => {
    updateProjectActiveStatus(conversationId);

    return () => {
      updateProjectActiveStatus();
    };
  }, [conversationId]);

  useEffect(() => {
    handleChangeEventLogsParams({ roundtableId: conversationId });
  }, [conversationId, handleChangeEventLogsParams]);

  useEffect(() => {
    setEventDetail({
      isOpened: !!proposalId,
      id: proposalId,
    });
  }, [proposalId]);

  const currentAttendee = useMemo(() => {
    return roundtable?.attendees?.find((attendee) => attendee.userId === user?.id);
  }, [roundtable, user]);

  const representatives = useMemo(() => {
    return roundtable?.attendees?.filter((attendee) =>
      attendee.subRoundTable?.subRoundTableRepresentatives?.filter(
        (subRoundtableRepresentative) => subRoundtableRepresentative.userId === user.id
      )
    );
  }, [roundtable, user]);

  const [dialogToggle, setDialogToggle] = useState<
    Partial<Record<ToolOptionType, boolean>>
  >({
    DETAIL: false,
    POLL: false,
    INVITATION: false,
    POSITION: false,
    SETTING: false,
    SUB_ROUNDTABLE_BECOMING: false,
    PROJECT: false,
    SUB_PROJECT_UPDATE: false,
    LEAVE: false,
    LEAVE_PROJECT: false,
    VOTE_DELEGATE: false,
    DELETE: false,
    PROJECT_PUBLISHING: false,
    isEdit: false,
    ROUNDTABLE_MANAGEMENT: false,
    ROUNDTABLE_PROFILE: false,
  });

  const toggleDialog = (type: ToolOptionType, isOpened: boolean) => {
    setDialogToggle((prevState) => ({ ...prevState, [type]: isOpened }));
  };

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar({ open: false });
  }, []);

  const fetchEventLogs = useCallback(() => {
    eventLogsRef.current?.refetchEventLog?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventLogsRef.current?.refetchEventLog]);

  const handleClickLog = useCallback(
    (id: string) => {
      if (!id) {
        return;
      }

      setSearchParams({ proposal: id });
    },
    [setSearchParams]
  );

  const tabData = useMemo(() => {
    const data = [
      {
        dataKey: TAB_DATA_KEY.EVENT_LOG,
        label: "Activity Log",
        content: (
          <Stack height="100%">
            <EventLogs
              ref={eventLogsRef}
              onClick={handleClickLog}
              roundtable={roundtable}
              currentAttendee={currentAttendee}
              refetchRoundtable={refetchRoundtable}
            />
          </Stack>
        ),
      },
      {
        dataKey: TAB_DATA_KEY.ATTACHTMENTS,
        label: "Attachments",
        content: (
          <Stack height="100%">
            <AttachmentsChat />
          </Stack>
        ),
      },
      // {
      //   dataKey: TAB_DATA_KEY.RT_PROJECTS,
      //   label: "Projects",
      //   content: <Projects roundtableId={conversationId} setSnackbar={setSnackbar} />,
      // },
    ];
    if (isRoundtable) {
      data.push({
        dataKey: TAB_DATA_KEY.RT_PROJECTS,
        label: "Projects",
        content: <Projects roundtableId={conversationId} setSnackbar={setSnackbar} />,
      });
    } else {
      if (isFreelancerView) {
        data.push({
          dataKey: TAB_DATA_KEY.SUBMIT_DELIVERY,
          label: "Submit delivery",
          content: (
            <Stack height="100%">
              <SubmitDelivery />
            </Stack>
          ),
        });
      } else {
        data.push({
          dataKey: TAB_DATA_KEY.FREELANCER_DELIVERIES,
          label: "Freelance delivery",
          content: (
            <Stack height="100%">
              <DeliveriesJob />
            </Stack>
          ),
        });
      }
      data.push({
        dataKey: TAB_DATA_KEY.JOBS_MANAGEMENT,
        label: "Quests management",
        content: (
          // <Navigate
          //   to={ProtectedPaths.jobsManagement
          //     .replace(":roundtableId", roundtableId)
          //     .replace(":projectId", projectId)}
          // />
          <JobsManagement isFreelancer={isFreelancerView} roundtableData={roundtable} />
        ),
      });
    }

    return isDesktop
      ? data
      : [
          {
            label: "Chat",
            content: (
              <Conversation
                conversationId={conversationId}
                user={user}
                userForMentions={roundtable?.attendees}
                isRoundtableConversation
                representatives={representatives}
              />
            ),
          },
          ...data,
        ];
  }, [isDesktop, handleClickLog, roundtable, user, conversationId]);

  const isRTRepresentativeOnly = useMemo(() => {
    if (!user) {
      return false;
    }
    const isRTMember = roundtable?.alreadyJoined;
    return (
      !isRTMember &&
      roundtable?.attendees
        ?.filter((attendee) => attendee?.type === RoundTableAttendeeEnum.SUB_ROUNDTABLE)
        ?.some((attendee) =>
          attendee?.subRoundTable?.subRoundTableRepresentatives?.some(
            (atd) => atd.userId === userInfo?.user?.id
          )
        )
    );
  }, [roundtable?.id]);

  if (roundtable && memberToolMngOpen && !isRTRepresentativeOnly) {
    return (
      <Stack>
        <Header
          roundtable={roundtable}
          setDialogToggle={setDialogToggle}
          userNotification={currentAttendee?.isReceiveNotification}
        />
        <MemberToolManagement conversationId={conversationId} roundtable={roundtable} />
        <Dialogs
          dialogToggle={dialogToggle}
          toggleDialog={toggleDialog}
          conversationId={conversationId}
          setSnackbar={setSnackbar}
          fetchEventLogs={fetchEventLogs}
          roundtable={roundtable}
        />
      </Stack>
    );
  }

  return roundtable ? (
    <>
      <SelectJobProvider>
        {!isDesktop && (
          <Header
            roundtable={roundtable}
            setDialogToggle={setDialogToggle}
            userNotification={currentAttendee?.isReceiveNotification}
          />
        )}
        <Paper
          sx={{
            m: 0,
            height: isDesktop ? "100vh" : "calc(100vh - 143px)",
            overflow: "auto",
          }}
        >
          {isDesktop && (
            <Header
              roundtable={roundtable}
              setDialogToggle={setDialogToggle}
              userNotification={currentAttendee?.isReceiveNotification}
            />
          )}
          {isDesktop ? (
            <Grid
              container
              height="calc(100% - 108px)"
              overflow={"hidden"}
              mt={0}
              sx={{ "& .MuiGrid-item": { pt: 0 } }}
            >
              <Grid
                item
                xs={12}
                // desktop={eventDetail.isOpened ? 5 : 9}
                // lg={eventDetail.isOpened ? 6 : 9}
                // xxl={eventDetail.isOpened ? 8 : 10}
                lg={
                  12 -
                  desktopComponentWidth.lg.eventDetail -
                  desktopComponentWidth.lg.rightBar
                }
                xxl={
                  12 -
                  desktopComponentWidth.xxl.eventDetail -
                  desktopComponentWidth.xxl.rightBar
                }
                height="100%"
                sx={{
                  overflow: "auto",
                }}
              >
                {projectContentView === "chat" && (
                  <Conversation
                    conversationId={conversationId}
                    user={user}
                    userForMentions={roundtable?.attendees}
                    isRoundtableConversation
                    representatives={representatives}
                  />
                )}
                {!isRoundtable && projectContentView === "list" && (
                  <JobsManagement
                    isFreelancer={isFreelancerView}
                    roundtableData={roundtable}
                  />
                )}
              </Grid>

              {!!(eventDetail.isOpened && eventDetail.id) && (
                <Grid
                  item
                  // desktop={4}
                  // lg={3}
                  // xxl={2}
                  lg={desktopComponentWidth.lg.eventDetail}
                  xxl={desktopComponentWidth.xxl.eventDetail}
                  width="100%"
                  position="relative"
                  borderLeft={common.border}
                >
                  <Box
                    mt={2}
                    borderRadius={2}
                    p={1}
                    minHeight={420}
                    mb={2}
                    sx={{
                      backgroundColor: common.deutziaWhite,
                    }}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        left: -12,
                        top: 16,
                        backgroundColor: common.white,
                        width: 24,
                        height: 24,
                        borderRadius: 1,
                        border: "1px solid",
                        color: common.black,
                        borderColor: common.bellflowerBlue,
                      }}
                      onClick={
                        () => setSearchParams({ proposal: "" })
                        // setEventDetail((prevState) => ({ ...prevState, isOpened: false }))
                      }
                    >
                      <KeyboardDoubleArrowRightOutlinedIcon />
                    </IconButton>
                    <EventDetails
                      proposalId={eventDetail.id}
                      roundtableId={conversationId}
                      roundtable={roundtable}
                      currentAttendee={currentAttendee}
                      refetchRoundtable={refetchRoundtable}
                    />
                  </Box>
                  <Divider />
                </Grid>
              )}
              {rightBarOpen === "project" && (
                <Grid
                  item
                  xs={12}
                  lg={desktopComponentWidth.lg.rightBar}
                  xxl={desktopComponentWidth.xxl.rightBar}
                  sx={{ height: "100%" }}
                >
                  {/* <CustomTabs data={tabData} hasBorderLeft /> */}
                  <Box height={"100%"} py={2} pr={2}>
                    <DeliveryProvider>
                      <RightBar
                        data={tabData?.filter(
                          (t: TabItem) => t.dataKey !== TAB_DATA_KEY.JOBS_MANAGEMENT
                        )}
                        isRoundtable={isRoundtable}
                        isFreelancer={isFreelancerView}
                      />
                    </DeliveryProvider>
                  </Box>
                </Grid>
              )}
              {rightBarOpen === "quest" && (
                <Grid
                  item
                  xs={12}
                  lg={desktopComponentWidth.lg.rightBar}
                  xxl={desktopComponentWidth.xxl.rightBar}
                  sx={{ height: "100%" }}
                >
                  {openCheckout ? (
                    <Box height={"100%"} pb={2} pt={11.5} pr={2}>
                      <ContentWrapper
                        borderRadius={4}
                        border={common.border}
                        height={"100%"}
                        title="Checkout"
                        onClose={() => {
                          dispatch(applicationActions.setOpenCheckout(false));
                        }}
                      >
                        <CheckoutCardList jobId={jobId} />
                      </ContentWrapper>
                    </Box>
                  ) : (
                    <SidebarQuestTool isFreelancer={isFreelancerView} />
                  )}
                </Grid>
              )}
            </Grid>
          ) : (
            <CustomTabs data={tabData} />
          )}
        </Paper>
        <CustomSnackbar onClose={handleCloseSnackbar} {...snackbar} />
        <Dialogs
          dialogToggle={dialogToggle}
          toggleDialog={toggleDialog}
          conversationId={conversationId}
          setSnackbar={setSnackbar}
          fetchEventLogs={fetchEventLogs}
          roundtable={roundtable}
        />
      </SelectJobProvider>
    </>
  ) : (
    <></>
  );
};

export default RoundTableDetail;
