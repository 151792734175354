import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

interface IRequest {
  id: string;
  reason: string;
}

const useCloseProject = () => {
  return useMutation(async ({ id, reason }: IRequest) => {
    const url = `/round-table/${id}/close`;
    const response = await axios.patch(url, {
      params: {
        reason
      }
    });

    return response.data.data;
  });
};

export default useCloseProject;
