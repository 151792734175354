import { Key } from "react";

import { EditRoundtableForm } from "@components/Dialog/AddEditRoundtableDialog/EditRoundtableDialog";
import { UpdatePositionForm } from "@components/Dialog/UpdatePositionDialog";

import { User } from "@dto/user";

import { RoundtableType } from "@@types/type";

import { Roundtable, SubRoundTableRepresentative } from "./roundtable";

export enum ProposalStatus {
  ONGOING = "ONGOING",
  COMPLETED = "COMPLETED",
  UNVOTABLE = "UNVOTABLE",
  ALL = "ONGOING,COMPLETED",
}

export enum ProposalType {
  APPLY_TO_JOIN = "APPLY_TO_JOIN",
  REQUEST_VOTE_WEIGHT = "REQUEST_VOTE_WEIGHT",
  CREATE_PROPOSAL_PROJECT = "CREATE_PROPOSAL_PROJECT",
  UPDATE_SETTINGS = "UPDATE_SETTINGS",
  UPDATE_ROUND_TABLE_MANAGEMENT = "UPDATE_ROUND_TABLE_MANAGEMENT",
  UPDATE_ROUND_TABLE_PROFILE = "UPDATE_ROUND_TABLE_PROFILE",
  PROJECT_MANAGEMENT = "UPDATE_PROJECT_MANAGEMENT",
  PROJECT_PROFILE = "UPDATE_PROJECT_PROFILE",
  DELETE_ROUND_TABLE = "DELETE_ROUND_TABLE",
  CLOSE_PROJECT = "CLOSE_PROJECT",
  CREATE_ROUND_TABLE = "CREATE_ROUND_TABLE",
  POLL = "POLL",
  BECOME_SUB_ROUND_TABLE = "BECOME_SUB_ROUND_TABLE",
  APPROVE_SUB_ROUND_TABLE = "APPROVE_SUB_ROUND_TABLE",
  SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT = "SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT",
  SUB_ROUND_TABLE_BECOME_FORMAL_MEMBER = "SUB_ROUND_TABLE_BECOME_FORMAL_MEMBER",
  BECOME_FORMAL_MEMBER = "BECOME_FORMAL_MEMBER",
  APPROVE_SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT = "APPROVE_SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT",
  UPDATE_REPRESENTATIVES = "UPDATE_REPRESENTATIVES",
  PUBLISH_PROJECT = "PUBLISH_PROJECT",
  EXIT_PARENT_ROUNDTABLE = "EXIT_PARENT_ROUNDTABLE",
  EVENT = "EVENT",
  APPLY_TO_JOIN_EXTERNAL = "APPLY_TO_JOIN_EXTERNAL",
  KICKOUT_MEMBER = "KICKOUT_MEMBER",
}

export type VoteOption = {
  id: string;
  label: string;
  votes: {
    userId: string;
  }[];
  totalVoteWeight: number;
};

type AttachmentType = {
  url: string;
  name: string;
  size: number;
  type: string;
};

export type JoinRequest = {
  reason?: string;
  attachment?: AttachmentType[];
};

export type BecomeSubRoundTableForm = {
  parentRoundTableId: Key;
  subRoundTableId: Key;
  representatives: Key[];
  reason: string;
  originalValues?: any;
};

export type ExitParentRoundtableForm = {
  reason: string;
};

type CreateProjectMember = {
  percentageProfit: number;
  userId: string;
  voteWeight: number;
};

export type CreateProjectForm = {
  avatar: string;
  description: string;
  fund: number;
  type: RoundtableType;
  title: string;
  parentId: string;
  memberList: CreateProjectMember[];
  category?: string;
  tags?: string;
};

export type CreateEventProposalForm = {
  title: string;
  question: string;
  from: string;
  to: string;
  link: string;
  reminder: string;
};

export type KickoutEventProposalForm = {
  reason: string;
  createdByRole: string;
  user: {
    id: string;
    name: string;
    avatar: string;
  };
  type: string;
  voteWeight: string;
  isSuperAdmin: boolean;
  mainRoundtable: {
    managementStyle: string;
  };
};

type ProposalResult = {
  label: string;
  rt_id: string;
  rt_weight: string;
  vote_mode: string;
  vote_option_id: string;
  vote_weight: number;
};

export type Proposal = {
  id: string;
  title: string;
  question: string;
  result?: string;
  startAt?: string;
  endAt?: string;
  voteOptions: VoteOption[];
  isVoted: boolean;
  createdByUser?: User;
  status?: ProposalStatus;
  type?: ProposalType;
  data?:
    | Roundtable
    | EditRoundtableForm
    | UpdatePositionForm
    | BecomeSubRoundTableForm
    | CreateProjectForm
    | ExitParentRoundtableForm
    | CreateEventProposalForm
    | KickoutEventProposalForm;
  joinRequest?: JoinRequest;
  parentRoundTableTitle?: string;
  subRoundTableTitle?: string;
  roundTable: Roundtable;
  representatives?: SubRoundTableRepresentative[];
  maxVotes: number;
  resultDetail: ProposalResult[];
};
