import { useCallback, useMemo, useState } from "react";
import { FiFileText } from "react-icons/fi";

import { Close as CloseIcon } from "@mui/icons-material";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Link,
  Stack,
  StackTypeMap,
  Typography,
  styled,
  useTheme,
  Modal,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import dayjs from "dayjs";

import ViewImageDialog from "@components/Dialog/ViewImageDialog";
import EllipsisContent from "@components/EllipsisContent";

import { humanFileSize } from "@utils/format/fileSize";

import { MessageType } from "./type";

const styleMap = {
  left: {
    justifyContent: "flex-start",
    flexDirection: "row",
  },
};

const MessageBox = ({
  title,
  text,
  position,
  avatar,
  representatives,
  userId,
  ...rest
}: MessageType) => {
  const {
    palette: { primary, common },
  } = useTheme();

  const [isOpenedModal, setIsOpenedModal] = useState("");
  const [embedLink, setEmbedLink] = useState({
    url: "",
    displayText: "",
  });

  const positionStyle: Partial<OverridableComponent<StackTypeMap>> = styleMap.left;

  const rednerTime = (date?: any) => {
    if (!date) {
      return null;
    }
    const msgDate = dayjs(typeof date === "number" ? date : date?.seconds * 1000);
    const format = "h:mm A";
    // let format = "hh:mm A, ddd DD MMM, YYYY";

    // const dateStartWeek = dayjs().day(1);
    // if (msgDate.diff(dateStartWeek, "second") > 0) {
    //   format = "ddd hh:mm A";
    // }

    let formatedDate = msgDate.format(format);
    const todayStart = dayjs().startOf("day");
    if (msgDate.diff(todayStart, "second") > 0) {
      formatedDate = msgDate.fromNow();
    }

    return (
      <Typography color={"#0000005C"} fontSize={"10px"}>
        {formatedDate}
      </Typography>
    );
  };

  const currentRepresentative = useMemo(() => {
    return representatives?.find((r) =>
      r.subRoundTable?.subRoundTableRepresentatives?.find(
        (subRoundTableRepresentative) => subRoundTableRepresentative.userId === userId
      )
    );
  }, [userId, representatives]);

  const handleToggleModal = useCallback(
    (modalSection?: string) => {
      setIsOpenedModal(modalSection);
    },
    [isOpenedModal]
  );

  return (
    <Stack className="message-item" gap={1} px={2} py={1} {...positionStyle}>
      <Box>
        <Avatar src={avatar}>{title}</Avatar>
      </Box>
      <Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={currentRepresentative ? 1 : 1.3}
          alignSelf={"flex-start"}
          mb={0.5}
        >
          {currentRepresentative && position === "right" && (
            <Chip
              label={currentRepresentative.subRoundTable.title}
              size="small"
              sx={{
                bgcolor: common.doverGrey,
                border: common.border,
                color: common.white,
                height: 18,
                fontSize: 10,
              }}
            />
          )}
          <Typography component={"span"} fontSize={"12px"} fontWeight={"600"}>
            {title}
          </Typography>
          {currentRepresentative && position === "left" && (
            <Typography fontSize={"12px"}>
              {currentRepresentative.subRoundTable.title}
            </Typography>
          )}
          {rednerTime(rest.date)}
        </Stack>
        {!!(text || rest.fileUrls?.length) && (
          <MessageMentionContainer
            p={1.5}
            bgcolor={" #EDF2F7"}
            borderRadius={`0 8px 8px 8px`}
            fontSize={14}
            alignSelf={"flex-start"}
            display="flex"
            flexDirection="column"
            {...(!!(text && rest.fileUrls?.length) && { gap: 1 })}
          >
            {!!text && (
              <Typography
                component={"p"}
                fontSize={14}
                sx={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                }}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            {rest.fileUrls && (
              <FileContainer gap={1}>
                {rest.fileUrls?.map?.((f) => {
                  return (
                    <Link
                      href={f.url}
                      color="#000"
                      fontWeight="bold"
                      target="_blank"
                      rel="noreferrer"
                      underline="none"
                      key={f.url}
                      display="flex"
                      alignItems="center"
                      gap={1}
                      sx={{
                        ":hover": {
                          p: 0.5,
                          m: -0.5,
                          backgroundColor:
                            position === "left" ? "#c3d4eb" : "common.wePeep",
                        },
                        borderRadius: 1,
                      }}
                    >
                      <Box
                        borderRadius="100%"
                        p={1}
                        bgcolor="primary.main"
                        width={40}
                        height={40}
                        color="white"
                      >
                        <FiFileText size={24} />
                      </Box>
                      <Box>
                        <Box>{f.name}</Box>
                        {!!f.size && (
                          <Typography color="#0000007A" fontSize={10}>
                            {humanFileSize(f.size)}
                          </Typography>
                        )}
                      </Box>
                    </Link>
                  );
                })}
              </FileContainer>
            )}
          </MessageMentionContainer>
        )}
        {rest.imageUrls?.length > 0 && (
          <FileContainer flexDirection={"column"} gap={1}>
            {rest.imageUrls?.map?.((image) => {
              return image.type?.startsWith("video/") ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <Box
                  key={image.url}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 8,
                  }}
                >
                  <OndemandVideoIcon />
                  <Link
                    target="_parent"
                    href={image.url}
                    style={{ color: "#000", textDecorationColor: "#000" }}
                  >
                    <EllipsisContent
                      numberOfLines={1}
                      sx={{ fontSize: 16, marginTop: 0 }}
                      content={image.name}
                    />
                  </Link>
                </Box>
              ) : (
                //   <video controls autoPlay>
                //   <source src={image.url} type="video/mp4" />
                //   Your browser does not support the video tag.
                // </video>
                <Image
                  key={image.url}
                  src={image.url}
                  alt={image.name}
                  onClick={() => {
                    setEmbedLink({ url: image.url, displayText: "" });
                    handleToggleModal("image");
                  }}
                />
              );
            })}
          </FileContainer>
        )}
      </Stack>
      {!!isOpenedModal && (
        <ViewImageDialog {...{ embedLink, isOpenedModal, handleToggleModal }} />
      )}
    </Stack>
  );
};

const FileContainer = styled(Stack)`
  display: flex;
`;

const Image = styled("img")`
  max-width: 300px;
`;

const MessageMentionContainer = styled(Box)`
  max-width: 100%;

  textarea:disabled {
    border: 0;
    color: #000;
    line-height: 1.4;
  }
  width: fit-content;
  span[data-lexical-beautiful-mention="true"] {
    font-family: Poppins;
    font-weight: 500;
    color: #3182ce;
    padding: 2px 4px;
    border-radius: 4px;
  }
`;

export default MessageBox;
