import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton, Modal, useTheme } from "@mui/material";

type PropsType = {
  isOpenedModal: string | null;
  handleToggleModal: (type: string) => void;
  embedLink: {
    url: string;
    displayText?: string;
  };
};

const ViewImageDialog = ({ isOpenedModal, handleToggleModal, embedLink }: PropsType) => {
  const {
    palette: { common },
  } = useTheme();
  return (
    <Modal
      open={!!isOpenedModal}
      onClose={() => handleToggleModal("")}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        {isOpenedModal === "video" && embedLink && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              border: "2px solid #000",
            }}
          >
            <Box
              component="iframe"
              src={`${embedLink.url}?autoplay=1&mute=1`}
              sx={{
                display: "block" /* iframes are inline by default */,
                border: "none" /* Reset default border */,
                height: "90vh" /* Viewport-relative units */,
                width: "90vw",
              }}
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={embedLink.displayText}
            />
          </Box>
        )}
        {isOpenedModal === "image" && embedLink && (
          <Box position="relative">
            <Box
              component="img"
              alt={`image`}
              src={embedLink.url}
              sx={{
                maxWidth: "80vw",
                maxHeight: "90vh",
                borderRadius: 3,
              }}
            />
            <IconButton
              onClick={() => handleToggleModal("")}
              sx={{
                position: "absolute",
                top: 10,
                left: 10,
                bgcolor: common.white,
                boxShadow: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </div>
    </Modal>
  );
};

export default ViewImageDialog;
