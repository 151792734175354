import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { FiSend } from "react-icons/fi";

import { Stack, Box, Button, Popover, TextField, Typography } from "@mui/material";
import { maxBy } from "lodash";

import { RemainingVoteWeight } from "@dto/delegations";
import { Attendee } from "@dto/roundtable";

import { useVoteShare } from "./CustomizedVoteShareContext";
import VoteShareRow from "./VoteShareRow";

interface IProps {
  voteShareByProposalType: RemainingVoteWeight[];
  selectedAttendee: Attendee;
  hasSelectEventCallback?: (data?: any) => void;
}

const VoteShareList = (props: IProps, ref: any) => {
  const { voteShareByProposalType, selectedAttendee, hasSelectEventCallback } = props;
  const { setDelegations, setErrors, errors, selectVote, delegations } = useVoteShare();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const methods = useForm();
  const proposalTypes = Object.keys(selectVote);
  // handle popover
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = methods.handleSubmit((data: { voteValue: number }) => {
    const newDelegations = { ...delegations };
    const newErrors = { ...errors };
    const voteShareByList: { [key: string]: RemainingVoteWeight } = {};
    voteShareByProposalType.forEach((item) => {
      voteShareByList[item.proposalType] = item;
    });
    for (const proposalType in selectVote) {
      if (selectVote[proposalType]) {
        const newValue = Number(data.voteValue || 0);
        newDelegations[proposalType] = newValue;
        newErrors[proposalType] =
          +newValue <= 0 ||
          +newValue > +voteShareByList[proposalType].shareableVoteWeight * 100;
      }
    }
    setErrors(newErrors);
    setDelegations(newDelegations);
    handleClose();
    methods.reset();
  });

  const open = Boolean(anchorEl);
  const id = open ? "bulk-update-value" : undefined;
  // const hasSelect = !!proposalTypes.length;
  const hasSelect = proposalTypes?.length > 1;
  const max =
    Number(maxBy(voteShareByProposalType, "shareableVoteWeight").shareableVoteWeight) ||
    0;

  useEffect(() => {
    if (hasSelectEventCallback) {
      hasSelectEventCallback({ hasSelect });
    }
  }, [hasSelect])

  useImperativeHandle(ref, () => ({
    hasSelect,
    handleClick,
  }));

  return (
    <Stack spacing={{ xs: 1, md: 3 }} mt={3} pl={1}>
      {/* {hasSelect && (
        <Box ml="auto">
          <Button aria-describedby={id} variant="contained" onClick={handleClick}>
            Bulk update
          </Button>
        </Box>
      )} */}
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <form style={{ padding: "16px" }} onSubmit={handleSubmit}>
          <Stack flexDirection="row" gap={2} textAlign="left">
            <TextField
              fullWidth
              type="number"
              placeholder="Vote Weight"
              variant="outlined"
              {...methods.register("voteValue", {
                // min,
                max: max * 100,
                valueAsNumber: true,
              })}
            />
            <Button onClick={handleSubmit} type="submit" variant="contained">
              <FiSend color="white" size={24} fill="white" />
            </Button>
          </Stack>
          {methods.formState.errors.voteValue && (
            <Typography mt={1}>
              The value is exceeded the maximum vote weight!
            </Typography>
          )}
        </form>
      </Popover>
      {voteShareByProposalType?.map((voteShare) => (
        <VoteShareRow
          key={`vote-share-${voteShare.proposalType}-${selectedAttendee.attendeeId}`}
          voteShare={voteShare}
        />
      ))}
    </Stack>
  );
};

export default forwardRef<unknown, IProps>(VoteShareList);
