import { useQuery, useMutation } from "@tanstack/react-query";

import { axios, handleApiError } from "@features/config";

type ConnectedBankAccountResponse = {
  success: boolean;
  data?: {
    payoutsEnabled: boolean;
    externalAccounts: {
      data: {
        id: string;
        last4: string;
      }[];
    };
  };
};

type ConnectBankAccountResponse = {
  success: boolean;
  data?: {
    url: string;
  };
};

export const getConnectedBankAccount =
  async (): Promise<ConnectedBankAccountResponse> => {
    try {
      const { data } = await axios.get("/stripe/account");
      return { success: true, data: data?.data };
    } catch (error) {
      return handleApiError(error);
    }
  };

export const useGetConnectedBankAccount = () =>
  useQuery(["getConnectedBankAccount"], () => getConnectedBankAccount());

export const connectBankAccount = async (): Promise<ConnectBankAccountResponse> => {
  try {
    const { data } = await axios.get("/stripe/connect-account");
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};

const withdrawMoney = async (amount: number) => {
  try {
    const { data } = await axios.post("/payment/withdraw", { amount });
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const useWithdrawMoney = () => {
  return useMutation(withdrawMoney);
};
