import { useState, useMemo } from "react";
import { GiPlainCircle } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

import {
  Close as CloseIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
  FacebookRounded as FacebookIcon,
  LinkedIn as LinkedInIcon,
  YouTube as YoutubeIcon,
} from "@mui/icons-material";
import {
  Stack,
  Box,
  IconButton,
  Button,
  useTheme,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { AuthPath } from "@routers/path/AuthPath";
import { PublicPaths } from "@routers/path/PublicPath";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { signOut } from "@features/auth/slice";

import { addNewUrlParams } from "@utils/helpers/stringHelper";

import gatherlanceLogo from "@assets/images/gatherlance-landing/gatherlance_logo.png";
import RoundtableCorpImg from "@assets/roundtable/PNG/Roundtable Corp/RoundtableCorp-Horizontal-MainAndWhiteColor.png";

import { useViewport } from "@contexts/ViewportContext";

import { Subcrible } from "./Subcrible";

const JOIN_CLOSE_BETA_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSdb3wlS206_V16D7G8SWFIq-7QuBDW2BDzJJlugiM7DTjTmbQ/viewform";

const SignInOutBtn = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    palette: { primary, common },
  } = useTheme();
  const { isMobile, isTablet } = useViewport();
  const isMobileDesign = isMobile || isTablet;

  const isLoggedIn = useMemo(() => {
    if (userInfo) {
      return true;
    }

    return false;
  }, [userInfo]);

  const handleClickSignInAndSignOut = () => {
    if (isLoggedIn) {
      dispatch(signOut());
      navigate(AuthPath.signIn);
    }

    return navigate(
      addNewUrlParams(AuthPath.signIn, {
        redirectTo: PublicPaths.indexPage,
      })
    );
  };
  // useEffect(() => {
  //   const link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
  //   link.rel = "icon";
  //   link.setAttribute("href", `${FAVICON_NEW}?v=${Date.now()}`);
  // }, []);

  return (
    <Stack flexDirection={isMobileDesign ? "column" : "row"} gap={2} p={3}>
      {/* {isLoggedIn && <DocumentHeader />} */}
      {isLoggedIn && (
        <Button
          sx={{
            fontWeight: "normal",
            padding: "5px",
            color: "common.black",
            ":hover": { backgroundColor: `${common.originMain}1A` },
            fontSize: 15,
          }}
          onClick={() => (window.location.href = "/dashboard")}
        >
          My dashboard
        </Button>
      )}

      <Button
        variant={isLoggedIn ? "text" : "contained"}
        sx={{
          borderRadius: "50px",
          textTransform: "unset",
          bgcolor: isLoggedIn ? common.white : common.originMain,
          color: isLoggedIn ? common.black : common.white,
          ":hover": {
            bgcolor: isLoggedIn ? common.white : `${common.originMain}1A`,
            color: common.black,
          },
          boxShadow: "none",
        }}
        onClick={handleClickSignInAndSignOut}
        // startIcon={!isLoggedIn && <LogoutIcon />}
        endIcon={isLoggedIn && <LogoutIcon />}
      >
        {isLoggedIn
          ? `${userInfo?.user?.name || userInfo?.user?.username} - Log out`
          : "Sign in"}
      </Button>
    </Stack>
  );
};

export const Header = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const {
    palette: { common },
  } = useTheme();
  const { isMobile, isTablet, isDesktop } = useViewport();
  const navigate = useNavigate();
  const isMobileDesign = isMobile || isTablet;

  return (
    <Stack px={{ sm: 3, md: 10 }}>
      <Stack
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        height={"70px"}
        px={isMobile ? 2 : 0}
        borderBottom={isMobileDesign ? "none" : common.border}
      >
        <Stack
          style={{ cursor: "pointer" }}
          onClick={() => navigate(PublicPaths.indexPage)}
        >
          <img src={"/logo.png"} alt="heading-logo" height={isMobileDesign ? 32 : 40} />
        </Stack>

        {isMobileDesign ? (
          <IconButton onClick={() => setOpenMobileMenu(!openMobileMenu)} sx={{ px: 0 }}>
            {openMobileMenu ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        ) : (
          <Stack flexDirection={"row"} gap={2} alignItems="center">
            <SignInOutBtn />
          </Stack>
        )}
      </Stack>

      {isMobileDesign && openMobileMenu && (
        <Stack rowGap={2} p={3}>
          <SignInOutBtn />
        </Stack>
      )}
    </Stack>
  );
};

export const Footer = () => {
  const { isMobile, isDesktop } = useViewport();

  const onNavigateCloseBeta = () => {
    window.open(JOIN_CLOSE_BETA_URL, "_blank");
  };

  return (
    <Stack>
      <Stack sx={{ backgroundColor: "#1C1C1C", pt: 10 }}>
        <Stack px={{ sm: 3, md: 10 }}>
          <Typography
            fontFamily={"Poppins"}
            color={"common.originMain"}
            textAlign={isMobile ? "center" : "initial"}
            fontSize={{ xs: 44, md: 64 }}
            fontWeight={600}
          >
            Ready to start?
          </Typography>
          <Stack
            flexDirection={"row"}
            flexWrap={"wrap"}
            alignItems={"center"}
            justifyContent={!isMobile ? "space-between" : "center"}
            sx={{ pb: 12 }}
            rowGap={16}
          >
            <Typography fontFamily={"Poppins"} fontSize={16} color={"white"}>
              Join gatherlance beta to help us test out!
            </Typography>
            <Box width="363px">
              <Subcrible />
            </Box>
          </Stack>
          <Stack
            sx={{
              borderTop: "1px solid #FFFFFF33",
              py: 3,
              alignItems: isMobile && "center",
            }}
          >
            <Stack height={"38px"} width={"fit-content"}>
              <img src={RoundtableCorpImg} height={"100%"} alt="footer logo" />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Box
        display="flex"
        flexDirection={"row"}
        alignItems={"center"}
        flexWrap="wrap"
        sx={{ backgroundColor: "#303030" }}
      >
        <Stack px={{ sm: 3, md: 10 }} sx={{ py: 2, width: "100%" }}>
          <Stack
            flexDirection={"row-reverse"}
            flexWrap={"wrap"}
            alignItems={"center"}
            justifyContent={!isMobile ? "space-between" : "center"}
            rowGap={2}
          >
            <Stack flexDirection={"row"} gap={2} color={"white"}>
              <FacebookIcon />
              <LinkedInIcon />
              <YoutubeIcon />
            </Stack>
            <Typography fontFamily={"Poppins"} fontSize={14} color={"#FFFFFF5E"}>
              Copyright © 2024 Roundtable Corporation.
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
