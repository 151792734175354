import { Dispatch, SetStateAction, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Menu, Stack, Typography } from "@mui/material";

import { useViewport } from "@contexts/ViewportContext";

type ProjectItemMenuProps = {
  anchorEl: HTMLButtonElement;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement>>;
  handleOpenDialog: () => void;
  handleCloseThreeDotMenu: () => void;
  projectId: string;
  hasCurrentUserJoinedTheProject: boolean;
};

const ProjectItemMenu = ({
  hasCurrentUserJoinedTheProject,
  anchorEl,
  setAnchorEl,
  handleOpenDialog,
  handleCloseThreeDotMenu,
  projectId,
}: ProjectItemMenuProps) => {
  const { isDesktop } = useViewport();
  const navigate = useNavigate();

  const responsiveSize = useMemo(() => {
    return {
      avatar: isDesktop ? 88 : 64,
      font12: isDesktop ? 12 : 10,
      font14: isDesktop ? 14 : 10,
      chipSize: isDesktop ? 24 : 16,
    };
  }, [isDesktop]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleCloseThreeDotMenu}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 1,
          minWidth: 140,
        },
      }}
      elevation={2}
    >
      <Stack gap={1}>
        <Button
          sx={{ color: "common.black", justifyContent: "left" }}
          onClick={() => {
            handleOpenDialog();
            setAnchorEl(null);
          }}
        >
          <Typography fontSize={responsiveSize.font12}>View Detail</Typography>
        </Button>
      </Stack>
    </Menu>
  );
};

export default ProjectItemMenu;
