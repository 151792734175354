import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

import { Proposal } from "@dto/proposal";

type Request = {
  roundtableId: string;
  payload: { title: string; question: string; options: string[] };
};

type Response = {
  success: boolean;
  data?: { code: number; message: string; data: Proposal };
  message?: string;
};

const createProposal = async ({ roundtableId, payload }: Request): Promise<Response> => {
  const response = await axios.post(`/round-tables/${roundtableId}/proposal`, payload);

  return response.data.data;
};

const useCreateProposal = () => {
  return useMutation(createProposal);
};

export default useCreateProposal;
