import { FC, memo, useEffect, useState } from "react";

import { Avatar, Box, Stack, SxProps, Typography, styled, useTheme } from "@mui/material";
import { isEmpty } from "lodash";

import UserAvatarButton from "@components/Button/userAvatarButton";

import { getAbbreviation } from "@utils/index";

// import UserAvatarButton from "@components/Button/userAvatarButton";
import { Attendee } from "@dto/roundtable";

import { useViewport } from "@contexts/ViewportContext";

type AvatarWrapperSizeProps = {
  width: number;
  maxWidth?: number;
};

type AvatarWrapperProps = {
  $size: AvatarWrapperSizeProps;
  isMember: boolean;
};

const AvatarWrapper = styled(Box)<AvatarWrapperProps>(({ theme, $size, isMember }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  top: "50%",
  left: "50%",
  width: $size.width,
  maxWidth: $size.maxWidth,
  height: $size.width,

  margin: `calc(-${Math.min($size.width)}px / 2)`,
  borderRadius: "100%",
  boxSizing: "border-box",
  ...(!isMember && {
    border: `2px solid ${theme.palette.common.bellflowerBlue}`,
    padding: theme.spacing(1),
  }),
}));

interface IProps {
  centerTitle?: string;
  roundtableAvatar: string;
  members: Attendee[];
  displayAsProject?: boolean;
  hideMembers?: boolean;
}

const Table: FC<IProps> = ({
  centerTitle,
  roundtableAvatar,
  members,
  displayAsProject,
  hideMembers,
}) => {
  const { isDesktop } = useViewport();

  const [width, setWidth] = useState<number>(0);
  const [styles, setStyles] = useState<SxProps[]>(null);
  const theme = useTheme();

  useEffect(() => {
    if (members?.length) {
      let angle = 360 - 90;
      const dangle = 360 / members.length;

      const styles: SxProps[] = [];
      members.forEach(() => {
        angle += dangle;
        styles.push({
          transform: `rotate(${angle}deg) translate(${width / 2}px) rotate(-${angle}deg)`,
        });
      });

      setStyles(styles);
    }
  }, [members, width]);

  useEffect(() => {
    const wrapper = document.querySelector("#rt-visual") as HTMLDivElement;
    const wrapperComputedStyle = window.getComputedStyle?.(wrapper, null);
    const wrapperWidth =
      wrapper.clientWidth -
      (parseFloat(wrapperComputedStyle.paddingLeft) +
        parseFloat(wrapperComputedStyle.paddingRight));
    setWidth(wrapperWidth - (isDesktop ? 100 : 50));
  }, [isDesktop]);

  const isHideMembers = isEmpty(members) || hideMembers;

  return (
    <Stack spacing={2} flex={1}>
      {isHideMembers && (
        <Typography>{`The ${
          displayAsProject ? "Project" : "Teamspace"
        } members are hidden`}</Typography>
      )}

      <Box
        sx={{
          position: "relative",
          width: width,
          height: width,
          margin: isDesktop ? 6.25 : 3.125,
          alignSelf: "center",
          ":before": {
            content: `""`,
            position: "absolute",
            top: 0,
            left: 0,
            border: (theme) => `2px solid ${theme.palette.common.bellflowerBlue}`,
            width: "calc(100% - 2px * 2)",
            height: "calc(100% - 2px * 2)",
            borderRadius: "50%",
            opacity: isHideMembers ? 0.3 : 1,
          },
        }}
      >
        {!isHideMembers &&
          members?.map(({ attendeeId, userId, id, avatar, name, type }, index) => (
            <AvatarWrapper
              key={id || attendeeId}
              $size={{
                width: 0.2 * width,
                // maxWidth: 100,
              }}
              isMember={type === "MEMBER"}
              sx={{
                ...styles?.[index],
              }}
            >
              <UserAvatarButton
                userId={id || userId}
                alt={name}
                src={avatar}
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
              {/* <Avatar alt={name} src={avatar} sx={{ width: "100%", height: "100%" }} /> */}
            </AvatarWrapper>
          ))}
        <AvatarWrapper
          isMember
          $size={{
            width: 0.3 * width,
            maxWidth: 250,
          }}
          sx={{ opacity: isHideMembers ? 0.3 : 1 }}
        >
          <Avatar
            variant={displayAsProject ? "rounded" : "circular"}
            alt="Teamspace Avatar"
            src={roundtableAvatar}
            sx={{
              width: "100%",
              height: "100%",
              aspectRatio: "1",
            }}
          >
            {!roundtableAvatar && getAbbreviation(centerTitle)}
          </Avatar>
        </AvatarWrapper>
      </Box>
    </Stack>
  );
};

export default Table;
