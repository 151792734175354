import { useCallback, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { Proposal } from "@dto/proposal";

interface Request {
  roundtableId: string;
  proposalId: string;
}

const getProposal = async ({ roundtableId, proposalId }: Request): Promise<Proposal> => {
  if (!roundtableId) {
    console.warn("roundtable Id is null");
    return null;
  }

  if (!proposalId) {
    console.warn("roundtable Id is null");
    return null;
  }

  const response = await axios.get(
    `/round-tables/${roundtableId}/proposals/${proposalId}`
  );

  return response.data.data;
};

const useGetProposal = (initialParams: Request) => {
  const [params, setParams] = useState(initialParams);

  const { data, refetch, ...rest } = useQuery(
    ["getProposal", params],
    () => getProposal({ ...params }),
    { enabled: !!params.roundtableId && !!params.proposalId }
  );

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  const handleChangeParams = useCallback((params: Partial<Request>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  return {
    data,
    params,
    refetch,
    handleChangeParams,
    ...rest,
  };
};

export default useGetProposal;
