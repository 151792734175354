import { FC } from "react";

import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  SxProps,
  Stack,
  Alert,
  Avatar,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";

import { UpdatePositionForm } from "@components/Dialog/UpdatePositionDialog";
import HelperText from "@components/HelperText";

import { convertBetween0And1ToPercentage } from "@utils/numberHelper";

import { Proposal } from "@dto/proposal";

const titleSxProps: SxProps = { fontSize: 16, fontWeight: 700, mb: 2 };
const helperTextSxProps: SxProps = {
  "& .MuiFormHelperText-root": {
    mx: 0,
    mt: 1,
  },
};

interface IProps {
  isOpen: boolean;
  proposal: Proposal;
  onClose: () => void;
}

const ViewPositionSettingsDialog: FC<IProps> = ({ isOpen, proposal, onClose }) => {
  const { endAt, startAt, createdByUser, data } = proposal;
  const { originalValues, isSubmittable } = data as UpdatePositionForm;
  const endDate = dayjs(endAt);
  const isRequestFormalMember = originalValues.isSubmittable !== isSubmittable;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
        Update My Position (View Only)
      </DialogTitle>

      <DialogContent>
        <Alert
          severity="info"
          icon={<InfoIcon fontSize="inherit" />}
          sx={{ alignItems: "center", color: "common.black", px: { xs: 1.5, sm: 2 } }}
        >
          <Typography sx={{ fontSize: 14 }}>
            The Teamspace is under approval. Expired by:{" "}
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              {dayjs.duration(endDate.diff(dayjs())).humanize(true)}
            </Typography>
          </Typography>

          <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              Last edited by:
            </Typography>

            <Stack direction="row" spacing={0.5} alignItems="center" mx={1}>
              <Avatar
                alt="Avatar"
                src={createdByUser?.avatar}
                sx={{ width: 20, height: 20 }}
              />

              <Typography
                component="span"
                sx={{ fontSize: 14, fontWeight: 600, color: "common.partyTime" }}
              >
                {createdByUser?.name}
              </Typography>
            </Stack>

            <Typography component="span" sx={{ fontSize: 14 }}>
              {dayjs(startAt).format("hh:mm A, YYYY MMM DD")}
            </Typography>
          </Stack>
        </Alert>

        <Box mt={1}>
          <Typography variant="h6" sx={titleSxProps}>
            Self-Contribution
          </Typography>

          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Fund Contribution ($)"
                size="small"
                value={originalValues?.fundContribution}
                helperText={
                  <HelperText
                    data={{
                      fundContribution: +(data as UpdatePositionForm)?.fundContribution,
                    }}
                    field="fundContribution"
                    currentValue={+originalValues?.fundContribution}
                    suffix="($)"
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid> */}

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Working Hour Weekly (hrs)"
                size="small"
                value={originalValues?.workingHourWeekly}
                helperText={
                  <HelperText
                    data={{
                      workingHourWeekly: +(data as UpdatePositionForm)?.workingHourWeekly,
                    }}
                    field="workingHourWeekly"
                    currentValue={+originalValues?.workingHourWeekly}
                    suffix="(hrs)"
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              {isRequestFormalMember ? (
                <TextField
                  fullWidth
                  disabled
                  label="Become a Formal Member"
                  size="small"
                  value={originalValues?.isSubmittable ? "Yes" : "No"}
                  helperText={
                    <HelperText
                      data={{ isSubmittable }}
                      field="isSubmittable"
                      currentValue={originalValues?.isSubmittable}
                      formatContent={(isSubmittable) => (isSubmittable ? "Yes" : "No")}
                    />
                  }
                  sx={helperTextSxProps}
                />
              ) : (
                <TextField
                  fullWidth
                  disabled
                  label="Vote Weight Seeking (%)"
                  size="small"
                  value={convertBetween0And1ToPercentage(originalValues?.voteWeight)}
                  helperText={
                    <HelperText
                      data={{ voteWeight: (data as UpdatePositionForm).voteWeight }}
                      field="voteWeight"
                      currentValue={convertBetween0And1ToPercentage(
                        originalValues?.voteWeight
                      )}
                      suffix="(%)"
                      formatContent={(voteWeight) =>
                        convertBetween0And1ToPercentage(voteWeight as string)
                      }
                    />
                  }
                  sx={helperTextSxProps}
                />
              )}
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Profit Share Seeking (%)"
                size="small"
                value={originalValues?.percentageProfit}
                helperText={
                  <HelperText
                    data={{
                      percentageProfit: +(data as UpdatePositionForm)?.percentageProfit,
                    }}
                    field="percentageProfit"
                    currentValue={+originalValues?.percentageProfit}
                    suffix="(%)"
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid> */}

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="One-Time Compensation Seeking ($)"
                size="small"
                value={originalValues?.oneTimeCompensation}
                helperText={
                  <HelperText
                    data={{
                      oneTimeCompensation: +(data as UpdatePositionForm)
                        ?.oneTimeCompensation,
                    }}
                    field="oneTimeCompensation"
                    currentValue={+originalValues?.oneTimeCompensation}
                    suffix="($)"
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Hourly Rate ($/hour)"
                size="small"
                value={originalValues?.hourlyRate}
                helperText={
                  <HelperText
                    data={{ hourlyRate: +(data as UpdatePositionForm)?.hourlyRate }}
                    field="hourlyRate"
                    currentValue={+originalValues?.hourlyRate}
                    suffix="($/hour)"
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewPositionSettingsDialog;
