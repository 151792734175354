import React, { createContext, useState, useContext } from "react";

import useControlSidebar from "@components/Layout/PageLayout/hooks/useControlSidebar";

import { useViewport } from "@contexts/ViewportContext";

const CollapseContext = createContext(null);

export const CollapseProvider = ({ children }: any) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { isDesktop } = useViewport();
  const [_, setOpen] = useControlSidebar(isDesktop);
  const toggleCollapse = (collapsed?: boolean) => {
    setIsCollapsed((prev) => collapsed ?? !prev);
    setOpen(false);
  };

  return (
    <CollapseContext.Provider value={{ isCollapsed, toggleCollapse }}>
      {children}
    </CollapseContext.Provider>
  );
};

export const useCollapseTable = () => {
  return useContext(CollapseContext);
};
