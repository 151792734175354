import { FC, useMemo } from "react";

import { getRoundtables } from "@api/roundtable/getRoundtables";
import { NOT_APPLICABLE } from "@constants/index";
import { DescriptionOutlined as DescriptionOutlinedIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  Link,
  styled,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import EllipsisContent from "@components/EllipsisContent";
import ReadMoreText from "@components/ReadMoreText.tsx";

import { checkImgURL } from "@utils/helpers/urlHelper";
import { getAbbreviation } from "@utils/index";

// import ImageGallery from "@components/ImageGallery";
import { Roundtable } from "@dto/roundtable";

import { RoundtableTypeEnum } from "@@types/type";

import { useViewport } from "@contexts/ViewportContext";

interface IProps {
  roundtable: Roundtable;
  displayAsProject?: boolean;
}

const Label = styled(Typography)(() => ({
  fontWeight: 700,
  // opacity: 0.64,
}));

const RoundtableDetails: FC<IProps> = ({ roundtable, displayAsProject }) => {
  const { id, avatar, title, isPublic, description, type, files, tags } =
    roundtable || {};

  const { isDesktop } = useViewport();
  const responsiveSize = useMemo(() => {
    return {
      font12: isDesktop ? 12 : 10,
      font14: isDesktop ? 14 : 10,
    };
  }, [isDesktop]);

  const { data: { data: { data: projects } = {} } = {}, isFetching: isFetchingProjects } =
    useQuery({
      queryKey: [
        "useGetRoundtables",
        RoundtableTypeEnum.SELF,
        RoundtableTypeEnum.FREELANCER,
        id,
      ],
      queryFn: () =>
        getRoundtables({
          type: [RoundtableTypeEnum.SELF, RoundtableTypeEnum.FREELANCER],
          page: 0,
          pageSize: 9999,
          parentId: id,
        }),
      refetchOnWindowFocus: false,
    });

  const {
    data: { data: { data: subRoundtables } = {} } = {},
    isFetching: isFetchingSubRoundtables,
  } = useQuery({
    queryKey: ["useGetRoundtables", RoundtableTypeEnum.TEAM, id],
    queryFn: () =>
      getRoundtables({
        type: [RoundtableTypeEnum.TEAM],
        page: 0,
        pageSize: 9999,
        parentId: id,
      }),
    refetchOnWindowFocus: false,
  });

  const images = files?.map((f) => f.link);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        <Grid item container spacing={2} alignItems="center">
          <Stack direction="row" alignItems="start" sx={{ width: "100%", ml: 2 }}>
            <Avatar
              variant={displayAsProject ? "rounded" : "circular"}
              src={avatar}
              alt={title}
              sx={{ width: 80, height: 80, mr: 2 }}
            >
              {!avatar && getAbbreviation(title)}
            </Avatar>
            <Grid item container rowGap={isDesktop ? 0 : 1}>
              <Grid item container xs={12}>
                {/* <Typography
                  variant="h6"
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "common.partyTime",
                  }}
                >
                  {title}
                </Typography> */}
                <EllipsisContent
                  color={"common.partyTime"}
                  fontSize={16}
                  lineHeight={1.5}
                  fontWeight={700}
                  numberOfLines={1}
                  sx={{ wordBreak: "break-word", m: 0 }}
                  content={title}
                />
              </Grid>

              <Grid item container rowGap={isDesktop ? 0 : 1.5}>
                <Grid item xs={5} sm={5.5} desktop={2.5} direction="row">
                  <Stack direction="row" alignItems="center">
                    <Label
                      fontSize={responsiveSize.font14}
                      sx={{ mr: isDesktop ? 3 : 1 }}
                    >
                      Type
                    </Label>
                    <Chip
                      label={type
                        ?.toLowerCase()
                        ?.replace("_self", "")
                        ?.replace("self", "internal")}
                      // variant="outlined"
                      sx={{
                        height: isDesktop ? 24 : 16,
                        borderRadius: 1,
                        fontSize: responsiveSize.font12,
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        bgcolor: "#EDF2F7",
                        "& .MuiChip-label": { p: 1 },
                      }}
                    />
                  </Stack>
                </Grid>

                <Grid container xs={1} justifyContent="center">
                  <Divider orientation="vertical" flexItem sx={{ borderWidth: 1 }} />
                </Grid>

                <Grid item xs={5.5} desktop={2} direction="row">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Label
                      fontSize={responsiveSize.font14}
                      sx={{ mr: isDesktop ? 3 : 1 }}
                    >
                      Visibility
                    </Label>
                    <Chip
                      label={isPublic ? "Public" : "Private"}
                      // variant="outlined"
                      sx={{
                        height: isDesktop ? 24 : 16,
                        borderRadius: 1,
                        fontSize: responsiveSize.font12,
                        fontWeight: "bold",
                        bgcolor: "#EDF2F7",
                        "& .MuiChip-label": { p: 1 },
                      }}
                    />
                  </Stack>
                </Grid>

                {/* {isDesktop && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderWidth: 0.5, mx: 3 }}
                  />
                )} */}

                {/* <Grid item xs={12} desktop={2}>
                  <Stack
                    direction="row"
                    justifyContent={isDesktop ? "space-between" : "start"}
                    alignItems="center"
                    columnGap={isDesktop ? 0 : 2}
                  >
                    <Label fontSize={responsiveSize.font14}>Fund</Label>
                    <Typography fontSize={responsiveSize.font14} fontWeight="bold">
                      100,000 USD
                    </Typography>
                  </Stack>
                </Grid> */}

                {isDesktop && (
                  <>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ borderWidth: 0.5, mx: 3 }}
                    />
                    <Grid item xs={4}>
                      <Stack
                        direction="row"
                        // justifyContent="space-between"
                        alignItems="center"
                      >
                        <Label fontSize={responsiveSize.font14} sx={{ mr: 3 }}>
                          Tags
                        </Label>
                        {tags?.length > 0 && (
                          <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1.5}>
                            {tags?.map((tag, i) => (
                              <Chip
                                key={i}
                                label={tag}
                                // variant="outlined"
                                sx={{
                                  height: isDesktop ? 24 : 16,
                                  borderRadius: 1,
                                  fontSize: responsiveSize.font12,
                                  fontWeight: "bold",
                                  bgcolor: "#EDF2F7",
                                  "& .MuiChip-label": { p: 1 },
                                }}
                              />
                            ))}
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        <Grid item container spacing={1}>
          {!isDesktop && (
            <Grid item container alignItems="center" columnGap={1}>
              <Grid item xs={3} desktop={2}>
                <Label fontSize={responsiveSize.font14}>Tags</Label>
              </Grid>
              <Grid item xs={8} desktop={10}>
                {tags?.length > 0 && (
                  <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1.5}>
                    {tags?.map((tag, i) => (
                      <Chip
                        key={i}
                        label={tag}
                        // variant="outlined"
                        sx={{
                          height: isDesktop ? 24 : 16,
                          borderRadius: 1,
                          fontSize: responsiveSize.font12,
                          fontWeight: "bold",
                          bgcolor: "#EDF2F7",
                          "& .MuiChip-label": { p: 1 },
                        }}
                      />
                    ))}
                  </Stack>
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            alignItems="center"
            columnGap={1}
            sx={{ display: displayAsProject && "none" }}
          >
            <Grid item xs={3} desktop={2}>
              <Label fontSize={responsiveSize.font14}>Sub-Teamspace</Label>
            </Grid>
            <Grid container xs={8} desktop={8} columnGap={1}>
              {subRoundtables?.length ? (
                subRoundtables?.map((subRoundtable) => (
                  <Grid item key={`${subRoundtable.id}-subRoundtable-tag`}>
                    <Chip
                      label={subRoundtable.title}
                      variant="outlined"
                      sx={{
                        height: isDesktop ? 24 : 16,
                        borderRadius: 1,
                        fontSize: responsiveSize.font12,
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        bgcolor: "#EDF2F7",
                        "& .MuiChip-label": { p: 1 },
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Typography sx={{ fontSize: responsiveSize.font14, fontWeight: 400 }}>
                  {NOT_APPLICABLE}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            columnGap={1}
            sx={{ display: displayAsProject && "none" }}
          >
            <Grid item xs={3} desktop={2}>
              <Label fontSize={responsiveSize.font14}>Project</Label>
            </Grid>
            <Grid container xs={8} desktop={8} columnGap={1}>
              {projects?.length ? (
                projects.map((project) => {
                  return (
                    <Grid item key={`${project.id}-project-tag`}>
                      <Chip
                        label={project.title}
                        // variant="outlined"
                        sx={{
                          height: isDesktop ? 24 : 16,
                          borderRadius: 1,
                          fontSize: responsiveSize.font12,
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          bgcolor: "#EDF2F7",
                          "& .MuiChip-label": { p: 1 },
                        }}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Typography sx={{ fontSize: responsiveSize.font14, fontWeight: 400 }}>
                  {NOT_APPLICABLE}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item container alignItems="center" columnGap={1}>
            <Grid item xs={3} desktop={2}>
              <Label fontSize={responsiveSize.font14}>Description</Label>
            </Grid>
            <Grid item xs={8} desktop={8}>
              <ReadMoreText
                text={description}
                fontSize={`${responsiveSize.font14}px`}
                maxLength={isDesktop ? 200 : 100}
              />
            </Grid>
          </Grid>

          <Grid item container alignItems="center" spacing={1} mt={1}>
            {/* {images?.length && (
              <Box mt={2}>
                <ImageGallery images={["/static/media/auth2.3b2bb1fa3e4256fa9b39.png", "/static/media/auth2.3b2bb1fa3e4256fa9b39.pnghttp://roundtable-uat.s3-website-us-east-1.amazonaws.com/static/media/auth2.3b2bb1fa3e4256fa9b39.png"]} />
              </Box>
            )} */}
            {images?.map((image, i) => (
              <Grid key={i} item xs={12} sm={6} lg={3}>
                {checkImgURL(image) ? (
                  <img
                    src={image}
                    alt="proposal project"
                    style={{
                      aspectRatio: "16 / 9",
                      objectFit: "cover",
                      borderRadius: "4px",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                ) : (
                  <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
                    <DescriptionOutlinedIcon />
                    <Typography>
                      <Link
                        href={image?.startsWith("http") ? image : "//" + image}
                        target="_blank"
                        color={"common.heirloomHydrangea"}
                        fontSize={"14px"}
                        fontFamily={"inherit"}
                        underline="hover"
                      >
                        Attachment file
                      </Link>
                    </Typography>
                  </Stack>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoundtableDetails;
