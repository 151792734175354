import { useEffect } from "react";

import { Stack, CircularProgress, Box, Typography } from "@mui/material";
// import dayjs from "dayjs";

import MessageBox from "./MessageBox";
import { MessageType } from "./type";

type Props = {
  data: MessageType[];
  loading: boolean;
};
const MessageList = ({ data, loading }: Props) => {
  useEffect(() => {
    const lastElement = document.querySelector(`.msg-item:last-child`);
    lastElement?.scrollIntoView();
  }, [data]);

  if (loading) {
    return (
      <Stack alignItems={"center"}>
        <CircularProgress />
      </Stack>
    );
  }

  // const todayStart = dayjs().startOf("day")

  return (
    <Box className="message-list">
      {data.map((message, idx) => {
        const prevMsg = data?.[idx - 1];
        // let todayMark;

        // if (prevMsg?.date && message?.date) {
        //   const prevMsgDate = dayjs(
        //     typeof prevMsg.date === "number"
        //       ? prevMsg.date
        //       : (prevMsg.date as any).seconds * 1000
        //   );
        //   const currentMsgDate = dayjs(
        //     typeof message.date === "number"
        //       ? message.date
        //       : (message.date as any).seconds * 1000
        //   );

        //   if (
        //     prevMsgDate.diff(todayStart, "second") < 0 &&
        //     currentMsgDate.diff(todayStart, "second") > 0
        //   ) {
        //     todayMark = (
        //       <Typography textAlign={"center"} color={"#00000099"} fontSize={"10px"}>
        //         Today
        //       </Typography>
        //     );
        //   }
        // }
        const groupMark = <Typography textAlign={"center"} color={"#00000099"} fontSize={"10px"}>{prevMsg?.groupDate !== message?.groupDate && message?.groupDate}</Typography>
        return (
          <Box key={message.id} className="msg-item">
            {/* {todayMark} */}
            {groupMark}
            <MessageBox {...message} />
          </Box>
        )
      })}
    </Box>
  );
};

export default MessageList;
