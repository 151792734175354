export const formatNumberString = (strNum: string | number, decimals?: number) => {
  if (isNaN(Number(strNum))) {
    return strNum
  }

  return Intl.NumberFormat("en-US", decimals ? {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } : {})
    .format(Number(strNum))
}
