import { useState, useCallback, ReactNode } from "react";

export function useClientRect(deps?: any[]) {
  const [rect, setRect] = useState(null);
  const ref = useCallback(
    (node: ReactNode | HTMLElement) => {
      if (node) {
        setRect((node as HTMLElement)?.getBoundingClientRect());
      }
    },
    [...(deps || [])]
  );
  return [rect, ref];
}

export default useClientRect;
