import React, { useState } from "react";

import { getDeposit } from "@api/user/userDeposit";
import AddIcon from "@mui/icons-material/Add";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import Button from "@components/Button";
import TopUpDialog from "@components/Dialog/TopUpDialog";

type TopUpType = {
  selectable?: boolean;
};
const TopupDeposit = ({ selectable }: TopUpType) => {
  const {
    palette: { common },
  } = useTheme();

  const [openTopUp, setOpenTopUp] = useState(false);
  const { data: balances } = useQuery({
    queryKey: ["getDeposit"],
    queryFn: getDeposit,
  });

  return (
    <Box
      border={common.border}
      borderRadius={4}
      p={4}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Stack>
        <Typography fontSize={16} fontWeight={600} color={common.doverGrey}>
          My deposit
        </Typography>
        <Typography fontSize={36} fontWeight={700} color={common.bulmaHair}>
          ${balances?.data?.lockedBalance || 0.0}
        </Typography>
      </Stack>
      {!selectable && (
        <Button
          sx={{
            borderRadius: 2,
            height: 48,
          }}
          onClick={() => setOpenTopUp(true)}
          startIcon={<AddIcon />}
        >
          Add deposit
        </Button>
      )}

      {openTopUp && <TopUpDialog open={openTopUp} onClose={() => setOpenTopUp(false)} />}
    </Box>
  );
};
export default TopupDeposit;
