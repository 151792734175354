import { useLayoutEffect, useRef, useState } from "react" 
import {
  Box,
  Stack,
  BoxProps,
  Typography,
} from "@mui/material";

interface IDeterminateProgress extends BoxProps {
  value?: number | string;
  valueLabel?: string;
  progressColor?: string;
  progressStyle?: "normal" | "diagonal";
}

const DeterminateProgress = ({
  value,
  valueLabel,
  // progressColor = "#CC2630",
  progressColor = "#B6B6B6",
  progressStyle = "diagonal",
  height = 8,
  borderRadius = 0.5,
  bgcolor = "rgba(0, 0, 0, 0.06)",
  ...otherProps
}: IDeterminateProgress) => {
  const trackRef: any = useRef();
  const progressRef: any = useRef();
  const [pItems, setPItems] = useState([]);
  const [isValueLabelFixed, setIsValueLabelFixed] = useState(false);

  useLayoutEffect(() => {
    const progressWidth = progressRef?.current?.offsetWidth
    const tempItems = [];
    const ITEM_WIDTH = 16;
    while (tempItems.length * ITEM_WIDTH < progressWidth) {
      tempItems.push(ITEM_WIDTH)
    }
    setPItems(tempItems)
    const leftSpace = trackRef?.current?.offsetWidth - progressRef?.current?.offsetWidth
    if (leftSpace <= 60 && !isValueLabelFixed) {
      setIsValueLabelFixed(true)
    }
  }, [value]);
  // const isValueLabelFixed = trackRef?.current?.offsetWidth - progressRef?.current?.offsetWidth <= 60

  return (
    <Box ref={trackRef} height={height} borderRadius={borderRadius} bgcolor={bgcolor} {...otherProps} display={"flex"} alignItems={"center"}>
      <Box ref={progressRef} height={"100%"} borderRadius={borderRadius} width={`${!Number(value) ? 0 : value}%`} bgcolor={progressColor} display={"flex"} overflow={"hidden"}>
        {progressStyle === "diagonal" && pItems.map((k, i) => (
          <Stack key={i} height={"calc(100% + 2px)"} width={k} flex={"0 0 16px"}>
            <Box
              bgcolor={"rgb(235, 172, 176)"}
              width={8}
              height={"100%"}
              ml={"2px"}
              sx={{ transform: "skew(30deg)" }}
            />
          </Stack>
        ))}
      </Box>
      <Box position={"relative"} height={"100%"}>
        {valueLabel && (
          <Typography
            sx={{
              position: "absolute",
              bottom: "50%",
              right: isValueLabelFixed && 8,
              left: !isValueLabelFixed && 8,
              transform: "translateY(50%)",
              color: "common.black",
              fontSize: 14,
            }}
          >
            {valueLabel}
          </Typography>
        )}
      </Box>
    </Box>
  )
};

export default DeterminateProgress;
