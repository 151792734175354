import { FC } from "react";

import useGetUser from "@api/user/useGetUser";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  SxProps,
  Typography,
} from "@mui/material";

// import UserInfo from "@components/UserInfo";
import UserProfile from "@components/UserProfile";

import { useViewport } from "@contexts/ViewportContext";

const labelStyles: SxProps = {
  fontSize: 18,
  fontWeight: 700,
};

interface IUserDetailDialog {
  userId: string | null;
  open: boolean;
  dialogTitle?: string;
  onClose: () => void;
}

const UserDetailDialog: FC<IUserDetailDialog> = ({
  userId,
  dialogTitle,
  open,
  onClose,
}) => {
  const { isDesktop } = useViewport();
  const { data: user, isLoading } = useGetUser({ userId });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          m: 1,
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
        <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
          {dialogTitle || `Member Profile`}
        </Typography>
        <CloseIcon
          sx={{
            width: 24,
            height: 24,
            color: "common.black",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </DialogTitle>
      {!isLoading && (
        <DialogContent
          sx={{
            p: isDesktop ? 2 : 1,
            backgroundColor: isDesktop ? "common.mainBg" : "unset",
          }}
        >
          <Paper elevation={isDesktop ? 3 : 0} sx={{ padding: 0, mt: isDesktop ? 3 : 0 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: isDesktop ? 2 : 1,
                py: 3,
              }}
            >
              <Typography variant="h5" sx={labelStyles}>
                Personal Information
              </Typography>
            </Box>
            <Box pb={2} pl={isDesktop ? 2 : 1} pr={isDesktop ? 2 : 1}>
              {/* <UserInfo user={user} hiddenKeys={["dateOfBirth", "email"]} /> */}
              <UserProfile userData={user} />
            </Box>
          </Paper>
        </DialogContent>
      )}

      {isLoading && (
        <DialogContent sx={{ backgroundColor: "common.mainBg" }}>
          <LinearProgress />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default UserDetailDialog;
