import { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Close as CloseIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import { useAppSelector, useAppDispatch } from "@app/hooks";

import { sidebarActions } from "@features/sidebar/slice";

import { PanelList as ApplicationList } from "@components/Application";

import { useSelectJob } from "@pages/jobs-management/SelectJobContext";
import { JOB_STATUS } from "@pages/jobs-management/constants";

import { ReactComponent as GawainIcon } from "@assets/icons/gawain_ai.svg";

import DeliveryList from "./DeliveryList";
import GawainAI from "./GawainAI";
import WorkSubmission from "./WorkSubmission";

type SidebarQuestToolProps = {
  isFreelancer?: boolean;
};

const QuestToolEnum = {
  WORK_SUBMISSION: "Work Submission",
  DELIVERY_LIST: "Delivery list",
  GAWAIN_AI: "Gawain.AI",
  FREELANCERS: "Freelancers",
};
const SidebarQuestTool = (props: SidebarQuestToolProps) => {
  const { isFreelancer } = props;
  const { jobSelected } = useSelectJob();
  const [selected, setSelected] = useState("");
  const showOptions = ![JOB_STATUS.TODO, JOB_STATUS.HIRING].includes(jobSelected?.status);
  const questOptions = [
    {
      key: "deliveries",
      label: "Delivery list",
      onClick: () => false,
    },
  ];
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const rightLayoutKey = searchParams.get("r");
  const { userInfo } = useAppSelector((state) => state.auth);

  const notFoundQuest = useMemo(() => {
    if (!jobSelected) {
      return true;
    }
    if (jobSelected?.status === JOB_STATUS.HIRING) {
      return false;
    }
    if (isFreelancer) {
      return (
        jobSelected?.status === JOB_STATUS.TODO ||
        !jobSelected.orders?.some((order) => order.freelancer.id === userInfo?.user?.id)
      );
    }

    return false;
  }, [jobSelected, isFreelancer]);

  useEffect(() => {
    if (Number(rightLayoutKey || 0) == 8) {
      setSelected(QuestToolEnum.FREELANCERS);
    } else if (Number(rightLayoutKey || 0) == 10) {
      setSelected(QuestToolEnum.DELIVERY_LIST);
    } else {
      setSelected("");
    }
  }, [jobSelected, rightLayoutKey]);

  if (isFreelancer) {
    jobSelected?.status === JOB_STATUS.IN_PROGRESS &&
      questOptions.push({
        key: "work_submission",
        label: "Work Submission",
        onClick: () => false,
      });
  } else {
    ![JOB_STATUS.DELIVERED, JOB_STATUS.DONE].includes(jobSelected?.status) &&
      questOptions.push({
        key: "freelancer",
        label: "Freelancers",
        onClick: () => false,
      });
  }
  const renderQuestTool = () => {
    switch (selected) {
      case QuestToolEnum.WORK_SUBMISSION:
        return <WorkSubmission cb={() => setSelected("")} />;
      case QuestToolEnum.DELIVERY_LIST:
        return <DeliveryList isFreelancer={isFreelancer} cb={() => setSelected("")} />;
      case QuestToolEnum.GAWAIN_AI:
        return <GawainAI />;
      case QuestToolEnum.FREELANCERS:
        return <ApplicationList jobId={jobSelected.id} />;
      default:
        return null;
    }
  };
  return (
    <Box height={"100%"} pb={2} pt={11.5} pr={2}>
      <Stack
        height="100%"
        sx={{
          borderRadius: 3,
          border: "0.5px solid #E2E8F0",
          backgroundColor: selected === "Freelancers" ? "white" : "#F7FAFC",
          overflow: "hidden",
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={2}
          py={1.5}
        >
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            {selected && (
              <KeyboardArrowLeftIcon
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={() => setSelected("")}
              />
            )}
            <Typography fontSize={16} fontWeight={700}>
              {selected || `Quest tools`}
            </Typography>
          </Stack>
          <CloseIcon
            fontSize="medium"
            sx={{ cursor: "pointer" }}
            onClick={() => dispatch(sidebarActions.setRightBarOpen({ open: "" }))}
          />
        </Stack>
        {selected && !notFoundQuest ? (
          renderQuestTool()
        ) : (
          <Stack px={2} py={1} gap={1.5} flex={1}>
            {showOptions &&
              !notFoundQuest &&
              questOptions.map((item) => (
                <Stack
                  key={item.key}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  px={2}
                  py={"15px"}
                  bgcolor={"white"}
                  onClick={() => setSelected(item.label)}
                  borderRadius={2}
                  border={"1px solid #E2E8F0"}
                  sx={{ cursor: "pointer" }}
                >
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1.3}>
                    <Typography fontSize={14} fontWeight={700}>
                      {item.label}
                    </Typography>
                  </Stack>
                  <KeyboardArrowRightIcon fontSize="small" />
                </Stack>
              ))}
            <Stack flexGrow={1} alignItems={"center"} justifyContent={"center"}>
              <Box onClick={() => setSelected(QuestToolEnum.GAWAIN_AI)}>
                <GawainIcon />
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
export default SidebarQuestTool;
