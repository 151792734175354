import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { MenuOpen as MenuOpenIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

import { useAppDispatch } from "@app/hooks";
import { useAppSelector } from "@app/hooks";

import { sidebarActions } from "@features/sidebar/slice";

const RightBarToggleBtn = () => {
  const dispatch = useAppDispatch();
  const rightBarOpen = useAppSelector((state) => state.sideBar.openRightBar);

  return (
    <Button
      variant="outlined"
      sx={{
        borderRadius: 2,
        border: "1px solid #E2E8F0",
        ":hover": { border: "1px solid #E2E8F0" },
        minWidth: "unset",
        p: 1,
      }}
      onClick={() =>
        dispatch(sidebarActions.setRightBarOpen({ open: rightBarOpen ? "" : "project" }))
      }
    >
      <MenuOpenIcon fontSize="small" />
    </Button>
  );
};

export default RightBarToggleBtn;
