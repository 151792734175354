import { useCallback, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { Roundtable } from "@dto/roundtable";

interface Request {
  id: string;
}

const getRoundtable = async (id: string): Promise<Roundtable> => {
  const response = await axios.get(`/round-table/${id}`);

  return response.data.data;
};

const useGetRoundtable = (params: Request) => {
  const { data, refetch, ...rest } = useQuery(
    ["getRoundTableDetail", params.id],
    () => getRoundtable(params.id),
    { enabled: !!params.id }
  );

  return {
    data,
    refetch,
    ...rest
  };
};

export default useGetRoundtable;
