import React, {
  ChangeEvent,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";

// import useGetPublishProject from "@api/roundtable/useGetPublishProject";
import useGetRoundtable from "@api/roundtable/useGetRoundtable";
import usePublishProject from "@api/roundtable/usePublishProject";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  Switch,
  debounce,
  useTheme,
} from "@mui/material";
import _pick from "lodash/pick";

import { handleApiError } from "@features/config";

import CustomButton from "@components/Button";
import MultiFileUploader from "@components/MultiFileUploader";
import { ISnackbar } from "@components/Snackbar";

import {
  isValidUrl,
  isValidYoutubeEmbedUrl,
  checkImgURL,
} from "@utils/helpers/urlHelper";
import { capitalizeFirstLetter } from "@utils/index";

import { AttachmentProps, RTFile } from "@dto/file";

import { useViewport } from "@contexts/ViewportContext";

import queryClient from "@config/queryClient";

type onCloseConfirmType = {
  title?: string;
  description?: string;
};
interface IProps {
  projectId: string;
  isOpened: boolean;
  onClose: (params?: { confirmation?: onCloseConfirmType }) => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  isViewOnly?: boolean;
}

const AUTO_PUBLISHED_FIELDS = ["DESCRIPTION", "LANGUAGE"];

const PUBLISHED_FIELDS = [
  "DESCRIPTION",
  "MEMBERS",
  "LANGUAGE",
  "LOCATION",
  "INSTITUTION",
];

interface ProjectPublishingForm {
  links: { url: string; displayText: string }[];
  videoLinks: { url: string; displayText: string }[];
  information: string[];
  attachments: AttachmentProps[];
  thumbnails: string[];
  isPublish: boolean;
  nonDisclosure: boolean;
  requireApplication: boolean;
}

export const FieldWrapper: FC<{ label: string; children: ReactNode }> = ({
  label,
  children,
}) => {
  return (
    <Stack rowGap={"10px"}>
      <Typography fontSize={16} fontWeight={700}>
        {label}
      </Typography>
      {children}
    </Stack>
  );
};

const PublishProjectDialog: FC<IProps> = ({
  projectId,
  isOpened,
  onClose,
  setSnackbar,
  isViewOnly,
}) => {
  const { isDesktop } = useViewport();
  const {
    palette: { common },
  } = useTheme();
  const imageUploaderRef = useRef(null);

  // const { data: publishProjectData, refetch } = useGetPublishProject();
  const { data: projectData } = useGetRoundtable({ id: projectId });
  const { mutate: publishProject, isLoading } = usePublishProject();

  const [isOpenedLinkModal, setIsOpenedLinkModal] = useState(false);
  const [isVideoLinkMode, setIsVideoLinkMode] = useState(false);

  const [linkDetail, setLinkDetail] = useState({
    url: "",
    displayText: "",
  });

  const { handleSubmit, setValue, getValues, watch } = useForm<ProjectPublishingForm>({
    defaultValues: {
      links: [],
      videoLinks: [],
      information: [],
      attachments: [],
      thumbnails: [],
    },
    mode: "onChange",
  });

  // useEffect(() => {
  //   if (projectData.isPublish && publishProjectData?.data?.publishData) {
  //     const { information, attachments, links, thumbnails, videoLinks } =
  //       publishProjectData.data.publishData;
  //       console.log("attachments", attachments)
  //     setValue("links", links);
  //     setValue("videoLinks", videoLinks || []);
  //     setValue("information", information);
  //     setValue("attachments", attachments);
  //     setValue("thumbnails", thumbnails);
  //   }
  //   setValue("isPublish", projectData.isPublish);
  //   setValue("nonDisclosure", projectData.nonDisclosure);
  //   setValue("requireApplication", projectData.requireApplication);
  // }, [projectData.isPublish, projectData.nonDisclosure, projectData.requireApplication, publishProjectData.success]);

  useEffect(() => {
    if (!projectData) {
      return;
    }
    let thumbnailImgs =
      projectData?.files
        ?.filter((file) => checkImgURL(file?.link))
        ?.map((file) => file.link) || [];
    if (projectData.isPublish && projectData.publishData) {
      const { links, videoLinks, information, attachments, thumbnails } =
        projectData.publishData;
      console.log("information", information);
      setValue("links", links || []);
      setValue("videoLinks", videoLinks || []);
      setValue(
        "information",
        PUBLISHED_FIELDS?.filter((f) => !(information as string[])?.includes(f)) || []
      );
      setValue("attachments", attachments || []);
      if (thumbnails?.length > 0) {
        thumbnailImgs = [...thumbnails];
      }
    }
    setValue("thumbnails", thumbnailImgs);
    setValue("isPublish", projectData.isPublish);
    setValue("nonDisclosure", projectData.nonDisclosure);
    setValue("requireApplication", projectData.requireApplication);
  }, [projectData?.isPublish]);

  const attachmentFiles =
    projectData?.files?.filter((file) => !checkImgURL(file?.link)) || [];

  const watchedLinks = watch("links") || [];
  const watchedVideoLinks = watch("videoLinks") || [];
  const watchedInformation = watch("information") || [];
  const watchedAttachments = watch("attachments") || [];
  const watchedIsPublished = watch("isPublish") || false;
  const watchedNonDisclosure = watch("nonDisclosure") || false;
  const watchedRequireApplication = watch("requireApplication") || false;

  const responsiveSize = useMemo(() => {
    return {
      avatar: isDesktop ? 88 : 64,
      font12: isDesktop ? 12 : 10,
      font14: isDesktop ? 14 : 10,
      chipSize: isDesktop ? 24 : 16,
      closeIcon: isDesktop ? 32 : 24,
      imageStyles: {
        width: isDesktop ? "24.5%" : "30%",
        aspectRatio: "16 / 9",
        objectFit: "cover",
        borderRadius: "4px",
      } as React.CSSProperties,
    };
  }, [isDesktop]);

  const handleClickSaveLink = () => {
    if (isVideoLinkMode) {
      if (!isValidYoutubeEmbedUrl(linkDetail.url)) {
        return setSnackbar({
          message: "Please enter a valid embed URL",
          open: true,
          severity: "error",
        });
      }

      setValue("videoLinks", [
        ...getValues("videoLinks"),
        {
          url: linkDetail.url,
          displayText: linkDetail.displayText,
        },
      ]);
    } else {
      if (!isValidUrl(linkDetail.url)) {
        return setSnackbar({
          message: "Please enter a valid URL",
          open: true,
          severity: "error",
        });
      }

      setValue("links", [
        ...getValues("links"),
        {
          url: linkDetail.url,
          displayText: linkDetail.displayText,
        },
      ]);
    }

    handleCloseAddLinkModal();
  };

  const handleClickDeleteLink = (url: string, isVideoLinkMode?: boolean) => {
    if (isVideoLinkMode) {
      setValue("videoLinks", [
        ...getValues("videoLinks").filter((link) => link.url !== url),
      ]);
    } else {
      setValue("links", [...getValues("links").filter((link) => link.url !== url)]);
    }
  };

  const handleChangeLinkDetail = debounce(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      type: "url" | "displayText"
    ) => {
      const value = e.target?.value || "";
      setLinkDetail((prevState) => ({
        ...prevState,
        [type === "url" ? "url" : "displayText"]: value,
      }));
    },
    300
  );

  const handleSelectAllPublishedFields = () => {
    if (watchedInformation.length === PUBLISHED_FIELDS.length) {
      return setValue("information", AUTO_PUBLISHED_FIELDS);
    }

    setValue("information", PUBLISHED_FIELDS);
  };

  const handleSelectPublishedField = (field: string) => {
    const isSelected = watchedInformation.includes(field);

    if (isSelected) {
      return setValue(
        "information",
        watchedInformation.filter((item) => item !== field)
      );
    }

    setValue("information", [...watchedInformation, field]);
  };

  const handleSelectAllAttachments = () => {
    if (watchedAttachments.length === attachmentFiles.length) {
      return setValue("attachments", []);
    }

    setValue("attachments", attachmentFiles);
  };

  const handleSelectAttachment = (attachment: RTFile) => {
    const currentAttachments = watchedAttachments || [];
    const isAttachmentSelected = currentAttachments
      .map((_) => _.id)
      .includes(attachment.id);

    if (isAttachmentSelected) {
      setValue(
        "attachments",
        currentAttachments.filter(({ id }) => id !== attachment.id)
      );
    } else {
      setValue("attachments", [
        ...currentAttachments,
        _pick(attachment, ["id", "link", "contentLength", "name"]),
      ]);
    }
  };

  const handleClickPublish = async (data: ProjectPublishingForm) => {
    let thumbnails = [];

    if (imageUploaderRef.current.files) {
      const result = await imageUploaderRef.current.processingImages();
      thumbnails = result;
    }
    // console.log("thumbnails", thumbnails)
    // return console.log("handleClickPublish", data)

    publishProject(
      {
        id: projectId,
        payload: {
          information: PUBLISHED_FIELDS?.filter((f) => !data.information?.includes(f)),
          links: data.links,
          videoLinks: data.videoLinks,
          attachments: data.attachments,
          thumbnails,
          isPublish: !!projectData.isPublish ? data.isPublish : true,
          nonDisclosure: !!data.nonDisclosure,
          requireApplication: !!data.requireApplication,
        },
      },
      {
        onSuccess: () => {
          setSnackbar({
            message: "Your proposal was created successfully!",
            open: true,
            severity: "success",
          });

          queryClient.invalidateQueries(["getRoundTableDetail", projectId]);
          queryClient.invalidateQueries(["getEventLogs"]);
          onClose({
            confirmation: {
              title: "Proposal created",
              description:
                "Your proposal was successfully created and is currently awaiting approval from the Teamspace.",
            },
          });
        },
        onError: (error) => {
          setSnackbar({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  const handleCloseAddLinkModal = () => {
    if (isVideoLinkMode) {
      setIsVideoLinkMode(false);
    }
    setIsOpenedLinkModal(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={isOpened}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            m: 2,
          },
        }}
      >
        <DialogTitle fontWeight={"bold"}>
          Publish/edit published project {isViewOnly && "(View Only)"}
        </DialogTitle>
        <DialogContent>
          <Stack rowGap={2}>
            <FieldWrapper label="Project name">
              <Typography fontSize={18} fontWeight={700} color="primary.main">
                {projectData.title}
              </Typography>
            </FieldWrapper>

            {projectData.isPublish && (
              <FormGroup
                sx={{
                  width: "100%",
                  alignItems: "baseline",
                  "& .MuiFormControlLabel-label": {
                    fontWeight: "bold",
                  },
                  "& .MuiFormControlLabel-root": {
                    ml: 0,
                  },
                }}
              >
                <FormControlLabel
                  label="Hide on Teamspace Project"
                  control={
                    <Switch
                      checked={!watchedIsPublished}
                      onChange={() => setValue("isPublish", !watchedIsPublished)}
                    />
                  }
                  labelPlacement="start"
                  disabled={isViewOnly}
                />
              </FormGroup>
            )}

            <FieldWrapper label="Project category">
              {projectData?.category && (
                <Chip
                  label={projectData?.category}
                  variant="filled"
                  sx={{
                    bgcolor: "primary.main",
                    color: common.white,
                    height: 18,
                    fontSize: 12,
                    width: "fit-content",
                  }}
                />
              )}
            </FieldWrapper>

            <FieldWrapper label="Project tags">
              {projectData?.tags?.length > 0 && (
                <Box display="flex" flexWrap={"wrap"} gap={1}>
                  {projectData?.tags?.map((tag, index) => {
                    return (
                      <Chip
                        key={`${index}-${tag}-tag`}
                        label={tag}
                        variant="filled"
                        sx={{
                          borderRadius: 8,
                          // mr: 1,
                          // borderColor: "primary.main",
                          bgcolor: "#DB00110A",
                          color: "primary.main",
                          fontSize: responsiveSize.font12,
                          height: responsiveSize.chipSize,
                        }}
                      />
                    );
                  })}
                </Box>
              )}
            </FieldWrapper>

            <FormGroup
              sx={{
                width: "100%",
                alignItems: "baseline",
                "& .MuiFormControlLabel-label": {
                  fontWeight: "bold",
                },
                "& .MuiFormControlLabel-root": {
                  ml: 0,
                },
              }}
            >
              <FormControlLabel
                label="Non-disclosure project"
                control={
                  <Checkbox
                    checked={watchedNonDisclosure}
                    onChange={() => setValue("nonDisclosure", !watchedNonDisclosure)}
                  />
                }
                labelPlacement="start"
                disabled={isViewOnly}
              />
            </FormGroup>
            <FormGroup
              sx={{
                width: "100%",
                alignItems: "baseline",
                "& .MuiFormControlLabel-label": {
                  fontWeight: "bold",
                },
                "& .MuiFormControlLabel-root": {
                  ml: 0,
                },
              }}
            >
              <FormControlLabel
                label="Application required"
                control={
                  <Checkbox
                    checked={watchedRequireApplication}
                    onChange={() =>
                      setValue("requireApplication", !watchedRequireApplication)
                    }
                  />
                }
                labelPlacement="start"
                disabled={isViewOnly}
              />
            </FormGroup>

            <FieldWrapper label="Links">
              {watchedLinks.map((link, index) => {
                return (
                  <Box
                    key={`${index}-${link.displayText}-link`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    border={common.border}
                    borderRadius={3}
                    p="4px 16px"
                  >
                    <Box
                      display="flex"
                      columnGap={1}
                      color={common.heirloomHydrangea}
                      sx={{ cursor: "pointer" }}
                    >
                      <LinkIcon />
                      <Link
                        href={link.url.includes("http") ? link.url : `//${link.url}`}
                        color="inherit"
                        target="_blank"
                      >
                        {link.displayText}
                      </Link>
                    </Box>
                    <IconButton
                      aria-label="delete-link"
                      onClick={() => handleClickDeleteLink(link.url)}
                      disabled={isViewOnly}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                );
              })}
              <Button
                variant="text"
                startIcon={<AddIcon />}
                sx={{
                  width: "fit-content",
                  height: 36,
                  fontSize: responsiveSize.font14,
                  color: common.doverGrey,
                  border: common.border,
                  borderRadius: 1,
                }}
                onClick={() => setIsOpenedLinkModal(true)}
                disabled={isViewOnly}
              >
                Add link
              </Button>
            </FieldWrapper>

            <FieldWrapper label="Choose what you want to hide">
              <FormControl>
                <FormGroup
                  sx={{
                    // border: common.border,
                    "& .MuiFormControlLabel-root": {
                      m: 0,
                      pl: 0,
                    },
                  }}
                >
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={watchedInformation?.length === PUBLISHED_FIELDS.length}
                        onChange={handleSelectAllPublishedFields}
                      />
                    }
                    label="Select All"
                    sx={{
                      pt: 1,
                      pb: 1,
                      pl: 1,
                      borderBottom: common.border,
                    }}
                    disabled={isViewOnly}
                  /> */}
                  {PUBLISHED_FIELDS.map((item, index) => {
                    if (AUTO_PUBLISHED_FIELDS.includes(item)) {
                      return;
                    }
                    return (
                      <FormControlLabel
                        key={`${index}-${item}-to-be-published`}
                        control={
                          <Switch
                            checked={watchedInformation.includes(item)}
                            onChange={() => handleSelectPublishedField(item)}
                          />
                        }
                        label={`Hide ${capitalizeFirstLetter(item)}`}
                        sx={{
                          pt: index === 0 ? 1 : 0,
                          pl: 1,
                          pb: index === PUBLISHED_FIELDS.length - 1 ? 1 : 0,
                        }}
                        disabled={isViewOnly}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </FieldWrapper>

            <FieldWrapper label="Select attachment">
              <FormControl>
                <FormGroup
                  sx={{
                    border: common.border,
                    "& .MuiFormControlLabel-root": {
                      m: 0,
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!watchedAttachments?.length &&
                          watchedAttachments?.length === attachmentFiles.length
                        }
                        onChange={handleSelectAllAttachments}
                      />
                    }
                    label={
                      attachmentFiles.length
                        ? "Select All"
                        : `No attachment to be selected.`
                    }
                    sx={{
                      p: 1,
                      borderBottom: common.border,
                    }}
                    disabled={!attachmentFiles.length || isViewOnly}
                  />
                  <Grid
                    container
                    justifyContent="space-between"
                    pl={2}
                    pr={2}
                    columnSpacing={1}
                  >
                    {attachmentFiles.map((file, index) => {
                      const isAttachmentSelected = watchedAttachments
                        .map((_) => _.id)
                        .includes(file.id);

                      return (
                        <Grid key={`${index}-${file.id}-attachment`} item xs={5} lg={6}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                              overflow: "hidden",
                              "label": {
                                width: 20,
                                height: 20,
                              },

                              pt: index <= 1 ? 2 : 0,
                              pb: index >= attachmentFiles.length - 2 ? 2 : 0,
                            }}
                            columnGap={1}
                          >
                            <Typography
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                direction: "rtl",
                              }}
                            >
                              {file.name || "Unknown name"}
                            </Typography>

                            <FormControlLabel
                              label=""
                              control={
                                <Checkbox
                                  checked={isAttachmentSelected}
                                  onChange={() => handleSelectAttachment(file)}
                                />
                              }
                              sx={{
                                "span": {
                                  width: 20,
                                  height: 20,
                                },
                              }}
                              disabled={isViewOnly}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormGroup>
              </FormControl>
            </FieldWrapper>

            <FieldWrapper label="Youtube video links">
              {watchedVideoLinks.map((link, index) => {
                return (
                  <Box
                    key={`${index}-${link.displayText}-link`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    border={common.border}
                    borderRadius={3}
                    p="4px 16px"
                  >
                    <Box
                      display="flex"
                      columnGap={1}
                      color={common.heirloomHydrangea}
                      sx={{ cursor: "pointer" }}
                    >
                      <LinkIcon />
                      <Link
                        href={link.url.includes("http") ? link.url : `//${link.url}`}
                        color="inherit"
                        target="_blank"
                      >
                        {link.displayText}
                      </Link>
                    </Box>
                    <IconButton
                      aria-label="delete-link"
                      onClick={() => handleClickDeleteLink(link.url, true)}
                      disabled={isViewOnly}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                );
              })}
              <Button
                variant="text"
                startIcon={<AddIcon />}
                sx={{
                  width: "fit-content",
                  height: 36,
                  fontSize: responsiveSize.font14,
                  color: common.doverGrey,
                  border: common.border,
                  borderRadius: 1,
                }}
                onClick={() => {
                  setIsVideoLinkMode(true);
                  setIsOpenedLinkModal(true);
                }}
                disabled={isViewOnly}
              >
                Add link
              </Button>
            </FieldWrapper>

            <FieldWrapper label="Project images">
              <MultiFileUploader
                isViewOnly={isViewOnly}
                ref={imageUploaderRef}
                // initialFiles={publishProjectData?.data?.publishData?.thumbnails}
                initialFiles={getValues("thumbnails")}
                acceptedFileTypes="image"
              />
            </FieldWrapper>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "end",
            borderTop: common.border,
            pt: 2,
            pb: 2,
            flexDirection: !isDesktop && "column",
          }}
        >
          <Box
            display="flex"
            columnGap={1}
            width={!isDesktop && "100%"}
            justifyContent="end"
          >
            <Button variant="outlined" onClick={() => onClose()}>
              {isViewOnly ? "Close" : "Cancel"}
            </Button>
            {!isViewOnly && (
              <CustomButton
                loading={isLoading}
                onClick={handleSubmit(handleClickPublish)}
                disabled={isLoading}
              >
                {!watchedIsPublished && projectData.isPublish ? "Hide" : "Publish"}
              </CustomButton>
            )}
          </Box>
        </DialogActions>
      </Dialog>

      {isOpenedLinkModal && (
        <Box
          sx={{
            width: 540,
            maxWidth: "80vw",
          }}
        >
          <Dialog fullWidth open={isOpenedLinkModal} onClose={handleCloseAddLinkModal}>
            <DialogTitle>
              <Typography fontSize={20} fontWeight="bold">
                Add link
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ pb: 5 }}>
              <Stack rowGap={2} mt={1}>
                <TextField
                  label="URL"
                  onChange={(e) => handleChangeLinkDetail(e, "url")}
                />
                <TextField
                  label="Display Text"
                  onChange={(e) => handleChangeLinkDetail(e, "displayText")}
                />
              </Stack>
            </DialogContent>

            <DialogActions>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap={2}
              >
                <Button variant="outlined" onClick={handleCloseAddLinkModal}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={!linkDetail.url || !linkDetail.displayText}
                  onClick={handleClickSaveLink}
                >
                  Submit
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default PublishProjectDialog;
