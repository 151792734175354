import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { Attendee } from "@dto/roundtable";
import { VOTE_DELEGATION_TYPE } from "@dto/delegations";
import useRemainingVoteWeight from "@api/delegations/useRemainingVoteWeight";
import VoteShareRow from "@components/Dialog/VoteDelegateDialog/NewDelegates/VoteShareRow";
import { useVoteShare } from "@components/Dialog/VoteDelegateDialog/NewDelegates/CustomizedVoteShareContext";
import DelegateButton from "@components/Dialog/VoteDelegateDialog/NewDelegates/DelegateButton";
import VoteShareList from "./VoteShareList";

type delegateOptionsTypes = {
  label: string,
  value: VOTE_DELEGATION_TYPE
}

const delegateOptions: delegateOptionsTypes[] = [{
  label: 'Full Vote Weight',
  value: VOTE_DELEGATION_TYPE.FULL,
}, {
  label: 'Customized Vote Weight',
  value: VOTE_DELEGATION_TYPE.CUSTOMIZE,
}]

type NewDelegatesProps = {
  delegatees: Attendee[],
  onClose: () => void,
}

const NewDelegates = ({ delegatees, onClose }: NewDelegatesProps) => {
  const [selectedDelegatee, setSelectedDelegatee] = useState('')
  const [selectedType, setSelectedType] = useState<VOTE_DELEGATION_TYPE>(null)

  const { data: voteShareByProposalType, isLoading, } = useRemainingVoteWeight()
  const { handleResetAll } = useVoteShare()

  const voteShareRef = useRef(null);
  const [_, setReRender] = useState(Date.now());

  const isDelegatedFullVoteWeight = useMemo(() => {
    return !voteShareByProposalType?.find(share => +share.shareableVoteWeight > 0)
  }, [voteShareByProposalType])

  const selectedAttendee = useMemo(() => delegatees.find(_ => _.attendeeId === selectedDelegatee),
    [selectedDelegatee, delegatees]
  )
  
  const handleSelectDelegatee = (delegatee: string) => {
    setSelectedDelegatee(delegatee)
    handleResetAll()
  }

  return (
    <>
      <Box my={4}>
        <Typography variant='h2' fontSize={16} fontWeight={700}>New Delegation</Typography>
        <FormControl fullWidth size="medium" sx={{ mt: 3 }}>
          <InputLabel id='select-delegatee-label'>Select Delegatee</InputLabel>
          <Select
            labelId="select-delegatee-label"
            onChange={(event) => handleSelectDelegatee(event.target.value as string)}
            input={<OutlinedInput label="Select Delegatee" />}
          >
            {delegatees.map(option => (
              <MenuItem key={`delegateOption-${option.attendeeId}`} value={option.attendeeId}>{option.name || option.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth size="medium" sx={{ mt: 3 }} error={!isLoading && isDelegatedFullVoteWeight}>
          <InputLabel id='select-type-label'>Delegate Type</InputLabel>
          <Select
            labelId="select-type-label"
            onChange={(event) => setSelectedType(event.target.value as VOTE_DELEGATION_TYPE)}
            input={<OutlinedInput label="Delegate Type" />}
          >
            {delegateOptions.map(option => (
              <MenuItem key={`delegateOption-${option.value}`} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
          {!isLoading && isDelegatedFullVoteWeight && (
            <FormHelperText>You have delegated all of your vote weight! Please revoke before delegating to another member!</FormHelperText>
          )}
        </FormControl>

        {selectedAttendee && (
          <Box pt={4}>
            <Divider />
            <Stack direction='row' alignItems='center' mt={2}>
              <Avatar
                src={selectedAttendee.avatar}
                sx={{ mr: 1, bgcolor: 'common.partyTime' }}
              >
                {(selectedAttendee.name || selectedAttendee.title)[0]?.toUpperCase()}
              </Avatar>
              <Stack direction='column'>
                <Typography fontWeight={600}>
                  {selectedAttendee.name || selectedAttendee.title}
                </Typography>
                <Typography color='common.partyTime'>
                  Member
                </Typography>
              </Stack>
              {voteShareRef?.current?.hasSelect && (
                <Box ml={"auto"}>
                  <Button aria-describedby={"bulk-update-value"} variant="contained" onClick={voteShareRef?.current?.handleClick}>
                    Bulk update
                  </Button>
                </Box>
              )}
            </Stack>

            {selectedType === VOTE_DELEGATION_TYPE.CUSTOMIZE && (
              <VoteShareList
                voteShareByProposalType={voteShareByProposalType}
                selectedAttendee={selectedAttendee}
                ref={voteShareRef}
                hasSelectEventCallback={setReRender}
              />
            )}
          </Box>
        )}
      </Box>
      <Stack direction='row' justifyContent='end' spacing={1}>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <DelegateButton
          selectedType={selectedType}
          selectedDelegatee={selectedDelegatee}
        />
      </Stack>
    </>
  )
}

export default NewDelegates
