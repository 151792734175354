import React, { FC, SyntheticEvent, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled, useTheme } from "@mui/material/styles";

import { useViewport } from "@contexts/ViewportContext";

interface IAntTabs {
  tabHeight: number;
}

interface IAntTab {
  label: string;
  totalItems: number;
  isMobile: boolean;
  tabHeight: number;
  hasRedDot?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  noPadding?: boolean;
  tabHeight?: number;
}

export interface TabItem {
  label: string;
  content: JSX.Element;
  hasRedDot?: boolean;
  dataKey?: string;
}

interface ICustomTabs {
  data: TabItem[];
  hasBorderLeft?: boolean;
}

export const AntTabs = styled(Tabs)<IAntTabs>(({ tabHeight }) => ({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "common.black",
  },
  minHeight: tabHeight,
  maxHeight: tabHeight,
}));

export const AntTab = styled((props: IAntTab) => <Tab disableRipple {...props} />)(
  ({ theme, totalItems, tabHeight, hasRedDot }) => ({
    position: "relative",
    minHeight: tabHeight,
    maxHeight: tabHeight,
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    width: `calc(100% / ${totalItems})`,
    minWidth: 0,
    maxWidth: "100%",
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      opacity: 1,
    },
    "&.Mui-selected": {
      fontWeight: 700,
    },
    "&::after": {
      content: '""',
      display: hasRedDot ? "block" : "none",
      position: "absolute",
      top: "50%",
      right: 8,
      transform: "translate(50%, -50%)",
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
    },
  })
);

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, tabHeight, ...other } = props;

  return (
    <Box
      pt={1}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      height={tabHeight && `calc(100% - ${tabHeight}px)`}
      {...other}
    >
      {value === index && <Box height="100%">{children}</Box>}
    </Box>
  );
};

const CustomTabs: FC<ICustomTabs> = ({ data = [], hasBorderLeft }) => {
  const {
    palette: { common },
  } = useTheme();
  const { isMobile } = useViewport();
  const [value, setValue] = useState(0);

  const tabHeight = useMemo(() => {
    return isMobile ? 36 : 42;
  }, [isMobile]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderTabLabelItem = (): JSX.Element[] => {
    return data.map((tab: TabItem) => (
      <AntTab
        key={`renderTabLabelItem-${tab.label}`}
        label={tab?.label}
        isMobile={isMobile}
        tabHeight={tabHeight}
        totalItems={data.length}
        hasRedDot={tab.hasRedDot}
      />
    ));
  };

  const renderTabChildrenItem = () => {
    return data.map((tab: TabItem, index: number) => (
      <TabPanel
        key={`renderTabChildrenItem-${tab.label}`}
        value={value}
        index={index}
        tabHeight={tabHeight}
      >
        {tab?.content}
      </TabPanel>
    ));
  };

  return (
    <Box
      pb={0}
      height="100%"
      sx={{
        borderLeft: hasBorderLeft ? common.border : null,
      }}
    >
      <AntTabs value={value} tabHeight={tabHeight} onChange={handleChange}>
        {renderTabLabelItem()}
      </AntTabs>
      {renderTabChildrenItem()}
    </Box>
  );
};

export default CustomTabs;
