import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

import { Roundtable } from "@dto/roundtable";

type Request = {
  id: string;
  payload: {
    parentRoundTableId: string;
    reason: string;
    representatives: string[];
  };
};

const becomeSubRoundtable = async ({ id, payload }: Request) => {
  const response = await axios.post(`/round-tables/${id}/become-sub`, payload);

  return response.data.data;
};

const useBecomeSubRoundtable = () => {
  return useMutation(becomeSubRoundtable);
};

export default useBecomeSubRoundtable;
