import { FC, ReactNode, useCallback, useRef, useState } from "react";

import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  Paper,
  Popper,
  Stack,
  MenuList as MuiMenuList,
  SxProps,
} from "@mui/material";

import { useViewport } from "@contexts/ViewportContext";

export interface IMenuItem {
  text: string;
  sx?: SxProps;
  onClick?: () => void;
}

interface IProps {
  endIcon?: ReactNode;
  label?: string;
  menuItems: IMenuItem[];
}

const MenuList: FC<IProps> = ({ endIcon, label, menuItems }) => {
  const { isMobile } = useViewport();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleSelect = useCallback(
    (fn: () => void) => () => {
      setOpen(false);
      fn();
    },
    []
  );

  return (
    <Stack>
      <Button
        ref={anchorRef}
        variant="contained"
        onClick={handleToggle}
        sx={{
          borderRadius: 2,
          ...(isMobile ? {
            width: 38,
            minWidth: 38,
            height: 38,
          }: {
            minWidth: "unset",
            height: 34,
            width: 42
          })
        }}
      >
        {endIcon}
        {label}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 5 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MuiMenuList autoFocusItem={open} id="composition-menu">
                  {menuItems?.map(({ text, sx = {}, onClick }, index) => (
                    <MenuItem
                      key={`${text}-${index}`}
                      onClick={handleSelect(onClick)}
                      sx={{ fontSize: 14, fontWeight: 400, ...sx }}
                    >
                      {text}
                    </MenuItem>
                  ))}
                </MuiMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};

export default MenuList;
