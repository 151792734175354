import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useGetApplicationDetails from "@api/job/useGetApplicationDetails";
import { Stack, Typography } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { clearPaymentMethod } from "@features/payment/slice";

import { CheckoutForm } from "@pages/dashboard/components/CheckoutForm";

const CheckoutCardList = ({ jobId }: { jobId?: string }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const {
    userInfo: { user },
  } = useAppSelector((state) => state.auth);
  const { applicationId } = useAppSelector((state) => state.application);
  const { data: app } = useGetApplicationDetails(jobId, applicationId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearPaymentMethod());
    };
  }, []);

  return (
    <Stack rowGap={1} px={2} sx={{ overflowX: "auto", height: "100%" }}>
      <Stack flex={1}>
        <Stack sx={{ overflowY: "scroll" }} gap={2} mt={2} display={"flex"}>
          {Number(app?.expectBudget) > 0 && (
            <Elements
              stripe={stripePromise}
              options={
                {
                  mode: "payment",
                  amount: 1,
                  currency: "usd",
                  appearance: {
                    theme: "stripe",
                  },
                } as StripeElementsOptions
              }
            >
              <CheckoutForm user={user} appDetails={app} />
            </Elements>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CheckoutCardList;
