import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

type Request = {
  targetMemberId: string;
  roundTableId: string;
  reason?: string;
};

const kickoutMemberRoundtable = async ({
  targetMemberId,
  roundTableId,
  reason,
}: Request) => {
  const response = await axios.put(`/round-table/${roundTableId}/kickout`, {
    targetMemberId,
    reason,
  });

  return response.data.data;
};

const useKickoutMember = () => {
  return useMutation(kickoutMemberRoundtable);
};

export default useKickoutMember;
