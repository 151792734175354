import React, { Dispatch, SetStateAction, useState } from "react";

import { DialogEnum, ToolOptionType } from "@constants/roundtable";

import EditRoundtableDialog from "@components/Dialog/AddEditRoundtableDialog/EditRoundtableDialog";
import BecomeASubRoundtableDialog from "@components/Dialog/BecomeASubRoundtableDialog";
import ConfirmDialog from "@components/Dialog/ConfirmDialog";
import CreateEventProposalDialog from "@components/Dialog/CreateEventProposalDialog";
import CreateUpdatePollDialog from "@components/Dialog/CreateUpdatePollDialog";
import CreateUpdateProjectDialog from "@components/Dialog/CreateUpdateProjectDialog";
import PublishProjectDialog from "@components/Dialog/PublishProjectDialog";
import RoundtableDetailsDialog from "@components/Dialog/RoundtableDetailsDialog";
import UpdatePositionDialog from "@components/Dialog/UpdatePositionDialog";
import UpdateProjectProfileDialog from "@components/Dialog/UpdateProjectProfileDialog";
import UpsertRoundtableDialog from "@components/Dialog/UpsertRoundtableDialog";
import VoteDelegateDialog from "@components/Dialog/VoteDelegateDialog";
import InviteMemberDialog from "@components/InviteMemberDialog";
import { ISnackbar } from "@components/Snackbar";

import ConfirmDeleteRoundtableDialog from "@pages/chat/Dialogs/ConfirmDeleteRoundtableDialog";
import ExitParentRoundtableDialog from "@pages/chat/Dialogs/ExitParentRoundtableDialog";
import LeaveRoundtableDialog from "@pages/chat/Dialogs/LeaveRoundtableDialog";
import UpdateSubProjectDialog from "@pages/chat/Dialogs/UpdateSubProjectDialog";

import { isProject } from "@utils/roundtable.utils";

import { Roundtable } from "@dto/roundtable";

import LeaveProjectDialog from "./Dialogs/LeaveProjectDialog";

type ChatDialogsProps = {
  dialogToggle: Partial<Record<ToolOptionType, boolean>>;
  toggleDialog: (type: ToolOptionType, isOpened: boolean) => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  conversationId: string;
  fetchEventLogs: () => void;
  roundtable: Roundtable;
};
const Dialogs = (props: ChatDialogsProps) => {
  const [confirmPopup, setConfirmPopup] = useState<{
    title: string;
    description: string;
  }>();

  const {
    dialogToggle,
    toggleDialog,
    conversationId,
    setSnackbar,
    fetchEventLogs,
    roundtable,
  } = props;

  const isRoundtable = !isProject(roundtable);

  return (
    <>
      {dialogToggle.DETAIL && (
        <RoundtableDetailsDialog
          open={dialogToggle.DETAIL}
          roundtableId={conversationId}
          onClose={() => toggleDialog(DialogEnum.DETAIL, false)}
          displayAsProject={!isRoundtable}
        />
      )}
      {dialogToggle.POLL && (
        <CreateUpdatePollDialog
          isOpen={dialogToggle.POLL}
          onClose={() => toggleDialog(DialogEnum.POLL, false)}
          conversationId={conversationId}
          setSnackbar={setSnackbar}
        />
      )}
      {dialogToggle.INVITATION && (
        <InviteMemberDialog
          isOpen={dialogToggle.INVITATION}
          onClose={() => toggleDialog(DialogEnum.INVITATION, false)}
          setSnackbar={setSnackbar}
          isRoundtable={isRoundtable}
          roundtableId={conversationId}
          fetchEventLogs={fetchEventLogs}
        />
      )}
      {dialogToggle.POSITION && (
        <UpdatePositionDialog
          id={conversationId}
          isOpened={dialogToggle.POSITION}
          roundtable={roundtable}
          onClose={() => toggleDialog(DialogEnum.POSITION, false)}
          setSnackbar={setSnackbar}
        />
      )}
      {dialogToggle.SETTING && (
        <EditRoundtableDialog
          isOpen={dialogToggle.SETTING}
          initData={roundtable}
          onClose={() => toggleDialog(DialogEnum.SETTING, false)}
          setSnackbar={setSnackbar}
          id={conversationId}
        />
      )}
      {dialogToggle.PROJECT_PROFILE && (
        <UpdateProjectProfileDialog
          isOpen={dialogToggle.PROJECT_PROFILE}
          initData={roundtable}
          onClose={() => toggleDialog(DialogEnum.PROJECT_PROFILE, false)}
          setSnackbar={setSnackbar}
          id={conversationId}
          attendees={roundtable.attendees}
        />
      )}
      {dialogToggle.PROJECT && (
        <CreateUpdateProjectDialog
          id={conversationId}
          isOpen={dialogToggle.PROJECT}
          isEdit={dialogToggle.isEdit}
          project={roundtable}
          onClose={() => toggleDialog(DialogEnum.PROJECT, false)}
          setSnackbar={setSnackbar}
        />
      )}
      {dialogToggle.SUB_ROUNDTABLE_BECOMING && (
        <BecomeASubRoundtableDialog
          id={conversationId}
          isOpen={dialogToggle.SUB_ROUNDTABLE_BECOMING}
          roundtable={roundtable}
          onClose={() => toggleDialog(DialogEnum.SUB_ROUNDTABLE_BECOMING, false)}
          setSnackbar={setSnackbar}
        />
      )}
      {dialogToggle.DELETE && (
        //Also close project feature
        <ConfirmDeleteRoundtableDialog
          isRoundtable={isRoundtable}
          id={conversationId}
          roundtableName={roundtable?.title}
          membersCount={roundtable?.attendees?.length}
          onCancel={() => toggleDialog(DialogEnum.DELETE, false)}
          setSnackbar={setSnackbar}
        />
      )}
      {dialogToggle.LEAVE && (
        <LeaveRoundtableDialog
          roundtableId={conversationId}
          roundtableName={roundtable.title}
          currentTotalMembers={roundtable.attendees.length}
          openDialog={dialogToggle.LEAVE}
          setOpenDialog={
            ((isOpened: boolean) => toggleDialog(DialogEnum.LEAVE, isOpened)) as Dispatch<
              SetStateAction<boolean>
            >
          }
          attendees={roundtable.attendees}
        />
      )}
      {dialogToggle.LEAVE_PROJECT && (
        <LeaveProjectDialog
          roundtableId={conversationId}
          roundtableName={roundtable.title}
          currentTotalMembers={roundtable.attendees.length}
          openDialog={dialogToggle.LEAVE_PROJECT}
          setOpenDialog={
            ((isOpened: boolean) =>
              toggleDialog(DialogEnum.LEAVE_PROJECT, isOpened)) as Dispatch<
              SetStateAction<boolean>
            >
          }
          attendees={roundtable.attendees}
        />
      )}
      {dialogToggle.SUB_PROJECT_UPDATE && (
        <UpdateSubProjectDialog
          parent={roundtable.parent}
          isOpen={dialogToggle.SUB_PROJECT_UPDATE}
          roundtable={roundtable}
          onClose={() => toggleDialog(DialogEnum.SUB_PROJECT_UPDATE, false)}
          setSnackbar={setSnackbar}
        />
      )}
      {dialogToggle.PROJECT_PUBLISHING && (
        <PublishProjectDialog
          isOpened={dialogToggle.PROJECT_PUBLISHING}
          projectId={conversationId}
          onClose={(data) => {
            toggleDialog(DialogEnum.PROJECT_PUBLISHING, false);
            if (data?.confirmation) {
              setConfirmPopup({
                title: data?.confirmation?.title,
                description: data?.confirmation?.description,
              });
            }
          }}
          setSnackbar={setSnackbar}
        />
      )}
      {dialogToggle.EXIT && (
        <ExitParentRoundtableDialog
          isOpened={dialogToggle.EXIT}
          onClose={() => toggleDialog(DialogEnum.EXIT, false)}
          setSnackbar={setSnackbar}
          roundtable={roundtable}
        />
      )}
      {dialogToggle.EVENT_PROPOSAL && (
        <CreateEventProposalDialog
          isOpen={dialogToggle.EVENT_PROPOSAL}
          onClose={(data) => {
            toggleDialog(DialogEnum.EVENT_PROPOSAL, false);
            if (data?.confirmation) {
              setConfirmPopup({
                title: data?.confirmation?.title,
                description: data?.confirmation?.description,
              });
            }
          }}
          conversationId={conversationId}
          setSnackbar={setSnackbar}
        />
      )}
      {dialogToggle.VOTE_DELEGATE && (
        <VoteDelegateDialog
          isOpen={dialogToggle.VOTE_DELEGATE}
          onClose={() => toggleDialog(DialogEnum.VOTE_DELEGATE, false)}
          roundtable={roundtable}
        />
      )}
      {confirmPopup && (
        <ConfirmDialog
          isOpen={Boolean(confirmPopup)}
          onClose={() => setConfirmPopup(null)}
          title={confirmPopup?.title}
          description={confirmPopup?.description}
        />
      )}
      {(dialogToggle.ROUNDTABLE_MANAGEMENT || dialogToggle.ROUNDTABLE_PROFILE) && (
        <UpsertRoundtableDialog
          isOpen={dialogToggle.ROUNDTABLE_MANAGEMENT || dialogToggle.ROUNDTABLE_PROFILE}
          onClose={() => {
            toggleDialog(DialogEnum.ROUNDTABLE_MANAGEMENT, false);
            toggleDialog(DialogEnum.ROUNDTABLE_PROFILE, false);
          }}
          data={roundtable}
          type={
            dialogToggle.ROUNDTABLE_MANAGEMENT
              ? "ROUNDTABLE_MANAGEMENT"
              : "ROUNDTABLE_PROFILE"
          }
        />
      )}
    </>
  );
};

export default Dialogs;
