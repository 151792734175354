import { useState } from "react";

import { Avatar, AvatarProps } from "@mui/material";

import UserDetailDialog from "@components/Dialog/UserDetailDialog";

interface IProps extends AvatarProps {
  userId?: string | null;
}

export default function UserAvatarButton({ userId, ...rest }: IProps) {
  const [openUserDetail, setOpenUserDetail] = useState(false);

  return (
    <>
      <Avatar
        {...rest}
        sx={{ ...rest.sx, cursor: "pointer" }}
        onClick={userId ? () => setOpenUserDetail(true) : null}
      />

      {userId && (
        <UserDetailDialog
          userId={userId}
          open={openUserDetail}
          onClose={() => setOpenUserDetail(false)}
        />
      )}
    </>
  );
}

// export default forwardRef(function UserAvatarButton(
//   { userId, ...rest }: IProps,
//   // ref: Ref<HTMLButtonElement>
//   ref: Ref<ReactNode>
// ) {
//   return (
//     <div><Avatar {...rest} /></div>
//   )
// });
