import { axios, handleApiError } from "@features/config";
import { User } from "@dto/user";

type Response = {
  success: boolean;
  data?: User;
  message?: string;
};

export const getMe = async (): Promise<Response> => {
  try {
    const { data } = await axios.get(`/me`);
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};
