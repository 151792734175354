import { useState } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { IconButton } from "@mui/material";
import { $createTextNode, $insertNodes } from "lexical";

import { ReactComponent as EmojiIcon } from "@assets/emoji/emoji_icon.svg";

import { AppEmojiPicker } from "../AppEmojiPicker";

export const emojiPopoverAnchorDiv = `emoji-popover-chat-editor-new`;

const EmojiPlugin = () => {
  const [open, setOpen] = useState(false);
  const [editor] = useLexicalComposerContext();

  const addEmoji = (emoji: string) => {
    editor.update(() => {
      $insertNodes([$createTextNode(emoji)]);
    });
  };

  const onClose = () => setOpen(false);
  return (
    <div id={emojiPopoverAnchorDiv} className="toolbar-item">
      <IconButton onClick={() => setOpen(true)}>
        <EmojiIcon />
      </IconButton>
      <AppEmojiPicker
        {...{
          popOverAnchorDiv: emojiPopoverAnchorDiv,
          showPicker: open,
          closePicker: onClose,
          onAddReaction: addEmoji,
        }}
      />
    </div>
  );
};

export default EmojiPlugin;
