import { useEffect, useState } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import { getRoundtables } from "@api/roundtable/getRoundtables";
import { Box, Stack, Skeleton } from "@mui/material";

import { ISnackbar } from "@components/Snackbar";

import { Roundtable } from "@dto/roundtable";

import { RoundtableTypeEnum } from "@@types/type";

import ProjectItem from "./ProjectItem";

interface IProjectsProps {
  roundtableId: string;
  setSnackbar: (value: React.SetStateAction<ISnackbar>) => void;
}

const Projects: React.FC<IProjectsProps> = ({ roundtableId, setSnackbar }) => {
  const [projects, setProjects] = useState<Roundtable[]>([]);
  const [projectLoading, setProjectLoading] = useState(true);
  const params = useParams();
  const { pathname } = useLocation();
  // const [searchParams, setSearchParams] = useSearchParams();

  const { projectId } = params;
  // const proposalId = searchParams.get("proposal");

  const fetchProjects = async () => {
    setProjectLoading(true)
    const { success, data } = await getRoundtables({
      type: [RoundtableTypeEnum.SELF, RoundtableTypeEnum.FREELANCER],
      parentId: roundtableId,
      page: 0,
      pageSize: 9999,
    });

    if (success) {
      setProjects(data.data);
    } else {
      setSnackbar({
        message: "Something went wrong!",
        open: true,
        severity: "error",
      });
    }
    setProjectLoading(false)
  };

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, pathname]);

  return (
    <Stack sx={{
      overflowY: "scroll",
      // maxHeight: "calc(100vh - 260px)"
      maxHeight: "100%"
    }}>
      {projectLoading && (
        <Stack gap={1.3} p={2}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={"100%"} height={60} />
        </Stack>
      )}
      {!projectLoading && projects?.map((item, index) => (
        <ProjectItem key={item.id || index} data={item} />
      ))}
    </Stack>
  );
};

export default Projects;
