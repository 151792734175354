import { ProposalType } from "@dto/proposal";

export const getEventDescription = (
  type: ProposalType,
  target?: string,
  isRoundtable?: boolean
) => {
  switch (type) {
    case ProposalType.APPLY_TO_JOIN:
      return `wants to join this ${isRoundtable ? "Teamspace" : "project"}.`;
    case ProposalType.BECOME_FORMAL_MEMBER:
      return "wants to become a formal member";
    case ProposalType.REQUEST_VOTE_WEIGHT:
      return "wants to start a proposal";
    case ProposalType.UPDATE_SETTINGS:
      return "wants to start a vote";
    case ProposalType.BECOME_SUB_ROUND_TABLE:
      return "starts a vote.";
    case ProposalType.POLL:
      return "starts a poll.";
    case ProposalType.KICKOUT_MEMBER:
      return `want to kick ${target}`;
    default:
      return "starts a vote.";
  }
};

const proposalTypeKeys = Object.keys(ProposalType) as (keyof typeof ProposalType)[];

export type EVENT_KEYS = (typeof proposalTypeKeys)[number];

export const EVENT_HEADING_TITLE: Record<EVENT_KEYS, string> = {
  APPLY_TO_JOIN: "Member Application",
  REQUEST_VOTE_WEIGHT: "Position Updated",
  SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT: "Position Updated",
  APPROVE_SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT: "Position Updated",
  UPDATE_SETTINGS: "Setting updated",
  UPDATE_ROUND_TABLE_MANAGEMENT: "Teamspace management",
  UPDATE_ROUND_TABLE_PROFILE: "Teamspace profile",
  PROJECT_MANAGEMENT: "Project Management",
  PROJECT_PROFILE: "Project Profile",
  CREATE_ROUND_TABLE: "New Teamspace Created",
  POLL: "New Vote",
  BECOME_SUB_ROUND_TABLE: "Become Sub-Teamspace",
  APPROVE_SUB_ROUND_TABLE: "Sub-Teamspace Application",
  DELETE_ROUND_TABLE: "Delete Teamspace",
  CLOSE_PROJECT: "Close project",
  BECOME_FORMAL_MEMBER: "Become Formal Member",
  SUB_ROUND_TABLE_BECOME_FORMAL_MEMBER: "Become Formal Member",
  CREATE_PROPOSAL_PROJECT: "New Project",
  UPDATE_REPRESENTATIVES: "Update Sub-Teamspace",
  PUBLISH_PROJECT: "Publish Project",
  EXIT_PARENT_ROUNDTABLE: "Exit Parent-Teamspace",
  EVENT: "Event Proposal",
  APPLY_TO_JOIN_EXTERNAL: "External Member Application",
  KICKOUT_MEMBER: "Kickout Member",
};
