import { useState } from "react";
import { useParams } from "react-router-dom";

import useRemainingVoteWeight from "@api/delegations/useRemainingVoteWeight";
import voteDelegate from "@api/delegations/voteDelegation";
import { Button } from "@mui/material";
import { cloneDeep } from "lodash";

import { handleApiError } from "@features/config";

import { useVoteShare } from "@components/Dialog/VoteDelegateDialog/NewDelegates/CustomizedVoteShareContext";

import { VOTE_DELEGATION_TYPE } from "@dto/delegations";

import { useSnackbar } from "@contexts/SnackbarContext";

type DelegateButtonProps = {
  selectedType: VOTE_DELEGATION_TYPE;
  selectedDelegatee: string;
};

const DelegateButton = ({ selectedDelegatee, selectedType }: DelegateButtonProps) => {
  const { delegations, errors } = useVoteShare();
  const { refetch } = useRemainingVoteWeight();
  const { setSnackbar } = useSnackbar();
  const { roundtableId, projectId } = useParams();
  const conversationId = projectId ?? roundtableId;

  const [loading, setLoading] = useState(false);

  const handleSubmitDelegate = (p: any) => {
    const params = cloneDeep(p);
    /**
     * Remove delegation with vote weight is equal to 0
     */
    Object.keys(params.delegations).forEach((proposalType) => {
      params.delegations[proposalType] /= 100;
      if (params.delegations[proposalType] <= 0) {
        delete params.delegations[proposalType];
      }
    });

    setLoading(true);
    voteDelegate(params)
      .then(() => {
        setSnackbar({
          message: "Your delegation was created successfully!",
          open: true,
          severity: "success",
        });
        return refetch();
      })
      .catch((error) => {
        let errorMessage = handleApiError(error).message
        if (errorMessage?.includes('no vote weight left')) {
          errorMessage = "Please revoke your delegated vote weight first!"
        }
        return setSnackbar({
          message: errorMessage,
          open: true,
          severity: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button
      onClick={() =>
        handleSubmitDelegate({
          delegatee: selectedDelegatee,
          type: selectedType,
          roundtableId: conversationId,
          delegations,
        })
      }
      variant="contained"
      disabled={loading || Object.values(errors).findIndex((_) => _) > -1}
    >
      Delegate
    </Button>
  );
};

export default DelegateButton;
