import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Member } from "@api/proposal/createRoundTableProject";
import useUpdateRoundtableOrProject from "@api/roundtable/useUpdateRoundtableOrProject";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { handleApiError } from "@features/config";

import { ISnackbar } from "@components/Snackbar";

import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { Attendee, Roundtable } from "@dto/roundtable";

import queryClient from "@config/queryClient";

import RoundtableDialog, {
  ROUNDTABLE_DIALOG_TYPE,
} from "../AddEditRoundtableDialog/RoundtableDialog";

const formSchema = yup.object().shape({
  avatar: yup.mixed().nullable(),
  title: yup.string().required("Title is required."),
  // type: yup.string(),
  // visibility: yup.number().required("Visibility is required."),
  // language: yup.string().nullable(),
  // institution: yup.string().nullable(),
  // location: yup.string().nullable(),
  // description: yup.string(),
  tags: yup.array().of(yup.string()).nullable(),
  // category: yup.string().when("tags", (tags, schema) => {
  //   if (!!tags?.length && typeof tags[0] === "string" ? !!tags[0] : !!tags[0]?.length) {
  //     return schema.required("Category is required when Tags are provided.");
  //   }

  //   return schema.nullable();
  // }),
});

export type EditRoundtableForm = {
  avatar: any;
  title: string;
  type: string;
  language: string;
  institution: string;
  visibility: number;
  location: string;
  description: string;
  fund?: number;
  members?: string[];
  memberList?: {
    userId?: string;
    voteWeight?: number;
    percentageProfit?: number;
    subRoundTableId?: string;
  }[];
  category: string;
  tags?: string[];
};

interface IProps {
  isOpen: boolean;
  initData: Roundtable;
  onClose: () => void;
  setSnackbar?: Dispatch<SetStateAction<ISnackbar>>;
  id: string;
  isViewOnly?: boolean;
  attendees: Attendee[];
}

const UpdateProjectProfileDialog: FC<IProps> = ({
  isOpen,
  initData,
  onClose,
  setSnackbar,
  id,
  isViewOnly,
  attendees,
}) => {
  const [imageUploading, setImageUploading] = useState(false);
  const updateRoundtableOrProject = useUpdateRoundtableOrProject();

  const defaultValues: EditRoundtableForm = useMemo(() => {
    return {
      avatar: null,
      title: "",
      type: "Team",
      visibility: 0,
      category: "",
      language: "",
      institution: "",
      location: "",
      description: "",
      members: [],
      memberList: [],
    };
  }, [initData]);

  const formMethods = useForm<EditRoundtableForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const {
    reset,
    trigger,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (!initData) {
      return;
    }

    const {
      avatar,
      title,
      type,
      language,
      isPublic,
      institution,
      location,
      description,
      category,
      tags,
    } = initData;

    reset({
      avatar,
      title,
      type: type || "Team",
      language,
      institution,
      location,
      visibility: +isPublic,
      description,
      category,
      tags,
      fund: initData.fund || null,
      members:
        isViewOnly &&
        (initData as any).memberList?.map((attendee: any) => attendee.userId).length
          ? (initData as any).memberList?.map((attendee: any) => attendee.userId)
          : initData.attendees?.map((attendee) => attendee.userId) || [],
      memberList:
        isViewOnly && (initData as any).memberList?.length
          ? (initData as any).memberList
          : initData.attendees?.map((attendee) => ({
              userId: attendee.userId,
              voteWeight: +attendee.voteWeight,
              percentageProfit: +attendee.percentageProfit,
              subRoundTableId: attendee.subRoundTable?.id,
            })) || [],
    });

    trigger();
  }, [initData, reset, trigger, isViewOnly]);

  const handleClickOnSave = async (formData: EditRoundtableForm) => {
    let avatar = null;
    if (formData?.avatar instanceof File) {
      setImageUploading(true);
      const result = await uploadImagesToServer([formData.avatar]).finally(() =>
        setImageUploading(false)
      );
      avatar = result[0] || null;
    } else {
      avatar = formData?.avatar;
    }

    const {
      title,
      description,
      location,
      institution,
      visibility,
      language,
      tags,
      category,
      memberList,
    } = formData;

    const _memberList = memberList.map((member) => {
      const obj: Member = {
        percentageProfit: member.percentageProfit,
        voteWeight: member.voteWeight,
      };
      const attendee = initData?.attendees.find(
        (attendee) =>
          attendee.userId === member.userId || attendee.roundTableId === member.userId
      );
      if (attendee.userId) {
        obj.userId = attendee.userId;
      } else if (attendee.roundTableId) {
        obj.subRoundTableId = attendee.roundTableId;
      }

      return obj;
    });

    const payload = {
      title,
      avatar,
      description,
      isPublic: Boolean(visibility),
      location,
      institution,
      language,
      tags,
      category,
      memberList: _memberList,
    };

    updateRoundtableOrProject.mutate(
      {
        id,
        payload,
        proposalType: ROUNDTABLE_DIALOG_TYPE.PROJECT_PROFILE,
      },
      {
        onSuccess: () => {
          setSnackbar?.({
            message: "Your proposal was created successfully!",
            open: true,
            severity: "success",
          });

          queryClient.invalidateQueries(["getRoundTableDetail", id]);
          queryClient.invalidateQueries(["getEventLogs"]);
          onClose();
        },
        onError: (error) => {
          setSnackbar?.({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <FormProvider {...formMethods}>
      <RoundtableDialog
        isEdit
        isOpen={isOpen}
        isLoading={updateRoundtableOrProject.isLoading || imageUploading}
        isDisabledSubmitBtn={!isDirty}
        dialogTitle={`Update Project Profile ${isViewOnly ? "(View Only)" : ""}`}
        roundtableId={initData?.id}
        onClose={onClose}
        onSave={handleClickOnSave}
        project={{ ...initData, attendees }}
        isViewOnly={isViewOnly}
        type="UPDATE_PROJECT_PROFILE"
      />
    </FormProvider>
  );
};

export default UpdateProjectProfileDialog;
