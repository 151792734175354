import React, { useMemo } from "react";

import { Chip, ChipProps } from "@mui/material";

import { useViewport } from "@contexts/ViewportContext";

type AppChipProps = ChipProps;

const AppChip = (props: AppChipProps) => {
  const { ...rest } = props;
  const { isDesktop } = useViewport();
  const responsiveSize = useMemo(() => {
    return {
      font12: isDesktop ? 12 : 10,
      font14: isDesktop ? 14 : 10,
    };
  }, [isDesktop]);
  return (
    <Chip
      {...rest}
      sx={{
        height: isDesktop ? 24 : 16,
        borderRadius: 1,
        fontSize: responsiveSize.font12,
        fontWeight: "bold",
        bgcolor: "#EDF2F7",
        "& .MuiChip-label": { p: 1 },
      }}
    />
  );
};
export default AppChip;
