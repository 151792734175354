import { FC, useEffect, useState } from "react";

import useToggleNotification from "@api/roundtable/useToggleNotification";
import {
  NotificationsActive as NotificationsActiveIcon,
  NotificationsOff as NotificationsOffIcon,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  DialogProps,
  Box,
} from "@mui/material";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

interface IConfirmActionProps extends DialogProps {
  onClose: (params?: any) => void;
  onSubmit: (params?: any) => void;
  textContent?: string;
  submitLabel?: string;
  submitLoading?: boolean;
}

const ConfirmActionDialog: FC<IConfirmActionProps> = ({
  open,
  onClose,
  onSubmit,
  textContent,
  submitLabel,
  sx,
  submitLoading,
}) => {
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "280px",
          m: 2,
        },
        ...sx,
      }}
    >
      <DialogTitle sx={{ fontWeight: 700, textAlign: "center" }}>
        Confirmation
      </DialogTitle>
      <DialogContent>
        <Typography textAlign={"center"}>{textContent}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
        <Button variant="outlined" onClick={onClose} disabled={submitLoading}>
          Close
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={submitLoading}>
          {submitLabel || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IProps {
  userNotification?: boolean;
  roundtableId: string;
}

const NotificationButton: FC<IProps> = ({
  userNotification: notiStatus,
  roundtableId,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmOff, setConfirmOff] = useState("");
  const { setSnackbar } = useSnackbar();

  const { mutate: toggleRtNotification } = useToggleNotification();
  const patchNotification = () => {
    const successMsg = notiStatus ? "Notification is off!" : "Notification is on!";
    setLoading(true);
    toggleRtNotification(
      { id: roundtableId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getRoundTableDetail"]);
          if (confirmOff) {
            setConfirmOff("");
          }
          setSnackbar({
            message: successMsg,
            open: true,
            severity: "success",
          });
        },
        onError: (error) => {
          setLoading(false);
          setSnackbar({
            message: error,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };
  const handleToggleNoti = () => {
    if (notiStatus) {
      setConfirmOff(
        "You will no longer receive notification of this Teamspace. Are you sure to turn off?"
      );
    } else {
      patchNotification();
    }
  };

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [notiStatus]);

  return (
    <div>
      <Button
        variant="outlined"
        sx={{
          borderRadius: 2,
          border: "1px solid #E2E8F0",
          ":hover": { border: "1px solid #E2E8F0" },
          minWidth: "unset",
          p: 1,
        }}
        onClick={() => handleToggleNoti()}
        disabled={loading}
      >
        <Tooltip
          title={`${notiStatus ? "Mute" : "Unmute"} notification`}
          placement="left"
          arrow
        >
          {notiStatus ? (
            <NotificationsActiveIcon fontSize="small" />
          ) : (
            <NotificationsOffIcon
              fontSize="small"
              sx={{ fill: loading ? "currentcolor" : "#718096" }}
            />
          )}
        </Tooltip>
      </Button>
      <ConfirmActionDialog
        open={!!confirmOff}
        onClose={() => setConfirmOff("")}
        onSubmit={() => patchNotification()}
        textContent={confirmOff}
        submitLabel="Turn off"
        sx={{ "& .MuiDialog-paper": { maxWidth: "460px" } }}
        submitLoading={loading}
      />
    </div>
  );
};

export default NotificationButton;
