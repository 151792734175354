import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { calculateStripeFee, useGetPaymentMethods } from "@api/payment/paymentMethod";
import { confirmTopUp, topUpDeposit } from "@api/user/userDeposit";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as yup from "yup";

import { useAppSelector } from "@app/hooks";

import { handleApiError } from "@features/config";

import Button from "@components/Button";
import FormInput from "@components/FormInput";
import LineSeparateText from "@components/Order/LineSeparateText";

import { SERVICE_CHARGE } from "@pages/jobs-management/constants";

import { getBrandIcon } from "@utils/format/stripe";

import { useSnackbar } from "@contexts/SnackbarContext";
// import UserInfo from "@components/UserInfo";
import { useViewport } from "@contexts/ViewportContext";

import queryClient from "@config/queryClient";

const formStyles = {
  label: {
    fontSize: 16,
    fontWeight: 400,
    color: "#718096",
    width: 180,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
};

interface IUserDetailDialog {
  open: boolean;
  onClose: () => void;
}

const TopUpDialog: FC<IUserDetailDialog> = ({ open, onClose }) => {
  const { isDesktop } = useViewport();

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          m: 1,
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
        <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
          Top up
        </Typography>
        <CloseIcon
          sx={{
            width: 24,
            height: 24,
            color: "common.black",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </DialogTitle>

      <Elements
        stripe={stripePromise}
        options={
          {
            mode: "payment",
            amount: 1,
            currency: "usd",
            appearance: {
              theme: "stripe",
            },
          } as StripeElementsOptions
        }
      >
        <DialogContent
          sx={{
            p: isDesktop ? 2 : 1,
          }}
        >
          <TopUpForm onClose={onClose} />
        </DialogContent>
      </Elements>
    </Dialog>
  );
};

export default TopUpDialog;

const formSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError("Amount must be a number")
    .required("Please fill in this field")
    .min(1, "Minimum amount is 1 US Dollars"),
});

let timeoutInput: string | number | NodeJS.Timeout;

const TopUpForm = ({ onClose }: { onClose: () => void }) => {
  const {
    palette: { common },
  } = useTheme();
  const [loading, setLoading] = useState(false);
  const user = useAppSelector((state) => state.auth.userInfo.user);
  const stripe = useStripe();
  const { setSnackbar } = useSnackbar();
  const { data: cards, refetch } = useGetPaymentMethods();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: 0,
      paymentMethod: "",
    },
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });
  const selectedCard = watch("paymentMethod");
  const amountWatch = watch("amount");

  const {
    data,
    refetch: refetchStripeFee,
    isLoading,
  } = useQuery(
    ["getStripeFee"],
    () => calculateStripeFee(selectedCard, Math.abs(+amountWatch)),
    {
      enabled: !!selectedCard,
    }
  );
  const stripeFee = isLoading ? 0 : data?.stripeFee || 0;

  // const debounce = (func: any, timeout = 300) => {
  //   return (...args: any) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(this, args);
  //     }, timeout);
  //   };
  // };

  useEffect(() => {
    if (!!selectedCard) {
      refetchStripeFee();
    }
  }, [selectedCard]);

  useEffect(() => {
    if (cards?.data?.length) {
      setValue("paymentMethod", cards.data[0]?.id);
    }
  }, [cards]);
  const { mutate: topUp, isLoading: loadingTopUp } = useMutation({
    mutationFn: topUpDeposit,
    onSuccess: async (res) => {
      if ("paymentIntent" in res) {
        try {
          const { paymentIntent } = res;
          const { client_secret } = paymentIntent;

          const { error, paymentIntent: paymentIntentResult } =
            await stripe.confirmCardPayment(client_secret, {
              payment_method: selectedCard,
            });

          if (error) {
            setSnackbar({
              message: error?.message || "Topup failed",
              open: true,
              severity: "error",
            });
          } else if (paymentIntentResult && paymentIntentResult.status === "succeeded") {
            const { success } = await confirmTopUp({
              paymentIntentId: paymentIntent.id,
            });
            if (success) {
              setSnackbar({
                message: "Topup successful",
                open: true,
                severity: "success",
              });
              onClose();
              queryClient.invalidateQueries(["getDeposit"]);
            }
          }
        } catch (error) {
          handleApiError(error);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const submitTopUp = async (data: { amount: number; paymentMethod: string }) => {
    const { amount, paymentMethod } = data;

    if (!paymentMethod) {
      setSnackbar({
        message: "Please select a payment method",
        open: true,
        severity: "error",
      });
      return;
    }

    setLoading(true);
    await topUp({
      amount,
      customerId: user?.stripeCustomerId,
      paymentMethod: selectedCard,
    });
  };

  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          border: common.border,
          borderRadius: 4,
          justifyContent: "space-between",
          gap: 2,
          p: 5,
          flexDirection: "column",
        }}
      >
        <Stack sx={formStyles.row}>
          <Typography sx={formStyles.label}>Top-up value</Typography>
          <TextField
            fullWidth
            type="number"
            name="amount"
            value={amountWatch}
            error={!!errors?.amount?.message}
            helperText={errors?.amount?.message}
            onChange={(event: any) => {
              let inputValue = event.target.value;
              if (/^0[0-9]+/.test(inputValue)) {
                inputValue = inputValue.replace(/^0+/, "");
              }
              const decimalPattern = /^\d+(\.\d{0,2})?$/;

              if (decimalPattern.test(inputValue) || inputValue === "") {
                setValue("amount", inputValue);
                if (timeoutInput) {
                  clearTimeout(timeoutInput);
                }

                timeoutInput = setTimeout(async () => {
                  refetchStripeFee();
                }, 1000);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#718096",
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <LineSeparateText
          startText="Transaction fee:"
          endText={`$${stripeFee.toFixed(2)}`}
        />
        <LineSeparateText
          startText="You will pay:"
          endText={`$${(+amountWatch + +stripeFee).toFixed(2)}`}
        />
        <Stack sx={formStyles.row}>
          <Typography sx={formStyles.label}>Top-up from</Typography>
          <Controller
            name={"paymentMethod"}
            control={control}
            render={({ field }) => (
              <Select {...field} fullWidth>
                {cards?.data?.map((card, i) => (
                  <MenuItem key={card.id} value={card.id}>
                    <Stack
                      gap={2.5}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Avatar
                        variant="square"
                        src={getBrandIcon(card.card?.brand)}
                        alt={card.card?.brand}
                        sx={{ width: 36, height: "max-content" }}
                      >
                        {card.card?.brand}
                      </Avatar>
                      <Stack gap={0}>
                        <Typography
                          fontWeight={"bold"}
                        >{`•••• •••• •••• ${card.card?.last4}`}</Typography>
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Stack>
      </Box>
      <Box display="flex" flex={1} columnGap={1} justifyContent="end">
        <Button
          fullWidth
          variant="outlined"
          disabled={loadingTopUp || loading}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          loading={loadingTopUp || loading}
          onClick={handleSubmit(submitTopUp)}
          disabled={loadingTopUp || loading}
        >
          Top-up
        </Button>
      </Box>
    </Stack>
  );
};
