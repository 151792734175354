import { FC } from "react";
import {
  AiOutlineUser,
  AiOutlineCarryOut,
  AiOutlineSetting,
  AiOutlineChrome,
} from "react-icons/ai";

import { EventLogStatusLabel } from "@constants/index";
import { EVENT_HEADING_TITLE } from "@constants/proposal";
import { Box, Stack, Typography } from "@mui/material";

import { EventLog } from "@dto/event-log";
import { ProposalStatus } from "@dto/proposal";

interface ITitleProps {
  log: EventLog;
  action?: string;
}
const CommonTitle: FC<ITitleProps> = ({ log, action }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "start",
    }}
  >
    <Box sx={{ textAlign: "left" }}>
      <Typography component="span" sx={{ fontSize: 12, fontWeight: 700 }}>
        {`${log.createdByUser?.name ? "@" : ""}${log.createdByUser?.name}`}
      </Typography>{" "}
      <Typography
        textTransform="lowercase"
        component="span"
        sx={{ fontSize: 12, fontWeight: 400 }}
      >
        {action}
      </Typography>
    </Box>
  </Box>
);

const RequestJoinTitle: FC<ITitleProps> = ({ log }) => (
  <Typography sx={{ fontSize: 12 }}>
    Roundtable{" "}
    <Typography component="span" textTransform="lowercase" sx={{ fontSize: 12 }}>
      is created by{" "}
    </Typography>
    <Typography component="span" sx={{ fontSize: 12, fontWeight: 700 }}>
      @{log.createdByUser?.name}
    </Typography>
  </Typography>
);

type LogContent = {
  heading: string;
  title: JSX.Element | string;
  bgColor: string;
  color: string;
  icon: JSX.Element;
  action?: string;
};

const iconStyle = { width: 20, height: 20 };
const mappingLog = (
  log: EventLog,
  isRoundtable?: boolean
): Record<string, LogContent> => {
  const isDone = log.proposal?.status === "COMPLETED";

  return {
    APPLY_TO_JOIN: {
      heading: "Member Application",
      title: (
        <CommonTitle
          log={log}
          action={`wants to join ${isRoundtable ? "Teamspace" : "project"}`}
        />
      ),
      bgColor: "#e3f2fd",
      color: "#0d47a1",
      icon: <AiOutlineUser style={iconStyle} />,
    },
    APPLY_TO_JOIN_EXTERNAL: {
      heading: "External - Member Application",
      title: (
        <CommonTitle
          log={log}
          action={`wants to join ${isRoundtable ? "Teamspace" : "project"}`}
        />
      ),
      bgColor: "#e3f2fd",
      color: "#0d47a1",
      icon: <AiOutlineUser style={iconStyle} />,
    },
    REQUEST_VOTE_WEIGHT: {
      heading: "Position updated",
      title: <CommonTitle log={log} action="wants to start a vote" />,
      bgColor: "#8C8C8C",
      color: "#060606",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT: {
      heading: "Setting updated",
      title: (
        <Typography
          sx={{ fontSize: 12, fontWeight: 400, width: "100%", textAlign: "left" }}
        >
          {log.title}
        </Typography>
      ),
      bgColor: "#8C8C8C",
      color: "#060606",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    APPROVE_SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT: {
      heading: "Position Updated",
      title: (
        <Typography
          sx={{ fontSize: 12, fontWeight: 400, width: "100%", textAlign: "left" }}
        >
          {log.title}
        </Typography>
      ),
      bgColor: "#8C8C8C",
      color: "#060606",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    UPDATE_SETTINGS: {
      heading: "Setting updated",
      title: <CommonTitle log={log} action="starts a proposal for updating setting" />,
      bgColor: "#feebcb",
      color: "#dd6b20",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    UPDATE_ROUND_TABLE_PROFILE: {
      heading: "Teamspace profile",
      title: (
        <CommonTitle
          log={log}
          action="starts a proposal for updating Teamspace profile"
        />
      ),
      bgColor: "#feebcb",
      color: "#dd6b20",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    UPDATE_ROUND_TABLE_MANAGEMENT: {
      heading: "Teamspace management",
      title: (
        <CommonTitle
          log={log}
          action="starts a proposal for updating Teamspace management"
        />
      ),
      bgColor: "#feebcb",
      color: "#dd6b20",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    UPDATE_PROJECT_MANAGEMENT: {
      heading: "Project management",
      title: (
        <CommonTitle
          log={log}
          action="starts a proposal for updating project management"
        />
      ),
      bgColor: "#feebcb",
      color: "#d53f8c",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    UPDATE_PROJECT_PROFILE: {
      heading: "Project profile",
      title: (
        <CommonTitle log={log} action="starts a proposal for updating project profile" />
      ),
      bgColor: "#feebcb",
      color: "#805ad5",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    CREATE_ROUND_TABLE: {
      heading: "New Teamspace Created",
      title: <RequestJoinTitle log={log} />,
      action: "wants to join Teamspace",
      bgColor: "#fff5f7",
      color: "#d53f8c",
      icon: <AiOutlineChrome style={iconStyle} />,
    },
    POLL: {
      heading: "New Vote",
      title: isDone ? (
        <Typography
          sx={{ fontSize: 12, fontWeight: 400, width: "100%", textAlign: "left" }}
        >
          The vote ends. Click to view the result!
        </Typography>
      ) : (
        <CommonTitle log={log} action="wants to start a vote" />
      ),
      bgColor: "#e8f5e9",
      color: "#1b5e20",
      icon: <AiOutlineCarryOut style={iconStyle} />,
    },
    BECOME_SUB_ROUND_TABLE: {
      heading: "Become Sub-Teamspace",
      // title: <CommonTitle log={log} action="wants to join Teamspace" />,
      title: (
        <Typography
          textTransform={"initial"}
          sx={{
            fontSize: 12,
            fontWeight: 400,
            width: "100%",
            textAlign: "left",
            "::first-letter": { textTransform: "uppercase" },
          }}
        >
          {log.title}
        </Typography>
      ),
      bgColor: "#faf5ff",
      color: "#805ad5",
      icon: <AiOutlineChrome style={iconStyle} />,
    },
    UPDATE_REPRESENTATIVES: {
      heading: "Update Sub-Teamspace",
      title: <CommonTitle log={log} action="wants to update sub-project" />,
      bgColor: "#faf5ff",
      color: "#805ad5",
      icon: <AiOutlineChrome style={iconStyle} />,
    },
    CREATE_PROPOSAL_PROJECT: {
      heading: "New Project",
      title: <CommonTitle log={log} action="wants to start a project" />,
      bgColor: "#e6fffa",
      color: "#319795",
      icon: <AiOutlineChrome style={iconStyle} />,
    },
    APPROVE_SUB_ROUND_TABLE: {
      heading: "Approve Sub-Teamspace",
      // title: <CommonTitle log={log} action="wants to join Teamspace" />,
      title: (
        <Typography
          textTransform={"initial"}
          sx={{
            fontSize: 12,
            fontWeight: 400,
            width: "100%",
            textAlign: "left",
            "::first-letter": { textTransform: "uppercase" },
          }}
        >
          {log.title}
        </Typography>
      ),
      bgColor: "#edfdfd",
      color: "#00b5d8",
      icon: <AiOutlineChrome style={iconStyle} />,
    },
    APPROVED_SUB_ROUND_TABLE: {
      heading: "Sub-Teamspace Application",
      title: (
        <Stack>
          <Typography
            align="left"
            component="span"
            sx={{ fontSize: 12, fontWeight: 700 }}
          >
            {log.title}
          </Typography>
        </Stack>
      ),
      bgColor: "#edfdfd",
      color: "#00b5d8",
      icon: <AiOutlineChrome style={iconStyle} />,
    },
    DELETE_ROUND_TABLE: {
      heading: `${isRoundtable ? "Delete Teamspace" : "Close project"}`,
      title: (
        <CommonTitle
          log={log}
          action={`starts a proposal for ${
            isRoundtable ? "delete Teamspace" : "close project"
          }`}
        />
      ),
      bgColor: "#000",
      color: "#fff",
      icon: <AiOutlineCarryOut style={iconStyle} />,
    },
    CLOSE_PROJECT: {
      heading: "Close project",
      title: <CommonTitle log={log} action={"starts a proposal for close project"} />,
      bgColor: "#000",
      color: "#fff",
      icon: <AiOutlineCarryOut style={iconStyle} />,
    },

    BECOME_FORMAL_MEMBER: {
      heading: "Become Formal Member",
      title: <CommonTitle log={log} action="wants to become a formal member" />,
      bgColor: "#A0AEC0",
      color: "#000",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    SUB_ROUND_TABLE_BECOME_FORMAL_MEMBER: {
      heading: "Become Formal Member",
      title: (
        <Typography
          sx={{ fontSize: 12, fontWeight: 400, width: "100%", textAlign: "left" }}
        >
          {log.title}
        </Typography>
      ),
      bgColor: "#A0AEC0",
      color: "#000",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    PUBLISH_PROJECT: {
      heading: "Publish Project",
      title: (
        <Typography
          sx={{ fontSize: 12, fontWeight: 400, width: "100%", textAlign: "left" }}
        >
          {log.proposal.title}
        </Typography>
      ),
      bgColor: "#e6fffa",
      color: "#319795",
      icon: <AiOutlineSetting style={iconStyle} />,
    },
    EXIT_PARENT_ROUNDTABLE: {
      heading: "Exit Parent-Teamspace",
      title: (
        <Typography
          sx={{ fontSize: 12, fontWeight: 400, width: "100%", textAlign: "left" }}
        >
          {log.title}
        </Typography>
      ),
      bgColor: "#faf5ff",
      color: "#805ad5",
      icon: <AiOutlineChrome style={iconStyle} />,
    },
    EVENT: {
      heading: EVENT_HEADING_TITLE.EVENT,
      title: <CommonTitle log={log} action="wants to create an event" />,
      bgColor: "#C6F6D5",
      color: "#38A169",
      icon: <></>,
    },
    KICKOUT_MEMBER: {
      heading: "Kickout member",
      title: <CommonTitle log={log} action="wants to create an event" />,
      bgColor: "#C6F6D5",
      color: "#38A169",
      icon: <></>,
    },
  };
};

const formatLogs = (logs: EventLog[] = [], isRoundtable?: boolean) => {
  return logs.map((log) => {
    const mappedType = mappingLog(log, isRoundtable)[log.type];
    const status = EventLogStatusLabel[log.proposal?.status as ProposalStatus];
    const { title, heading, icon, action, bgColor, color } = mappedType || {};

    return {
      ...log,
      status,
      title,
      heading,
      icon,
      action,
      bgColor,
      color,
    };
  });
};

export default formatLogs;
