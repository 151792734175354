import { axios } from "@features/config";

type ReviewOrderRequest = {
  star: number;
  review: string;
  orderId: string;
};

export const writeReviewOrder = async (payload: ReviewOrderRequest): Promise<boolean> => {
  const { star, review, orderId } = payload;
  const res = await axios.post(`/orders/${orderId}/review`, {
    star,
    review,
  });
  return res?.data?.data;
};
