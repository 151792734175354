import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

interface IRequest {
  roundtableId: string;
}

const leaveRoundtable = async ({ roundtableId }: IRequest) => {
  return await axios.delete(`/round-table/${roundtableId}/leave`);
};

const useLeaveRoundtable = () => {
  return useMutation(leaveRoundtable);
};

export default useLeaveRoundtable;
