
import { FC } from "react";
import { Stack } from "@mui/material";

import UpdateMyPositionComponent from "@components/Dialog/UpdatePositionDialog";

import { Roundtable } from "@dto/roundtable";
import { useSnackbar } from "@contexts/SnackbarContext";

interface IProps {
  roundtable: Roundtable;
  onClose: () => void;
}

const UpdatePosition: FC<IProps> = ({
  roundtable,
  onClose,
}) => {
  const { setSnackbar } = useSnackbar();

  return (
    <Stack>
      <UpdateMyPositionComponent
        id={roundtable.id}
        roundtable={roundtable}
        onClose={onClose}
        setSnackbar={setSnackbar}
        removeDialog
      />
    </Stack>
  )
}

export default UpdatePosition;
