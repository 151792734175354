import { MESSAGE_COLlECTION } from "@fb/const";
import { db } from "@fb/db";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import { MessageType } from "./type";

type NewMessage = {
  message: MessageType;
  conversationId: string;
  userId: string;
};

export async function addNewMessage(newMessage: NewMessage) {
  try {
    const docRef = await addDoc(collection(db, MESSAGE_COLlECTION), {
      ...newMessage.message,
      conversationId: newMessage.conversationId,
      userId: newMessage.userId,
      date: serverTimestamp(),
    });

    return docRef;
  } catch (e) {
    console.error("Error adding message: ", e);
  }
}
