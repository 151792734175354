export enum VOTE_DELEGATION_TYPE {
  FULL = "FULL",
  CUSTOMIZE = "CUSTOMIZE"
}

export enum PROPOSAL_DELEGATION_TYPE {
  MEMBER_APPLICATION = "MEMBER_APPLICATION",
  POSITION_UPDATED = "POSITION_UPDATED",
  UPDATE_SETTINGS = "SETTING_UPDATED",
  UPDATE_ROUND_TABLE_MANAGEMENT = "UPDATE_ROUND_TABLE_MANAGEMENT",
  UPDATE_ROUND_TABLE_PROFILE = "UPDATE_ROUND_TABLE_PROFILE",
  PROJECT_MANAGEMENT = "UPDATE_PROJECT_MANAGEMENT",
  PROJECT_PROFILE = "UPDATE_PROJECT_PROFILE",
  POLL = "NEW_VOTE",
  BECOME_SUB_ROUND_TABLE = "BECOME_SUB_ROUNDTABLE",
  APPROVE_SUB_ROUND_TABLE = "SUB_ROUNDTABLE_APPLICATION",
  DELETE_ROUND_TABLE = "ROUNDTABLE_DELETED",
  CREATE_PROPOSAL_PROJECT = "PROJECT_CREATED",
  UPDATE_REPRESENTATIVES = "UPDATE_SUB_ROUNDTABLE",
  PUBLISH_PROJECT = "PROJECT_PUBLISHED",
  CLOSE_PROJECT = "CLOSE_PROJECT",
  EXIT_PARENT_ROUNDTABLE = "EXIT_PARENT_ROUNDTABLE",
  EVENT = "EVENT_PROPOSAL"
}

export type Delegatee = {
  avatar: string;
  name: string;
  title?: string;
  type: string;
};

export type Delegation = {
  id: string;
  delegatee: Delegatee;
  delegator: string;
  proposalType: PROPOSAL_DELEGATION_TYPE;
  voteWeight: string;
  createdAt: string;
};

export type RemainingVoteWeight = {
  currentVoteWeight: string;
  shareableVoteWeight: string;
  id: string;
  proposalType: string;
};
