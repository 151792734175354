import { Dispatch, SetStateAction } from "react";
import { UseFormReturn } from "react-hook-form";

import { Grid, MenuItem } from "@mui/material";

import {
  BecomeFormalMemberOptions,
  RequestType,
  UpdateObject,
  UpdatePositionForm,
} from "@components/Dialog/UpdatePositionDialog/index";
import FormInput from "@components/FormInput";

import { convertBetween0And1ToPercentage } from "@utils/numberHelper";

import { Attendee, ManagementEnum, Roundtable } from "@dto/roundtable";

type SelfContributionProps = {
  roundtable: Roundtable;
  currentAttendee: Attendee;
  updateObject: UpdateObject;
  form: UseFormReturn<UpdatePositionForm, any, undefined>;
  setIsDisabledSubmitButton: Dispatch<SetStateAction<boolean>>;
};

const SelfContribution = ({
  roundtable,
  form,
  currentAttendee,
  setIsDisabledSubmitButton,
}: SelfContributionProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = form;

  const watchedType = watch("type");
  const watchedWorkingHourWeekly = watch("workingHourWeekly");
  const watchedCompensationSeeking = watch("oneTimeCompensation");
  const watchedHourlyRate = watch("hourlyRate");
  const watchedMessage = watch("message");
  const watchedFormalMember = watch("isSubmittable");
  const watchedVoteWeight = watch("voteWeight");

  const isRequestVoteWeight = watchedType === RequestType.REQUEST_VOTE_WEIGHT;
  const isTraditional = roundtable.managementStyle === ManagementEnum.TRADITIONAL;

  setIsDisabledSubmitButton(
    +watchedWorkingHourWeekly === +currentAttendee?.workingHourWeekly &&
      +watchedCompensationSeeking === +currentAttendee?.oneTimeCompensation &&
      +watchedHourlyRate === +currentAttendee?.hourlyRate &&
      (isRequestVoteWeight
        ? +watchedVoteWeight ===
          convertBetween0And1ToPercentage(currentAttendee?.voteWeight)
        : currentAttendee?.isSubmittable ===
          (watchedFormalMember === "YES" ? true : false))
  );

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} md={6}>
        <FormInput
          control={control}
          name="fundContribution"
          label="Fund Contribution ($)"
          size="small"
          type="number"
        />
      </Grid> */}

      <Grid item xs={12} md={9}>
        <FormInput
          control={control}
          name="workingHourWeekly"
          label="Working Hour Weekly (hrs)"
          size="small"
          type="number"
          sx={{ background: 'white' }}
        />
      </Grid>
      {!isTraditional && (
        <Grid item xs={12} md={9}>
          {isRequestVoteWeight ? (
            <FormInput
              required
              control={control}
              name="voteWeight"
              label="Vote Weight Seeking (%)"
              size="small"
              type="number"
              sx={{ background: 'white' }}
              error={!!errors?.voteWeight?.message}
              helperText={errors?.voteWeight?.message}
            />
          ) : (
            <FormInput
              select
              control={control}
              size="small"
              name="isSubmittable"
              label="Become a Formal Member"
              sx={{ background: 'white' }}
              defaultValue={
                currentAttendee?.isSubmittable
                  ? BecomeFormalMemberOptions.YES
                  : BecomeFormalMemberOptions.NO
              }
              disabled={currentAttendee?.isSubmittable}
            >
              <MenuItem value={BecomeFormalMemberOptions.NO}>No</MenuItem>
              <MenuItem value={BecomeFormalMemberOptions.YES}>Yes</MenuItem>
            </FormInput>
          )}
        </Grid>
      )}

      {/* <Grid item xs={12} md={6}>
        <FormInput
          control={control}
          name="percentageProfit"
          label="Profit Share Seeking (%)"
          size="small"
          type="number"
        />
      </Grid> */}

      <Grid item xs={12} md={9}>
        <FormInput
          control={control}
          name="oneTimeCompensation"
          label="One-Time Compensation Seeking ($)"
          size="small"
          type="number"
          sx={{ background: 'white' }}
        />
      </Grid>

      <Grid item xs={12} md={9}>
        <FormInput
          control={control}
          name="hourlyRate"
          label="Hourly Rate ($/hour)"
          size="small"
          type="number"
          sx={{ background: 'white' }}
        />
      </Grid>

      <Grid item xs={12} md={9}>
        <FormInput
          multiline
          maxRows={5}
          minRows={5}
          control={control}
          name="message"
          label="Message"
          sx={{ background: 'white' }}
        />
      </Grid>
    </Grid>
  );
};

export default SelfContribution;
