import { useCallback, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { PROPOSAL_DELEGATION_TYPE } from "@dto/delegations";
import { ProposalType } from "@dto/proposal";
import { User } from "@dto/user";

interface CurrentVoteWeightModel {
  id: string;
  proposalType: keyof typeof PROPOSAL_DELEGATION_TYPE;
  currentVoteWeight: string;
  shareableVoteWeight: string;
}

interface ICurrentVoteWeightRequest {
  roundtableId: string;
}

const getCurrentVoteWeight = async ({
  roundtableId
}: ICurrentVoteWeightRequest): Promise<CurrentVoteWeightModel[]> => {
  const response = await axios.get(`/round-tables/${roundtableId}/current-vote-weight`);
  return response.data.data;
};

const useGetCurrentVoteWeight = ({ roundtableId }: ICurrentVoteWeightRequest) => {
  const [params, setParams] = useState({ roundtableId });

  const { data, refetch, ...rest } = useQuery({
    queryKey: ["getCurrentVoteWeight", roundtableId],
    queryFn: () => getCurrentVoteWeight({ roundtableId }),
    cacheTime: 0
  });

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  const handleChangeParams = useCallback((params: ICurrentVoteWeightRequest) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  return { data, refetch, handleChangeParams, ...rest };
};

export default useGetCurrentVoteWeight;
