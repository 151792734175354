import { PROPOSAL_DELEGATION_TYPE } from "@dto/delegations";
import { ProposalType } from "@dto/proposal";

/**
 * Format proposal types. Eg:
 * BECOME_SUB_ROUNDTABLE --> Become Sub Teamspace
 * CLOSE_PROJECT --> Close Project
 * EVENT_PROPOSAL --> Event Proposal
 * UPDATE_SUB_ROUNDTABLE --> Update Sub Teamspace
 */
export const formatProposalName = (proposalType: string) => {
  const arr = proposalType.toLowerCase().split("_");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
};

//For delegation
export const mappingProposalType = (type: ProposalType) => {
  switch (type) {
    case ProposalType.APPLY_TO_JOIN:
    case ProposalType.APPLY_TO_JOIN_EXTERNAL:
      return PROPOSAL_DELEGATION_TYPE.MEMBER_APPLICATION;

    case ProposalType.REQUEST_VOTE_WEIGHT:
    case ProposalType.SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT:
    case ProposalType.APPROVE_SUB_ROUND_TABLE_REQUEST_VOTE_WEIGHT:
    case ProposalType.BECOME_FORMAL_MEMBER:
    case ProposalType.SUB_ROUND_TABLE_BECOME_FORMAL_MEMBER:
      return PROPOSAL_DELEGATION_TYPE.POSITION_UPDATED;

    case ProposalType.UPDATE_SETTINGS:
      return PROPOSAL_DELEGATION_TYPE.UPDATE_SETTINGS;
    case ProposalType.UPDATE_ROUND_TABLE_MANAGEMENT:
      return PROPOSAL_DELEGATION_TYPE.UPDATE_ROUND_TABLE_MANAGEMENT;
    case ProposalType.UPDATE_ROUND_TABLE_PROFILE:
      return PROPOSAL_DELEGATION_TYPE.UPDATE_ROUND_TABLE_PROFILE;

    case ProposalType.POLL:
      return PROPOSAL_DELEGATION_TYPE.POLL;

    case ProposalType.BECOME_SUB_ROUND_TABLE:
      return PROPOSAL_DELEGATION_TYPE.BECOME_SUB_ROUND_TABLE;

    case ProposalType.APPROVE_SUB_ROUND_TABLE:
      return PROPOSAL_DELEGATION_TYPE.APPROVE_SUB_ROUND_TABLE;

    case ProposalType.DELETE_ROUND_TABLE:
      return PROPOSAL_DELEGATION_TYPE.DELETE_ROUND_TABLE;

    case ProposalType.CREATE_PROPOSAL_PROJECT:
      return PROPOSAL_DELEGATION_TYPE.CREATE_PROPOSAL_PROJECT;

    case ProposalType.UPDATE_REPRESENTATIVES:
      return PROPOSAL_DELEGATION_TYPE.UPDATE_REPRESENTATIVES;

    case ProposalType.PUBLISH_PROJECT:
      return PROPOSAL_DELEGATION_TYPE.PUBLISH_PROJECT;

    case ProposalType.CLOSE_PROJECT:
      return PROPOSAL_DELEGATION_TYPE.CLOSE_PROJECT;

    case ProposalType.EXIT_PARENT_ROUNDTABLE:
      return PROPOSAL_DELEGATION_TYPE.EXIT_PARENT_ROUNDTABLE;

    case ProposalType.EVENT:
      return PROPOSAL_DELEGATION_TYPE.EVENT;

    case ProposalType.PROJECT_PROFILE:
      return PROPOSAL_DELEGATION_TYPE.PROJECT_PROFILE;

    case ProposalType.PROJECT_MANAGEMENT:
      return PROPOSAL_DELEGATION_TYPE.PROJECT_MANAGEMENT;
  }
};
