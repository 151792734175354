import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { GetJobByProjectDto } from "@dto/job";

interface SkillResponse {
  name: string;
  id: number;
}
// TODO: sync this interface and Type GetJobByProjectDto
export interface JobDetailResponse {
  attachment: {
    link: string;
    name: string;
  }[];
  budget: number;
  createdAt: string;
  createdBy: string;
  createdByUser: {
    name: string;
    id: string;
    avatar: any;
  };
  deliverables: any;
  description: string;
  id: string;
  isPublic: boolean;
  jobSkills: {
    skill: SkillResponse;
  }[];
  project: { title: string; id: string };
  projectId: string;
  regional: any;
  title: string;
  type: any;
}

const getJobDetail = async (id: string, params: any): Promise<JobDetailResponse> => {
  const response = await axios.get(`job/${id}`, { params });
  return response?.data?.data;
};

const getQuestDraft = async (id: string): Promise<GetJobByProjectDto> => {
  const response = await axios.get(`/job/${id}/draft`);
  return response?.data?.data;
};

const useGetJobDetail = (params?: any) => {
  const { jobId } = useParams();

  const { data, refetch, ...rest } = useQuery(
    ["getJobDetail", jobId, params],
    () => getJobDetail(jobId, params),
    { enabled: !!jobId }
  );

  return {
    data,
    refetch,
    ...rest,
  };
};

export const useGetQuestDraft = (jobId: string) => {
  const { data, refetch, ...rest } = useQuery(
    ["getQuestDraft", jobId],
    () => getQuestDraft(jobId),
    { enabled: !!jobId }
  );

  return {
    data,
    refetch,
    ...rest,
  };
};

export default useGetJobDetail;
