import React, { createContext, useState } from "react";

// Create the context
const DeliveryContext = createContext({ selectedDelivery: "", setDelivery: null });

// Context Provider component
const DeliveryProvider = ({ children }: any) => {
  const [selectedDelivery, setDelivery] = useState("");

  return (
    <DeliveryContext.Provider value={{ selectedDelivery, setDelivery }}>
      {children}
    </DeliveryContext.Provider>
  );
};

export { DeliveryContext, DeliveryProvider };
