import { Dispatch, FC, SetStateAction, useMemo } from "react";

import { NOT_APPLICABLE, RoundTableAttendeeEnum } from "@constants/index";
import { ToolOptionType } from "@constants/roundtable";
import SettingIcon from "@mui/icons-material/SettingsOutlined";
import { Avatar, Box, Link, Stack, Typography, useTheme } from "@mui/material";

import { useAppSelector, useAppDispatch } from "@app/hooks";

import { sidebarActions } from "@features/sidebar/slice";

import EllipsisContent from "@components/EllipsisContent";
import MenuList, { IMenuItem } from "@components/MenuList";

import { getAbbreviation } from "@utils/index";
import { isProject } from "@utils/roundtable.utils";

import { ManagementEnum, Roundtable, VoteModeEnum } from "@dto/roundtable";

import { useViewport } from "@contexts/ViewportContext";

import MemberToolBtn from "./MemberToolManagement/MemberToolButton";
import Notification from "./NotificationButton";
import ProjectViewContentBtnGroup from "./ProjectViewContentBtn";
import RightBarToggleBtn from "./RightBar/ToggleButton";

interface IProps {
  roundtable: Roundtable;
  setDialogToggle?: Dispatch<SetStateAction<Partial<Record<ToolOptionType, boolean>>>>;
  userNotification?: boolean;
}

const Header: FC<IProps> = ({ roundtable, setDialogToggle, userNotification }) => {
  const {
    palette: { common },
  } = useTheme();
  const { isMobile } = useViewport();
  const isRoundtable = !isProject(roundtable);

  const { userInfo } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const currentUser = roundtable.attendees.find(
    (attendee) => attendee.userId === userInfo?.user.id
  );
  const freelancer = currentUser.isFreelancer;

  const isRTRepresentativeOnly = useMemo(() => {
    if (!userInfo?.user) {
      return false;
    }
    const isRTMember = roundtable?.alreadyJoined;
    return (
      !isRTMember &&
      roundtable?.attendees
        ?.filter((attendee) => attendee?.type === RoundTableAttendeeEnum.SUB_ROUNDTABLE)
        ?.some((attendee) =>
          attendee?.subRoundTable?.subRoundTableRepresentatives?.some(
            (atd) => atd.userId === userInfo?.user?.id
          )
        )
    );
  }, [roundtable?.id]);

  const menuItems: IMenuItem[] = [
    {
      text: isRoundtable ? "View Teamspace Details" : "View Project Details",
      onClick: () => setDialogToggle({ DETAIL: true }),
    },
    // {
    //   text: isRoundtable ? "Update Settings" : "Update Project Management",
    //   onClick: () => setDialogToggle({ SETTING: true }),
    // },
    // { text: "New Vote", onClick: () => setDialogToggle({ POLL: true }) },
    // // member management tool
    // { text: "Invite Member", onClick: () => setDialogToggle({ INVITATION: true }) },
    // !isRoundtable
    //   ? {
    //       text: "Update Project Management",
    //       onClick: () => setDialogToggle({ SETTING: true }),
    //     }
    //   : null,
    // isRoundtable
    //   ? {
    //       text: "Update Teamspace Management",
    //       onClick: () => setDialogToggle({ ROUNDTABLE_MANAGEMENT: true }),
    //     }
    //   : null,
    // { text: "Update My Position", onClick: () => setDialogToggle({ POSITION: true }) },
    // roundtable.voteMode !== VoteModeEnum.SIMPLE_VOTE
    // ? {
    //     text: "Vote Delegation",
    //     onClick: () => setDialogToggle({ VOTE_DELEGATE: true }),
    //   }
    // : null,
    // isRoundtable
    // ? { text: "Leave Teamspace", onClick: () => setDialogToggle({ LEAVE: true }) }
    // : null,
    // !isRoundtable
    //   ? { text: "Leave Project", onClick: () => setDialogToggle({ LEAVE_PROJECT: true }) }
    //   : null,
    // // end member management tool
    isRoundtable
      ? {
          text: "Update Teamspace Profile",
          onClick: () => setDialogToggle({ ROUNDTABLE_PROFILE: true }),
        }
      : null,
    !isRoundtable
      ? !freelancer && {
          text: "Update Project Profile",
          onClick: () => setDialogToggle({ PROJECT_PROFILE: true }),
        }
      : null,
    isRoundtable
      ? {
          text: "Create Project",
          onClick: () => setDialogToggle({ PROJECT: true }),
        }
      : null,
    roundtable.parent && {
      text: "Update Sub-Teamspace",
      onClick: () => setDialogToggle({ SUB_PROJECT_UPDATE: true }),
    },
    // : {
    //     text: "Become a Sub-Teamspace",
    //     onClick: () => setDialogToggle({ SUB_ROUNDTABLE_BECOMING: true }),
    //   },
    // {
    //   text: "Event proposal",
    //   onClick: () => setDialogToggle({ EVENT_PROPOSAL: true }),
    // },
    // !isRoundtable
    //   ? {
    //       text: "Publish/edit published project",
    //       onClick: () => setDialogToggle({ PROJECT_PUBLISHING: true }),
    //     }
    //   : null,
    // {
    //   text: isRoundtable ? "Delete Teamspace" : "Close Project",
    //   onClick: () => setDialogToggle({ DELETE: true }),
    //   sx: { color: "common.partyTime" },
    // },
    roundtable.parent && {
      text: "Exit Parent-Roundtable",
      onClick: () => setDialogToggle({ EXIT: true }),
    },
    !isRTRepresentativeOnly && {
      text: "Member management",
      onClick: () => dispatch(sidebarActions.setMemberToolMngOpen({ open: true })),
    },
    isRoundtable
      ? {
          text: "Delete Teamspace",
          onClick: () => setDialogToggle({ DELETE: true }),
          sx: { color: "common.partyTime" },
        }
      : null,
  ].filter((item) => item !== null && item !== undefined);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2.5,
        py: isMobile ? 2 : 3,
        borderBottom: common.border,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!isMobile && (
          <Box>
            <Avatar
              src={roundtable?.avatar}
              alt="roundtable-avatar"
              variant={!isRoundtable ? "rounded" : "circular"}
              sx={{ width: 48, height: 48 }}
            >
              {!roundtable.avatar && getAbbreviation(roundtable.title)}
            </Avatar>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: 1,
          }}
        >
          {/* <Typography
            sx={{
              color: "common.partyTime",
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            <Link
              component="button"
              onClick={() => setDialogToggle({ DETAIL: true })}
              sx={{ textDecoration: "none" }}
            >
              {roundtable?.title || NOT_APPLICABLE}
            </Link>
          </Typography> */}
          <EllipsisContent
            onClick={() => setDialogToggle({ DETAIL: true })}
            color={"common.partyTime"}
            fontSize={16}
            lineHeight={"inherit"}
            fontWeight={700}
            numberOfLines={1}
            sx={{ mt: 0, wordBreak: "break-word" }}
            content={roundtable?.title || NOT_APPLICABLE}
          />
          {!isMobile && (
            <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
              {roundtable?.attendees?.length} members
            </Typography>
          )}
        </Box>
      </Box>

      <Stack direction={"row"} alignItems={"center"} spacing={isMobile ? 1 : 2}>
        {!isRoundtable && !isMobile && (
          <ProjectViewContentBtnGroup projectId={roundtable.id} />
        )}
        {isRoundtable && (
          <Notification
            roundtableId={roundtable.id}
            userNotification={userNotification}
          />
        )}
        {/* {!isRTRepresentativeOnly && <MemberToolBtn />} */}
        {!isRTRepresentativeOnly && (
          <MenuList
            // label={!isMobile ? (isRoundtable ? "Teamspace Tool" : "Project Tool") : null}
            endIcon={<SettingIcon fontSize="small" />}
            menuItems={menuItems}
          />
        )}
        {!isMobile && <RightBarToggleBtn />}
      </Stack>
    </Box>
  );
};

export default Header;
