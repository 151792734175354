import { useMemo, useState } from "react";

import { DATE_FORMAT } from "@constants/datetime";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar, // AvatarGroup,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";

import { useAppSelector } from "@app/hooks";

// import ProjectDetailsDialog from "@components/Dialog/ProjectDetailsDialog";
import RoundtableDetailsDialog from "@components/Dialog/RoundtableDetailsDialog";
import ProjectItemOptions from "@components/ProjectItem/ProjectItemOptions";
import ReadMoreText from "@components/ReadMoreText.tsx";

import { ProjectColorMappingByType } from "@utils/projects";

import { Roundtable } from "@dto/roundtable";

import { RoundtableType } from "@@types/type";

interface IProjectItemProps {
  data: Roundtable;
}

const ProjectItem: React.FC<IProjectItemProps> = (props) => {
  const { data } = props;
  const { userInfo } = useAppSelector((state) => state.auth);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const hasCurrentUserJoinedTheProject = useMemo(() => {
    return !!data.attendees.filter((attendee) => attendee.userId === userInfo.user.id)
      .length;
  }, [userInfo.user.id]);

  const renderProjectType = () => {
    const color = ProjectColorMappingByType[data.type as RoundtableType];

    return (
      <Chip
        label={data.type
          ?.toLowerCase()
          ?.replace("_self", "")
          ?.replace("self", "internal")}
        variant="outlined"
        sx={{
          color: color,
          borderColor: color,
          fontSize: "10px",
          fontWeight: 600,
          width: "80px",
          height: "20px",
          textTransform: "capitalize",
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        // border: 1,
        // borderColor: "common.bellflowerBlue",
        borderRadius: 1,
        padding: 2,
        // mb: 1,
        position: "relative",
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: 0.5, top: 0 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <ProjectItemOptions
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        projectId={data.id}
        handleCloseThreeDotMenu={() => setAnchorEl(null)}
        handleOpenDialog={() => setIsOpenDialog(true)}
        hasCurrentUserJoinedTheProject={hasCurrentUserJoinedTheProject}
      />

      <Stack direction="row" alignItems="center" mb={1}>
        <Avatar
          src={data.avatar}
          alt="roundtable-avatar"
          variant="rounded"
          sx={{ width: 40, height: 40, mr: 1, cursor: "pointer" }}
          onClick={() => setIsOpenDialog(true)}
        />
        <Button onClick={() => setIsOpenDialog(true)}>
          <Typography sx={{ fontWeight: 600, color: "primary.main", fontSize: 14 }}>
            {data.title}
          </Typography>
        </Button>
      </Stack>
      <Grid container alignItems="center" mb={1.2}>
        <Grid item xs={12} sx={{ borderRight: "none" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              sx={{ fontSize: "10px", fontWeight: 600, opacity: 0.64, width: 80 }}
            >
              Type
            </Typography>
            {renderProjectType()}
          </Box>
        </Grid>
        {/* <Grid item xs={6} sx={{ display: "flex", gap: 2, paddingLeft: 2 }}>
          <Typography sx={{ fontSize: "10px", fontWeight: 600, opacity: 0.64 }}>
            Fund
          </Typography>
          <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
            {data.fund || 0} USD
          </Typography>
        </Grid> */}
      </Grid>
      <Grid container alignItems="center" mb={1.2}>
        <Grid item xs={12} sx={{ borderRight: "none" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              sx={{ fontSize: "10px", fontWeight: 600, opacity: 0.64, width: 80 }}
            >
              Due date
            </Typography>
            <Typography sx={{ fontSize: "10px", fontWeight: 400 }}>
              {data.createdAt ? dayjs(data.createdAt).format(DATE_FORMAT) : "-/-"}
            </Typography>
          </Box>
        </Grid>
        {/* <Grid
          item
          xs={6}
          sx={{ display: "flex", gap: 1, paddingLeft: 2, alignItems: "center" }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: 600, opacity: 0.64 }}>
            Member list
          </Typography>
          <AvatarGroup
            max={4}
            sx={{
              "& .MuiAvatarGroup-avatar": {
                width: "20px",
                height: "20px",
                fontSize: "10px",
                borderColor: "common.bellflowerBlue",
                borderWidth: "1px",
              },
              "& .MuiAvatarGroup-avatar:first-of-type": {
                backgroundColor: "primary.main",
                zIndex: 3,
              },
            }}
          >
            {data.attendees?.map((item, index) => (
              <Avatar
                key={index}
                alt="attendee avatar"
                src={item.avatar}
                sx={{ zIndex: index < 3 ? index : 0 }}
              />
            ))}
          </AvatarGroup>
        </Grid> */}
      </Grid>
      <Grid container direction={"column"} gap={0.5}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "10px", fontWeight: 600, opacity: 0.64 }}>
            Description
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ReadMoreText text={data.description} />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, borderColor: "common.bellflowerBlue" }} />
      {/* <ProjectDetailsDialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        projectId={data.id}
      /> */}
      <RoundtableDetailsDialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        roundtableId={data?.id}
        displayAsProject
      />
    </Box>
  );
};

export default ProjectItem;
