import { forwardRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiFileText } from "react-icons/fi";

import { ListItemNode, ListNode } from "@lexical/list";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { FileCopy } from "@mui/icons-material";
import { Avatar, Box, CircularProgress, Stack, Typography, styled } from "@mui/material";
import {
  BeautifulMentionsPlugin,
  BeautifulMentionsMenuItemProps,
  createBeautifulMentionNode,
  BeautifulMentionComponentProps,
  ZeroWidthPlugin,
  ZeroWidthNode,
} from "lexical-beautiful-mentions";

import EllipsisContent from "@components/EllipsisContent";

import editorTheme from "./RTBTheme";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import HtmlPlugin from "./plugins/HtmlPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import "./styleEditor.css";

const CustomMentionComponent = forwardRef<
  HTMLDivElement,
  BeautifulMentionComponentProps<any>
>(({ trigger, value }, ref) => {
  return (
    <div
      style={{
        color: "#3182CE",
        width: "fit-content",
        padding: "2px 4px",
        borderRadius: 4,
        display: "inline-block",
      }}
      ref={ref}
      title={trigger + value}
    >
      <Typography fontWeight={500}>@{value}</Typography>
    </div>
  );
});
CustomMentionComponent.displayName = "CustomMentionComponent";

const editorConfig: any = {
  // The editor theme
  theme: editorTheme,
  onError(error: any) {
    throw error;
  },
  nodes: [
    ListNode,
    // MentionNode,
    ListItemNode,
    // BeautifulMentionNode,
    ZeroWidthNode,
    ...createBeautifulMentionNode(CustomMentionComponent),
  ],
};

type PropsType = {
  onChange: (value: string) => void;
  mentionData: { id: string; value: string }[];
  loading?: boolean;
  selectedFile: File[];
  setSelectedFile: (file: File[]) => void;
  handleSubmit: (text?: string) => Promise<void>;
};

const ChatEditor = (
  {
    onChange,
    mentionData,
    loading = false,
    selectedFile,
    setSelectedFile,
    handleSubmit,
  }: PropsType,
  ref: any
) => {
  function Placeholder() {
    return <div className="editor-placeholder">Type your messages...</div>;
  }

  const renderAttachments = (item: any, index: number) => {
    if (item.type.startsWith("image/")) {
      return (
        <Image
          key={index}
          height={50}
          sx={{
            objectFit: "cover",
            borderRadius: 1,
          }}
          alt="preview"
          src={URL.createObjectURL(item)}
        />
      );
      // } else if (item.type.startsWith("video/")) {
      //   return (
      //     <video width="50" height="50" controls>
      //       <track kind="captions" />
      //       <source src={item.url} type="video/mp4" />
      //       Your browser does not support the video tag.
      //     </video>
      //   );
    } else {
      return (
        <>
          <Box
            borderRadius="100%"
            p={1}
            bgcolor="primary.main"
            width={40}
            height={40}
            color="white"
          >
            <FiFileText size={24} />
          </Box>
          <EllipsisContent
            fontSize={16}
            lineHeight={1.5}
            fontWeight={"normal"}
            numberOfLines={1}
            sx={{ mt: 0, wordBreak: "break-word", userSelect: "none" }}
            content={item.name}
          />
        </>
      );
    }
  };

  return (
    <LexicalComposer initialConfig={{ ...editorConfig }}>
      <Box
        display={"block"}
        className="editor-box-area"
        flexDirection={"column"}
        flex={1}
        border={"1px solid #E2E8F0"}
        sx={{
          borderRadius: "8px",
        }}
      >
        <div className="editor-inner">
          <BeautifulMentionsPlugin
            items={{
              "@": mentionData,
            }}
            menuComponent={CustomMenu}
            menuItemComponent={CustomMenuItem}
          />
          <ZeroWidthPlugin />
          <ListPlugin />
          <EmojiPickerPlugin />
          <HistoryPlugin />
          <ClearEditorPlugin />
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HtmlPlugin
            {...{ handleSubmit, selectedFile }}
            onHtmlChanged={(html) => onChange(html)}
          />
        </div>
        {selectedFile.length ? (
          <FileContainer gap={1} flexDirection="row" alignItems={"flex-start"}>
            {selectedFile?.map?.((item: any, index: number) => {
              const showImage =
                item.type.startsWith("image/") || item.type.startsWith("video/");
              return (
                <FileItem key={index} isImage={showImage}>
                  {renderAttachments(item, index)}
                  {loading ? (
                    <Stack alignItems={"center"}>
                      <CircularProgress size={20} />
                    </Stack>
                  ) : (
                    <CloseButton
                      onClick={() =>
                        setSelectedFile(
                          selectedFile?.filter((_: any, i: number) => i !== index)
                        )
                      }
                    />
                  )}
                </FileItem>
              );
            })}
          </FileContainer>
        ) : null}

        <ToolbarPlugin {...{ selectedFile, setSelectedFile }} ref={ref} />
      </Box>
    </LexicalComposer>
  );
};

export default forwardRef(ChatEditor);

const CustomMenu = ({ ...props }) => (
  <MenuMention id={"mention-menu-display"} {...props} />
);
CustomMenu.displayName = "CustomMenu";

const CustomMenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(
  ({ item, ...props }, ref) => {
    const { avatar, label } = props;
    return (
      <MenuMentionItem {...props} ref={ref}>
        <Avatar src={avatar} sx={{ width: 24, height: 24 }} />
        <Typography fontWeight={500}>{label}</Typography>
      </MenuMentionItem>
    );
  }
);
CustomMenuItem.displayName = "CustomMenuItem";

const MenuMention = styled("ul")`
  position: absolute;
  margin: 0;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.375rem;
  gap: 8;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  padding: 0.25rem;
  color: #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
`;

const MenuMentionItem = styled("li")<{ selected?: boolean }>(({ theme, selected }) => ({
  position: "relative",
  display: "flex",
  cursor: "pointer",
  userSelect: "none",
  alignItems: "center",
  borderRadius: "0.125rem",
  gap: 6,
  padding: "0.375rem 0.5rem",
  fontSize: "0.875rem" /* equivalent to text-sm */,
  outline: "none",
  ...(selected && {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  }),
}));

const FileContainer = styled(Stack)`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  padding: ${({ theme }) => theme.spacing(2, 2, 0)};
`;

const CloseButton = styled(AiOutlineClose)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.common.white,
  padding: "2px",
  backgroundColor: "rgba(0, 0, 0, 0.64)",
  borderRadius: "50%",
  position: "absolute",
  top: -5,
  right: -5,
}));

const FileItem = styled(Stack)<{ isImage?: boolean }>(({ theme, isImage }) => ({
  display: "flex",
  gap: "10px",
  maxWidth: "200px",
  height: "50px",
  position: "relative",
  border: theme.palette.common.border,
  flexDirection: "row",
  padding: theme.spacing(isImage ? 0 : 1),
  borderRadius: "4px",
  alignItems: "center",
}));

const Image = styled("img")`
  max-width: 60px;
`;
