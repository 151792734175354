import React, { useEffect, useState } from "react";

import { getDeliveryHistory } from "@api/job/getDeliveryHistory";
import { reviewDelivery } from "@api/job/reviewDelivery";
import { Delivery_Status } from "@api/job/type";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import Button from "@components/Button";
import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";

import { useSelectJob } from "@pages/jobs-management/SelectJobContext";
import { JOB_STATUS } from "@pages/jobs-management/constants";

import { humanFileSize } from "@utils/format/fileSize";
import { getMiddleEllipsisText } from "@utils/helpers/stringHelper";
import { getFileNameUrlS3 } from "@utils/helpers/urlHelper";

import { ReactComponent as FileAttachment } from "@assets/icons/file_attachment.svg";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

type DeliveryListProps = {
  isFreelancer: boolean;
  cb?: () => void;
};
const DeliveryList = ({ isFreelancer, cb }: DeliveryListProps) => {
  const { jobSelected } = useSelectJob();
  const assigneeInfor = jobSelected?.orders?.[0];
  const {
    palette: { common },
  } = useTheme();
  const { setSnackbar } = useSnackbar();
  const [reviewType, setReviewType] = useState<Delivery_Status | null>(null);
  const [reviewData, setReviewData] = useState({
    note: "",
    deliveryId: "",
  });
  const { mutate: review, isLoading } = useMutation({
    mutationFn: reviewDelivery,
    onSuccess: ({ success }) => {
      if (success) {
        setSnackbar({
          message: "Your proposal was created successfully!",
          open: true,
          severity: "success",
        });
        queryClient.invalidateQueries(["getJobsByProject", jobSelected.projectId]);
        queryClient.invalidateQueries(["getDeliveryHistory"]);
        cb && cb();
      }
    },
  });
  const { jobId, freelancerId } = assigneeInfor || {};
  const {
    data: deliveries,
    isFetching: loading,
    refetch,
  } = useQuery({
    queryKey: ["getDeliveryHistory"],
    queryFn: () =>
      getDeliveryHistory({
        jobId,
        userId: freelancerId,
      }),
  });

  useEffect(() => {
    refetch();
  }, [jobId, refetch]);

  if (loading) {
    return (
      <Stack alignItems={"center"}>
        <CircularProgress />
      </Stack>
    );
  }
  const onReviewDelivery = () => {
    review({
      status: reviewType,
      deliveryId: reviewData.deliveryId,
      note: reviewData.note,
      jobId: jobSelected.id,
    });
  };

  const groupByDate = (arr: any) => {
    arr.sort(
      (a: any, b: any) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
    );

    return arr.reduce((acc: any, item: any) => {
      const date = dayjs(item.createdAt).format("MM/DD/YYYY HH:mm A");
      if (!acc[date]) {
        acc[date] = {};
      }
      acc[date] = item;

      return acc;
    }, {});
  };

  const groupedItems = groupByDate(deliveries?.data || []);

  return (
    <Stack flex={1} height={"100%"} p={2} gap={1} overflow={"auto"}>
      {Object.entries(groupedItems).map(([key, item]: any) => {
        const files = item?.attachment;
        const isWaitingReview = !isFreelancer && item.status === Delivery_Status.PENDING;
        return (
          <Box key={key}>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={1}
            >
              <Typography color={common.heirloomHydrangea}>{key}</Typography>
              <Stack height={"1px"} flex={1} bgcolor={common.heirloomHydrangea} />
            </Stack>
            <Stack
              mb={1.5}
              borderRadius={2}
              p={1.5}
              bgcolor={isWaitingReview ? "#3182CE1A" : "#0000000A"}
              gap={1}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                  <Avatar
                    src={item["freelancer.avatar"]}
                    sx={{ width: 20, height: 20 }}
                  />
                  <Typography>
                    {`${item["freelancer.name"]} ${
                      isWaitingReview ? " submitted a new delivery" : ""
                    }`}
                  </Typography>
                </Stack>
                {item.status !== Delivery_Status.PENDING && (
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    color={item.status === Delivery_Status.REJECTED ? "error" : "green"}
                  >
                    {item.status === Delivery_Status.REJECTED ? "Rejected" : "Accepted"}
                  </Typography>
                )}
              </Stack>
              {files.map((file: { link: string; contentLength: any }, i: React.Key) => {
                const fileName = getFileNameUrlS3(file?.link || "");
                return (
                  <Stack
                    key={i}
                    {...(isWaitingReview && { my: 1.5, p: 1, bgcolor: "white" })}
                    borderRadius={2}
                  >
                    <Stack
                      height={90}
                      bgcolor={isWaitingReview ? "#0000000A" : "white"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={1}
                    >
                      <FileAttachment />
                    </Stack>
                    <Stack
                      flexDirection={"row"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography>{getMiddleEllipsisText(fileName, 18)}</Typography>
                      <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
                        <Typography noWrap>
                          {humanFileSize(file?.contentLength || 0)}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            window.open(file?.link, "_blank");
                          }}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}

              {isWaitingReview && (
                <Stack flexDirection={"row"} flex={1} gap={2}>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      bgcolor: common.white,
                      border: "none",
                      color: common.gray5,
                      "&:hover": {
                        border: "none",
                      },
                    }}
                    onClick={() => {
                      setReviewType(Delivery_Status.REJECTED);
                      setReviewData((prev) => ({ ...prev, deliveryId: item.id }));
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    fullWidth
                    sx={{
                      bgcolor: common.heirloomHydrangea,
                      "&:hover": {
                        bgcolor: common.gray7,
                      },
                    }}
                    onClick={() => {
                      setReviewData((prev) => ({ ...prev, deliveryId: item.id }));
                      setReviewType(Delivery_Status.APPROVED);
                    }}
                  >
                    Accept
                  </Button>
                </Stack>
              )}
            </Stack>
          </Box>
        );
      })}

      {/* {Array.from(deliveries?.data || []).map((item: any, i) => {
        const files: any = item?.attachment;

        const isWaitingReview = !isFreelancer && item.status === Delivery_Status.PENDING;
        return (
          <Box
            key={i}
            bgcolor={isWaitingReview ? "#3182CE1A;" : "#0000000A"}
            borderRadius={1.5}
            p={1.5}
          >
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                <Avatar src={item["freelancer.avatar"]} sx={{ width: 12, height: 12 }} />
                <Typography>
                  {`${item["freelancer.name"]} ${
                    isWaitingReview ? " submitted a new delivery" : ""
                  }`}
                </Typography>
              </Stack>
              {item.status === Delivery_Status.REJECTED && (
                <Typography color={"error"}>Rejected</Typography>
              )}
            </Stack>
            {files.map((file: { link: string; contentLength: any }, i: React.Key) => {
              const fileName = getFileNameUrlS3(file?.link || "");
              return (
                <Stack key={i} my={1.5}>
                  <Stack
                    height={90}
                    bgcolor={"white"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={1}
                  >
                    <FileAttachment />
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography>{getMiddleEllipsisText(fileName, 18)}</Typography>
                    <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
                      <Typography>{humanFileSize(file?.contentLength || 0)}</Typography>
                      <IconButton
                        onClick={() => {
                          window.open(file?.link, "_blank");
                        }}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}

            {isWaitingReview && (
              <Stack flexDirection={"row"} flex={1} gap={2}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    bgcolor: common.white,
                    border: "none",
                    color: common.gray5,
                    "&:hover": {
                      border: "none",
                    },
                  }}
                  onClick={() => {
                    setReviewType(Delivery_Status.REJECTED);
                    setReviewData((prev) => ({ ...prev, deliveryId: item.id }));
                  }}
                >
                  Reject
                </Button>
                <Button
                  fullWidth
                  sx={{
                    bgcolor: common.heirloomHydrangea,
                    "&:hover": {
                      bgcolor: common.gray7,
                    },
                  }}
                  onClick={() => {
                    setReviewData((prev) => ({ ...prev, deliveryId: item.id }));
                    setReviewType(Delivery_Status.APPROVED);
                  }}
                >
                  Accept
                </Button>
              </Stack>
            )}
          </Box>
        );
      })} */}
      {reviewType && (
        <CustomConfirmationDialog
          title={
            reviewType === Delivery_Status.REJECTED
              ? "Are you sure to reject this delivery?"
              : "Are you sure to accept this delivery?"
          }
          buttons={[
            {
              variant: "outlined",
              label: "Review again",
              isLoading,
            },
            {
              label:
                reviewType === Delivery_Status.REJECTED ? "Reject it!" : "Yes, I like it",
              isLoading,
              handleOnClickButton: () => onReviewDelivery(),
            },
          ]}
          content={
            reviewType === Delivery_Status.REJECTED ? (
              <TextField
                fullWidth
                multiline
                maxRows={10}
                minRows={10}
                value={reviewData.note}
                onChange={(e) =>
                  setReviewData((prev) => ({ ...prev, note: e.target.value }))
                }
                placeholder="Note the reason to freelancer"
                size="small"
              />
            ) : (
              "By accepting this delivery, you agree to proceed with payment to the freelancer."
            )
          }
          openDialog={!!reviewType}
          setOpenDialog={() => setReviewType(null)}
        />
      )}
    </Stack>
  );
};
export default DeliveryList;
