import React, { useState } from "react";

import useVoteDelegations from "@api/delegations/useVoteDelegations";
import { DATE_WITH_SLASH } from "@constants/datetime";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Button,
  DialogActions,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";

import ConfirmRevokeDelegation from "@components/Dialog/VoteDelegateDialog/CurrentDelegation/ConfirmRevokeDelegation";

import { formatProposalName } from "@utils/format/proposalType";
import { convertBetween0And1ToPercentage } from "@utils/numberHelper";

const CurrentDelegation = ({ onClose }: { onClose: () => void }) => {
  const [selectedDelegation, setSelectedDelegation] = useState("");
  const { data, isLoading } = useVoteDelegations();

  return (
    <>
      <TableContainer sx={{ maxHeight: 442, mb: 4 }}>
        {!isLoading ? (
          <Table sx={{ "& td, & th": { border: 0 } }}>
            <TableHead
              sx={{
                "& th": {
                  color: (theme) => theme.palette.common.gray5,
                },
              }}
            >
              <TableRow>
                <TableCell>Delegatee</TableCell>
                <TableCell>Proposal type</TableCell>
                <TableCell>Vote weight delegated</TableCell>
                <TableCell>Time</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((delegation) => (
                <TableRow key={delegation.id} sx={{ "& td": { fontSize: 16 } }}>
                  <TableCell sx={{ fontWeight: 600 }}>
                    {delegation.delegatee.name}
                  </TableCell>
                  <TableCell>{formatProposalName(delegation.proposalType)}</TableCell>
                  <TableCell>
                    {convertBetween0And1ToPercentage(delegation.voteWeight)}%
                  </TableCell>
                  <TableCell>
                    {dayjs(delegation.createdAt).format(DATE_WITH_SLASH)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => setSelectedDelegation(delegation.id)}>
                      <UndoIcon width={24} height={24} sx={{ color: "common.gray5" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Skeleton variant="rectangular" width="100%" height={118} />
        )}
      </TableContainer>
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled>
          Delegate
        </Button>
      </Stack>
      {selectedDelegation && (
        <ConfirmRevokeDelegation
          onClose={() => setSelectedDelegation("")}
          id={selectedDelegation}
        />
      )}
    </>
  );
};

export default CurrentDelegation;
