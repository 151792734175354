/* eslint-disable no-await-in-loop */
import sendMissedActivity from "@api/job/sendMissedActivity";
import { CONVERSATION_COLlECTION, UNREAD_REALTIME, USER_COLlECTION } from "@fb/const";
import { db, realtimeDb } from "@fb/db";
import { get, ref } from "firebase/database";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import { useAppSelector } from "@app/hooks";

import { Missed_Message_Type } from "@pages/chat/Conversation/type";

import { User } from "@dto/user";

export const markInactiveUserUnreadMessage = async ({
  userId,
  conversationId,
  sendData,
}: {
  userId: string;
  conversationId: string;
  sendData?: any;
}) => {
  try {
    const unreadUser = await get(ref(realtimeDb, `${UNREAD_REALTIME}/${userId}`));

    const unreadRoundTableIds = await getDoc(doc(db, USER_COLlECTION, userId)).then(
      (doc) => doc?.data()?.unreadRoundTableIds || []
    );
    const unreadData = unreadUser?.val() || {};
    // If user is not active in this conversation then set unread

    if (
      unreadData.active !== conversationId &&
      !unreadRoundTableIds.includes(conversationId)
    ) {
      // Send missed activity notification
      await sendMissedActivity(conversationId, {
        ...sendData,
        userId,
        type: Missed_Message_Type.MESSAGE,
      });

      // Update unread roundtable IDs for user
      await updateDoc(doc(db, USER_COLlECTION, userId), {
        unreadRoundTableIds: [...unreadRoundTableIds, conversationId],
      });
    }

    return { ...unreadData };
  } catch (error) {
    console.error(`Error checking status for userId ${userId}:`, error);
  }
};

export const useChatManagement = () => {
  const { userInfo } = useAppSelector((state) => state.auth);

  const doSendNotificationToUsers = async (
    conversationId: string,
    sendData?: unknown
  ) => {
    const activityDoc = await getDoc(doc(db, CONVERSATION_COLlECTION, conversationId));
    const activityData = activityDoc?.data();

    // Get list of participants from activity
    const partner = activityData?.participantsList.filter(
      (u: User) => u.id !== userInfo.user.id
    );

    for (const user of partner) {
      await markInactiveUserUnreadMessage({
        userId: user.id,
        conversationId,
        sendData,
      });
    }
  };

  return {
    doSendNotificationToUsers,
  };
};
