import { axios, handleApiError } from "@features/config";

import { JobDto } from "@dto/job";

type FreelancerWorkResponseType = {
  data?: JobDto[];
  success?: boolean;
};

type FreelancerWorkRequestType = {
  projectId: string;
};

export const getFreelancerWorkInProject = async ({
  projectId,
}: FreelancerWorkRequestType): Promise<FreelancerWorkResponseType> => {
  try {
    const response = await axios.get(`/project/${projectId}/jobs`);

    return { data: response?.data?.data, success: true };
  } catch (error) {
    return handleApiError(error);
  }
};
