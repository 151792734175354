import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

import { convertPercentageToBetween0And1 } from "@utils/numberHelper";

import { Proposal } from "@dto/proposal";

export type Member = {
  userId?: string;
  subRoundTableId?: string;
  voteWeight: number;
  percentageProfit: number;
};

type Request = {
  roundtableId: string;
  payload: {
    title: string;
    avatar: string;
    type: string;
    description: string;
    fund: number;
    memberList: Member[];
    requireApplication?: boolean;
  };
};

type Response = {
  success: boolean;
  data?: {
    code: number;
    message: string;
    data: Proposal;
  };
  message?: string;
};

const createProjectRoundtable = async ({
  roundtableId,
  payload,
}: Request): Promise<Response> => {
  const response = await axios.post(`/round-tables/${roundtableId}/project`, {
    ...payload,
    memberList: payload.memberList.map((member) => ({
      ...member,
      voteWeight: convertPercentageToBetween0And1(member.voteWeight),
    })),
  });

  return response.data.data;
};

const useCreateProjectRoundtable = () => {
  return useMutation(createProjectRoundtable);
};

export default useCreateProjectRoundtable;
