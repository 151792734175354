import { axios, handleApiError } from "@features/config";

type Request = {
  roundtableId: string;
  reason?: string;
  inviteFreelancer?: boolean;
};

type Response = {
  success: boolean;
  data?: { code: number; message: string };
  message?: string;
};

export const applyToJoin = async ({
  roundtableId,
  reason,
  inviteFreelancer,
}: Request): Promise<Response> => {
  try {
    const { data } = await axios.post(`/round-tables/${roundtableId}/apply`, {
      reason,
      inviteFreelancer,
    });
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};
