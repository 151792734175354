import { useCallback, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { User } from "@dto/user";

interface Request {
  userId: string;
}

const getUser = async ({ userId }: Request): Promise<User> => {
  const response = await axios.get(`/users/${userId}`);
  return response.data.data;
};

const useGetUser = ({ userId }: Request) => {
  const [params, setParams] = useState({ userId });

  const { data, isLoading, isSuccess, isError, refetch, ...rest } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => getUser({ userId }),
    enabled: !!userId
  });

  useEffect(() => {
    refetch();
  }, [params]);

  const handleChangeParams = useCallback((params: Request) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  return { data, isLoading, isSuccess, isError, refetch, handleChangeParams, ...rest };
};

export default useGetUser;
