import { FC, useCallback, useMemo } from "react";
import "react-chat-elements/dist/main.css";

import updateLastMessage from "@api/chat/updateLastMessage";
import useUpdateReadStatus from "@api/chat/updateReadStatus";
import useMentionUsers from "@api/chat/useMentionUsers";
import { Box } from "@mui/material";
import dayjs from "dayjs";

import { useChatManagement } from "@hooks/useChatManagement";

import { Attendee } from "@dto/roundtable";
import { User } from "@dto/user";

import MessageInput from "./MessageInput";
import MessageList from "./MessageList";
import { useMessages } from "./hooks";
import { addNewMessage } from "./service";
import { MessageType } from "./type";

interface IProps {
  conversationId: string;
  user: User;
  userForMentions?: Attendee[];
  onSubmitNewMessage?: (message: MessageType) => void;
  isRoundtableConversation?: boolean;
  representatives?: Attendee[];
}

const Conversation: FC<IProps> = ({
  conversationId,
  user,
  onSubmitNewMessage,
  userForMentions,
  isRoundtableConversation,
  representatives,
}) => {
  useUpdateReadStatus({
    user,
    participants: userForMentions,
    conversationId,
  });
  const [messages = [], loading] = useMessages(conversationId);
  const { mutate: mentionUsers } = useMentionUsers();
  const { doSendNotificationToUsers } = useChatManagement();
  const thisYearStart = dayjs().startOf("year");
  const todayStart = dayjs().startOf("day");
  const yesterDayStart = dayjs(todayStart).add(-1, "day");

  const handleAddNewMessage = useCallback(
    async ({
      text,
      imageUrls,
      fileUrls,
      mentionedUsers,
    }: {
      text: string;
      imageUrls: { name: string; url: string }[];
      fileUrls: { name: string; url: string }[];
      mentionedUsers: string[];
    }) => {
      if (!text && !imageUrls.length && !fileUrls.length) {
        return;
      }

      const newMessage = {
        text,
        title: user.name || user.username,
        avatar: user.avatar,
        imageUrls,
        fileUrls,
      } as MessageType;

      const messageDoc = await addNewMessage({
        message: newMessage,
        conversationId,
        userId: user.id,
      });

      if (messageDoc) {
        const formatMessage = `${text} </br>
              ${imageUrls
                ?.map(
                  (i) =>
                    `<i class="fa-regular fa-file" style="color: #3182ce" ></i><a href="${i.url}">Attachment</a>`
                )
                .join("</br>")}
                ${fileUrls
                  ?.map(
                    (i) =>
                      `<i class="fa-regular fa-file" style="color: #3182ce" ></i><a href="${i.url}">Attachment</a>`
                  )
                  .join("</br>")}`;
        await doSendNotificationToUsers(conversationId, {
          message: formatMessage,
          sender: {
            name: user.name,
            avatar: user.avatar,
          },
          sendingTime: dayjs(new Date()).format("HH:mm:ss DD MMM YYYY"),
        });
      }
      if (mentionedUsers?.length) {
        mentionUsers({
          roundtableId: conversationId,
          userIds: mentionedUsers,
        });
      }
      onSubmitNewMessage?.(newMessage);
      await updateLastMessage({ user, conversationId, isRoundtableConversation });
    },
    [
      conversationId,
      doSendNotificationToUsers,
      isRoundtableConversation,
      mentionUsers,
      onSubmitNewMessage,
      user,
    ]
  );

  const renderGroupDate = (date?: any) => {
    if (!date) {
      return "";
    }
    let dateFormat = "ddd, DD MMM, YYYY";
    const dateData = dayjs(typeof date === "number" ? date : date?.seconds * 1000);
    if (dateData.diff(thisYearStart, "second") >= 0) {
      dateFormat = "ddd, DD MMM";
    }
    if (dateData.diff(yesterDayStart, "second") >= 0) {
      dateFormat = "Yesterday";
    }
    if (dateData.diff(todayStart, "second") >= 0) {
      dateFormat = "Today";
    }

    return ["Yesterday", "Today"].includes(dateFormat)
      ? dateFormat
      : dayjs(dateData).format(dateFormat);
  };

  const dataMessage = useMemo(() => {
    return messages.map((message) => ({
      ...message,
      position: user.id === message.userId ? "right" : "left",
      representatives,
      groupDate: renderGroupDate(message.date),
    }));
  }, [messages, user.id]) as MessageType[];

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      flex={1}
      height={"100%"}
      id="chat-editor-area"
    >
      <Box flex={1} height={"100%"} className="test" overflow={"auto"}>
        <MessageList loading={loading} data={dataMessage} />
      </Box>
      <MessageInput onSubmit={handleAddNewMessage} userForMentions={userForMentions} />
    </Box>
  );
};

export default Conversation;
