import { axios } from "@features/config";

type Response = {
  data: any;
};

type Request = {
  roundtableId: string,
  delegationId: string,
}

const revokeVoteDelegate = async ({ roundtableId, delegationId }: Request): Promise<Response> => {
  const response = await axios.delete(`/round-tables/${roundtableId}/delegations/${delegationId}`);

  return response.data;
};

export default revokeVoteDelegate
