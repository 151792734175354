import useRemainingVoteWeight from "@api/delegations/useRemainingVoteWeight";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";

import { formatProposalName } from "@utils/format/proposalType";
import { convertBetween0And1ToPercentage } from "@utils/numberHelper";

const CurrentVoteWeight = ({ onClose }: { onClose: () => void }) => {
  const { data, isLoading } = useRemainingVoteWeight();

  return (
    <>
      <Box py={4}>
        <Typography fontSize={14} fontWeight={400} color="common.gray5">
          Including original vote weight and vote weight delegated to you.
        </Typography>

        {!isLoading ? (
          <Stack spacing={2} mt={3} sx={{ maxHeight: 442, overflowY: "scroll" }}>
            {data?.map((item) => (
              <Stack
                key={`current-vote-weight-${item.id}`}
                p={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  backgroundColor: (theme) => theme.palette.common.deutziaWhite,
                  border: (theme) => "1px solid " + theme.palette.common.bellflowerBlue,
                  borderRadius: 1,
                }}
              >
                <Typography color="common.gray5">
                  {formatProposalName(item.proposalType)}
                </Typography>
                <Typography color="common.partyTime">
                  {convertBetween0And1ToPercentage(item.currentVoteWeight)}%
                </Typography>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Skeleton variant="rectangular" width="100%" height={118} />
        )}
      </Box>
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled>
          Delegate
        </Button>
      </Stack>
    </>
  );
};

export default CurrentVoteWeight;
