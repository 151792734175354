import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { GetJobByProjectDto } from "@dto/job";

type Response = {
  totalItems: number;
  jobs: GetJobByProjectDto[];
};

const getJobsByProject = async (projectId: string, params?: any): Promise<Response> => {
  const res = await axios.get(`job-list/${projectId}`, params);
  return res?.data?.data;
};

const useGetJobsByProject = (projectId: string, params?: any) =>
  useQuery(["getJobsByProject", projectId], () => getJobsByProject(projectId, params));

export default useGetJobsByProject;
