import React from "react";

import { Box, Typography, styled } from "@mui/material";

import { SidebarLogo } from "@components/icon";

import { ReactComponent as GatherlanceLogo } from "@assets/images/gatherlance-landing/gatherlance-logo.svg";
import gatherlanceLogo from "@assets/images/gatherlance-landing/gatherlance_logo.png";

import { useViewport } from "@contexts/ViewportContext";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .title": {
    color: theme.palette.common.gray6,
    fontSize: 20,
    marginLeft: 12,
    fontWeight: 600,
  },
}));

const SidebarLogoDrawer: React.FC = () => {
  const { isMobile, isTablet, isDesktop } = useViewport();
  const isMobileDesign = isMobile || isTablet;
  return (
    <Container>
      <img src={"/logo.png"} alt="heading-logo" height={isMobileDesign ? 32 : 40} />
      {/* <GatherlanceLogo />
      <Typography className="title">Gatherlance</Typography> */}
    </Container>
  );
};

export default SidebarLogoDrawer;
