import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useCreateFirstNewProjectWithRoundtable from "@api/roundtable/useCreateFirstNewProjectWithRoundtable";
import useGetJoinedRoundtables from "@api/roundtable/useGetJoinedRoundtables";
import useGetSearchCriteria from "@api/roundtable/useGetSearchCriteria";
import { Category_List } from "@constants/category";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Switch,
  MenuItem,
  Chip,
} from "@mui/material";
import { ProtectedPaths } from "@routers/path/ProtectedPath";
import { PublicPaths } from "@routers/path/PublicPath";
import * as yup from "yup";

import { useCopyToClipboard } from "@hooks/useCopyToClipboard";

import { useAppSelector } from "@app/hooks";

import Button from "@components/Button";
import FormInput from "@components/FormInput";
import ImageUploader, { ImageUploaderType } from "@components/ImageUploader";
import LinkInput from "@components/LinkInput";
import Loading from "@components/Loading";
import MultiFileUploader from "@components/MultiFileUploader/MultiFileUploader";

import { capitalizeFirstChar, isValidEmail } from "@utils/helpers/stringHelper";
import { uploadImagesToServer } from "@utils/uploadImageToServer";

import RoundtableCreateProjectSuccess from "@assets/roundtable/PNG/Roundtables/Roundtable-Create-Project-Success.png";

import { ManagementEnum } from "@dto/roundtable";

import { RoundtableTypeEnum } from "@@types/type";

import { useSnackbar } from "@contexts/SnackbarContext";
import { useViewport } from "@contexts/ViewportContext";

import queryClient from "@config/queryClient";

import { Roundtable } from "../UpsertRoundtableDialog/RountableFormDialog";

const InputStyle = {
  backgroundColor: "white",
  "fieldset": { borderColor: "common.bellflowerBlue" },
};
enum VisibilityOption {
  PRIVATE,
  PUBLIC,
}
type Visibility = {
  value: VisibilityOption;
  text: string;
};

export type ProjectField = {
  id?: string;
  avatar?: any;
  roundTableTitle: string;
  projectTitle: string;
  visibility?: number;
  nonDisclosure?: boolean;
  requireApplication?: boolean;
  description?: string;
  category?: string;
  location?: string;
  language?: string;
  institution?: string;
  tags?: string[];
  invitees?: string[];
  documents?: any[];
  images?: any[];
  hideMember?: boolean;
  links: { url: string; displayText: string }[];
  videoLinks: { url: string; displayText: string }[];
};

const visibilities: Visibility[] = [
  {
    value: VisibilityOption.PUBLIC,
    text: "Public",
  },
  {
    value: VisibilityOption.PRIVATE,
    text: "Private",
  },
];

const defaultValues: ProjectField = {
  id: null,
  avatar: null,
  roundTableTitle: "",
  projectTitle: "",
  visibility: 0,
  nonDisclosure: false,
  requireApplication: false,
  description: "",
  location: "",
  language: "",
  institution: "",
  hideMember: false,
  category: "",
  tags: [],
  invitees: [],
  documents: [],
  images: [],
  links: [],
  videoLinks: [],
};

const DEFAULT_PROJECT_LOCATION = "Online";
const DEFAULT_PROJECT_LANGUAGE = "English";
const DefaultCategory = "Others";
const formSchema = yup.object().shape({
  avatar: yup.mixed().nullable(),
  roundTableTitle: yup.string().required("Team title is required."),
  projectTitle: yup.string().required("Project title is required."),
  visibility: yup.number(),
  nonDisclosure: yup.boolean(),
  requireApplication: yup.boolean(),
  description: yup.string(),
  category: yup.string(),
  tags: yup.array().of(yup.string()).nullable(),
  invitees: yup.array().of(yup.string().email("Invalid email address")).nullable(),
});

interface IPropsType {
  openTutorial: boolean;
  handleClose: () => void;
  defaultValues?: ProjectField;
  fixedCategory?: boolean;
}
const CreateProjectLandingDialog = (props: IPropsType) => {
  const { openTutorial, handleClose } = props;
  const [newPublishProjectPath, setPublishProjectPath] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [newChatPath, setChatPath] = useState("");
  const [step, setStep] = useState(0);
  const [focusInvitees, setFocusInvitees] = useState(false);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { isMobile } = useViewport();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const _clipboard = useCopyToClipboard();
  const { data: searchCriteria } = useGetSearchCriteria();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    clearErrors,
    setError,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      ...props.defaultValues,
    },
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });
  // const watchedrequireApplication = watch("requireApplication") || false;
  const watchedNonDisclosure = watch("nonDisclosure") || false;
  const hideMemberWatch = watch("hideMember") || false;
  const watchedTags = watch("tags") || [];
  const roundTableTitle = watch("roundTableTitle");

  const watchId = watch("id");
  const watchedAvatar = watch("avatar");
  const watchedInvitees = watch("invitees") || [];
  const watchedLinks = watch("links") || [];
  const watchedVideoLinks = watch("videoLinks") || [];
  const { data: roundtableList, isLoading } = useGetJoinedRoundtables({
    userId: userInfo?.user?.id,
    pageSize: 50,
    type: [RoundtableTypeEnum.TEAM],
    requireVoteWeight: true,
  });

  useEffect(() => {
    setStep(roundtableList.length ? 0 : 1);
  }, [roundtableList.length]);

  const handleUploadAvatar = useCallback(
    (file: File) => {
      setValue("avatar", file, {
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const handleCreate = async (data: ProjectField) => {
    setImageUploading(true);

    const [avatarResult, fileResults] = await Promise.all([
      data?.avatar ? uploadImagesToServer([data?.avatar]) : null,
      uploadImagesToServer([...getValues("documents"), ...getValues("images")]),
    ]);

    const files: string[] = [];
    if (fileResults?.length > 0) {
      files.push(...fileResults);
    }

    setImageUploading(false);

    const {
      id,
      roundTableTitle,
      projectTitle,
      visibility,
      links,
      invitees = [],
      videoLinks,
      nonDisclosure,
      requireApplication,
      category,
      location,
      language,
      institution,
      tags,
      hideMember,
      description,
    } = data;

    let payload: any = {
      avatar: avatarResult?.[0] || null,
      isPublic: Boolean(visibility),
      category: category || DefaultCategory,
      tags,
      description,
      links,
      videoLinks,
      institution,
      publishData: {
        information: hideMember ? [] : ["MEMBERS"],
      },
      nonDisclosure,
      requireApplication,
      files,
      location: location || DEFAULT_PROJECT_LOCATION,
      language: language || DEFAULT_PROJECT_LANGUAGE,
      projectTitle,
      managementStyle: ManagementEnum.TRADITIONAL,
      invitees,
    };

    if (!!id) {
      payload = {
        ...payload,
        roundTableId: id,
      };
    } else {
      payload = {
        ...payload,
        roundTableTitle,
      };
    }
    createProjectWithRoundtable(
      { payload },
      {
        onSuccess: (apiData) => {
          const projectUrl = `${ProtectedPaths.chat}/${apiData?.data?.roundTableId}/${apiData?.data?.projectId}`;
          let publishProjectUrl = `${PublicPaths.publishedProjects}/${apiData?.data?.projectShortId}`;
          if (payload.category === Category_List.ComputerProduct) {
            publishProjectUrl = `${PublicPaths.product}/${apiData?.data?.projectShortId}`;
          }
          queryClient.invalidateQueries(["getJoinedRoundtables"]);

          setStep(-1);
          setChatPath(projectUrl);
          setPublishProjectPath(publishProjectUrl);
        },
        onError: (error) => {
          setSnackbar({
            message: "Create project error.",
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  const handleKeyDownTag = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const input = e.target as HTMLInputElement;
      const value = input.value;
      value && setValue("tags", [...getValues("tags"), value]);
    }
  }, []);

  const onInputInvitees = useCallback((input: string) => {
    if (input) {
      setError("invitees", {
        type: "inviteesEnter",
        message: "Please enter after input new email.",
      });
    } else {
      trigger("invitees");
    }
  }, []);

  const handleKeyDownInvitees = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;
      if (e.key === "Enter") {
        const value = input.value;
        value &&
          setValue("invitees", [...getValues("invitees"), value], {
            shouldValidate: true,
          });
        input.value = "";
      }
    },
    []
  );

  const handleBack = () => {
    // const errStepfields: any = FieldsPerEachStep?.[step]
    if (step === 4) {
      clearErrors("invitees");
      setValue("invitees", [], { shouldValidate: false });
      // reset({ invitees: [] });
      trigger("invitees");
    }

    setStep(step - 1);
  };

  const handleDeleteInvitees = (index: number) => () => {
    const invitees = getValues("invitees").filter(
      (tag: string, i: number) => i !== index
    );

    const invalidInvitees = getValues("invitees").filter(
      (tag: string, i: number) => i !== index && !isValidEmail(tag)
    );

    if (!invitees.length || !invalidInvitees.length) {
      trigger("invitees");
    }

    setValue("invitees", invitees);
  };

  const handleClickDeleteLink = (indexItem: number, isVideoLinkMode?: boolean) => {
    if (isVideoLinkMode) {
      watchedVideoLinks.splice(indexItem, 1);
      setValue("videoLinks", watchedVideoLinks);
    } else {
      watchedLinks.splice(indexItem, 1);
      setValue("links", watchedLinks);
    }
  };

  const { mutate: createProjectWithRoundtable, isLoading: isLoadingCreateProject } =
    useCreateFirstNewProjectWithRoundtable();

  const disableChangeData = imageUploading || isLoadingCreateProject;

  const onUseExistedRountable = (selectTedRountable: Roundtable) => {
    setValue("avatar", selectTedRountable.avatar);
    setValue("id", selectTedRountable.id);
    setValue("roundTableTitle", selectTedRountable.title, {
      shouldValidate: true,
    });
    setValue("institution", selectTedRountable.institution);
    setValue("language", selectTedRountable.language);
    setValue("location", selectTedRountable.location);
  };

  const handleDeleteTag = (index: number) => {
    const filteredTags = getValues("tags").filter(
      (tag: string, i: number) => i !== index
    );

    setValue("tags", filteredTags);
  };

  const onClickCreateNewProject = () => {
    reset();
    setStep(1);
  };

  return (
    <Dialog
      open={openTutorial}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          // width: "100%",
          maxWidth: 800,
          m: 1,
          bgcolor: "#F7FAFC",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "flex-end", px: 2 }}>
        <CloseIcon
          sx={{
            width: 24,
            height: 24,
            color: "common.black",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {step >= 0 && (
              <>
                <Typography
                  color={"#1A202C"}
                  fontSize={30}
                  fontWeight={"bold"}
                  textAlign={"center"}
                  mb={0.8}
                >
                  {step === 0 ? "Create new team" : "Create new project"}
                </Typography>
                {step === 0 && (
                  <Typography color={"common.gray5"} fontSize={14} textAlign={"center"}>
                    Choose which team this new project belongs to
                  </Typography>
                )}
                {step === 5 && !watchId && (
                  <Typography color={"common.gray5"} fontSize={14} textAlign={"center"}>
                    Your new project will automatically generate a new Teamspace.
                  </Typography>
                )}
              </>
            )}

            <Stack
              width={{ xs: "100%", md: "100%", lg: 500 }}
              maxWidth={510}
              mt={5}
              mb={4}
              spacing={2}
            >
              {step < 0 && (
                <Stack spacing={2} alignItems={"center"}>
                  <Stack>
                    <Stack
                      component={"img"}
                      src={RoundtableCreateProjectSuccess}
                      sx={{ width: isMobile ? "100%" : 325, ml: "auto", mr: "auto" }}
                    />
                  </Stack>
                  <Typography
                    color={"#1A202C"}
                    fontSize={30}
                    fontWeight={"bold"}
                    textAlign={"center"}
                    mb={0.8}
                  >
                    Project created!
                  </Typography>
                  <Stack>
                    {!!watchId ? (
                      <Typography
                        color={"common.gray5"}
                        fontSize={14}
                        textAlign={"center"}
                      >
                        Your project is created and displayed on Teamspace Project.
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          color={"common.gray5"}
                          fontSize={14}
                          textAlign={"center"}
                        >
                          Your project has been created.
                        </Typography>
                        <Typography
                          color={"common.gray5"}
                          fontSize={14}
                          textAlign={"center"}
                        >
                          Go to Teamspace to complete set up and get it running!
                        </Typography>
                      </>
                    )}
                  </Stack>
                  <Stack height={4} />
                  <>
                    <Typography fontWeight={700} textAlign={"center"}>
                      Project link
                    </Typography>
                    <TextField
                      id="project-link"
                      value={
                        `${window.location.host}${newPublishProjectPath}`.substring(
                          0,
                          isMobile ? 22 : 30
                        ) + "..."
                      }
                      sx={{ ...InputStyle, width: !isMobile ? 355 : "100%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              cursor: "pointer",
                              height: "100%",
                              minHeight: 24,
                              minWidth: 24,
                            }}
                            onClick={() => {
                              _clipboard.copy(
                                `${window.location.host}${newPublishProjectPath}`,
                                {
                                  snackbar: {
                                    enable: true,
                                    message: "Copied!",
                                  },
                                }
                              );
                            }}
                          >
                            <ContentCopyIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      disabled
                    />
                  </>
                  <Stack height={8} />
                </Stack>
              )}

              {step === 0 && (
                <Stack textAlign={"center"}>
                  <Autocomplete
                    options={roundtableList}
                    getOptionLabel={(rtb) => rtb?.title || ""}
                    renderInput={(params) => {
                      return <TextField {...params} size="small" label="Team" />;
                    }}
                    defaultValue={roundtableList.find(
                      (rtb) => rtb.title === roundTableTitle
                    )}
                    disableClearable
                    onChange={(_, value) => onUseExistedRountable(value)}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid
                              item
                              sx={{
                                width: "calc(100% - 44px)",
                                wordWrap: "break-word",
                              }}
                            >
                              <Typography variant="body2" color="text.secondary">
                                {option.title}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                    sx={{ mt: 1 }}
                    noOptionsText="Team not found"
                  />
                  <Typography my={3} variant="body2" color={"common.gray4"}>
                    or
                  </Typography>
                  <Button
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={onClickCreateNewProject}
                    sx={{
                      width: "fit-content",
                      alignSelf: "center",
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    Create new team
                  </Button>
                </Stack>
              )}

              {step === 1 && (
                <>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    gap={2}
                  >
                    {!!watchId ? (
                      <Avatar
                        src={watchedAvatar}
                        sx={{
                          width: 120,
                          height: 120,
                        }}
                      />
                    ) : (
                      <ImageUploader
                        onSetValue={handleUploadAvatar}
                        type={ImageUploaderType.ROUNDED}
                        initAvatar={getValues("avatar")}
                      />
                    )}
                    <FormInput
                      required
                      control={control}
                      name="roundTableTitle"
                      label="Team Name"
                      // size="small"
                      error={!!errors?.roundTableTitle?.message}
                      helperText={errors?.roundTableTitle?.message}
                      // sx={{ backgroundColor: "white", "fieldset": { borderColor: "common.bellflowerBlue" } }}
                      sx={{ ...InputStyle, alignSelf: "center" }}
                      disabled={disableChangeData || !!watchId}
                    />
                  </Box>
                  <FormInput
                    required
                    control={control}
                    name="projectTitle"
                    label="Project Name"
                    error={!!errors?.projectTitle?.message}
                    helperText={errors?.projectTitle?.message}
                    sx={InputStyle}
                    disabled={disableChangeData}
                  />
                  <Box display={"flex"} flexWrap={"wrap"} gap={2}>
                    <Box flex="1 0 calc(50% - 16px)">
                      <FormInput
                        flex={""}
                        select
                        control={control}
                        name="visibility"
                        label="Visibility"
                        error={!!errors?.visibility?.message}
                        helperText={errors?.visibility?.message}
                        sx={InputStyle}
                        disabled={disableChangeData}
                      >
                        {visibilities.map(({ value, text }) => (
                          <MenuItem key={value} value={value}>
                            {text}
                          </MenuItem>
                        ))}
                      </FormInput>
                    </Box>
                    <Box flex="1 0 calc(50% - 16px)">
                      <FormInput
                        flex={""}
                        control={control}
                        name="institution"
                        label="Institution"
                        sx={InputStyle}
                        disabled={disableChangeData}
                      />
                    </Box>
                    <Box flex="1 0 calc(50% - 16px)">
                      <FormInput
                        flex={""}
                        control={control}
                        name="language"
                        label="Language"
                        sx={InputStyle}
                        disabled={disableChangeData}
                      />
                    </Box>
                    <Box flex="1 0 calc(50% - 16px)">
                      <FormInput
                        flex={""}
                        control={control}
                        name="location"
                        label="Location"
                        sx={InputStyle}
                        disabled={disableChangeData}
                      />
                    </Box>
                  </Box>

                  <Stack direction={"row"} flexWrap={"wrap"}>
                    <FormControlLabel
                      label="Non-disclosured"
                      control={
                        <Checkbox
                          checked={watchedNonDisclosure}
                          disabled={disableChangeData}
                          onChange={() =>
                            setValue("nonDisclosure", !watchedNonDisclosure)
                          }
                          sx={{ color: "common.bellflowerBlue" }}
                        />
                      }
                      labelPlacement="end"
                      sx={{
                        "& span.MuiCheckbox-root": { p: 0, mx: "9px" },
                        "& span.MuiTypography-root": { color: "common.gray5" },
                      }}
                    />
                    {/* <FormControlLabel
                      label="Application required"
                      control={
                        <Checkbox
                          checked={watchedrequireApplication}
                          disabled={disableChangeData}
                          onChange={() =>
                            setValue("requireApplication", !watchedrequireApplication)
                          }
                          sx={{ color: "common.bellflowerBlue" }}
                        />
                      }
                      labelPlacement="end"
                      sx={{
                        "& span.MuiCheckbox-root": { p: 0, mx: "9px" },
                        "& span.MuiTypography-root": { color: "common.gray5" },
                      }}
                    /> */}
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          ml: 0,
                        },
                        "& .MuiSwitch-root": {
                          position: "relative",
                          left: "-12px",
                        },
                      }}
                    >
                      <FormControlLabel
                        label=""
                        control={
                          <Switch
                            checked={hideMemberWatch}
                            onChange={() => setValue("hideMember", !hideMemberWatch)}
                          />
                        }
                        labelPlacement="start"
                        disabled={disableChangeData}
                      />
                    </FormGroup>

                    <Typography>Hide member</Typography>
                  </Stack>
                </>
              )}

              {step === 2 && (
                <>
                  <FormInput
                    select
                    control={control}
                    name="category"
                    label="Category"
                    type="string"
                    error={!!errors?.category?.message}
                    helperText={errors?.category?.message}
                    sx={InputStyle}
                    disabled={disableChangeData || props.fixedCategory}
                  >
                    {searchCriteria?.categories.map((category, index) => {
                      return (
                        <MenuItem
                          key={`${index}-${category.name}-category-option-from-proj-creation`}
                          value={category.name}
                        >
                          {capitalizeFirstChar(category.name ?? "")}
                          {/* {category.name ?? ""} */}
                        </MenuItem>
                      );
                    })}
                  </FormInput>
                  <Autocomplete
                    clearIcon={false}
                    options={[]}
                    freeSolo
                    multiple
                    limitTags={2}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={disableChangeData}
                        sx={InputStyle}
                        label="Tags"
                        onKeyDown={handleKeyDownTag}
                      />
                    )}
                    value={watchedTags ?? []}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          key={`${index}-${option}-chip-label`}
                          color="primary"
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          onDelete={(event: any) => handleDeleteTag(index)}
                        />
                      ))
                    }
                    disabled={disableChangeData}
                    sx={{
                      "& > .MuiFormControl-root .MuiInputBase-root": {
                        ".MuiChip-root": {
                          maxHeight: 24,
                        },
                      },
                    }}
                  />
                  <FormInput
                    multiline
                    maxRows={5}
                    minRows={5}
                    control={control}
                    name="description"
                    label="Description"
                    sx={InputStyle}
                    disabled={disableChangeData}
                  />
                </>
              )}

              {step === 3 && (
                <>
                  <Typography fontWeight={700} textAlign={"center"}>
                    {" "}
                    Project images
                  </Typography>
                  <MultiFileUploader
                    acceptedFileTypes="image"
                    sx={{ backgroundColor: "white" }}
                    suffixText="image"
                    onChangeFiles={(data) =>
                      data?.files && setValue("images", data.files)
                    }
                    initialFiles={getValues("images")}
                  />
                  <LinkInput
                    center
                    labelTitle="Youtube video links"
                    displayLinks={watchedVideoLinks}
                    handleClickDeleteLink={handleClickDeleteLink}
                    setSnackbar={setSnackbar}
                    isVideoLinkMode
                    handleVideoLink={(linkDetail) =>
                      setValue("videoLinks", [
                        ...getValues("videoLinks"),
                        {
                          url: linkDetail.url,
                          displayText: linkDetail.displayText || linkDetail.url,
                        },
                      ])
                    }
                  />
                </>
              )}

              {step === 4 && (
                <>
                  <LinkInput
                    center
                    labelTitle="Add Website (URL)"
                    displayLinks={watchedLinks}
                    handleClickDeleteLink={handleClickDeleteLink}
                    setSnackbar={setSnackbar}
                    handleLink={(linkDetail) =>
                      setValue("links", [
                        ...getValues("links"),
                        {
                          url: linkDetail.url,
                          displayText: linkDetail.displayText || linkDetail.url,
                        },
                      ])
                    }
                  />
                  <Typography fontWeight={700} textAlign={"center"}>
                    Add files attachment
                  </Typography>

                  <MultiFileUploader
                    acceptedFileTypes="document"
                    sx={{ backgroundColor: "white" }}
                    suffixText="file (PDF, DOC, DOCX)"
                    onChangeFiles={(data) =>
                      data?.files && setValue("documents", data.files)
                    }
                    initialFiles={getValues("documents")}
                  />
                </>
              )}
              {/** step 5 */}
              {step === 5 && (
                <>
                  <Typography fontWeight={700} textAlign={"center"}>
                    Invite team to join your Teamspace
                  </Typography>
                  <Stack p={2} bgcolor={"white"} borderRadius={1}>
                    <Autocomplete
                      clearIcon={false}
                      options={[]}
                      freeSolo
                      multiple
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled={disableChangeData}
                          sx={{
                            ...InputStyle,
                            "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                              width: focusInvitees ? "max-content" : 0,
                            },
                          }}
                          placeholder="Enter Email"
                          onChange={(e) => onInputInvitees(e.target.value)}
                          onKeyDown={handleKeyDownInvitees}
                          error={!!errors.invitees}
                          helperText={
                            errors.invitees?.message ||
                            (errors.invitees || []).filter((invitee) => invitee)?.[0]
                              ?.message
                          }
                          onFocus={() => setFocusInvitees(true)}
                          onBlur={() => setFocusInvitees(false)}
                        />
                      )}
                      value={watchedInvitees}
                      renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip
                            key={`${index}-${option}-chip-label`}
                            color="primary"
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            onDelete={handleDeleteInvitees(index)}
                          />
                        ))
                      }
                      disabled={disableChangeData}
                      sx={{
                        "& > .MuiFormControl-root .MuiInputBase-root": {
                          ".MuiChip-root": {
                            maxHeight: 24,
                          },
                        },
                      }}
                    />
                  </Stack>
                </>
              )}
            </Stack>

            <Grid container>
              <Grid item xs={12} md={4} />
              <Grid
                item
                xs={12}
                md={4}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={1.5}
              >
                <Button
                  sx={{ width: 200 }}
                  onClick={
                    step >= 0 && step < 5
                      ? () => setStep(step + 1)
                      : step === 5
                      ? handleSubmit(handleCreate)
                      : () => navigate(newChatPath)
                  }
                  disabled={step === 0 ? !roundTableTitle : !isValid || disableChangeData}
                  loading={disableChangeData}
                >
                  {step === 5 ? "Create" : step < 0 ? "To Project" : "Continue"}
                </Button>
                <Button
                  variant="text"
                  sx={{ color: "#00000029", width: 200 }}
                  onClick={step > 0 ? handleBack : step < 0 ? handleClose : null}
                  disabled={step === 0 || disableChangeData}
                >
                  {step < 0 ? "Close" : "Back"}
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                {step > 2 && step < 5 && (
                  <Typography
                    mt={1}
                    fontSize={14}
                    color={"common.gray5"}
                    textAlign={"center"}
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => setStep(5)}
                  >
                    Set up later
                  </Typography>
                )}
              </Grid>
            </Grid>
            {step > 0 && (
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                color={"common.gray4"}
                mt={4}
                gap={1}
              >
                <InfoOutlinedIcon sx={{ width: 20, height: 20 }} />
                <Typography fontSize={14} alignItems={"center"}>
                  You can always change this later in Settings
                </Typography>
              </Stack>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateProjectLandingDialog;
