import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { axios } from "@features/config";
import { Delegation } from "@dto/delegations";

const useVoteDelegations = () => {
  const { roundtableId, projectId } = useParams();
  const conversationId = projectId ?? roundtableId;
  
  const { data, ...rest } = useQuery(
    ['delegations', conversationId],
    () => axios.get(`/round-tables/${conversationId}/delegations`),
    {
      enabled: !!conversationId,
    }
  )

  return {
    data: data?.data?.data as Delegation[],
    ...rest,
  }
}

export default useVoteDelegations
