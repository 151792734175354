import { useEffect } from "react";

import useGetApplicationsByJob from "@api/job/useGetApplicationsByJob";

// import useGetApplicationDetails from "@api/job/useGetApplicationDetails";
import { useAppDispatch } from "@app/hooks";

import { applicationActions } from "@features/application/slice";

import ApplicationDetails from "@components/Application/PanelDetails";

import { useSelectJob } from "./SelectJobContext";

const HiringQuestApplicationDetails = () => {
  const dispatch = useAppDispatch();
  const { jobSelected } = useSelectJob();
  const { isLoading, isFetching, data: apps } = useGetApplicationsByJob(jobSelected.id);

  useEffect(() => {
    if (apps && !isLoading) {
      dispatch(applicationActions.setApplicationId(""));
      dispatch(applicationActions.setJobId(jobSelected.id));
      dispatch(applicationActions.setApplicationIds(apps.map((item) => item.id)));
    }
    if (apps?.length && (!isLoading || !isFetching) && apps?.[0]?.id) {
      dispatch(applicationActions.setApplicationId(apps?.[0]?.id));
    }
  }, [apps, jobSelected.id]);

  useEffect(() => {
    return () => {
      dispatch(applicationActions.setApplicationId(""));
      dispatch(applicationActions.setJobId(""));
      dispatch(applicationActions.setApplicationIds([]));
    };
  }, []);

  return <ApplicationDetails hireButtonText="Assign" />;
};

export default HiringQuestApplicationDetails;
