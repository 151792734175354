import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

type Request = {
  assigneeId: string;
  roundTableId: string;
  assignToLeave?: boolean;
};

const providePermissionTraditionalRoundtable = async ({
  assigneeId,
  roundTableId,
  assignToLeave,
}: Request) => {
  const response = await axios.post(`/round-tables/${roundTableId}/provide`, {
    assigneeId,
    assignToLeave,
  });

  return response.data.data;
};

const useProvidePermissionRoundtable = () => {
  return useMutation(providePermissionTraditionalRoundtable);
};

export default useProvidePermissionRoundtable;
