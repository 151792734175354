import { useQuery } from "@tanstack/react-query";
import { axios } from "@features/config";
import { Application } from "@dto/application";

// type Response = {
//   data: Application;
// };

const getApplicationDetails = async (jobId: string, applicationId: string): Promise<Application> => {
  const res = await axios.get(`/job/${jobId}/application/${applicationId}`);
  return res?.data?.data;
};

const useGetApplicationDetails = (jobId: string, applicationId: string) =>
  useQuery(["getApplicationDetails", jobId, applicationId], () => getApplicationDetails(jobId, applicationId), { enabled: !!jobId && !!applicationId });

export default useGetApplicationDetails;
