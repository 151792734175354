import { DragEvent, useCallback, useEffect, useId, useRef, useState } from "react";
import { FiSend } from "react-icons/fi";

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  StackProps,
  styled,
  useTheme,
} from "@mui/material";

import ChatEditor from "@components/ChatEditor";

import {
  getLexicalMentionUsersFromHtml,
  removeEmptyHtmlTags,
} from "@utils/helpers/stringHelper";
import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { ReactComponent as AttactmentIcon } from "@assets/icons/attachments.svg";
import { ReactComponent as DisabledSendIcon } from "@assets/icons/disabled_send.svg";
import { ReactComponent as ImageIcon } from "@assets/icons/images.svg";
import { ReactComponent as SendIcon } from "@assets/icons/send.svg";

import { Attendee } from "@dto/roundtable";

import { useViewport } from "@contexts/ViewportContext";

type Props = {
  onSubmit: ({
    text,
    imageUrls,
    fileUrls,
  }: {
    text: string;
    imageUrls: { name: string; url: string }[];
    fileUrls: { name: string; url: string }[];
    mentionedUsers: string[];
  }) => Promise<void>;
  userForMentions?: Attendee[];
};

const MessageInput = ({ onSubmit, userForMentions }: Props) => {
  const { isMobile } = useViewport();
  const [input, setInput] = useState("");
  const theme = useTheme();
  const fileSelectId = useId();
  const imageSelectId = useId();
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const editorRef: any = useRef();

  const handleDrop = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;

    if (files && files.length && !loading) {
      const filesSelected: File[] = [];

      Array.from(files).map((file: File) => {
        filesSelected.push(file);
      });
      setSelectedFile((prev) => [...prev, ...filesSelected]);
    }
  }, []);

  useEffect(() => {
    const dropZone = document.getElementById("chat-editor-area");
    dropZone?.addEventListener("dragover", (e: any) => {
      e.preventDefault();
    });

    const handleDropEvent = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      handleDrop(e);
    };
    dropZone?.addEventListener("drop", handleDropEvent);
    return () => {
      dropZone?.removeEventListener("drop", handleDropEvent);
    };
  }, [handleDrop]);

  const onImageChange = (event: any) => {
    setSelectedFile((prev) => prev.concat(Array.from(event.target.files)));
  };

  async function upload(files: File[]) {
    if (files.length === 0) {
      return [];
    }

    let imageUrls: { name: string; url: string }[] = [];
    const result = await uploadImagesToServer(files);

    imageUrls = result.map((r, i) => ({
      name: files[i].name,
      url: r,
      size: files[i].size,
      type: files[i].type,
      date: new Date(),
    }));
    return imageUrls;
  }

  async function handleSubmit(text = "", uploadFiles?: File[]) {
    try {
      setLoading(true);
      editorRef?.current?.toggleEditable(false);

      const inpuImages: File[] = [];
      const inpuFiles: File[] = [];

      uploadFiles?.forEach((file) => {
        if (file.type.startsWith("image/") || file.type.startsWith("video/")) {
          inpuImages.push(file);
        } else {
          inpuFiles.push(file);
        }
      });

      const formatText = removeEmptyHtmlTags(text);

      const imageUrls = await upload(inpuImages);
      const fileUrls = await upload(inpuFiles);

      const filterMentions = getLexicalMentionUsersFromHtml(formatText);

      const mentionedUsers = new Set<string>(
        filterMentions.map((user) => {
          return user?.id;
        })
      );

      await onSubmit({
        text: formatText,
        imageUrls,
        fileUrls,
        mentionedUsers: mentionedUsers.size ? Array.from(mentionedUsers) : [],
      });
    } catch (e) {
      console.log("Send message error", e);
    } finally {
      editorRef?.current?.clear();
      setTimeout(() => setInput("")); // To clear the enter key
      setSelectedFile([]);
      editorRef?.current?.toggleEditable(true);
      setLoading(false);
    }
  }

  const mentionData = userForMentions
    ?.map((user) => ({
      id: user.userId,
      value: user.name,
      avatar: user.avatar,
    }))
    .filter((user) => !!user.id);

  return (
    <Container>
      <InputContainer isMobile={isMobile}>
        <MainInput
          // onDragOver={handleDragOver}
          // onDragLeave={handleDragLeave}
          // onDrop={handleDrop}
          isMobile={isMobile}
        >
          <ChatEditor
            onChange={setInput}
            ref={editorRef}
            loading={loading}
            mentionData={mentionData}
            {...{
              selectedFile,
              setSelectedFile,
              handleSubmit,
            }}
          />

          <Box className="function-btn">
            <Box
              component="label"
              htmlFor={fileSelectId}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                mb: 0.5,
                borderRadius: 2.5,
                ":hover": {
                  backgroundColor: loading ? "transparent" : "#eee",
                },
              }}
            >
              <AttactmentIcon />
              <VisuallyHiddenInput
                disabled={loading}
                onChange={(e: any) => {
                  setSelectedFile((prev) => prev.concat(Array.from(e.target.files)));
                }}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
                id={fileSelectId}
                type="file"
                multiple
              />
            </Box>

            <Box
              component="label"
              htmlFor={imageSelectId}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                p: 1,
                mb: 0.5,
                borderRadius: 2.5,
                ":hover": {
                  backgroundColor: loading ? "transparent" : "#eee",
                },
              }}
            >
              <ImageIcon />
              <VisuallyHiddenInput
                disabled={loading}
                onChange={onImageChange}
                id={imageSelectId}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
                type="file"
                accept="image/*"
                multiple
              />
            </Box>
            <Button
              className="submit-btn"
              onClick={() => handleSubmit(input, selectedFile)}
              disabled={loading || (!removeEmptyHtmlTags(input) && !selectedFile.length)}
            >
              {!loading && (removeEmptyHtmlTags(input) || selectedFile.length > 0) ? (
                <SendIcon />
              ) : (
                <DisabledSendIcon />
              )}
            </Button>
          </Box>
        </MainInput>
      </InputContainer>
    </Container>
  );
};

const Container = styled(Stack)`
  // background-color: ${({ theme }) => theme.palette.common.mainBg};
`;

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const MainInput = styled(Stack)<StackProps & { isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    flex: 1,
    position: "relative",
    justifyContent: "flex-end",
    overflow: "auto",

    ".mentionsWrapper": {
      width: "100%",
      background: "transparent",
      color: "#a9b5c4",

      "&__control": {
        borderRadius: 12,
        border: "1px solid #3a546f",
        padding: "12px 8px",
        minHeight: 45,
        maxHeight: 120,
        overflowY: "auto",

        "& .mentionsWrapper__highlighter": {},

        "& .mentionsWrapper__input": {
          outline: 0,
          border: 0,
          resize: "none",
          color: "#7288a3",
          borderColor: "#3a546f",
          overflow: "hidden",

          "&::placeholder": {
            color: "#7288a3",
          },
        },
      },

      "&__suggestions": {
        padding: 10,
        borderRadius: 8,

        "&__item--focused": {
          color: "#ffffff",
        },
      },
      "textarea": {
        padding: "12px 80px 12px 16px",
        overflow: "auto !important",
        "&:focus": {
          padding: "12px 80px 12px 16px",
        },
      },
    },

    ".function-btn": {
      display: "flex",
      position: "absolute",
      right: "8px",
      height: "100%",
      alignItems: "flex-end",
      justifyContent: "center",

      ".submit-btn": {
        height: isMobile ? 20 : 36,
        minWidth: isMobile ? 20 : 36,
        cursor: "pointer",
        display: "flex",
        padding: 8,
        marginBottom: 5,
        marginLeft: 8,
        borderRadius: 4,
        alignItems: "center",
        justifyContent: "center",
        "svg": {
          opacity: 1,
          fill: "#060606",
          ".MuiCircularProgress-circle": {
            stroke: "white",
          },
        },
      },
    },
  })
);

const InputContainer = styled(Box)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  display: "flex",
  padding: theme.spacing(2),
  justifyContent: "center",
  position: "relative",

  ".input": {
    flex: 1,
    borderRadius: 4,

    "textarea, textarea:focus-visible, textarea:focus": {
      borderRadius: 12,
      outline: "none",
      border: theme.palette.common.border,
      padding: theme.spacing(1.5, 2),
    },

    ".input__control": {
      background: theme.palette.common.gramsHair,
      borderRadius: 12,
      padding: theme.spacing(1.5, 2),
      height: isMobile ? 40 : 48,
      fontSize: isMobile ? 12 : 16,
    },

    "strong": {
      backgroundColor: theme.palette.common.wePeep,
      borderRadius: 2,
    },
  },
}));

export default MessageInput;
