import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

// import { ROUNDTABLE_DIALOG_TYPE } from "@components/Dialog/AddEditRoundtableDialog/RoundtableDialog";
// import { ROUNDTABLE_FORM_TYPE } from "@components/Dialog/UpsertRoundtableDialog/RountableFormDialog"

import { Roundtable } from "@dto/roundtable";

import { RoundtableType } from "@@types/type";

type Request = {
  id: string;
  payload: Partial<Roundtable> & { type?: RoundtableType };
  // proposalType?: Omit<
  //   (typeof ROUNDTABLE_DIALOG_TYPE)[keyof typeof ROUNDTABLE_DIALOG_TYPE],
  //   "ROUNDTABLE"
  // >;
  proposalType?: string,
};

const useUpdateRoundtableOrProject = () => {
  return useMutation(async ({ id, payload, proposalType }: Request) => {
    const response = await axios.put(`/round-table/${id}`, {
      ...payload,
      proposalType
    });

    return response.data.data;
  });
};

export default useUpdateRoundtableOrProject;
