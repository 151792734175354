import { useState, useEffect } from "react";

import { ToolOptionType, DialogEnum } from "@constants/roundtable";
import {
  Close as CloseIcon,
  KeyboardArrowUp as ArrowUpIcon,
  KeyboardArrowDown as ArrowDownICon,
} from "@mui/icons-material";
// import { useParams } from "react-router-dom"
import { Grid, Stack, Button, Typography, IconButton, Divider } from "@mui/material";

import { useAppDispatch } from "@app/hooks";

import { sidebarActions } from "@features/sidebar/slice";

import InviteMemberTool from "@components/MemberList";
import UpdatePositionTool from "@components/MemberManagementTools/UpdatePosition";
import UpdateProjectManagementTool from "@components/MemberManagementTools/UpdateProjectManagement";
import UpsertRoundtableTool from "@components/MemberManagementTools/UpsertRoundtable";
import VoteDelegationTool from "@components/MemberManagementTools/VoteDelegation";

import LeaveProjectDialog from "@pages/chat/Dialogs/LeaveProjectDialog";
import LeaveRoundtableDialog from "@pages/chat/Dialogs/LeaveRoundtableDialog";

import { isProject } from "@utils/roundtable.utils";

import { ManagementEnum, Roundtable, VoteModeEnum } from "@dto/roundtable";

// import { useAppSelector } from "@app/hooks";
import { useViewport } from "@contexts/ViewportContext";

type MenuItemType = {
  text: string;
  toolType: ToolOptionType | string;
};
type MemberToolManagementProps = {
  conversationId: string;
  roundtable: Roundtable;
};

const MemberToolManagement = (props: MemberToolManagementProps) => {
  const { roundtable, conversationId } = props;
  const [openToolList, setOpenToolList] = useState(true);
  const [reloadTime, setReloadTime] = useState(Date.now());
  const dispatch = useAppDispatch();
  // const memberToolMngOpen = useAppSelector((state) => state.sideBar.openMemberToolMng);
  const { isDesktop, isMobile } = useViewport();
  const isRoundtable = !isProject(roundtable);

  // const params = useParams();
  // const { roundtableId, projectId } = params;

  // useEffect(() => {
  //   console.log('conversation')
  //   if (memberToolMngOpen) {
  //     dispatch(sidebarActions.setMemberToolMngOpen({ open: false }))
  //   }
  // }, [roundtableId, projectId, memberToolMngOpen])

  const toggleToolList = () => setOpenToolList(!openToolList);

  const menuItems: MenuItemType[] = [
    {
      text: "Member Management",
      toolType: DialogEnum.INVITATION as string,
    },
    isRoundtable
      ? {
          text: "Update Teamspace Management",
          toolType: DialogEnum.ROUNDTABLE_MANAGEMENT,
        }
      : roundtable.managementStyle !== ManagementEnum.TRADITIONAL
      ? {
          text: "Update Project Management",
          toolType: DialogEnum.SETTING,
        }
      : null,
    roundtable.voteMode !== VoteModeEnum.SIMPLE_VOTE &&
    roundtable.managementStyle !== ManagementEnum.TRADITIONAL
      ? {
          text: "Vote Delegation",
          toolType: DialogEnum.VOTE_DELEGATE,
        }
      : null,
    // {
    //   text: "Update My Position",
    //   toolType: DialogEnum.POSITION,
    // },
    isRoundtable
      ? {
          text: "Leave Teamspace",
          toolType: DialogEnum.LEAVE,
        }
      : {
          text: "Leave Project",
          toolType: DialogEnum.LEAVE_PROJECT,
        },
  ].filter((item) => item !== null && item !== undefined);

  const [selectedTool, setSelectedTool] = useState<MenuItemType>(menuItems[0]);

  const renderToolBody = () => {
    if (!roundtable) {
      return <>LOADING...</>;
    }
    if (selectedTool.toolType === DialogEnum.INVITATION) {
      return <InviteMemberTool roundTableId={roundtable.id} />;
    }
    if (selectedTool.toolType === DialogEnum.ROUNDTABLE_MANAGEMENT) {
      return (
        <UpsertRoundtableTool
          key={reloadTime}
          data={roundtable}
          type={DialogEnum.ROUNDTABLE_MANAGEMENT}
          onClose={() => setReloadTime(Date.now())}
        />
      );
    }
    if (selectedTool.toolType === DialogEnum.VOTE_DELEGATE) {
      return (
        <VoteDelegationTool
          key={reloadTime}
          onClose={() => setReloadTime(Date.now())}
          roundtable={roundtable}
        />
      );
    }
    if (selectedTool.toolType === DialogEnum.POSITION) {
      return (
        <UpdatePositionTool
          key={reloadTime}
          roundtable={roundtable}
          onClose={() => setReloadTime(Date.now())}
        />
      );
    }
    if (selectedTool.toolType === DialogEnum.SETTING) {
      return (
        <UpdateProjectManagementTool
          roundtable={roundtable}
          onClose={() => setReloadTime(Date.now())}
        />
      );
    }

    return;
  };

  return (
    <Stack
      sx={{
        m: 0,
        // height: isDesktop ? "100vh" : "calc(100vh - 192px)",
        height: isDesktop ? "calc(100vh - 97px)" : "calc(100vh - 150px)",
        overflow: "auto",
      }}
    >
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={12} md={3} sx={{ py: 2.5, px: 2 }}>
          {isMobile && (
            <Stack flexDirection={"row"} justifyContent={"right"}>
              <IconButton
                onClick={() =>
                  dispatch(sidebarActions.setMemberToolMngOpen({ open: false }))
                }
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          )}
          <Stack gap={1}>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              onClick={
                isMobile ? () => toggleToolList() : () => console.log("disable toggle")
              }
            >
              <Typography fontSize={12} color={"common.gray4"} py={1} px={1}>
                MEMBER MANAGEMENT
              </Typography>
              {isMobile && <>{openToolList ? <ArrowUpIcon /> : <ArrowDownICon />}</>}
            </Stack>
            {openToolList &&
              menuItems?.map((item, idx) => (
                <Button
                  key={idx}
                  variant="text"
                  sx={{
                    justifyContent: "left",
                    bgcolor:
                      selectedTool.toolType === item.toolType && "common.deutziaWhite",
                    color:
                      selectedTool.toolType === item.toolType
                        ? "#171923"
                        : "common.gray7",
                  }}
                  onClick={() => setSelectedTool(item)}
                >
                  <Typography
                    fontWeight={selectedTool.toolType === item.toolType && "bold"}
                    textAlign={"left"}
                  >
                    {item.text}
                  </Typography>
                </Button>
              ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={9} sx={{ bgcolor: "#F7FAFC", p: 3.5 }}>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={{ xs: 18, md: 24 }} fontWeight={"bold"}>
              {selectedTool.text}
            </Typography>
            {!isMobile && (
              <IconButton
                onClick={() =>
                  dispatch(sidebarActions.setMemberToolMngOpen({ open: false }))
                }
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
          <Divider sx={{ borderColor: "#EAECF0", my: 3 }} />
          <Stack>{renderToolBody()}</Stack>
        </Grid>
      </Grid>

      {selectedTool.toolType === DialogEnum.LEAVE && (
        <LeaveRoundtableDialog
          roundtableId={roundtable.id}
          roundtableName={roundtable.title}
          currentTotalMembers={roundtable.attendees.length}
          openDialog={selectedTool.toolType === DialogEnum.LEAVE}
          setOpenDialog={() => setSelectedTool({ toolType: "", text: "" })}
          attendees={roundtable.attendees}
        />
      )}
      {selectedTool.toolType === DialogEnum.LEAVE_PROJECT && (
        <LeaveProjectDialog
          roundtableId={roundtable.id}
          roundtableName={roundtable.title}
          currentTotalMembers={roundtable.attendees.length}
          openDialog={selectedTool.toolType === DialogEnum.LEAVE_PROJECT}
          setOpenDialog={() => setSelectedTool({ toolType: "", text: "" })}
          attendees={roundtable.attendees}
        />
      )}
    </Stack>
  );
};

export default MemberToolManagement;
