import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

export type Request = {
  roundtableId: string;
  userIds: string[];
};

const mentionUsers = async ({ roundtableId, userIds = [] }: Request) => {
  const response = await axios.post(`/round-tables/${roundtableId}/mention`, {
    receivers: userIds
  });

  return response.data.data;
};

const useMentionUsers = () => {
  return useMutation(mentionUsers);
};

export default useMentionUsers;
