import { useState, useEffect } from "react";

import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import {
  $insertNodes,
  COMMAND_PRIORITY_HIGH,
  INSERT_PARAGRAPH_COMMAND,
  KEY_ENTER_COMMAND,
} from "lexical";

interface Props {
  initialHtml?: string;
  selectedFile?: File[];
  onHtmlChanged: (html: string) => void;
  handleSubmit: (text?: string, selectedFile?: File[]) => Promise<void>;
}

const HtmlPlugin = ({
  initialHtml,
  onHtmlChanged,
  handleSubmit,
  selectedFile,
}: Props) => {
  const [editor] = useLexicalComposerContext();

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!initialHtml || !isFirstRender) {
      return;
    }

    setIsFirstRender(false);

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHtml, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      $insertNodes(nodes);
    });
  }, []);

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        const { shiftKey, key } = event;

        const menuMention = document.getElementById("mention-menu-display");
        if (key == "Enter" && shiftKey == false && !menuMention) {
          event.preventDefault();
          const editorState = editor.getEditorState();
          editorState.read(async () => {
            editor.setEditable(false);
            const htmlString = $generateHtmlFromNodes(editor);

            await handleSubmit(htmlString, selectedFile);
            editor.setEditable(true);
            // editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
          });
        } else {
          event.preventDefault();

          if (event.shiftKey) {
            return editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
          }
        }
      },
      COMMAND_PRIORITY_HIGH
    );
  }, [editor, selectedFile]);

  return (
    <OnChangePlugin
      onChange={(editorState) => {
        editorState.read(() => {
          onHtmlChanged($generateHtmlFromNodes(editor));
        });
      }}
    />
  );
};

export default HtmlPlugin;
