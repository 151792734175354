import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

type Request = {
  roundtableId: string;
  emails: string[];
  reason?: string;
  isBothRtbAndPrj?: boolean;
  inviteFreelancer?: boolean;
};

type Response = {
  success: boolean;
  data?: { code: number; message: string };
  message?: string;
};

const inviteMember = async ({
  roundtableId,
  emails,
  reason,
  isBothRtbAndPrj = false,
  inviteFreelancer,
}: Request): Promise<Response> => {
  const response = await axios.post(`/round-table/${roundtableId}/invites`, {
    emails,
    reason,
    isBothRtbAndPrj,
    inviteFreelancer,
  });

  return response.data.data;
};

const useInviteMember = () => {
  return useMutation(inviteMember);
};

export default useInviteMember;
