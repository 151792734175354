import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

import { AttachMoney as AttachMoneyIcon } from "@mui/icons-material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {
  Avatar,
  Chip,
  Stack,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";

import ImageViewer from "@components/Dialog/ImageViewer";
import FormInput from "@components/FormInput";
import ImageUploader, { ImageUploaderType } from "@components/ImageUploader";
import LinkInput, { LinkItem } from "@components/LinkInput";
import MultiFileUploader from "@components/MultiFileUploader/MultiFileUploader";

import { toTitleCase } from "@utils/helpers/stringHelper";

import Experiences, { IDataChangeProps } from "./Experiences";
import Projects, { IDataChangeProps as IProjectDataChangeProps } from "./Projects";
import { UserProfileCommonProps } from "./index";

const WorkingScheduleUnit = "hour(s)/week";

const MainUserInfo = ({ userData, isEdit, imageUploaderRef }: UserProfileCommonProps) => {
  const user = { ...userData };
  const {
    name,
    bio,
    skillTag,
    link,
    location,
    level,
    institution,
    userLanguages,
    workingSchedule,
    description,
    hourRateTo,
    // profileProjects
  } = user;

  const { control, watch, setValue, getValues } = useFormContext();

  // const avatar = watch("avatar");
  const watchedSkillTags = watch("skillTag");
  const watchedLinks = watch("links");
  const watchedExperiences = watch("experiences");
  const watchedLanguages = watch("languages");
  const watchedProjects = watch("projects");
  const images: string[] = user?.files?.map((f) => f.link);

  // const imageUploaderRef = useRef(null);
  const [isOpenedModal, setIsOpenedModal] = useState<number | null>(null);

  const displayElement = (value: any, trueValue?: string) => {
    return value ? trueValue || "initial" : "none";
  };

  const renderDefaultHint = (display?: any) => {
    return !!display && <Typography>-</Typography>;
  };

  const handleUploadImage = useCallback(
    (file: File) => {
      setValue("avatar", file, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue]
  );

  const handleToggleModal = (modalSection?: number) => {
    setIsOpenedModal(modalSection);
  };

  const handleKeyDownList = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>, fieldName?: string) => {
      const value = (e.target as HTMLInputElement)?.value;
      if (e.key === "Enter") {
        value &&
          setValue(fieldName, [...(getValues(fieldName) || []), value], {
            shouldDirty: true,
          });
      }
      if (e.key === "Backspace" && !value) {
        const newList = [
          ...(getValues(fieldName)?.slice(0, getValues(fieldName)?.length - 1) || []),
        ];
        setValue(fieldName, newList);
      }
    },
    []
  );

  const handleDeleteList = (index: number, fieldName?: string) => {
    if (index < 0) {
      return setValue(fieldName, []);
    }
    const filteredTags = getValues(fieldName)?.filter(
      (tag: string, i: number) => i !== index
    );

    setValue(fieldName, filteredTags);
  };

  const handleClickDeleteLink = (indexItem: number, _isVideoLinkMode?: boolean) => {
    watchedLinks.splice(indexItem, 1);
    setValue("links", watchedLinks);
  };

  const handleExperiencesChange = (props: IDataChangeProps) => {
    if (props.action === "add") {
      watchedExperiences?.splice(props?.index, 0, props?.data);
    } else if (props.action === "update") {
      watchedExperiences?.splice(props?.index, 1, props?.data);
    } else {
      watchedExperiences?.splice(props?.index, 1);
    }
    setValue("experiences", watchedExperiences);
  };

  const handleProjectsChange = (props: IProjectDataChangeProps) => {
    // console.log("Project change", props)
    if (props.action === "add") {
      watchedProjects?.splice(props?.index, 0, props?.data);
    } else if (props.action === "update") {
      watchedProjects?.splice(props?.index, 1, props?.data);
    } else {
      watchedProjects?.splice(props?.index, 1);
    }
    setValue("projects", watchedProjects);
  };

  const renderGallery = useCallback(
    (numberOfImages = 5) => {
      return images?.slice(0, numberOfImages).map((file, index) => (
        <Box
          key={index}
          sx={{ position: "relative", cursor: "pointer" }}
          width={"8rem"}
          onClick={() => {
            handleToggleModal(index);
          }}
        >
          <Avatar
            variant="rounded"
            src={!file.startsWith("data:application/") && file}
            sx={{ width: "8rem", height: "8rem" }}
          >
            <DocumentScannerIcon fontSize="large" />
          </Avatar>
          {images?.length > numberOfImages && index === numberOfImages - 1 && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                top: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.6)",
                position: "absolute",
              }}
            >
              <Box
                sx={{
                  color: "common.white",
                }}
                fontSize={24}
                fontWeight={500}
              >
                +{images?.length - numberOfImages}
              </Box>
            </Box>
          )}
        </Box>
      ));
    },
    [images]
  );

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Typography
          fontSize={14}
          color={"#0000005C"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Profile Picture
        </Typography>
        {isEdit ? (
          <Stack alignItems={"flex-start"}>
            <ImageUploader
              initAvatar={user?.avatar}
              onSetValue={handleUploadImage}
              type={ImageUploaderType.ROUNDED}
            />
          </Stack>
        ) : (
          <Avatar src={user.avatar} sx={{ width: 80, height: 80 }} />
        )}
      </Stack>

      <Stack gap={{ xs: 1, md: 4 }}>
        <Stack gap={2}>
          <Typography
            fontSize={14}
            color={"#0000005C"}
            fontWeight={600}
            textTransform={"uppercase"}
            display={{ xs: "none", md: "initial" }}
          >
            Full Name
          </Typography>
          {isEdit ? (
            <Stack mb={{ xs: 2, md: 0 }}>
              <FormInput
                // required
                control={control}
                name="name"
                label="Name"
                // size="small"
                // error={!!errors?.name?.message}
                // helperText={errors?.name?.message}
              />
            </Stack>
          ) : (
            <>
              <Typography fontSize={16} fontWeight={700} display={displayElement(name)}>
                {toTitleCase(name)}
              </Typography>
              {renderDefaultHint(!name)}
            </>
          )}
        </Stack>
        <Stack gap={2}>
          <Typography
            fontSize={14}
            color={"#0000005C"}
            fontWeight={600}
            textTransform={"uppercase"}
            display={{ xs: "none", md: "initial" }}
          >
            Hourly rate
          </Typography>
          {isEdit ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormInput
                  control={control}
                  name="hourRateTo"
                  type="number"
                  label="To"
                  InputProps={{
                    endAdornment: <AttachMoneyIcon />,
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Typography
                fontSize={16}
                fontWeight={700}
                display={displayElement(hourRateTo)}
              >
                ${hourRateTo}
              </Typography>
              {renderDefaultHint(!hourRateTo)}
            </>
          )}
        </Stack>
        <Stack gap={2}>
          <Typography
            fontSize={14}
            color={"#0000005C"}
            fontWeight={600}
            textTransform={"uppercase"}
            display={{ xs: "none", md: "initial" }}
          >
            Gallery
          </Typography>
          {isEdit ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MultiFileUploader
                  initialFiles={images}
                  userId={user?.id}
                  ref={imageUploaderRef}
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              display={"flex"}
              gap={0.5}
              sx={{
                overflowX: "auto",
                maxWidth: "100%",
              }}
            >
              {renderGallery()}
            </Box>
          )}
        </Stack>
        <Stack gap={2}>
          <Typography
            fontSize={14}
            color={"#0000005C"}
            fontWeight={600}
            textTransform={"uppercase"}
            display={{ xs: "none", md: "initial" }}
          >
            Headline
          </Typography>
          {isEdit ? (
            <Stack mt={{ xs: 2, md: 0 }}>
              <FormInput
                control={control}
                name="bio"
                label="Headline"
                multiline
                minRows={5}
                maxRows={5}
              />
            </Stack>
          ) : (
            <>
              <Typography fontSize={16} display={displayElement(bio)}>
                {bio}
              </Typography>
              {renderDefaultHint(!bio)}
            </>
          )}
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Typography
          fontSize={14}
          color={"#0000005C"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Skills
        </Typography>
        {isEdit ? (
          <Autocomplete
            id="multiple-skill-tags"
            options={[]}
            freeSolo
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                label="Skill"
                placeholder="Press enter to add skill"
                onKeyDown={(e) => handleKeyDownList(e, "skillTag")}
              />
            )}
            value={watchedSkillTags ?? []}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  key={`${index}-${option}-chip-label`}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={(_e: any) => handleDeleteList(index, "skillTag")}
                  color="primary"
                  size="small"
                />
              ))
            }
            onChange={(_e, _v, reason) =>
              reason === "clear" && handleDeleteList(-1, "skillTag")
            }
            // disabled={isViewOnly}
            sx={{
              "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                minWidth: "unset",
              },
            }}
          />
        ) : (
          <>
            <Stack
              flexDirection={"row"}
              flexWrap={"wrap"}
              gap={2}
              display={displayElement(!!skillTag, "flex")}
            >
              {skillTag
                ?.split(",")
                ?.filter((s) => s)
                ?.map((skill, i) => (
                  <Chip
                    key={i}
                    label={skill}
                    sx={{
                      height: 24,
                      borderRadius: 1,
                      fontSize: 14,
                      fontWeight: "bold",
                      bgcolor: "#EDF2F7",
                      "& .MuiChip-label": { p: 1 },
                    }}
                  />
                ))}
            </Stack>
            {renderDefaultHint(!skillTag)}
          </>
        )}
      </Stack>
      <Stack gap={2}>
        <Typography
          fontSize={14}
          color={"#0000005C"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Links
        </Typography>
        {isEdit ? (
          <Stack>
            <LinkInput
              columnLayout
              displayLinks={watchedLinks ?? []}
              handleClickDeleteLink={handleClickDeleteLink}
              setSnackbar={() => console.log("setSnackbar")}
              handleLink={(linkDetail) =>
                setValue("links", [
                  ...getValues("links"),
                  {
                    url: linkDetail.url,
                    displayText: linkDetail.displayText || linkDetail.url,
                  },
                ])
              }
            />
          </Stack>
        ) : (
          <>
            <Stack gap={1} display={displayElement(link?.length > 0, "flex")}>
              {link?.map((link: any, i: number) => (
                <LinkItem key={i} index={i} data={link} />
              ))}
            </Stack>
            {renderDefaultHint(!(link?.length > 0))}
          </>
        )}
      </Stack>
      <Stack gap={2}>
        <Typography
          fontSize={14}
          color={"#0000005C"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          Basic informations
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} display={displayElement(isEdit ? true : location)}>
            {isEdit ? (
              <FormInput control={control} name="location" label="Location" />
            ) : (
              <Stack flexDirection={"row"} display={displayElement(location, "flex")}>
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  width={{ xs: "45%", md: "40%" }}
                >
                  Location
                </Typography>
                <Typography fontSize={14} width={{ xs: "55%", md: "60%" }}>
                  {location}
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={displayElement(isEdit ? true : userLanguages?.length > 0)}
          >
            {isEdit ? (
              <Autocomplete
                id="multiple-languages"
                options={[]}
                freeSolo
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Language"
                    placeholder="Press enter to add language"
                    onKeyDown={(e) => handleKeyDownList(e, "languages")}
                  />
                )}
                value={watchedLanguages ?? []}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      key={`${index}-${option}-chip-label`}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      onDelete={(_e: any) => handleDeleteList(index, "languages")}
                      color="primary"
                      size="small"
                    />
                  ))
                }
                onChange={(_e, _v, reason) =>
                  reason === "clear" && handleDeleteList(-1, "languages")
                }
                sx={{
                  "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                    minWidth: "unset",
                  },
                }}
              />
            ) : (
              <Stack
                flexDirection={"row"}
                display={displayElement(userLanguages?.length > 0, "flex")}
              >
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  width={{ xs: "45%", md: "40%" }}
                >
                  Language
                </Typography>
                <Typography fontSize={14} width={{ xs: "55%", md: "60%" }}>
                  {userLanguages?.join(", ")}
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} md={6} display={displayElement(isEdit ? true : institution)}>
            {isEdit ? (
              <FormInput control={control} name="institution" label="Institution" />
            ) : (
              <Stack flexDirection={"row"} display={displayElement(institution, "flex")}>
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  width={{ xs: "45%", md: "40%" }}
                >
                  Institution
                </Typography>
                <Typography fontSize={14} width={{ xs: "55%", md: "60%" }}>
                  {institution}
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={displayElement(
              isEdit ? true : workingSchedule?.includes("day") ? "" : workingSchedule
            )}
          >
            {isEdit ? (
              // <FormInput
              //   select
              //   control={control}
              //   name="workingSchedule"
              //   label="Working Schedule"
              // >
              //   {workScheduleOptions.map(item => (
              //     <MenuItem key={item} value={item}>{item}</MenuItem>
              //   ))}
              // </FormInput>
              <FormInput
                control={control}
                name="workingSchedule"
                label="Working Schedule"
                type="number"
                InputProps={{
                  endAdornment: <Typography>{WorkingScheduleUnit}</Typography>,
                }}
              />
            ) : (
              <Stack
                flexDirection={"row"}
                display={displayElement(workingSchedule, "flex")}
              >
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  width={{ xs: "45%", md: "40%" }}
                >
                  Working Schedule
                </Typography>
                <Typography fontSize={14} width={{ xs: "55%", md: "60%" }}>
                  {workingSchedule} {WorkingScheduleUnit}
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            display={displayElement(
              !isEdit &&
                [
                  location,
                  userLanguages?.length > 0,
                  level,
                  institution,
                  workingSchedule,
                ].every((item: any) => !!item === false)
            )}
          >
            {renderDefaultHint(true)}
          </Grid>
        </Grid>
      </Stack>
      <Stack gap={2}>
        <Typography
          fontSize={14}
          color={"#0000005C"}
          fontWeight={600}
          textTransform={"uppercase"}
        >
          About me
        </Typography>
        {isEdit ? (
          <FormInput
            multiline
            minRows={5}
            maxRows={5}
            control={control}
            name="description"
            label="Description"
            // error={!!errors?.name?.message}
            // helperText={errors?.name?.message}
          />
        ) : (
          <>
            <Typography fontSize={16} display={displayElement(description)}>
              {description}
            </Typography>
            {renderDefaultHint(!description)}
          </>
        )}
      </Stack>
      <Experiences
        title={
          <Typography
            fontSize={14}
            color={"#0000005C"}
            fontWeight={600}
            textTransform={"uppercase"}
          >
            Work experience
          </Typography>
        }
        type="WORK"
        isEdit={isEdit}
        experiences={watchedExperiences}
        onDataChange={handleExperiencesChange}
      />

      {isOpenedModal !== null && (
        <ImageViewer
          images={images}
          initialIndex={isOpenedModal}
          open={isOpenedModal !== null}
          onClose={() => setIsOpenedModal(null)}
        />
      )}
      <Experiences
        title={
          <Typography
            fontSize={14}
            color={"#0000005C"}
            fontWeight={600}
            textTransform={"uppercase"}
          >
            Education experience
          </Typography>
        }
        type="EDUCATION"
        isEdit={isEdit}
        experiences={watchedExperiences}
        onDataChange={handleExperiencesChange}
      />
      <Projects
        title={
          <Typography
            fontSize={14}
            color={"#0000005C"}
            fontWeight={600}
            textTransform={"uppercase"}
          >
            Projects
          </Typography>
        }
        isEdit={isEdit}
        projects={watchedProjects}
        onDataChange={handleProjectsChange}
        userIds={user.id}
      />
    </Stack>
  );
};

export default MainUserInfo;
