import { createContext, FC, useCallback, useContext, useState } from "react";

interface IToasterContextProps extends React.PropsWithChildren {
}

export const CustomizedVoteShareContext = createContext(null)

export const useVoteShare = () => useContext(CustomizedVoteShareContext)

type DelegationObject<T> = {
  [key: string]: T
}

const VoteShareProvider: FC<IToasterContextProps> = ({ children }) => {
  const [delegations, setDelegations] = useState<DelegationObject<string>>({})
  const [selectVote, setSelectVote] = useState<DelegationObject<boolean>>({})
  const [errors, setErrors] = useState({})

  const handleVoteShareChange = useCallback( (newValue: string, voteShareType: string) => {
    setDelegations({
      ...delegations,
      [voteShareType]: newValue,
    })
  }, [delegations])

  const handleSlectVote = (voteShareType: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectVote(prev => {
      const newValue = event.target.checked;
      const result = {
        ...prev,
        [voteShareType]: newValue
      };
      if(!newValue) {
        delete result[voteShareType];
      }
      return result;
    })
  }

  const handleResetAll = () => {
    setDelegations({})
    setErrors({})
  }
  
  return (
    <CustomizedVoteShareContext.Provider
      value={{
        delegations,
        handleVoteShareChange,
        errors,
        setErrors,
        handleResetAll,
        selectVote,
        setSelectVote,
        handleSlectVote,
        setDelegations
      }}
    >
      {children}
    </CustomizedVoteShareContext.Provider>
  )
}

export default VoteShareProvider
