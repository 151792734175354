import { useMutation } from "@tanstack/react-query";
// import { AxiosRequestConfig } from "axios";

import { axios } from "@features/config";

export type Request = {
  jobId: string;
  applicationId: string;
  saved?: boolean;
  archived?: boolean;
};

// type Response = {
//   jobId: string;
//   projectId: string;
// };

const updateApplicationStatus = async (payload: Request): Promise<any> => {
  const response = await axios.put(`/job/${payload.jobId}/application/${payload.applicationId}`, {
    saved: payload.saved,
    archived: payload.archived
  });
  return response?.data?.data;
};

const useUpdateApplicationStatus = () => {
  return useMutation(updateApplicationStatus);
};

export default useUpdateApplicationStatus;
