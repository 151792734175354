import { axios, handleApiError } from "@features/config";

import { Application } from "@dto/application";
import { User } from "@dto/user";

import { Delivery_Status } from "./type";

type HiringResponseType = {
  success: boolean;
  data?: {
    data: boolean;
  };
  message?: string;
};

type HiringRequestType = {
  jobId: string;
};

export const deleteJob = async ({ jobId }: HiringRequestType) => {
  try {
    const data: HiringResponseType = await axios.delete(`/jobs/${jobId}`);

    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return handleApiError(error);
  }
};
