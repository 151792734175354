import React, { useId } from "react";

import { Box, BoxProps, styled, useTheme } from "@mui/material";

import { ReactComponent as UploadIcon } from "@assets/icons/upload.svg";

export const ACCEPTED_FILE_TYPE = {
  "all": "*/*",
  "image": "image/*",
  "document": ".pdf, .doc, .docx, .xlsx",
};
type FileHiddenInputProps = {
  disabled?: boolean;
  onUpload?: React.ChangeEventHandler<HTMLInputElement>;
  icon?: React.ReactNode;
  btnLabel?: string;
  acceptedFileTypes?: keyof typeof ACCEPTED_FILE_TYPE;
};

const FileHiddenInput = (props: FileHiddenInputProps & BoxProps) => {
  const {
    disabled,
    onUpload,
    icon,
    acceptedFileTypes = "all",
    btnLabel,
    ...rest
  } = props;
  const {
    palette: { common },
  } = useTheme();
  const imageSelectId = useId();
  return (
    <Box
      component="label"
      htmlFor={imageSelectId}
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        mb: 0.5,
        borderRadius: 2,
        gap: 1,
        fontSize: 14,
        width: 161,
        height: 40,
        cursor: disabled ? "default" : "pointer",
        border: common.border,
        ":hover": {
          backgroundColor: disabled ? "transparent" : "#eee",
        },
        ...rest.sx,
      }}
    >
      {icon || <UploadIcon />}
      {btnLabel ?? "Upload attachment"}
      <VisuallyHiddenInput
        onChange={onUpload}
        disabled={disabled}
        id={imageSelectId}
        onClick={(event) => {
          event.currentTarget.value = null;
        }}
        type="file"
        accept={ACCEPTED_FILE_TYPE[acceptedFileTypes]}
        multiple
      />
    </Box>
  );
};
export default FileHiddenInput;

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
