import React, { useEffect, useMemo } from "react";

import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

import { useAppSelector } from "@app/hooks";

import { useMessages } from "@pages/chat/Conversation/hooks";
import { MessageType } from "@pages/chat/Conversation/type";

import ActivityItem from "./ActivityItem";

type ActivityChatProps = {
  conversationId: string;
};

const ActivityChat = (props: ActivityChatProps) => {
  const { conversationId } = props;
  const { user } = useAppSelector((state) => state.auth.userInfo);
  const thisYearStart = dayjs().startOf("year");
  const todayStart = dayjs().startOf("day");
  const yesterDayStart = dayjs(todayStart).add(-1, "day");
  const [messages = [], loading] = useMessages(conversationId);

  useEffect(() => {
    const lastElement = document.querySelector(`.msg-item:last-child`);
    lastElement?.scrollIntoView();
  }, [messages]);

  const renderGroupDate = (date?: any) => {
    if (!date) {
      return "";
    }
    let dateFormat = "ddd, DD MMM, YYYY";
    const dateData = dayjs(typeof date === "number" ? date : date?.seconds * 1000);
    if (dateData.diff(thisYearStart, "second") >= 0) {
      dateFormat = "ddd, DD MMM";
    }
    if (dateData.diff(yesterDayStart, "second") >= 0) {
      dateFormat = "Yesterday";
    }
    if (dateData.diff(todayStart, "second") >= 0) {
      dateFormat = "Today";
    }

    return ["Yesterday", "Today"].includes(dateFormat)
      ? dateFormat
      : dayjs(dateData).format(dateFormat);
  };

  const dataMessage = useMemo(() => {
    return messages.map((message) => ({
      ...message,
      position: user.id === message.userId ? "right" : "left",
      groupDate: renderGroupDate(message.date),
    }));
  }, [messages, user.id]) as MessageType[];

  if (loading) {
    return (
      <Stack alignItems={"center"}>
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <Stack gap={2}>
      {dataMessage?.length == 0 && (
        <Typography textAlign={"center"}>
          Your task has begin! Start a comment to provide/request more detail
        </Typography>
      )}
      {dataMessage.map((message, idx) => {
        const prevMsg = dataMessage?.[idx - 1];
        const groupMark = (
          <Typography textAlign={"center"} color={"#00000099"} fontSize={"10px"}>
            {prevMsg?.groupDate !== message?.groupDate && message?.groupDate}
          </Typography>
        );
        return (
          <Box key={message.id} className="msg-item">
            {/* {todayMark} */}
            {groupMark}
            <ActivityItem {...message} />
          </Box>
        );
      })}
    </Stack>
  );
};
export default ActivityChat;
