import {
  FC,
  useEffect,
  useState,
  ReactNode,
  Children,
  cloneElement,
  ReactElement,
} from "react";
import { FormProvider, useForm } from "react-hook-form";

import useUpdateRoundtableOrProject from "@api/roundtable/useUpdateRoundtableOrProject";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { handleApiError } from "@features/config";

import { uploadImagesToServer } from "@utils/uploadImageToServer";

import { Proposal } from "@dto/proposal";
import {
  // Roundtable,
  VoteModeEnum,
} from "@dto/roundtable";

import { RoundtableType as RoundtableTypeProp, RoundtableTypeEnum } from "@@types/type";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

import RoundtableFormDialog, {
  ROUNDTABLE_FORM_TYPE,
  Roundtable,
  RoundtableForm,
} from "./RountableFormDialog";

const defaultValues: RoundtableForm = {
  avatar: null,
  title: "",
  type: "",
  language: "",
  institution: "",
  location: "",
  description: "",
  visibility: null,
  passedPercentage: null,
  voteMode: null,
  voteDuration: null,
  requireApplication: false,
};

const transformFn = (value: any) => (isNaN(value) ? null : value);
const formSchema: any = {
  [ROUNDTABLE_FORM_TYPE.PROFILE]: yup.object().shape({
    avatar: yup.mixed().nullable(),
    title: yup.string().required("Title is required."),
  }),
  [ROUNDTABLE_FORM_TYPE.MANAGEMENT]: yup.object().shape({
    visibility: yup.number().required("Visibility is required."),
    passedPercentage: yup
      .number()
      .nullable()
      .transform(transformFn)
      .required("Pass Percentage is required."),
    voteMode: yup
      .mixed<VoteModeEnum>()
      .oneOf(Object.values(VoteModeEnum))
      .required("Vote Mode is required."),
    voteDuration: yup
      .number()
      .nullable()
      .transform(transformFn)
      .required("Vote Duration is required."),
    requireApplication: yup.boolean(),
  }),
};

interface IProps {
  isOpen?: boolean;
  data: Roundtable;
  proposal?: Proposal;
  onClose?: () => void;
  // id: string;
  isViewOnly?: boolean;
  type?: (typeof ROUNDTABLE_FORM_TYPE)[keyof typeof ROUNDTABLE_FORM_TYPE];
  removeDialog?: boolean;
}

const UpsertRoundtableDialog: FC<IProps> = ({
  isOpen,
  onClose,
  isViewOnly,
  type = ROUNDTABLE_FORM_TYPE.MANAGEMENT,
  data,
  proposal,
  removeDialog,
}) => {
  const formMethods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema[type]) as any,
  });
  const {
    reset,
    // trigger,
    // formState: { isDirty },
  } = formMethods;

  const [actionLoading, setActionLoading] = useState(false);
  const { setSnackbar } = useSnackbar();
  const [reloadTime, setReloadTime] = useState(Date.now());

  const { mutate: upsertRoundtable, isLoading: isUpsertRoundtableLoading } =
    useUpdateRoundtableOrProject();

  const resetFormData = () => {
    const {
      avatar,
      title,
      type,
      language,
      institution,
      location,
      description,
      isPublic,
      passedPercentage,
      voteMode,
      voteDuration,
      requireApplication,
    } =
      isViewOnly && proposal
        ? (proposal?.data as RoundtableForm)?.originalValues || {}
        : data;

    reset({
      avatar: isViewOnly && proposal ? data.avatar : avatar,
      title,
      type: isViewOnly && proposal ? data.type : type,
      language,
      institution,
      location,
      description,
      visibility: +isPublic,
      passedPercentage: +passedPercentage,
      voteMode,
      voteDuration: +voteDuration,
      requireApplication,
    });
  };
  useEffect(() => {
    if (!data) {
      return;
    }

    resetFormData();
    setReloadTime(Date.now());
  }, [data, proposal]);

  const handleClickOnSave = async (formData: RoundtableForm) => {
    let avatar = formData?.avatar;
    if (formData?.avatar instanceof File) {
      setActionLoading(true);
      const result = await uploadImagesToServer([formData.avatar]).finally(() =>
        setActionLoading(false)
      );
      avatar = result[0] || null;
    }

    const {
      title,
      type: RTType,
      language,
      institution,
      location,
      description,
      visibility,
      passedPercentage,
      voteMode,
      voteDuration,
      requireApplication,
    } = formData;

    // const payload = {
    //   [ROUNDTABLE_FORM_TYPE.MANAGEMENT]: {
    //     isPublic:  Boolean(visibility),
    //     passedPercentage,
    //     voteMode,
    //     voteDuration
    //   },
    //   [ROUNDTABLE_FORM_TYPE.PROFILE]: {
    //     avatar,
    //     title,
    //     type: RTType as RoundtableTypeProp,
    //     language,
    //     institution,
    //     location,
    //     description,
    //   }
    // }
    const payload = {
      isPublic: Boolean(visibility),
      passedPercentage,
      voteMode,
      voteDuration,
      avatar,
      title,
      type: RTType as RoundtableTypeProp,
      language,
      institution,
      location,
      description,
      requireApplication,
    };
    upsertRoundtable(
      {
        id: data?.id,
        payload: payload,
        proposalType:
          type === ROUNDTABLE_FORM_TYPE.MANAGEMENT
            ? "UPDATE_ROUND_TABLE_MANAGEMENT"
            : "UPDATE_ROUND_TABLE_PROFILE",
      },
      {
        onSuccess: () => {
          setSnackbar({
            message: "Your proposal was created successfully!",
            open: true,
            severity: "success",
          });

          queryClient.invalidateQueries(["getRoundTableDetail", data?.id]);
          queryClient.invalidateQueries(["getEventLogs"]);
          onClose();
          // resetFormData();
        },
        onError: (error) => {
          setSnackbar?.({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <FormProvider {...formMethods}>
      <RoundtableFormDialog
        key={reloadTime}
        title={
          (type === ROUNDTABLE_FORM_TYPE.MANAGEMENT
            ? "Update Teamspace Management"
            : "Update Teamspace Profile") + (isViewOnly ? " (View Only)" : "")
        }
        isOpen={isOpen}
        onClose={onClose}
        type={type}
        data={data}
        proposal={proposal}
        onSave={handleClickOnSave}
        isActionLoading={isUpsertRoundtableLoading || actionLoading}
        isViewOnly={isViewOnly}
        removeDialog={removeDialog}
      />
    </FormProvider>
  );
};

export default UpsertRoundtableDialog;
