import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import useLeaveRoundtable from "@api/roundtable/useLeaveRoundtable";

import { useAppSelector } from "@app/hooks";

import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";
import CustomSnackbar, { ISnackbar } from "@components/Snackbar";

import { Attendee } from "@dto/roundtable";

const ONE_MEMBER_CONFIRMATION_MESSAGE =
  "This Project has only one member. If you leave this Project, it will be deleted also. Are you sure to leave this Project?";

interface IProps {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  currentTotalMembers: number;
  roundtableName: string;
  roundtableId: string;
  attendees: Attendee[];
}

const LeaveProjectDialog: FC<IProps> = ({
  openDialog,
  setOpenDialog,
  currentTotalMembers,
  roundtableId,
  // roundtableName,
  // attendees,
}) => {
  const {
    userInfo: { user },
  } = useAppSelector((state) => state.auth);

  const leaveRoundtable = useLeaveRoundtable();

  const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false });
  const [content, setContent] = useState("Are you sure you want to leave this Project?");

  const shouldChangeContent =
    currentTotalMembers === 1 && content !== ONE_MEMBER_CONFIRMATION_MESSAGE;
  // const isARepresentative = attendees.some(
  //   (attendee) =>
  //     attendee.subRoundTable &&
  //     attendee.subRoundTable.subRoundTableRepresentatives?.some(
  //       (representative) => representative.userId === user.id
  //     )
  // );

  const handleClickLeaveRoundtable = useCallback(() => {
    // if (isARepresentative) {
    //   return setSnackbar({
    //     message: `You're the only representative in ${roundtableName}.
    //     Please update representative before leaving!`,
    //     open: true,
    //     severity: "error",
    //   });
    // }

    if (shouldChangeContent) {
      return setContent(ONE_MEMBER_CONFIRMATION_MESSAGE);
    }

    leaveRoundtable.mutate(
      { roundtableId },
      {
        onSuccess: async () => {
          window.location.href = "/my-roundtables";
        },
      }
    );
  }, [content, shouldChangeContent, leaveRoundtable, roundtableId]);

  return (
    <>
      <CustomConfirmationDialog
        title="Leave Project"
        content={content}
        buttons={[
          {
            variant: "outlined",
            label: "Cancel",
          },
          {
            label: "Leave Project",
            handleOnClickButton: handleClickLeaveRoundtable,
            //prevent unmount before the mutation finishes
            preventDialogClose: true,
            isLoading: leaveRoundtable.isLoading,
          },
        ]}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />

      <CustomSnackbar onClose={() => setSnackbar({ open: false })} {...snackbar} />
    </>
  );
};

export default LeaveProjectDialog;
