import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

import { AttachmentProps } from "@dto/file";

type Request = {
  id: string;
  payload: {
    information: string[];
    links: {
      url: string;
      displayText: string;
    }[];
    videoLinks: {
      url: string;
      displayText: string;
    }[];
    attachments: AttachmentProps[];
    thumbnails: string[];
    isPublish: boolean;
    nonDisclosure: boolean;
    requireApplication: boolean;
  };
};

const usePublishProject = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: async ({ id, payload }: Request) => {
      const { isPublish, nonDisclosure, requireApplication, ...rest } = payload;
      const response = await axios.post(`/round-table/${id}/publish`, {
        publishData: { ...rest },
        isPublish,
        nonDisclosure,
        requireApplication,
      });

      return response.data.data;
    },
    onSuccess,
  });
};

export default usePublishProject;
