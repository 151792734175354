import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  addPaymentMethod,
  useGetPaymentMethods,
  useDeletePaymentMethod,
  useGetClientSecret,
} from "@api/payment/paymentMethod";
import { getConnectedBankAccount } from "@api/payment/withdrawal";
import { getMe } from "@api/user";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Stack, Box, Typography, Avatar, IconButton, useTheme } from "@mui/material";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";

import { useAppSelector } from "@app/hooks";

import { setUser } from "@features/auth/slice";
import { handleApiError } from "@features/config";

import Button from "@components/Button";
import ConfirmActionDialog from "@components/Dialog/ConfirmDialog/ConfirmAction";
import Loading from "@components/Loading";
import TopupDeposit from "@components/TopupDeposit";

import { getBrandIcon } from "@utils/format/stripe";

import { ReactComponent as DepositIcon } from "@assets/icons/deposit.svg";

import { useSnackbar } from "@contexts/SnackbarContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

type PaymentMethodType = {
  selectable?: boolean;
  getCard?: (cardId: string) => void;
};

const PaymentMethod = ({ selectable = false, getCard }: PaymentMethodType) => {
  const [view, setView] = useState("list");
  const [confirmDel, setConfirmDel] = useState("");
  const { setSnackbar } = useSnackbar();
  const [selected, setSelected] = useState("");
  const { userInfo } = useAppSelector((state) => state.auth);
  const { user } = userInfo;
  const dispatch = useDispatch();
  const {
    palette: { common },
  } = useTheme();

  const { mutate: deletePaymentMethodMutation, isLoading: isLoadingDeletePaymentMethod } =
    useDeletePaymentMethod();
  const { isLoading, data, refetch } = useGetPaymentMethods();
  const { data: cs, isLoading: sessionLoading } = useGetClientSecret(view === "add");
  // console.log({ list: data?.data, clientSecret: cs?.data?.client_secret })

  const updateConnectStripeAccount = async () => {
    await getConnectedBankAccount();
    if (!user?.stripeAccountId) {
      const userInfoRes = await getMe();
      const newUserInfo = { ...userInfo };
      newUserInfo.user = {
        ...newUserInfo.user,
        ...userInfoRes.data,
      };
      dispatch(setUser(newUserInfo));
    }
  };

  const deletePaymentMethod = async () => {
    deletePaymentMethodMutation(
      {
        id: confirmDel,
      },
      {
        onSuccess: () => {
          refetch();
          setSnackbar({
            message: "Delete successfully!",
            open: true,
            severity: "success",
          });
        },
        onError: (error) => {
          setSnackbar({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  const selectCard = (cardId: string) => () => {
    setSelected(cardId);
    if (getCard) {
      getCard(cardId);
    }
  };

  return (
    <Box>
      {view === "list" && (
        <Stack gap={2}>
          <TopupDeposit selectable={selectable} />
          <Stack gap={2.5}>
            <Typography fontWeight={"bold"}>Payment methods</Typography>
            {(isLoading || isLoadingDeletePaymentMethod) && (
              <Typography>Loading...</Typography>
            )}
            {!isLoading && !isLoadingDeletePaymentMethod && (
              <>
                {selectable && (
                  <Box
                    onClick={selectCard("deposit")}
                    border={common.border}
                    display={"flex"}
                    borderRadius={4}
                    gap={2}
                    alignItems={"center"}
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                    bgcolor={
                      selected === "deposit" ? "common.featherSoftBlue" : "transparent"
                    }
                  >
                    <DepositIcon />
                    <Typography fontSize={16} fontWeight={700}>
                      My Deposit
                    </Typography>
                  </Box>
                )}
                {data?.data?.map((card, i) => (
                  <Stack
                    key={i}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{
                      cursor: selectable ? "pointer" : "default",
                      ...(selectable && { p: 2, borderRadius: 4 }),
                      backgroundColor:
                        selected === card.id ? "common.featherSoftBlue" : "transparent",
                    }}
                    onClick={selectCard(card.id)}
                  >
                    <Stack
                      gap={2.5}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Avatar
                        variant="square"
                        src={getBrandIcon(card.card?.brand)}
                        alt={card.card?.brand}
                        sx={{ width: 74, height: "max-content" }}
                      >
                        {card.card?.brand}
                      </Avatar>
                      <Stack gap={0}>
                        <Typography
                          fontWeight={"bold"}
                        >{`•••• •••• •••• ${card.card?.last4}`}</Typography>
                        <Typography fontSize={14} color={"common.gray5"}>{`Exp ${dayjs(
                          `${card.card?.exp_year}-${card.card?.exp_month}`
                        ).format("MM/YY")}`}</Typography>
                      </Stack>
                    </Stack>
                    <IconButton
                      sx={{ color: "common.gray3" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmDel(card.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
              </>
            )}
          </Stack>
          <Button
            variant="text"
            sx={{ fontWeight: "normal", textTransform: "unset", width: "max-content" }}
            onClick={() => setView("add")}
          >
            + Add payment method
          </Button>
        </Stack>
      )}
      {view === "add" && (
        <Box id="checkout" width={"100%"}>
          {sessionLoading && <Loading />}
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              // fetchClientSecret: () => new Promise((rs) => rs("client_secret")),
              // fetchClientSecret,
              clientSecret: cs?.data?.client_secret,
              onComplete: async () => {
                await updateConnectStripeAccount();
              },
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>

          <Stack alignItems={"center"} mt={3}>
            <Button
              variant="outlined"
              sx={{ width: "max-content", minWidth: 100 }}
              onClick={() => {
                refetch();
                setView("list");
              }}
            >
              Back
            </Button>
          </Stack>
        </Box>
      )}

      <ConfirmActionDialog
        isOpen={!!confirmDel}
        onClose={() => setConfirmDel("")}
        title="Delete payment method"
        description="Are you sure to delete this item?"
        onSubmit={deletePaymentMethod}
        submitBtnLabel="Delete"
      />
    </Box>
  );
};

export default PaymentMethod;
