/* eslint-disable react/jsx-no-bind */
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

import useUpdateRepresentatives from "@api/roundtable/useUpdateRepresentatives";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Box,
  Chip,
  Avatar,
  Typography,
  Stack,
} from "@mui/material";
import * as yup from "yup";

import FormInput from "@components/FormInput";
import { ISnackbar } from "@components/Snackbar";

import { Roundtable } from "@dto/roundtable";

const formSchema = yup.object().shape({
  parentRoundTableId: yup.string().required("Parent Roundtable is required"),
  representatives: yup.array().of(yup.string()).min(1, "Representatives is required"),
});

export type UpdateSubProjectForm = {
  parentRoundTableId: string;
  representatives: string[];
};

interface IProps {
  parent: Roundtable;
  isOpen: boolean;
  roundtable: Roundtable;
  onClose: () => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
}

const UpdateSubProjectDialog: FC<IProps> = ({
  parent,
  isOpen,
  roundtable,
  onClose,
  setSnackbar,
}) => {
  const representatives = useMemo(() => {
    return roundtable.attendees.filter((attendee) => !!attendee.userId) || [];
  }, [roundtable]);

  const { mutate: updateRepresentatives, isLoading: isLoadingRepresentativeUpdating } =
    useUpdateRepresentatives();

  const defaultValues: UpdateSubProjectForm = useMemo(() => {
    return {
      parentRoundTableId: parent.id,
      representatives:
        roundtable?.attendees
          .filter((attendee) => attendee.isRepresentative)
          .map((attendee) => attendee.attendeeId) || [],
    };
  }, [roundtable, parent.id]);

  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isValid,
      dirtyFields: { representatives: dirtyRepresentatives },
    },
  } = useForm<UpdateSubProjectForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const handleSave = useCallback(
    (formData: UpdateSubProjectForm) => {
      updateRepresentatives(
        {
          roundtableId: roundtable.id,
          representatives: formData.representatives,
        },
        {
          onSuccess: () => {
            setSnackbar({
              message: "Your proposal was created successfully!",
              open: true,
              severity: "success",
            });
          },
          onSettled: () => {
            onClose();
          },
        }
      );
    },
    [onClose, roundtable.id, setSnackbar, updateRepresentatives]
  );

  const mapRepresentativeName = useMemo(() => {
    return (representatives || []).reduce<Record<string, string>>(
      (acc, { attendeeId, name }) => {
        acc[attendeeId] = name;
        return acc;
      },
      {}
    );
  }, [representatives]);

  return (
    <>
      <Dialog fullWidth open={isOpen} maxWidth="sm">
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
          Update Sub-project
        </DialogTitle>

        <DialogContent>
          <Stack spacing={3} mt={1}>
            <FormInput
              required
              select
              control={control}
              name="parentRoundTableId"
              label="Parent Teamspace"
              size="small"
              error={!!errors?.parentRoundTableId?.message}
              helperText={errors?.parentRoundTableId?.message}
              SelectProps={{
                renderValue: () => (
                  <Chip
                    key={`update-subProjectDialog-parentId-${parent.id}`}
                    label={parent.title}
                  />
                ),
              }}
              disabled
              value={parent.id}
            />

            <FormInput
              required
              select
              control={control}
              name="representatives"
              label="Representative"
              size="small"
              error={!!errors?.representatives?.message}
              helperText={errors?.representatives?.message}
              SelectProps={{
                multiple: true,
                renderValue: (selected: any) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value: string) => (
                        <Chip key={value} label={mapRepresentativeName[value]} />
                      ))}
                    </Box>
                  );
                },
              }}
            >
              {representatives.map(({ attendeeId, userId, name, avatar }) => (
                <MenuItem key={userId} value={attendeeId}>
                  <Avatar alt={name} src={avatar} sx={{ width: 32, height: 32, mr: 2 }} />
                  <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{name}</Typography>
                </MenuItem>
              ))}
            </FormInput>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            disabled={isLoadingRepresentativeUpdating}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            disabled={
              !isValid || !dirtyRepresentatives || isLoadingRepresentativeUpdating
            }
            onClick={handleSubmit(handleSave)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateSubProjectDialog;
