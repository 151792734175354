import { MemberManagementType } from "@constants/attendee";

import { Attendee, ManagementEnum } from "@dto/roundtable";

export const renderAccountType = (member: Attendee, managementStyle: string) => {
  if (member.type === MemberManagementType.SUB_ROUNDTABLE) {
    return MemberManagementType.SUB_ROUNDTABLE;
  }

  if (managementStyle === ManagementEnum.DECENTRALIZED) {
    return member.createdAt === member.userId
      ? MemberManagementType.OWNER
      : MemberManagementType.MEMBER;
  }

  if (member.isSuperAdmin) {
    return MemberManagementType.OWNER;
  }

  return +member.voteWeight > 0
    ? MemberManagementType.ADMIN
    : MemberManagementType.MEMBER;
};
