/* eslint-disable no-await-in-loop */
import { useEffect, useState } from "react";

import sendMissedActivity from "@api/job/sendMissedActivity";
import { CONVERSATION_COLlECTION, UNREAD_REALTIME } from "@fb/const";
import { db, realtimeDb } from "@fb/db";
import { get, onValue, ref, update } from "firebase/database";
import { doc, getDoc } from "firebase/firestore";

import { useAppSelector } from "@app/hooks";

import { Missed_Message_Type } from "@pages/chat/Conversation/type";

import { User } from "@dto/user";

export const markInactiveUserUnreadMessage = async ({
  userId,
  activityId,
  projectId,
  sendData,
}: {
  userId: string;
  activityId: string;
  projectId: string;
  sendData?: any;
}) => {
  try {
    const unreadUser = await get(ref(realtimeDb, `${UNREAD_REALTIME}/${userId}`));

    const unreadData = unreadUser?.val() || {};
    // If user is not active in this conversation then set unread
    const unreadActivity = unreadData.quest || [];
    const unreadProjects = unreadData.project || [];
    if (unreadData.active !== projectId) {
      // set unread for project, including send email
      if (!unreadProjects.includes(projectId)) {
        await sendMissedActivity(activityId, {
          ...sendData,
          userId,
          type: Missed_Message_Type.QUEST,
        });
        unreadProjects.push(projectId);
      }
    }
    // set unread for quest
    if (!unreadActivity.includes(activityId)) {
      unreadActivity.push(activityId);
    }

    return { ...unreadData, quest: unreadActivity, project: unreadProjects };
    // }
  } catch (error) {
    console.error(`Error checking status for userId ${userId}:`, error);
  }
};

export const useQuestNotificationsManagement = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const userId = userInfo.user.id;
  const unreadUserRef = ref(realtimeDb, `${UNREAD_REALTIME}/${userId}`);

  const [unreadQuestList, setUnreadQuestList] = useState<string[]>([]);

  useEffect(() => {
    const unreadRef = ref(realtimeDb, `${UNREAD_REALTIME}/${userId}`);

    const unsubscribe = onValue(unreadRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setUnreadQuestList(data?.quest || []);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);
  const updateProjectActiveStatus = async (projectId?: string) => {
    try {
      const unreadUser = await get(unreadUserRef);
      if (unreadUser.exists()) {
        const unreadData = unreadUser?.val() || {};
        let unreadProject = unreadData?.project || [];

        if (unreadProject.includes(projectId)) {
          unreadProject = unreadProject.filter((id: string) => id !== projectId);
        }

        await update(unreadUserRef, {
          ...unreadData,
          project: unreadProject,
          active: projectId ?? "",
        });
      }
    } catch (error) {
      console.error(`Error checking status unread project for userId ${userId}:`, error);
    }
  };

  const updateUnreadQuestStatus = async (questId?: string) => {
    try {
      const unreadUser = await get(unreadUserRef);
      if (unreadUser.exists()) {
        const unreadData = unreadUser?.val() || {};
        let unreadQuest = unreadData?.quest || [];
        if (unreadQuest.includes(questId)) {
          unreadQuest = unreadQuest.filter((id: string) => id !== questId);
        }

        await update(unreadUserRef, {
          ...unreadData,
          quest: unreadQuest,
        });
      }
    } catch (error) {
      console.error(`Error checking status unread quest for userId ${userId}:`, error);
    }
  };

  const doSendNotificationToUsers = async (
    conversationId: string,
    projectId: string,
    sendData?: any
  ) => {
    const activityDoc = await getDoc(doc(db, CONVERSATION_COLlECTION, conversationId));
    const activityData = activityDoc?.data();

    // Get list of participants from activity
    const partner = activityData?.participantsList.filter(
      (u: User) => u.id !== userInfo.user.id
    );
    const offlineUsers: any = {};
    for (const user of partner) {
      const updateData = await markInactiveUserUnreadMessage({
        userId: user.id,
        activityId: conversationId,
        projectId,
        sendData,
      });
      // Collect unread data from inactive users
      if (updateData) {
        offlineUsers[user.id] = updateData;
      }
    }

    // Update realtime unread data for inactive users
    await update(ref(realtimeDb, `${UNREAD_REALTIME}`), offlineUsers);
  };

  return {
    unreadQuestList,
    doSendNotificationToUsers,
    updateProjectActiveStatus,
    updateUnreadQuestStatus,
  };
};
