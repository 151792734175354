import React, { useEffect } from "react";

import useGetApplicationDetails from "@api/job/useGetApplicationDetails";
import { calculateStripeFee } from "@api/payment/paymentMethod";
import { Star as StarIcon } from "@mui/icons-material";
import { Avatar, Box, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { applicationActions } from "@features/application/slice";

import Button from "@components/Button";

import { SERVICE_CHARGE } from "@pages/jobs-management/constants";

import { getMiddleEllipsisText } from "@utils/helpers/stringHelper";
import { roundedEarningNumber } from "@utils/numberHelper";

import LineSeparateText from "./LineSeparateText";

const OderPaymentCheck = () => {
  const { jobId, applicationId: selectedAppId } = useAppSelector(
    (state) => state.application
  );
  const dispatch = useAppDispatch();

  const {
    palette: { common },
  } = useTheme();
  const {
    userInfo: { user },
  } = useAppSelector((state) => state.auth);
  const { paymentMethod } = useAppSelector((state) => state.payment);
  const currentAppId = selectedAppId;
  const { data: app } = useGetApplicationDetails(jobId, currentAppId);
  const standardFree = Number(app.expectBudget) * Number(app.estimation);

  const estimationDay = Math.ceil(Number(app.estimation) / 8);
  const onBacktoApplicationDetail = () => {
    dispatch(applicationActions.setOpenCheckout(false));
    dispatch(applicationActions.setApplicationId(app.id));
    dispatch(applicationActions.setOpenListPanel(true));
    dispatch(applicationActions.setJobId(jobId));
  };

  const { data, refetch } = useQuery(
    ["getStripeFee"],
    () =>
      calculateStripeFee(
        paymentMethod,
        standardFree + standardFree * SERVICE_CHARGE.CLIENT
      ),
    {
      enabled: !!paymentMethod && paymentMethod !== "deposit",
    }
  );

  useEffect(() => {
    if (!!paymentMethod && paymentMethod !== "deposit") {
      refetch();
    }
  }, [paymentMethod]);

  const stripeFee = data?.stripeFee || 0;

  // Constants
  const roundedStandardFree = parseFloat(standardFree.toFixed(2));
  const roundedServiceCharge = parseFloat(
    (standardFree * SERVICE_CHARGE.CLIENT).toFixed(2)
  );
  const roundedStripeFee = parseFloat(stripeFee.toFixed(2));

  // Calculate total and round to 2 decimal places
  const total = parseFloat(
    (roundedStandardFree + roundedServiceCharge + roundedStripeFee).toFixed(2)
  );

  return (
    <Stack
      sx={{
        overflowX: "auto",
        height: "100%",
        position: "relative",
        justifyContent: "space-between",
      }}
    >
      <Stack p={2} flex={1}>
        <Stack flexDirection={"row"} gap={3} flexWrap={"wrap"} mb={2}>
          <Avatar src={app?.createdByUser?.avatar} sx={{ width: 84, height: 84 }} />
          <Stack gap={1.5}>
            <Typography fontWeight={700} fontSize={24}>
              {app?.createdByUser?.name}
            </Typography>
            <Stack flexDirection={"row"} gap={3} flexWrap={"wrap"}>
              <Stack gap={0.3} textAlign={"center"}>
                <Stack
                  flexDirection={"row"}
                  gap={0.7}
                  color={"primary.main"}
                  alignItems={"center"}
                >
                  <StarIcon sx={{ height: 20, width: 20 }} />
                  <Typography fontSize={18} color={"primary"} fontWeight={700}>
                    {Number(app.createdByUser.averageRating || 0).toFixed(1)}/5
                  </Typography>
                </Stack>
                <Typography fontSize={14} color={"common.gray5"}>
                  (
                  {+app.createdByUser.virtualOrderNumber +
                    +app.createdByUser.completedOrderCount}{" "}
                  orders)
                </Typography>
              </Stack>
              <Stack gap={0.3} textAlign={"center"}>
                <Typography fontSize={18} fontWeight={700}>
                  {roundedEarningNumber(app?.createdByUser?.totalEarnings || 0)}
                </Typography>
                <Typography fontSize={14} color={"common.gray5"}>
                  (Total earning)
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack p={4} flexGrow={1}>
          <Typography fontSize={20} fontWeight={700}>
            Invoice
          </Typography>
          <Grid container spacing={2} mt={2}>
            {/* Left part */}
            <Grid item xs={12} md={6}>
              <Box
                bgcolor={"common.deutziaWhite"}
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  borderRadius: 4,
                }}
                gap={2}
                p={2}
              >
                <LineSeparateText
                  startText="Standard Fee:"
                  endText={`$${standardFree.toFixed(2)}`}
                />
                <LineSeparateText
                  startText="Service Fee:"
                  endText={`$${(standardFree * SERVICE_CHARGE.CLIENT).toFixed(2)}`}
                />

                {!!paymentMethod && paymentMethod !== "deposit" && (
                  <LineSeparateText
                    startText="Transaction Fee:"
                    endText={`$${stripeFee ? stripeFee.toFixed(2) : "--"}`}
                  />
                )}
                <Divider />
                <LineSeparateText startText="Total:" endText={`$${total}`} />
              </Box>
            </Grid>

            {/* Right part */}
            <Grid item xs={12} md={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <LineSeparateText startText="Ordered by:" endText={user.name} />
                <LineSeparateText
                  startText="Project name:"
                  endText={getMiddleEllipsisText(app.applyToJob?.project?.title, 50)}
                />
                <LineSeparateText
                  startText="Duration:"
                  endText={`${app.duration} ${
                    Number(app.duration || 0) > 1 ? "days" : "day"
                  }`}
                  // endText={`${estimationDay} ${estimationDay > 1 ? "days" : "day"}`}
                />
                <LineSeparateText
                  startText="Delivery date:"
                  endText={`${dayjs().add(estimationDay, "day").format("MMM D, YYYY")}`}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <Button
          sx={{ width: 300, alignSelf: "center", borderRadius: 2 }}
          variant="outlined"
          onClick={onBacktoApplicationDetail}
        >
          Back
        </Button>
      </Stack>
    </Stack>
  );
};
export default OderPaymentCheck;
