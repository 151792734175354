import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import useCreateEventProposal from "@api/proposal/useCreateEventProposal";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import * as yup from "yup";

import { handleApiError } from "@features/config";

import Button from "@components/Button";
import DatePickerInput from "@components/DatePicker";
import FormInput from "@components/FormInput";
import { ISnackbar } from "@components/Snackbar";
import TimePickerInput from "@components/TimePicker";

import { CreateEventProposalForm, Proposal } from "@dto/proposal";

import { useViewport } from "@contexts/ViewportContext";

import queryClient from "@config/queryClient";

const InitDateTime = (sDate?: Dayjs) => {
  const sourceDate = sDate || dayjs();
  const endOfSourceDate = sourceDate.endOf("day");
  let startTime = sourceDate.add(5, "minute");
  if (startTime.diff(endOfSourceDate, "second") > 0) {
    startTime = endOfSourceDate;
  }
  let endTime = startTime.add(60, "minute");
  if (endTime.diff(endOfSourceDate, "second") > 0) {
    endTime = endOfSourceDate;
  }

  return { date: sourceDate, startTime, endTime };
};

const enum RemiderType {
  optNone = "NONE",
  opt15mins = "FIFTEEN_MINUTE",
  opt30mins = "THIRTY_MINUTE",
  opt45mins = "FORTYFIVE_MINUTE",
  opt1hour = "ONE_HOUR",
  opt1day = "ONE_DAY",
}

const MenuItems: { text: string; value: RemiderType }[] = [
  {
    text: "NONE",
    value: RemiderType.optNone,
  },
  {
    text: "15 mins",
    value: RemiderType.opt15mins,
  },
  {
    text: "30 mins",
    value: RemiderType.opt30mins,
  },
  {
    text: "45 mins",
    value: RemiderType.opt45mins,
  },
  {
    text: "1 hour",
    value: RemiderType.opt1hour,
  },
  {
    text: "1 day",
    value: RemiderType.opt1day,
  },
];
// const UrlRe =
// /((ftp|https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
// /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
const formSchema = yup.object().shape({
  name: yup.string().trim().required("Name is required."),
  startTime: yup.date().required().min(dayjs(), "Please choose future date"),
  // endTime: yup.date().required().min(dayjs(), "Please choose future date").when("startTime", (startTime, schema) => {
  //   if (startTime) {
  //     return schema.min(startTime, "End time has to be after than start time")
  //   }
  //   return schema;
  // }),
  endTime: yup
    .date()
    .required()
    .min(dayjs(), "Please choose future date")
    .test("same_dates_test", "End time must be later than start time.", function (value) {
      const { startTime } = this.parent;
      return value.getTime() > new Date(dayjs(startTime).format()).getTime();
    }),
  // meetingLink: yup.string().matches(UrlRe,"Enter correct url!")
});

export type EventProposalForm = {
  name: string;
  description: string;
  date: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  meetingLink: string;
  reminder: RemiderType;
};

const defaultValues: EventProposalForm = {
  name: "",
  description: "",
  // date: dayjs(),
  // startTime: dayjs().add(5, "minute"),
  // endTime: dayjs().add(65, "minute"),
  date: InitDateTime().date,
  startTime: InitDateTime().startTime,
  endTime: InitDateTime().endTime,
  meetingLink: "",
  reminder: MenuItems[0].value,
};

interface IProps {
  isOpen: boolean;
  onClose: (params?: any) => void;
  setSnackbar?: Dispatch<SetStateAction<ISnackbar>>;
  conversationId?: string;
  isViewOnly?: boolean;
  proposal?: Proposal;
}

const CreateEventProposalDialog: FC<IProps> = ({
  isOpen,
  onClose,
  conversationId,
  setSnackbar,
  isViewOnly,
  proposal,
}) => {
  const { isDesktop } = useViewport();
  const { mutate: createEventProposal, isLoading: isLoadingEventProposalCreation } =
    useCreateEventProposal();

  const disableInput = isLoadingEventProposalCreation || isViewOnly;

  useEffect(() => {
    if (isViewOnly && proposal?.data) {
      const { title, question, from, to, link, reminder } =
        proposal.data as CreateEventProposalForm;
      setValue("name", title);
      setValue("description", question);
      setValue("meetingLink", link);
      setValue("reminder", reminder as RemiderType);
      setValue("startTime", dayjs(from));
      setValue("endTime", dayjs(to));
      setValue("date", dayjs(from));
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    // setError,
    trigger,
    watch,
  } = useForm<EventProposalForm>({
    defaultValues,
    mode: "onChange",
    resolver: !isViewOnly && (yupResolver(formSchema) as any),
  });

  const dateWatch = watch("date");
  const startTimeWatch = watch("startTime");

  useEffect(() => {
    // console.log("dates", { date: dayjs(dateWatch).toString(), start: dayjs(getValues("startTime")).toString(), end: dayjs(getValues("endTime")).toString() })
    // const todayStart = dayjs().startOf("day");
    // const todayEnd = dayjs().endOf("day");
    // const insideDate = dateWatch.diff(todayStart, "second") >= 0 && dateWatch.diff(todayEnd, "second") <= 0;
    // const { startTime, endTime } = InitDateTime(insideDate ? null : dateWatch);
    if (isViewOnly) {
      return;
    }
    const { startTime, endTime } = InitDateTime(dateWatch);

    setValue("startTime", startTime);
    setValue("endTime", endTime);
    setTimeout(() => {
      trigger(["startTime", "endTime"]);
    }, 400);
  }, [dayjs(dateWatch).format("DD/MM/YYYY HH:mm")]);
  useEffect(() => {
    trigger("endTime");
  }, [startTimeWatch]);

  useEffect(() => {
    if (!isViewOnly && isOpen) {
      setValue("date", dayjs());
    }
  }, [isOpen]);
  // const sleep = (delay?: number) => new Promise((res) => setTimeout(() => res(1), delay || 3000))
  const _onSave = async (formData: EventProposalForm) => {
    const {
      name: title,
      description: question,
      meetingLink: link,
      startTime,
      endTime,
      reminder,
    } = formData;

    createEventProposal(
      {
        roundtableId: conversationId,
        payload: {
          title,
          question,
          link,
          from: dayjs(startTime).toString(),
          to: dayjs(endTime).toString(),
          reminder,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getEventLogs", "getEventProposals"]);
          onClose({
            confirmation: {
              title: "Proposal submitted",
              description:
                "Your proposal was successfully created and is currently awaiting approval from the Teamspace.",
            },
          });
        },
        onError: (error) => {
          if (setSnackbar) {
            setSnackbar({
              message: handleApiError(error).message,
              open: true,
              severity: "error",
            });
          }
        },
      }
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isOpen}
        maxWidth={isDesktop ? "sm" : "lg"}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            m: 2,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
          Event Proposal {isViewOnly && "(View Only)"}
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={{ md: 3, xs: 2 }}>
              <Grid item xs={12}>
                <FormInput
                  required
                  control={control}
                  name="name"
                  label="Name"
                  disabled={disableInput}
                  error={!!errors?.name?.message}
                  helperText={errors?.name?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  control={control}
                  name="description"
                  label="Description"
                  disabled={disableInput}
                  multiline
                  rows={4}
                  error={!!errors?.description?.message}
                  helperText={errors?.description?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <DatePickerInput
                      textFieldProps={{
                        fullWidth: true,
                        required: true,
                        disabled: disableInput,
                      }}
                      disabled={disableInput}
                      control={control}
                      name="date"
                      label="Date"
                      size="large"
                      disablePast={!isViewOnly}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={3}>
                    <TimePickerInput
                      textFieldProps={{
                        fullWidth: true,
                        required: true,
                        disabled: disableInput,
                        error: !!errors.startTime,
                        helperText: errors.startTime?.message,
                      }}
                      control={control}
                      name="startTime"
                      label="From"
                      size="large"
                      // disablePast={!isViewOnly}
                      disablePast={false}
                      // onError={(e) => setError("startTime", { type: "custom", message: e })}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={3}>
                    <TimePickerInput
                      textFieldProps={{
                        fullWidth: true,
                        required: true,
                        disabled: disableInput,
                        error: !!errors.endTime,
                        helperText: errors.endTime?.message,
                      }}
                      control={control}
                      name="endTime"
                      label="To"
                      size="large"
                      // disablePast={!isViewOnly}
                      disablePast={false}
                      // onError={(e) => setError("endTime", { type: "custom", message: e })}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  fullWidth
                  control={control}
                  name="meetingLink"
                  label="Meeting link"
                  disabled={disableInput}
                  error={!!errors?.meetingLink?.message}
                  helperText={errors?.meetingLink?.message}
                />
              </Grid>
            </Grid>
          </Box>

          <Stack rowGap={2} mt={4}>
            <Typography sx={{ fontSize: 16, fontWeight: 700 }}>Reminder</Typography>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Controller
                  control={control}
                  name="reminder"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      disabled={disableInput}
                      // sx={{ px: 1.5, width: 140, boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.10)" }}
                    >
                      {MenuItems.map(({ text, value }) => (
                        <MenuItem key={value} value={value}>
                          {text}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            disabled={isLoadingEventProposalCreation}
            onClick={onClose}
          >
            Cancel
          </Button>

          {!isViewOnly && (
            <Button
              loading={isLoadingEventProposalCreation}
              disabled={!isValid || isLoadingEventProposalCreation}
              onClick={handleSubmit(_onSave)}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateEventProposalDialog;
