import React from "react";

import { useGetOrderDetails, useGetOrderInvoice } from "@api/orders/useHandleOrders";
import { Skeleton, Stack } from "@mui/material";

import CollapseSection from "@pages/dashboard/components/MyOrders/Collapse";
import InvoiceDetails from "@pages/dashboard/components/MyOrders/InvoiceDetails";
import JobDetails from "@pages/dashboard/components/MyOrders/JobDetails";

import { Order } from "@dto/order";

import CommonDialog from "../CommonDialog";

type OrderDetailProps = {
  open: boolean;
  onClose: () => void;
  selectedOrder: string;
};

const OrderDetailDialog = (props: OrderDetailProps) => {
  const { open, onClose, selectedOrder } = props;
  const { data: orderDetailsData, isLoading: isOrderDetailsLoading } =
    useGetOrderDetails(selectedOrder);

  const { data: orderInvoiceData, isLoading: isLoadingOrderInvoice } =
    useGetOrderInvoice(selectedOrder);

  const loading = isOrderDetailsLoading || isLoadingOrderInvoice;
  return (
    <CommonDialog
      isOpen={open}
      title="Order details"
      onClose={onClose}
      sx={{ "& .MuiDialogContent-root": { bgcolor: "#F4F5F7" } }}
    >
      <Stack gap={3}>
        <CollapseSection title="Invoice" defaultExpanded>
          {loading ? (
            <Stack borderRadius={3} gap={2}>
              <Skeleton variant="rectangular" width={200} height={20} />
              <Skeleton variant="rectangular" width={300} height={40} />
              <Skeleton variant="rectangular" width={400} height={40} />
            </Stack>
          ) : (
            <InvoiceDetails data={orderInvoiceData} jobDetails={orderDetailsData} />
          )}
        </CollapseSection>
      </Stack>
    </CommonDialog>
  );
};
export default OrderDetailDialog;
