import { useCallback, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { User } from "@dto/user";

type Response = {
  data: User[];
  totalItems: number;
};

export interface ContactedP2PUserRequest {
  id: string;
  email?: string;
}

const getContactedP2PUsers = async ({ email }: { email: string }): Promise<Response> => {
  const response = await axios.get(`/suggested-users`, {
    params: {
      email,
    },
  });

  return response.data.data;
};

const useGetContactedP2PUsers = (initialParams: ContactedP2PUserRequest) => {
  const [params, setParams] = useState(initialParams);

  const { data, refetch, ...rest } = useQuery(["getContactedP2PUsers", params.id], () =>
    getContactedP2PUsers({ email: params.email })
  );

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  const handleChangeParams = useCallback((params: Partial<ContactedP2PUserRequest>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  return {
    data: data?.data || [],
    totalItems: data?.totalItems || 0,
    params,
    refetch,
    handleChangeParams,
    ...rest,
  };
};

export default useGetContactedP2PUsers;
