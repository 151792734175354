import { FC } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  MenuItem,
  SxProps,
  TextField,
  Avatar,
  Alert,
  Stack,
} from "@mui/material";
import dayjs from "dayjs";

import { EditRoundtableForm } from "@components/Dialog/AddEditRoundtableDialog/EditRoundtableDialog";
import HelperText from "@components/HelperText";

import { Proposal } from "@dto/proposal";
import { Roundtable } from "@dto/roundtable";

enum VisibilityOption {
  PRIVATE,
  PUBLIC,
}
type Visibility = {
  value: VisibilityOption;
  text: string;
};
const visibilities: Visibility[] = [
  {
    value: VisibilityOption.PUBLIC,
    text: "Public",
  },
  {
    value: VisibilityOption.PRIVATE,
    text: "Private",
  },
];

enum VoteModeOption {
  SIMPLE_VOTE = "SIMPLE_VOTE",
  WEIGHT_COMMON_VOTE = "WEIGHT_COMMON_VOTE",
  ELECTORS_VOTE = "ELECTORS_VOTE",
}
type VoteMode = {
  value: VoteModeOption;
  text: string;
};
const voteModes: VoteMode[] = [
  {
    value: VoteModeOption.SIMPLE_VOTE,
    text: "Simple",
  },
  {
    value: VoteModeOption.WEIGHT_COMMON_VOTE,
    text: "Weight Common",
  },
  {
    value: VoteModeOption.ELECTORS_VOTE,
    text: "Electors",
  },
];

const titleSxProps: SxProps = { fontSize: 16, fontWeight: 700, mb: 1 };
const helperTextSxProps: SxProps = {
  "& .MuiFormHelperText-root": {
    mx: 0,
    mt: 1,
  },
};

interface IProps {
  isOpen: boolean;
  roundtable: Roundtable;
  proposal: Proposal;
  onClose: () => void;
}

const ViewUpdatedSettingsDialog: FC<IProps> = ({ isOpen, proposal, onClose }) => {
  const { avatar, type } = proposal.roundTable;

  const { endAt, startAt, createdByUser, data } = proposal;
  const { originalValues = {} } = (data as EditRoundtableForm) || {};
  const endDate = dayjs(endAt);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography component="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
          Updated Settings (View Only)
        </Typography>

        <CloseIcon
          sx={{
            width: 24,
            height: 24,
            color: "common.black",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
      </DialogTitle>

      <DialogContent>
        <Alert
          severity="info"
          icon={<InfoIcon fontSize="inherit" />}
          sx={{ alignItems: "center", color: "common.black", px: { xs: 1.5, sm: 2 } }}
        >
          <Typography sx={{ fontSize: 14 }}>
            The Teamspace is under approval. Expired by:{" "}
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              {dayjs.duration(endDate.diff(dayjs())).humanize(true)}
            </Typography>
          </Typography>

          <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
            <Typography component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
              Last edited by:
            </Typography>

            <Stack direction="row" spacing={0.5} alignItems="center" mx={1}>
              <Avatar
                alt="Avatar"
                src={createdByUser?.avatar}
                sx={{ width: 20, height: 20 }}
              />

              <Typography
                component="span"
                sx={{ fontSize: 14, fontWeight: 600, color: "common.partyTime" }}
              >
                {createdByUser?.name}
              </Typography>
            </Stack>

            <Typography component="span" sx={{ fontSize: 14 }}>
              {dayjs(startAt).format("hh:mm A, YYYY MMM DD")}
            </Typography>
          </Stack>
        </Alert>

        <Box sx={{ mt: 1 }}>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={12} md={4}>
              <Avatar alt="Avatar" src={avatar} sx={{ width: 120, height: 120 }} />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                required
                disabled
                label="Teamspace Title"
                size="small"
                value={originalValues.title}
                helperText={
                  <HelperText
                    data={data as Roundtable}
                    field="title"
                    currentValue={originalValues.title}
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Typography variant="h6" sx={titleSxProps}>
            Teamspace Details
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Type"
                size="small"
                value={type}
                sx={helperTextSxProps}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                select
                disabled
                label="Visibility"
                size="small"
                value={+originalValues.isPublic}
                helperText={
                  <HelperText
                    data={{ isPublic: +(data as Roundtable).isPublic }}
                    field="isPublic"
                    currentValue={+originalValues.isPublic}
                    formatContent={(isPublic) => (isPublic ? "Public" : "Private")}
                  />
                }
                sx={helperTextSxProps}
              >
                {visibilities.map(({ value, text }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                disabled
                label="Pass Percentage (%)"
                size="small"
                value={+originalValues.passedPercentage}
                helperText={
                  <HelperText
                    data={data as Roundtable}
                    field="passedPercentage"
                    currentValue={+originalValues.passedPercentage}
                    suffix="(%)"
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                select
                disabled
                label="Vote Mode"
                size="small"
                value={originalValues.voteMode}
                helperText={
                  <HelperText
                    data={data as Roundtable}
                    field="voteMode"
                    currentValue={originalValues.voteMode}
                    formatContent={(voteMode) => {
                      if (voteMode === VoteModeOption.SIMPLE_VOTE) {
                        return "Simple";
                      }

                      if (voteMode === VoteModeOption.WEIGHT_COMMON_VOTE) {
                        return "Weight Common";
                      }

                      return "Electors";
                    }}
                  />
                }
                sx={helperTextSxProps}
              >
                {voteModes.map(({ value, text }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                disabled
                label="Vote Duration (hrs)"
                size="small"
                type="number"
                value={+originalValues.voteDuration}
                helperText={
                  <HelperText
                    data={data as Roundtable}
                    field="voteDuration"
                    currentValue={+originalValues.voteDuration}
                    suffix="(hrs)"
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Language"
                size="small"
                type="string"
                value={originalValues.language}
                helperText={
                  <HelperText
                    data={data as Roundtable}
                    field="language"
                    currentValue={originalValues.language}
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Institution"
                size="small"
                type="string"
                value={originalValues.institution}
                helperText={
                  <HelperText
                    data={data as Roundtable}
                    field="institution"
                    currentValue={originalValues.institution}
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Location"
                size="small"
                value={originalValues.location}
                helperText={
                  <HelperText
                    data={data as Roundtable}
                    field="location"
                    currentValue={originalValues.location}
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                disabled
                maxRows={5}
                minRows={5}
                label="Description"
                value={originalValues.description}
                helperText={
                  <HelperText
                    data={data as Roundtable}
                    field="description"
                    currentValue={originalValues.description}
                  />
                }
                sx={helperTextSxProps}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewUpdatedSettingsDialog;
