import { useCallback, useEffect, useMemo, useState } from "react";

import useGetJobsByProject from "@api/job/useGetJobsByProject";
import { Grid, Skeleton, Stack, Typography, Button, useTheme } from "@mui/material";
import { ProtectedPaths } from "@routers/path/ProtectedPath";

import useHandleJobMngLayout from "@hooks/useHandleJobMngLayoutByQuery";

import { useAppSelector } from "@app/hooks";

import ContentWrapper from "@components/ContentWrapper";

import { GetJobByProjectDto } from "@dto/job";
import { Attendee, Roundtable } from "@dto/roundtable";

import QuickAddQuest from "./AddQuest";
import { useCollapseTable } from "./CollapseContext";
import EditInlineQuest from "./EditInlineQuest";
import HiringQuestApplicationDetails from "./HiringQuestApplicationDetails";
import JobsTable from "./JobsTable";
import QuestActivity from "./QuestActivity";
import { useSelectJob } from "./SelectJobContext";
import UpdateQuest from "./UpdateQuest";
import { JOB_STATUS, JobStatusColor, VIEW } from "./constants";
import { TDataType } from "./types";
import useCommonEvents from "./useCommonEvents";

type JobManagementLayoutType = {
  projectId: string;
  isFreelancer?: boolean;
  roundtableData: Roundtable;
};

const JobManagementLayout = (props: JobManagementLayoutType) => {
  const { projectId, isFreelancer, roundtableData } = props;
  const { data, isLoading } = useGetJobsByProject(projectId);
  const { isCollapsed, toggleCollapse } = useCollapseTable();
  const { jobSelected } = useSelectJob();
  const { allQueries, appendQuery, setQueries, regenerateLayoutQueries } =
    useHandleJobMngLayout();
  const { onClickQuestRow } = useCommonEvents();
  const {
    palette: { common },
  } = useTheme();
  const { userInfo } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const selectedQuest = data?.jobs?.find((j) =>
      [j.shortId, j.id].includes(allQueries.job)
    );
    if (allQueries.job && !selectedQuest) {
      toggleCollapse(true);
    }
    if (!isLoading && selectedQuest) {
      // list participants in roundtable not freelancer
      const assignedFreelancer = selectedQuest.orders?.[0]?.freelancerId;
      const activityMembers = roundtableData.attendees.filter(
        (a) => !a.isFreelancer || a.userId === assignedFreelancer
      );

      onClickQuestRow(selectedQuest, activityMembers);
    }
  }, [allQueries.job, isLoading, isFreelancer]);

  useEffect(() => {
    if (!jobSelected || !allQueries.job) {
      return;
    }
    const selectedQuest = data?.jobs?.find((j) =>
      [j.shortId, j.id].includes(allQueries.job)
    );
    setQueries({
      job: selectedQuest.shortId,
      ...regenerateLayoutQueries({ isFreelancer, status: jobSelected.status }),
      ...allQueries,
    } as any);
  }, [jobSelected, allQueries]);

  const notFoundQuest = useMemo(() => {
    const selectedQuest = data?.jobs?.find((j) =>
      [j.shortId, j.id].includes(allQueries.job)
    );

    if (!selectedQuest) {
      return true;
    }
    if (selectedQuest?.status === JOB_STATUS.HIRING) {
      return false;
    }
    if (isFreelancer) {
      return (
        selectedQuest?.status === JOB_STATUS.TODO ||
        !selectedQuest.orders?.some((order) => order.freelancer.id === userInfo?.user?.id)
      );
    }

    return false;
  }, [allQueries.job, isLoading, isFreelancer]);

  // useEffect(() => {
  //   if (!isFetching) {
  //     const currentSelectedFromServer = data?.jobs?.find((j) => j.id === jobSelected?.id);
  //     if (currentSelectedFromServer) {
  //       setJobSelected({ ...jobSelected, ...currentSelectedFromServer });
  //     }
  //   }
  // }, [isFetching]);

  const renderViewById = useCallback(
    (viewId: string | number) => {
      const viewIdNumber = Number(viewId || 0);
      // const { display, ...restProps } = viewProps;
      // if (display === 'none' || viewIdNumber <= 0 || !jobSelected?.id) {
      //   return
      // }
      if (viewIdNumber <= 0 || !jobSelected?.id) {
        return;
      }

      switch (viewIdNumber) {
        case 3:
          return <EditInlineQuest maxHeight={"calc(100vh - 205px)"} />;
        case 4:
          return (
            <ContentWrapper
              title="Freelancer details"
              borderRadius={4}
              border={common.border}
              height={"calc(100vh - 205px)"}
            >
              <HiringQuestApplicationDetails />
            </ContentWrapper>
          );
        case 5:
          return <QuestActivity roundtableData={roundtableData} jobId={jobSelected.id} />;
        case 6:
          return (
            <Stack
              borderRadius={4}
              border={common.border}
              height={"calc(100vh - 205px)"}
              overflow={"auto"}
            >
              <UpdateQuest />
            </Stack>
          );
        case 7:
          return (
            <EditInlineQuest
              maxHeight={"calc(100vh - 205px)"}
              onBack={() => appendQuery({ l: VIEW[1].id, m: VIEW[9].id })}
              isReadOnly
            />
          );
        case 9:
          return (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              p={2}
              height={"100%"}
              border={common.border}
              borderRadius={4}
            >
              <Typography
                fontWeight={700}
                fontSize={18}
                fontStyle={"italic"}
                textAlign={"center"}
              >{`Please select a quest`}</Typography>
            </Stack>
          );
        default:
          return;
      }
    },
    [jobSelected?.id, allQueries.job, allQueries.l, allQueries.m]
  );

  // const viewProps = useMemo(() => {
  //   if (!isFreelancer && jobSelected?.status === JOB_STATUS.TODO) {
  //     return {}
  //   }
  //   if (!isFreelancer && jobSelected?.status === JOB_STATUS.HIRING) {
  //     return {}
  //   }
  //   if ([JOB_STATUS.IN_PROGRESS, JOB_STATUS.DONE, JOB_STATUS.DELIVERED].includes(jobSelected?.status)) {
  //     return { roundtableData: roundtableData, jobId: jobSelected.id }
  //   }
  //   return { display: 'none' }
  // }, [jobSelected])

  return isLoading ? (
    <Stack>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Stack>
  ) : (
    <Grid
      container
      wrap="nowrap"
      // alignItems={"flex-start"}
      alignItems={"stretch"}
      spacing={2}
      sx={{
        "& > .MuiGrid-item:first-child": {
          padding: 0,
          borderRadius: 4,
        },
      }}
    >
      <Grid
        item
        xs={isCollapsed ? 4 : 12}
        sx={{
          transition: "all 0.5s ease",
          maxHeight: "calc(100vh - 204px)",
          overflow: "auto",
          mt: 2,
          ml: 2,
          // border: common.border,
        }}
      >
        {jobSelected && allQueries.l == 7 ? (
          renderViewById(
            allQueries.l
            // viewProps
          )
        ) : (
          <CollapseTable
            jobs={data?.jobs}
            isFreelancer={isFreelancer}
            projectId={projectId}
            roundtableMembers={roundtableData.attendees}
          />
        )}
      </Grid>
      {isCollapsed && (
        <Grid item xs={isCollapsed ? 8 : 0}>
          {notFoundQuest ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              p={2}
              height={"100%"}
              border={common.border}
              borderRadius={4}
            >
              {!allQueries?.job ? (
                <Typography
                  fontWeight={700}
                  fontSize={18}
                  fontStyle={"italic"}
                  textAlign={"center"}
                >
                  Select a quest to view
                </Typography>
              ) : (
                <>
                  <Typography
                    fontWeight={700}
                    fontSize={18}
                    fontStyle={"italic"}
                    textAlign={"center"}
                  >
                    {`Sorry, this quest is not available or you don't have permission to view this link`}
                  </Typography>
                  <Button
                    LinkComponent={"a"}
                    href={ProtectedPaths.dashboard}
                    sx={{ width: "fit-content" }}
                  >
                    Go back
                  </Button>
                </>
              )}
            </Stack>
          ) : (
            jobSelected && allQueries.m && renderViewById(allQueries.m)
          )}
        </Grid>
      )}
    </Grid>
  );
};
export default JobManagementLayout;

const CollapseTable = ({
  jobs,
  isFreelancer,
  projectId,
  roundtableMembers,
}: {
  jobs: GetJobByProjectDto[];
  isFreelancer?: boolean;
  projectId: string;
  roundtableMembers: Attendee[];
}) => {
  const {
    palette: { common },
  } = useTheme();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { user } = userInfo;
  const [_, setReload] = useState(Date.now());

  const tableData: TDataType = useMemo(() => {
    return {
      TODO: {
        title: "To do",
        status: JOB_STATUS.TODO,
        color: JobStatusColor.TODO,
        data:
          jobs?.length && !isFreelancer
            ? jobs.filter((job) => !job.status || job.status === JOB_STATUS.TODO)
            : [],
      },
      HIRING: {
        title: "Hiring",
        status: JOB_STATUS.HIRING,
        color: JobStatusColor.HIRING,
        data: jobs?.length
          ? jobs.filter((job) => !job.status || job.status === JOB_STATUS.HIRING)
          : [],
      },
      IN_PROGRESS: {
        title: "In progress",
        status: JOB_STATUS.IN_PROGRESS,
        color: JobStatusColor.IN_PROGRESS,
        data: jobs?.length
          ? jobs?.filter(
              (job) =>
                job.status === JOB_STATUS.IN_PROGRESS &&
                //client can see the jobs
                (!isFreelancer ||
                  // assigned freelancer can see the jobs
                  job.orders.some((order) => order.freelancer.id === user.id))
            )
          : [],
      },
      DELIVER: {
        title: "Delivered",
        status: JOB_STATUS.DELIVERED,
        color: JobStatusColor.DELIVERED,
        data: jobs?.length
          ? jobs?.filter(
              (job) =>
                job.status === JOB_STATUS.DELIVERED &&
                //client can see the jobs
                (!isFreelancer ||
                  // assigned freelancer can see the jobs
                  job.orders.some((order) => order.freelancer.id === user.id))
            )
          : [],
      },
      DONE: {
        title: "Done",
        status: JOB_STATUS.DONE,
        color: JobStatusColor.DONE,
        data: jobs?.length
          ? jobs?.filter(
              (job) =>
                job.status === JOB_STATUS.DONE &&
                //client can see the jobs
                (!isFreelancer ||
                  // assigned freelancer can see the jobs
                  job.orders.some((order) => order.freelancer.id === user.id))
            )
          : [],
      },
    };
  }, [jobs]);

  const handleAddQuest = (data: any) => {
    if (!tableData.TODO) {
      return;
    }
    tableData.TODO.data.unshift({
      id: "Fake_" + String(Date.now()),
      projectId,
      status: "TO_DO",
      title: "new",
      budget: null,
      priority: null,
      createdByUser: {
        id: user.id,
        name: user.name,
        avatar: user.avatar,
      },
      orders: [],
      ...data,
    });

    setReload(Date.now());
  };

  return (
    <Stack
      width={"100%"}
      gap={3}
      sx={{
        pb: 2,
        overflow: "auto",
        // maxHeight: "100%",
        border: common.border,
        borderRadius: 4,
      }}
    >
      {Object.values(tableData).map((item, i) => {
        return item.status === "TO_DO" && !isFreelancer ? (
          <Stack key={i}>
            <JobsTable {...item} />
            <Stack px={5} py={1.5}>
              <QuickAddQuest projectId={projectId} onSubmit={handleAddQuest} />
            </Stack>
          </Stack>
        ) : (
          item.status !== "TO_DO" && !!item.data.length && (
            <JobsTable key={i} {...item} isFreelancer={isFreelancer} />
          )
        );
      })}
    </Stack>
  );
};
