import { FC, useState } from "react";

import { useStartHiringFreelancerForQuest } from "@api/job/hireFreelancer";
import { Stack, useTheme } from "@mui/material";

import useHandleJobManagementLayoutByQuery from "@hooks/useHandleJobMngLayoutByQuery";

import Button from "@components/Button";
import ConfirmActionDialog from "@components/Dialog/ConfirmDialog/ConfirmAction";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

import { useSelectJob } from "./SelectJobContext";
import { JOB_STATUS, VIEW } from "./constants";

type PropTypes = {
  onSubmit?: (cb?: () => void) => void;
};

const StartHiringQuestBtn: FC<PropTypes> = ({ onSubmit }) => {
  const {
    palette: { common },
  } = useTheme();
  const [openConfirm, setOpenConfirm] = useState(false);
  const { mutate: startHiring, isLoading } = useStartHiringFreelancerForQuest();
  const { setSnackbar } = useSnackbar();
  const { jobSelected, setJobSelected } = useSelectJob();
  const { appendQuery } = useHandleJobManagementLayoutByQuery();
  const handleSubmit = async () => {
    await startHiring(jobSelected.id, {
      onSuccess: async (_apiData) => {
        // setSnackbar({
        //   message: "Start hiring success.",
        //   open: true,
        //   severity: "success",
        // });
        setOpenConfirm(true);
        await queryClient.invalidateQueries(["getJobsByProject", jobSelected.projectId]);
        await queryClient.invalidateQueries(["getQuestDraft", jobSelected.id]);
        appendQuery({ l: VIEW[1].id, m: VIEW[4].id, r: VIEW[8].id });
      },
      onError: (_error) => {
        setSnackbar({
          message: "Start hiring failed.",
          open: true,
          severity: "error",
        });
        queryClient.invalidateQueries(["getJobsByProject", jobSelected.projectId]);
        queryClient.invalidateQueries(["getQuestDraft", jobSelected.id]);
      },
    });
  };

  if (!jobSelected?.id || jobSelected.status !== JOB_STATUS.TODO) {
    return;
  }

  return (
    <Stack>
      <Button
        disabled={isLoading}
        onClick={() => {
          onSubmit(() => {
            setJobSelected({ ...jobSelected, status: JOB_STATUS.HIRING });
            handleSubmit();
          });
        }}
        sx={{
          height: 36,
          borderRadius: 2,
          bgcolor: "black",
          color: "white",
          border: common.border,
          ":hover": { bgcolor: "black" },
          "&.Mui-disabled": { bgcolor: "#0000001f" },
          width: "max-content",
        }}
      >
        Start hiring
      </Button>
      <ConfirmActionDialog
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        // onSubmit={() => onSubmit(callServer)}
        title="Start hiring"
        description="Your quest has been changed to Hiring"
        // description="Are you sure you want to hire a freelancer for this quest?"
        cancelBtnLabel="Ok"
      />
    </Stack>
  );
};

export default StartHiringQuestBtn;
