import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

// import { useNavigate, useSearchParams, useLocation, createSearchParams } from "react-router-dom";
import { JOB_STATUS, VIEW, ACTION } from "@pages/jobs-management/constants";

type QueryType = {
  [x: string]: unknown;
  job?: string;
  act?: string | number;
  l?: string | number;
  m?: string | number;
  r?: string | number;
};
type JobStatusType = (typeof JOB_STATUS)[keyof typeof JOB_STATUS];

const useHandleJobManagementLayoutByQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const allQueries: QueryType = useMemo(() => {
    return Object.fromEntries(searchParams.entries());
  }, [searchParams]);

  const appendQuery = useCallback(
    (params?: Record<string, any>) => {
      setSearchParams({ ...allQueries, ...params });
    },
    [allQueries]
  );

  const regenerateLayoutQueries = useCallback(
    ({ isFreelancer, status }: { isFreelancer?: boolean; status?: JobStatusType }) => {
      // const currentQueries = { act: allQueries.act || '' }
      const currentQueries: any = { r: "" };
      if (!isFreelancer && status === JOB_STATUS.TODO) {
        currentQueries.l = VIEW[1].id;
        currentQueries.m = VIEW[3].id;
      }
      if (status === JOB_STATUS.HIRING) {
        currentQueries.l = VIEW[1].id;
        currentQueries.m = VIEW[4].id;
        currentQueries.r = VIEW[8].id;
        if (isFreelancer) {
          currentQueries.l = VIEW[7].id;
          currentQueries.m = VIEW[6].id;
          currentQueries.r = "";
        }
      }
      if (
        [JOB_STATUS.IN_PROGRESS, JOB_STATUS.DONE, JOB_STATUS.DELIVERED].includes(status)
      ) {
        currentQueries.l = VIEW[1].id;
        currentQueries.m = VIEW[5].id;
        if (status === JOB_STATUS.DELIVERED && !isFreelancer) {
          currentQueries.r = VIEW[10].id;
        }
      }

      // if (allQueries.act == ACTION[1].id) {
      //   currentQueries.l = VIEW[7].id;
      // }

      return currentQueries;
    },
    [allQueries]
  );
  // console.log('Render useHandleJobManagementLayoutByQuery')

  return {
    allQueries,
    setQueries: setSearchParams,
    appendQuery,
    regenerateLayoutQueries,
  };
};

export default useHandleJobManagementLayoutByQuery;
