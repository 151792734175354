import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

interface IRequest {
  roundtableId: string;
  representatives: string[];
}

const useUpdateRepresentatives = () => {
  const updateRepresentatives = async ({ roundtableId, representatives }: IRequest) => {
    return await axios({
      method: "put",
      url: `/round-tables/${roundtableId}/representatives`,
      data: {
        representatives
      }
    });
  };

  return useMutation(updateRepresentatives);
};

export default useUpdateRepresentatives;
