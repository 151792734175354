import { useState } from "react";

import { writeReviewOrder } from "@api/orders/reviewOrder";
import { Stack, Typography, Divider, Chip, Avatar, Button } from "@mui/material";
import dayjs from "dayjs";

// import { Check as CheckIcon } from '@mui/icons-material';
import ReviewDialog from "@components/Dialog/ReviewDialog";

import { SERVICE_CHARGE } from "@pages/jobs-management/constants";

import { formatNumberString } from "@utils/format/number";

import { ReactComponent as ReviewIcon } from "@assets/icons/review.svg";

import { Invoice, Order } from "@dto/order";

import queryClient from "@config/queryClient";

import { Order_Status } from "./types";
import { formatOrderStatus } from "./utils";

const InvoiceDetails = ({ data, jobDetails }: { data?: Invoice; jobDetails?: Order }) => {
  const [openReview, setOpenReview] = useState(false);

  if (!data) {
    return;
  }

  const { order, paymentIntent } = data;
  const receivedFee = Number(order.price);
  const standardFee = receivedFee / (1 + SERVICE_CHARGE.CLIENT);

  const onSubmitReviewOrder = async (star: number, review: string) => {
    const res = await writeReviewOrder({ star, review, orderId: order?.id });
    if (res) {
      queryClient.invalidateQueries(["getOrderLists"]);
      setOpenReview(false);
    }
  };

  return (
    <Stack gap={4}>
      <Stack flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
        <Stack flexDirection={"row"} flexWrap={"wrap"} alignItems={"center"} gap={3}>
          <Avatar
            src={order.freelancer?.avatar}
            variant="rounded"
            sx={{ width: 155, height: 87, borderRadius: 3 }}
          />
          <Stack gap={1.2}>
            <Typography fontSize={20} fontWeight={700} lineHeight={"28px"}>
              {order.job?.title}
            </Typography>
            <Typography>
              Ordered from{" "}
              <Typography component={"span"} fontWeight={700} color={"primary.main"}>
                {order.freelancer?.name}
              </Typography>
            </Typography>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Typography>{"Total price"}</Typography>
          <Typography fontSize={20} fontWeight={700}>{`$${formatNumberString(
            Number(receivedFee).toFixed(2)
          )}`}</Typography>
        </Stack>
      </Stack>
      <Stack flexDirection={"row"} flexWrap={"wrap"} gap={4}>
        <Stack
          gap={1}
          bgcolor={"common.deutziaWhite"}
          p={3}
          borderRadius={3}
          width={{ md: "55%" }}
        >
          {/* <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontSize={14}><CheckIcon sx={{ width: 20, height: 20 }} /> Standard package</Typography>
            <Typography fontSize={14} fontWeight={700}>$300.00</Typography>
          </Stack>
          <Divider sx={{ borderColor: "common.bellflowerBlue", my: 1.5 }} />
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontSize={14}><CheckIcon sx={{ width: 20, height: 20 }} /> Extra-fast 2-day delivery</Typography>
            <Typography fontSize={14} fontWeight={700}>$20.00</Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontSize={14}><CheckIcon sx={{ width: 20, height: 20 }} /> {`Including social media kit (+1 day)`}</Typography>
            <Typography fontSize={14} fontWeight={700}>$20.00</Typography>
          </Stack>
          <Divider sx={{ borderColor: "common.bellflowerBlue", my: 1.5 }} /> */}
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontSize={14}> Hour spent</Typography>
            <Typography fontSize={14} fontWeight={700}>{`${
              jobDetails?.estimation || 0
            }`}</Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontSize={14}> Hourly rate</Typography>
            <Typography fontSize={14} fontWeight={700}>{`$${
              order?.expectBudget || 0
            }`}</Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontSize={14}> Standard fee</Typography>
            <Typography fontSize={14} fontWeight={700}>{`$${formatNumberString(
              standardFee?.toFixed(2)
            )}`}</Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontSize={14} color={"common.gray5"}>
              {" "}
              Service fee
            </Typography>
            <Typography
              fontSize={14}
              color={"common.gray5"}
              fontWeight={700}
            >{`$${formatNumberString(
              Number(receivedFee - standardFee).toFixed(2)
            )}`}</Typography>
          </Stack>
          <Divider sx={{ borderColor: "common.bellflowerBlue", my: 1.5 }} />
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontSize={14}>{`You'll receive`}</Typography>
            <Typography fontSize={14} fontWeight={700}>{`$${formatNumberString(
              Number(receivedFee).toFixed(2)
            )}`}</Typography>
          </Stack>
        </Stack>

        <Stack gap={2} flex={1}>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography>Ordered by</Typography>
            <Typography color={"primary.main"} fontWeight={700}>
              {order.paidByUser?.name}
            </Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography>Project</Typography>
            <Typography fontWeight={700}>{order.job?.project?.title}</Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography>Duration</Typography>
            <Typography fontWeight={700}>
              {Math.ceil((order.estimation || 0) / 8)} day(s)
            </Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography>Delivery date</Typography>
            <Typography fontWeight={700}>
              {order.status === Order_Status.DONE
                ? dayjs(order.deliveryDate).format("MMM D, YYYY")
                : dayjs(order.startDate)
                    .add(Math.ceil((order.estimation || 0) / 8), "day")
                    .format("MMM D, YYYY")}
            </Typography>
          </Stack>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography>Status</Typography>
            <Chip
              label={formatOrderStatus(order.status).label}
              variant="outlined"
              sx={{
                color: formatOrderStatus(order.status)?.color,
                border: "unset",
                bgcolor: formatOrderStatus(order.status)?.bgcolor,
              }}
            />
          </Stack>
          <Button
            disabled={
              order.status !== Order_Status.DONE ||
              (order.status === Order_Status.DONE && !!order.rating)
            }
            variant="outlined"
            startIcon={<ReviewIcon color="primary.main" />}
            sx={{ textTransform: "none", borderColor: "primary.main", height: 42 }}
            onClick={() => setOpenReview(true)}
          >
            Write a Review
          </Button>
        </Stack>
      </Stack>

      <ReviewDialog
        isOpen={openReview}
        content={
          <Stack spacing={3} direction="row" mt={1} py={2}>
            <Avatar src={order?.freelancer.avatar} />
            <Stack>
              <Typography fontWeight={600}>{order.freelancer?.name}</Typography>
              <Typography color={"common.gray4"} fontSize={12}>
                Your review will be posted publicly
              </Typography>
            </Stack>
          </Stack>
        }
        submitBtnLabel="Post"
        handleClose={() => setOpenReview(false)}
        submitReview={onSubmitReviewOrder}
      />
    </Stack>
  );
};

export default InvoiceDetails;
