import { useState } from "react";

import { Stack, Typography, Divider, Button } from "@mui/material";

import AllProject from "./AllProject";
import FrontPageContent from "./FrontPageContent";

const FRONTPAGE_TAB = {
  FRONTPAGE_PROJECT_LANDING: "FRONTPAGE_PROJECT_LANDING",
  ALL_PROJECTS: "ALL_PROJECTS",
  // LOCKED_PROJECTS: 'LOCKED_PROJECTS',
};

const FrontpageManagement = () => {
  const [tabContent, setTabContent] = useState(FRONTPAGE_TAB.FRONTPAGE_PROJECT_LANDING);

  const renderTabContent = () => {
    switch (tabContent) {
      case FRONTPAGE_TAB.FRONTPAGE_PROJECT_LANDING:
        return <FrontPageContent />;
      case FRONTPAGE_TAB.ALL_PROJECTS:
        return <AllProject />;
      // case FRONTPAGE_TAB.LOCKED_PROJECTS:
      //   return <>Locked projects</>
      default:
        break;
    }
  };

  const tabLabelMapping = {
    [FRONTPAGE_TAB.FRONTPAGE_PROJECT_LANDING]: "Frontpage content",
    [FRONTPAGE_TAB.ALL_PROJECTS]: "All Projects",
    // [FRONTPAGE_TAB.LOCKED_PROJECTS]: 'Locked projects',
  };

  return (
    <Stack rowGap={3} p={4}>
      <Stack>
        <Typography fontWeight="bold" fontSize={24}>
          Frontpage Management
        </Typography>

        <Typography color={"common.gray5"} fontSize={14}>
          Manage content on Teamspace Project main page
        </Typography>

        <Stack direction={"row"} mt={2}>
          {Object.keys(FRONTPAGE_TAB)?.map((tab) => (
            <Button
              key={tab}
              sx={{
                bgcolor: tabContent === tab ? "#F7FAFC" : "white",
                color: tabContent === tab ? "#171923" : "#2D3748",
                fontWeight: "normal",
              }}
              onClick={() => setTabContent(tab)}
            >
              {tabLabelMapping[tab]}
            </Button>
          ))}
        </Stack>
      </Stack>
      <Divider />
      {renderTabContent()}
    </Stack>
  );
};
export default FrontpageManagement;
