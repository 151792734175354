export const PublicPaths = {
  projectPage: "/project",
  landingPage: "/project",
  publishedProjects: "/published-projects",
  publishedProjectDetail: "/published-projects/:projectId",
  product: "/product",
  productDetail: "/product/:projectId",
  gatherlance: "/gatherlance",
  joinProjectPage: "/apply",
  verifyTalentPage: "/talent-verify/:id",
  policy: "/policy",
  policyTerms: "terms",
  policyPrivacy: "privacy",
  indexPage: "/",
  // These paths below will be move to "gatherlance.com"
  gatherlanceNew: "/gatherlance-landing",
  freelance: "/gatherlance-landing/freelance",
  employee: "/gatherlance-landing/employee",
  verifyAccount: "/verify-account",
} as const;
