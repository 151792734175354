import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { Application } from "@dto/application";

// type Response = {
//   totalItems: number;
//   jobs: GetJobByProjectDto[];
// };

type GetJobByProjectType = Application & { hasNewUpdate: boolean };
const getApplicationsByJob = async (
  jobId: string,
  params?: any
): Promise<GetJobByProjectType[]> => {
  const res = await axios.get(`job/${jobId}/applications`, { params });
  return res?.data?.data;
};

const useGetApplicationsByJob = (jobId: string, params?: any) =>
  useQuery(
    ["getApplicationsByJob", jobId, params],
    () => getApplicationsByJob(jobId, params),
    { enabled: !!jobId }
  );

export default useGetApplicationsByJob;
