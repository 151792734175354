import { FC, ReactNode, useEffect } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import { Stack, Box, Typography, useTheme, BoxProps } from "@mui/material";

import { useAppDispatch } from "@app/hooks";

import { applicationActions } from "@features/application/slice";

const tabHeight = 51;

type WrapperType = BoxProps & {
  children: ReactNode;
  title?: string;
  childrenContentType?: "list" | "details";
  onClose?: () => void;
};

const PanelWrapper: FC<WrapperType> = ({
  children,
  title,
  childrenContentType = "list",
  onClose,
  ...rest
}) => {
  const {
    palette: { common },
  } = useTheme();
  const dispatch = useAppDispatch();

  return (
    <Box
      height={rest.width || "100%"}
      sx={{
        borderRadius: 3,
        border: `0.5px solid ${common.bellflowerBlue}`,
        backgroundColor: "white",
        overflow: "hidden",
      }}
      {...rest}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        px={2}
        py={1.5}
      >
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Typography fontSize={18} fontWeight={700}>
            {title}
          </Typography>
        </Stack>
        <CloseIcon
          fontSize="medium"
          sx={{ cursor: "pointer" }}
          onClick={() =>
            onClose
              ? onClose()
              : childrenContentType === "details"
              ? dispatch(applicationActions.setApplicationId(""))
              : dispatch(applicationActions.setOpenListPanel(false))
          }
        />
      </Stack>
      <Box pt={1} role="tabpanel" height={tabHeight && `calc(100% - ${tabHeight}px)`}>
        {children}
      </Box>
    </Box>
  );
};

export default PanelWrapper;
