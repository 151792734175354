/* eslint-disable react/jsx-no-bind */
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";

import { getRoundtables } from "@api/roundtable/getRoundtables";
import useBecomeSubRoundtable from "@api/roundtable/useBecomeSubRoundtable";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Box,
  Chip,
  TextField,
  Autocomplete,
  Avatar,
  Typography,
  Stack,
} from "@mui/material";
import _debounce from "lodash/debounce";
import * as yup from "yup";

import { handleApiError } from "@features/config";

import Button from "@components/Button";
import EllipsisContent from "@components/EllipsisContent";
import FormInput from "@components/FormInput";
import { ISnackbar } from "@components/Snackbar";

import { Attendee, Roundtable } from "@dto/roundtable";

import { RoundtableTypeEnum } from "@@types/type";

import queryClient from "@config/queryClient";

const formSchema = yup.object().shape({
  parentRoundTableId: yup.string().required("Parent Teamspace is required"),
  reason: yup.string(),
  representatives: yup.array().of(yup.string()).min(1, "Representatives is required"),
});

export type BecomeASubRoundtableForm = {
  parentRoundTableId: string;
  reason: string;
  representatives: string[];
};

const defaultValues: BecomeASubRoundtableForm = {
  parentRoundTableId: "",
  reason: "",
  representatives: [],
};

interface IProps {
  id: string;
  isOpen: boolean;
  roundtable: Roundtable;
  onClose: () => void;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
}

const BecomeASubRoundtableDialog: FC<IProps> = ({
  id,
  isOpen,
  roundtable,
  onClose,
  setSnackbar,
}) => {
  const { mutate: becomeSubRoundtable, isLoading: isLoadingSubRoundtalbeBecoming } =
    useBecomeSubRoundtable();

  const [inputValue, setInputValue] = useState("");
  const [rtFetching, setRtFetching] = useState(false);
  const [parentRoundtables, setParentRoundtables] = useState<Roundtable[]>([]);
  const [representatives, setRepresentatives] = useState<Attendee[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<BecomeASubRoundtableForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });

  const handleSave = useCallback(
    (formData: BecomeASubRoundtableForm) => {
      becomeSubRoundtable(
        {
          id,
          payload: formData,
        },
        {
          onSuccess: () => {
            setSnackbar({
              message: "Your proposal was created successfully!",
              open: true,
              severity: "success",
            });

            onClose();
            queryClient.invalidateQueries(["getEventLogs"]);
          },
          onError: (error) => {
            setSnackbar({
              message: handleApiError(error).message,
              open: true,
              severity: "error",
            });
          },
        }
      );
    },
    [setSnackbar, becomeSubRoundtable]
  );

  const mapRepresentativeName = useMemo(() => {
    return (representatives || []).reduce<Record<string, string>>(
      (acc, { attendeeId, name }) => {
        acc[attendeeId] = name;
        return acc;
      },
      {}
    );
  }, [representatives]);

  useEffect(() => {
    const attendees = roundtable?.attendees || [];
    setRepresentatives(attendees);
  }, [roundtable]);

  useEffect(() => {
    setRtFetching(true);
    const fetchRoundtables = async () => {
      const { success, data } = await getRoundtables({
        pageSize: 10,
        type:
          roundtable.type === RoundtableTypeEnum.TEAM
            ? [RoundtableTypeEnum.TEAM]
            : [RoundtableTypeEnum.SELF, RoundtableTypeEnum.FREELANCER],
        title: inputValue,
      }).finally(() => setRtFetching(false));
      if (success) {
        setParentRoundtables(data?.data.filter((_) => _.id !== roundtable.id) || []);
      }
    };

    fetchRoundtables();
  }, [inputValue]);

  const debounced = _debounce((newInputValue) => {
    setInputValue(newInputValue);
  }, 300);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
        Become a Sub-Teamspace
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3} mt={1}>
          <Controller
            render={({ field: { onChange } }) => (
              <Autocomplete
                onChange={(_, item) => {
                  onChange(item?.id);
                }}
                onInputChange={(_, newInputValue) => {
                  debounced(newInputValue);
                }}
                loading={rtFetching}
                options={parentRoundtables}
                getOptionLabel={(option) => option.title}
                renderOption={(props, item) => (
                  <Typography component="li" {...props} key={item.id}>
                    <Avatar
                      alt={item.title}
                      src={item.avatar}
                      sx={{ maxWidth: 20, maxHeight: 20, mr: 1 }}
                    />
                    <EllipsisContent
                      fontSize={16}
                      lineHeight={"inherit"}
                      fontWeight={"normal"}
                      numberOfLines={"unset"}
                      sx={{ mt: 0, wordBreak: "break-word" }}
                      content={item.title}
                    />
                  </Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    label="Parent Teamspace"
                    size="small"
                    variant="outlined"
                    error={!!errors?.parentRoundTableId?.message}
                    helperText={errors?.parentRoundTableId?.message}
                  />
                )}
              />
            )}
            name="parentRoundTableId"
            control={control}
          />

          <FormInput
            multiline
            rows={5}
            control={control}
            name="reason"
            label="Message"
            size="small"
          />

          <FormInput
            required
            select
            control={control}
            name="representatives"
            label="Representative"
            size="small"
            error={!!errors?.representatives?.message}
            helperText={errors?.representatives?.message}
            SelectProps={{
              multiple: true,
              renderValue: (selected: any) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value: string) => (
                    <Chip key={value} label={mapRepresentativeName[value]} />
                  ))}
                </Box>
              ),
            }}
          >
            {representatives.map(({ attendeeId, userId, name, avatar }) => (
              <MenuItem key={userId} value={attendeeId}>
                <Avatar alt={name} src={avatar} sx={{ width: 32, height: 32, mr: 2 }} />
                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>{name}</Typography>
              </MenuItem>
            ))}
          </FormInput>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          disabled={isLoadingSubRoundtalbeBecoming}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          loading={isLoadingSubRoundtalbeBecoming}
          disabled={!isValid || isLoadingSubRoundtalbeBecoming}
          onClick={handleSubmit(handleSave)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BecomeASubRoundtableDialog;
