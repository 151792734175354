import { useContext } from "react";

import { Delivery_Status } from "@api/job/type";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Avatar, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";

import EllipsisContent from "@components/EllipsisContent";

import { DeliveryDto } from "@dto/job";

import { DeliveryContext } from "./DeliveryContext";

type ItemDeliveryProps = {
  jobId?: string;
  data: DeliveryDto;
};

const ItemDelivery = (props: ItemDeliveryProps) => {
  const { data } = props;
  const {
    palette: { common },
  } = useTheme();
  const { setDelivery } = useContext(DeliveryContext);
  const statusIcon =
    data.status === Delivery_Status.APPROVED ? (
      <CheckCircleIcon color="success" />
    ) : data.status === Delivery_Status.REJECTED ? (
      <CancelIcon color="error" />
    ) : null;
  return (
    <Stack
      sx={{ cursor: "pointer" }}
      bgcolor={"white"}
      borderRadius={2}
      onClick={() => setDelivery(data.id)}
      border={"1px solid #E2E8F0"}
      p={2}
      m={2}
    >
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Avatar src={data.freelancer?.avatar} />
          <Typography fontSize={16} fontWeight={600}>
            {data.freelancer.name}
          </Typography>
          {statusIcon}
        </Stack>
        <Typography>{dayjs(data.createdAt).fromNow(true)}</Typography>
      </Stack>

      <EllipsisContent
        fontSize={14}
        color={"common.gray5"}
        content={data.note}
        numberOfLines={3}
      />
      {data.attachment.length > 0 && (
        <Typography color="common.partyTime">
          {data.attachment.length} attachment
        </Typography>
      )}
    </Stack>
  );
};
export default ItemDelivery;
