import { Stripe } from "@stripe/stripe-js";
import { useQuery, useMutation } from "@tanstack/react-query";

import { axios, handleApiError } from "@features/config";

import { Application } from "@dto/application";
import { User } from "@dto/user";

type Request = {
  type?: string;
};

type Response = {
  success: boolean;
  data?: {
    // code: number; message: string;
    client_secret: string;
  };
  message?: string;
};

type PaymentMethodsResponse = {
  success: boolean;
  data?: {
    id: string;
    card: {
      brand: string;
      exp_month: number;
      exp_year: number;
      funding: string;
      last4: string;
    };
  }[];
};

type CheckoutRequestType = {
  application: Application;
  user: User;
  paymentMethod?: string;
  customerId: string;
};

type DeletePaymentMethodRequest = {
  id: string;
};

export const addPaymentMethod = async ({
  type = "embedded",
}: Request): Promise<Response> => {
  try {
    const { data } = await axios.post("/stripe/session-add-payment-method", {
      type,
    });
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const useGetClientSecret = (enabled: boolean) =>
  useQuery(["getStripeClientSecret"], () => addPaymentMethod({}), {
    enabled,
  });

const deletePaymentMethod = async ({
  id,
}: DeletePaymentMethodRequest): Promise<Response> => {
  const response = await axios.delete("/stripe/payment-method", { data: { id } });

  return response.data.data;
};

export const useDeletePaymentMethod = () => {
  return useMutation(deletePaymentMethod);
};

export const getPaymentMethods = async (): Promise<PaymentMethodsResponse> => {
  try {
    const { data } = await axios.get("/stripe/payment-method");
    return { success: true, data: data?.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const calculateStripeFee = async (
  paymentMethodId: string,
  netAmount: number
): Promise<{
  stripeFee?: number;
  success?: boolean;
  message?: string;
}> => {
  try {
    const { data } = await axios.post("/payment/calc-payment", {
      paymentMethodId,
      netAmount,
    });
    return { stripeFee: data?.data?.recommendedFee };
  } catch (error) {
    return handleApiError(error);
  }
};

export const useGetPaymentMethods = () =>
  useQuery(["getPaymentMethods"], () => getPaymentMethods());

type CheckoutResponseType = {
  success: boolean;
  data?: {
    client_secret: string;
  };
  client_secret?: string;
  transaction?: any;
  message?: string;
};
export const checkoutHiring = async ({
  application,
  user,
  paymentMethod,
  customerId,
}: CheckoutRequestType): Promise<CheckoutResponseType> => {
  try {
    const { data } = await axios.post(`/job/${application.id}/pay`, {
      amount: Number(application.expectBudget),
      paymentMethodType: "card",
      appDetails: application,
      userInfo: user,
      freelancer: application.createdByUser,
      customerId,
      ...(paymentMethod && { paymentMethod }),
    });

    return {
      success: true,
      data: data?.data,
      message: "Payment Successful!",
      transaction: data?.data?.transaction,
      client_secret: data?.data?.client_secret,
    };
  } catch (error) {
    return handleApiError(error);
  }
};
