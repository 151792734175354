import { FC, SyntheticEvent, useState } from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import { TabPanel } from "@components/CustomTabs";

import NewDelegates from "@components/Dialog/VoteDelegateDialog/NewDelegates";
import CurrentDelegation from "@components/Dialog/VoteDelegateDialog/CurrentDelegation";
import CurrentVoteWeight from "@components/Dialog/VoteDelegateDialog/CurrentVoteWeight";
import VoteShareProvider from "@components/Dialog/VoteDelegateDialog/NewDelegates/CustomizedVoteShareContext";

import { Roundtable } from "@dto/roundtable";

import { useAppSelector } from "@app/hooks";
import { useViewport } from "@contexts/ViewportContext";

interface IProps {
  // isOpen: boolean;
  onClose: () => void;
  roundtable: Roundtable;
}

const VoteDelegation: FC<IProps> = ({
  onClose,
  roundtable
}) => {
  const { isDesktop } = useViewport();

  const { userInfo } = useAppSelector((state) => state.auth);
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          "& button": {
            width: isDesktop ? (1/3*100) + '%' : '40%',
            fontWeight: 600,
            textTransform: 'unset',
          }
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="New Delegations" />
        <Tab label="Current Delegation" />
        <Tab label="Current Vote Weight" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <VoteShareProvider>
          <NewDelegates
            delegatees={roundtable.attendees.filter(attendee => attendee.userId !== userInfo?.user?.id)}
            onClose={onClose}
          />
        </VoteShareProvider>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CurrentDelegation onClose={onClose} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CurrentVoteWeight onClose={onClose} />
      </TabPanel>
    </Stack>
  )
}

export default VoteDelegation
