import React, { useState } from "react";

import useCreateJob from "@api/job/useCreateJob";
import { Add as AddIcon } from "@mui/icons-material";
import { Stack, TextField, useTheme } from "@mui/material";

import Button from "@components/Button";

import { useSnackbar } from "@contexts/SnackbarContext";
import queryClient from "@config/queryClient";

type AddQuestProps = {
  onSubmit?: (data: { title: string }) => void;
  projectId: string
};

const QuickAddQuest = ({ onSubmit, projectId }: AddQuestProps) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [title, setTitle] = useState("");
  const {
    palette: { common },
  } = useTheme();
  const { setSnackbar } = useSnackbar();

  const { mutate: createJob } = useCreateJob();
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>) => {
    if (e.key === "Enter") {
      title && handleSubmit();
    }
  };

  const handleSubmit = () => {
    onSubmit?.({ title });
    createJob(
      { title, projectId, isQuest: true },
      {
        onSuccess: (_apiData) => {
          queryClient.invalidateQueries(["getJobsByProject", projectId]);
        },
        onError: (_error) => {
          setSnackbar({
            message: "Create quest failed.",
            open: true,
            severity: "error",
          });
        },
      }
    );
    setTitle("");
    setOpenEdit(false);
  };

  return (
    <Stack flexDirection={"row"} gap={3} flexWrap={"wrap"} alignItems={"center"}>
      {!openEdit && (
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={() => setOpenEdit(true)}
          sx={{
            color: "common.gray5",
            fontSize: 16,
            fontWeight: 400,
            width: "fit-content",
            textTransform: "none",
          }}
        >
          Add quest...
        </Button>
      )}
      {openEdit && (
        <>
          <TextField
            variant="standard"
            placeholder="Task name"
            sx={{ ml: 4, width: 330 }}
            InputProps={{ disableUnderline: true }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={handleKeyDown}
          />

          <Stack flexDirection={"row"} gap={1.5}>
            <Button
              variant="outlined"
              onClick={() => setOpenEdit(false)}
              sx={{
                height: 36,
                borderRadius: 2,
                color: "black",
                border: common.border,
                ":hover": { borderColor: common.bellflowerBlue },
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!title}
              onClick={handleSubmit}
              sx={{
                height: 36,
                borderRadius: 2,
                bgcolor: "black",
                color: "white",
                border: common.border,
                ":hover": { bgcolor: "black" },
                "&.Mui-disabled": { bgcolor: "#0000001f" },
              }}
            >
              Save
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};
export default QuickAddQuest;
