import { ChangeEvent, FC, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { PublishedProjectRequest } from "@api/roundtable/useGetPublishedProjects";
import { SearchCriteriaResponse } from "@api/roundtable/useGetSearchCriteria";
import {
  FilterList as FilterListIcon,
  Search as SearchIcon,
  LocationCity as LocationCityIcon,
  LocationOn as LocationOnIcon,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  debounce,
  useTheme,
} from "@mui/material";

import DropdownCheckboxes from "@components/DropdownCheckboxes";

import { useViewport } from "@contexts/ViewportContext";

interface PublishedProjectsFilterProps {
  params: Partial<PublishedProjectRequest>;
  handleChangeParams: (params: Partial<PublishedProjectRequest>) => void;
  searchCriteria: SearchCriteriaResponse;
  defaultCategory?: string[];
}

const PublishedProjectsFilter: FC<PublishedProjectsFilterProps> = ({
  params,
  handleChangeParams,
  searchCriteria,
  defaultCategory,
}) => {
  const { isDesktop } = useViewport();
  const {
    palette: { common },
  } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { categories = [], institutions = [], locations = [] } = searchCriteria || {};
  const allTagsOpt = categories?.reduce((acc, cat) => acc.concat([...cat.tags]), []);
  const filteredTagsOpt = useRef([]);
  const tagsOpt =
    filteredTagsOpt?.current?.length > 0 ? filteredTagsOpt.current : allTagsOpt;
  const isFixedCategory = searchParams.get("isFixedCategory") === "true";
  const category = searchParams.get("category");

  // const handleChangeCategory = (event: SelectChangeEvent<string[]>) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   handleChangeParams({
  //     tags: value as string[],
  //   });
  // };

  const handleChangeSortingByTime = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    handleChangeParams({
      orderByTime: value as "ASC" | "DESC",
    });
  };

  const handleChangeSearchInput = debounce(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target?.value?.trim() || "";

      handleChangeParams({ title: value });
      setSearchParams((prev) => {
        const preValue: any = {};
        prev.forEach((value, key) => {
          preValue[key] = value;
        });
        return { ...preValue, keyword: value };
      });
    },
    300
  );

  return (
    <Stack rowGap={8} pt={8} mb={4}>
      <Stack
        rowGap={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Stack rowGap={2}>
          <Typography fontSize={isDesktop ? 36 : 30} fontWeight={700}>
            Search projects on Gatherlance
          </Typography>
          <Typography fontSize={16} color={common.doverGrey}>
            Find projects by location, title, keyword, or a person’s name
          </Typography>
        </Stack>
        <TextField
          sx={{
            width: "100%",
            maxWidth: 560,
            "& fieldset": {
              borderRadius: 9,
            },
            "svg": {
              color: common.doverGrey,
              pr: 1,
            },
          }}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          placeholder="Search..."
          onChange={handleChangeSearchInput}
          defaultValue={searchParams.get("keyword")}
        />
      </Stack>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        rowGap={4}
      >
        <Grid
          item
          xs={12}
          desktop={7}
          display="flex"
          columnGap={1}
          rowGap={2}
          alignItems="center"
          // flexWrap="wrap"
        >
          <FilterListIcon />
          {/* <FormControl sx={{ minWidth: 140 }} size="small">
            <InputLabel>Category</InputLabel>
            <Select
              multiple

              value={params.tags || []}
              label="Category"
              onChange={handleChangeCategory}
              sx={{
                borderRadius: 9,
              }}
            >
              {tags?.map((tag, index) => {
                if (!tag) {
                  return;
                }

                return (
                  <MenuItem key={`${index}-${tag}-category`} value={tag}>
                    {tag}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
          <Stack direction={"row"} spacing={1} sx={{ overflow: "auto" }}>
            {/* <Stack flex={"0 0 auto"}>
              <DropdownCheckboxes
                label="Category"
                options={categories}
                optionDataFormat="categoryWithTag"
                onChangeData={debounce(
                  (data) => handleChangeParams({ categories: data?.categories, tags: data?.tags }),
                  600
                )}
              />
            </Stack> */}
            <Stack flex={"0 0 auto"}>
              {isFixedCategory ? (
                <Typography
                  fontSize={16}
                  sx={{
                    border: "1px solid #A0AEC0",
                    borderRadius: "196px",
                    cursor: "not-allowed",
                    padding: "8px 14px",
                    textAlign: "center",
                  }}
                >
                  {category}
                </Typography>
              ) : (
                <DropdownCheckboxes
                  label="Category"
                  options={categories?.filter((i) => i.isDisplay)?.map((c) => c.name)}
                  onChangeData={debounce((data) => {
                    const tags = categories
                      ?.filter((cat) => data.includes(cat.name))
                      ?.reduce((acc, cat) => acc.concat([...cat.tags]), []);
                    filteredTagsOpt.current = tags;

                    handleChangeParams({ category: data });
                  }, 600)}
                  defaultValues={defaultCategory || []}
                />
              )}
            </Stack>

            <Stack flex={"0 0 auto"}>
              <DropdownCheckboxes
                label="Tag"
                options={tagsOpt}
                filterSelectedWhenOptionsChange
                onChangeData={debounce((data) => handleChangeParams({ tags: data }), 600)}
              />
            </Stack>

            <Stack flex={"0 0 auto"}>
              <DropdownCheckboxes
                label="Institution"
                options={institutions}
                onChangeData={debounce(
                  (data) => handleChangeParams({ institutions: data }),
                  600
                )}
                optionIcon={<LocationCityIcon />}
              />
            </Stack>

            <Stack flex={"0 0 auto"}>
              <DropdownCheckboxes
                label="Location"
                options={locations}
                onChangeData={debounce(
                  (data) => handleChangeParams({ locations: data }),
                  600
                )}
                optionIcon={<LocationOnIcon />}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          desktop={3}
          display="flex"
          columnGap={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography>Sort by</Typography>
          <FormControl sx={{ minWidth: 140 }} size="small">
            <Select
              onChange={handleChangeSortingByTime}
              sx={{
                borderRadius: 2,
              }}
              value={params.orderByTime || "DESC"}
            >
              <MenuItem value="DESC">Newest</MenuItem>
              <MenuItem value="ASC">Oldest</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PublishedProjectsFilter;
