import React, { useState } from "react";

import { getDeliveryHistory } from "@api/job/getDeliveryHistory";
import { reviewDelivery } from "@api/job/reviewDelivery";
import { Delivery_Status } from "@api/job/type";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import Button from "@components/Button";
import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";

import { useSelectJob } from "@pages/jobs-management/SelectJobContext";
import { JOB_STATUS } from "@pages/jobs-management/constants";

import { getMiddleEllipsisText } from "@utils/helpers/stringHelper";
import { getFileNameUrlS3 } from "@utils/helpers/urlHelper";

import { ReactComponent as FileAttachment } from "@assets/icons/file_attachment.svg";
import { ReactComponent as GawainIcon } from "@assets/icons/gawain_ai.svg";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

type GawainAIProps = {
  isFreelancer?: boolean;
};
const GawainAI = ({ isFreelancer }: GawainAIProps) => {
  const { jobSelected } = useSelectJob();
  const {
    palette: { common },
  } = useTheme();
  const { setSnackbar } = useSnackbar();
  const [reviewType, setReviewType] = useState<Delivery_Status | null>(null);

  return (
    <Stack flex={1} height={"100%"} p={2} gap={1} overflow={"auto"}>
      {/* <Stack px={2} py={1} gap={1.5} flex={1}> */}
      <Stack flexGrow={1} alignItems={"center"} gap={6} mt={10}>
        <Box>
          <GawainIcon />
        </Box>
        <Typography textAlign={"center"} color={"#000000A3"} fontSize={14}>
          Gawain.AI is currently available only to a select group of beta testers.
        </Typography>
        <Typography textAlign={"center"} color={"#000000A3"} fontSize={14}>
          Please fill out the form below to secure your spot and be one of the first to
          try Gawain.AI!
        </Typography>
        {/* </Stack> */}
        <Button
          sx={{
            borderRadius: 2,
            bgcolor: "#000000",
          }}
        >
          Access Google Form
        </Button>
      </Stack>
    </Stack>
  );
};
export default GawainAI;
