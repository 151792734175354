import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

interface IRequest {
  id: string;
  reason: string;
}

const exitParentRoundtable = async ({ id, reason }: IRequest) => {
  return await axios.delete(`/round-table/${id}/exit`, { data: { reason } });
};

const useExitParentRoundtable = () => {
  return useMutation(exitParentRoundtable);
};

export default useExitParentRoundtable;
