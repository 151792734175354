import { ReactNode, FC, useState, useEffect, ChangeEvent } from "react";
import { Grid, Stack, Avatar, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Popover, debounce, CircularProgress } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon, Place as PlaceIcon, Add as AddIcon, CalendarToday as CalendarIcon, NoPhotographyOutlined as NoPhotoIcon } from "@mui/icons-material";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import FormInput from "@components/FormInput";
import ConfirmActionDialog from "@components/Dialog/ConfirmDialog/ConfirmAction";
import ReadMoreText from "@components/ReadMoreText.tsx";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { axios } from "@features/config";

// dayjs.extend(relativeTime)

const CurrentDateString = dayjs(new Date()).toISOString()

interface IExperience {
  type: string,
  institutionName: string,
  position: string,
  location: string,
  start: string,
  to: string,
  isCurrent: boolean,
  description: string,
  institution: {
    website: string,
    name: string,
    logo: string,
  }
}

export interface IDataChangeProps {
  action: 'add' | 'update' | 'delete';
  index: number;
  data?: IExperience;
}

interface IProps {
  title?: ReactNode;
  isEdit?: boolean;
  type: "EDUCATION" | "WORK";
  experiences?: IExperience[] | any[];
  onDataChange?: (props: IDataChangeProps) => void;
  isLoggedIn?: boolean;
}

interface IDateProps {
  label?: string;
  showCurrentOption?: boolean;
  initialDate?: string;
  onDataChange?: (date: string) => void;
  [x: string]: unknown;
}

const formSchema = yup.object().shape({
  type: yup.string(),
  institutionName: yup.string().required("This field is required"),
  position: yup.string().required("This field is required"),
  location: yup.string().required("This field is required"),
  start: yup.string().required(),
  to: yup.string().nullable().test(
    "later_date_test",
    "End time must be later than start time.",
    function (value) {
      const { start } = this.parent;
      return dayjs(value).startOf("month").diff(dayjs(start).startOf("month"), "month") >= 0;
    }
  ),
  isCurrent: yup.boolean(),
  description: yup.string(),
  institution: yup.object().shape({
    website: yup.string(),
    name: yup.string(),
    logo: yup.string(),
  })
})

const defaultValues = {
  type: "",
  institutionName: "",
  position: "",
  location: "",
  start: CurrentDateString,
  to: "",
  isCurrent: false,
  description: "",
  institution: {
    website: "",
    name: "",
    logo: "",
  }
}

const DateSelect = ({ label, showCurrentOption, initialDate, onDataChange, ...others }: IDateProps) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    // setAnchorEl(null);
    if (onDataChange) {
      onDataChange(value)
    }
  }

  useEffect(() => {
    setSelectedValue(initialDate)
  }, [initialDate])

  return (
    <Stack>
      <TextField
        label={label}
        InputProps={{
          readOnly: true,
          endAdornment: <IconButton onClick={(e) => anchorEl ? handleClose() : setAnchorEl(e?.currentTarget)}><CalendarIcon /></IconButton>
        }}
        value={selectedValue ? dayjs(selectedValue).format("MM/YYYY") : "Current job"}
        {...others}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showCurrentOption && (
          <Stack sx={{ cursor: "pointer" }} onClick={() => handleSelect("")}>
            <Typography sx={{ p: 2 }}>Current job</Typography>
          </Stack>
        )}
        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={selectedValue ? dayjs(selectedValue) : null}
            views={['year', 'month']}
            openTo="year"
            onMonthChange={_ => setAnchorEl(null)}
            onChange={date => {
              handleSelect(dayjs(date).toISOString());
            }}
          />
        </LocalizationProvider>
      </Popover>
    </Stack>
  )
}

const UserExperiences: FC<IProps> = ({
  title,
  type,
  isEdit,
  experiences,
  onDataChange,
  isLoggedIn = true
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [openUpsertDialog, setOpenUpsertDialog] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [institutionLogoLoad, setInstitutionLogoLoad] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues
  });

  const watchedInstitutionLogo = watch("institution.logo");

  const handleChangeInstitutionWebsite = debounce(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target?.value?.trim() || "";
      setValue("institution.website", value)

      if (!value) {
        return
      }
      if (!isLoggedIn) {
        return
      }
      setInstitutionLogoLoad(true);
      axios.post("/get-url-metadata", { url: value })
        .then(response => setValue("institution.logo", response.data?.data?.logo))
        .catch(_err => setValue("institution.logo", ""))
        .finally(() => setInstitutionLogoLoad(false))
    },
    300
  );

  const handleStartModifyAction = (action: 'add' | 'update' | 'delete', index: number) => {
    if (action === 'add') {
      setSelectedIndex(experiences?.length)
      reset({ ...defaultValues, type })
      setOpenUpsertDialog("add")
    } else if (action === 'update') {
      setSelectedIndex(index)
      reset(experiences?.[index])
      setOpenUpsertDialog("update")
    } else {
      setSelectedIndex(index)
      setConfirmDelete(true)
    }
  }

  const handleSave = (formData: IExperience) => {
    if (onDataChange) {
      onDataChange({
        action: openUpsertDialog === 'add' ? 'add' : 'update',
        index: selectedIndex,
        data: {
          ...formData,
          isCurrent: formData.to ? false : true
        }
      })
      setOpenUpsertDialog("")
    }
  }

  const handleDelete = () => {
    if (onDataChange) {
      onDataChange({
        action: 'delete',
        index: selectedIndex,
      })
      setConfirmDelete(false)
    }
  }

  const renderDuration = (startDate: string, endDate?: string) => {
    const StartDate = dayjs(startDate).startOf("month").toISOString()
    const EndDate = dayjs(endDate || (new Date()).toISOString()).startOf("month").toISOString();
    const monthDuration = dayjs(EndDate).diff(dayjs(StartDate), "month");
    //console.log("Diff", StartDate, EndDate, monthDuration)
    return (Math.floor(monthDuration / 12) > 0 ? `${Math.floor(monthDuration / 12)} yr(s) ` : "") +
      (monthDuration === 0 ? "in a mo(s)" : monthDuration % 12 === 0 ? "" : `${monthDuration % 12} mo(s)`)
  }

  return (
    <Stack gap={2}>
      {title}
      {experiences?.length < 1 && !isEdit && <Typography>-</Typography>}
      <Stack display={experiences?.length > 0 ? "flex" : "none"}>
        {experiences?.map((exp, i) => {
          if (exp?.type !== type) {
            return <div key={i} />
          }
          return (
            <Stack key={i} flexDirection={{ xs: "column", md: "row" }} gap={2} py={2} borderBottom={"1px solid #E2E8F0"}>
              <Avatar src={exp?.institution?.logo} variant="rounded" sx={{ border: "1px solid #E2E8F0" }} />
              <Typography fontSize={16} fontWeight={"bold"} display={{ xs: "initial", md: "none" }}>{exp?.institutionName}</Typography>
              <Stack width={"100%"} gap={1.5}>
                <Stack width={"100%"} flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"} gap={1}>
                  <Stack gap={0.4}>
                    <Typography fontSize={16} fontWeight={"bold"} display={{ xs: "none", md: "initial" }}>{exp?.institutionName}</Typography>
                    <Typography fontSize={14} color={"#000000A3"}>{exp?.position}</Typography>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={0.5} color={"common.gray5"}>
                      <PlaceIcon sx={{ width: 18, height: 18 }} />
                      <Typography fontSize={14} color={"#000000A3"}>{exp?.location}</Typography>
                    </Stack>
                  </Stack>
                  <Stack gap={0.4}>
                    <Typography fontSize={14} color={"#000000A3"}>{dayjs(exp?.start).format("MMM YYYY")} - {exp?.to ? dayjs(exp?.to).format("MMM YYYY") : "Present"}</Typography>
                    {type === "WORK" && (
                      <Typography fontSize={14} color={"#000000A3"} textAlign={"right"}>
                        {renderDuration(exp?.start, exp?.to)}
                      </Typography>
                    )}
                    {/* {dayjs(exp?.start).to(dayjs(exp?.to), true)} */}
                  </Stack>
                </Stack>
                <Typography color={"common.gray5"}>
                  <ReadMoreText
                    // text={"exp.description Lorem ipsum dolor sit amet, consectadipiscing elit. Fusce dui turpis, fermentum ligula non, tristique congue ex. Fusce in tellus vitae est feugiat hendrads ipsum dolor sit amet, consectad eliticece...Read more Lorem ipsum dolor sit amet, consectadipiscing elit. Fusce dui turpis, fermentum ligula non, tristique congue ex. Fusce in tellus vitae est feugiat hendrads ipsum dolor sit amet, consectad eliticece...Read more Lorem ipsum dolor sit amet, consectadipiscing elit. Fusce dui turpis, fermentum ligula non, tristique congue ex. Fusce in tellus vitae est feugiat hendrads ipsum dolor sit amet, consectad eliticece...Read more"}
                    text={exp?.description}
                    fontSize="16px"
                    maxLength={165}
                    // maxLength={isDesktop ? 500 : 260}
                  />
                </Typography>
              </Stack>
              {isEdit && (
                <Stack>
                  <Stack flexDirection={"row"} gap={0.5}>
                    <IconButton sx={{ color: "common.gray5" }} onClick={() => handleStartModifyAction("update", i)}><EditIcon /></IconButton>
                    <IconButton sx={{ color: "common.gray5" }} onClick={() => handleStartModifyAction("delete", i)}><DeleteIcon /></IconButton>
                  </Stack>
                </Stack>
              )}
            </Stack>
          )
        })}
      </Stack>
      {isEdit && (
        <Button
          variant="text"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ width: "fit-content" }}
          onClick={() => handleStartModifyAction("add", -1)}
        >
          Add {type?.toLowerCase()} experience
        </Button>
      )}
      <Dialog
        fullWidth
        open={!!openUpsertDialog}
        maxWidth="sm"
        onClose={() => setOpenUpsertDialog("")}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
          {openUpsertDialog && (openUpsertDialog === "add" ? `New ${type === "WORK" ? "work" : "education"} experience` : `Edit ${type === "WORK" ? "work" : "education"} experience`)}
        </DialogTitle>

        <DialogContent sx={{ py: 3 }}>
          <Stack gap={2.5} pt={3}>
            <FormInput
              required
              control={control}
              name="institutionName"
              label={type === "WORK" ? "Company" : "Institution"}
              // size="small"
              error={!!errors?.institutionName?.message}
              helperText={errors?.institutionName?.message}
            />
            <TextField
              label="URL"
              defaultValue={getValues("institution.website")}
              onChange={handleChangeInstitutionWebsite}
              InputProps={{
                endAdornment: institutionLogoLoad && <CircularProgress color="inherit" size={20} sx={{ color: "#5F6368" }} />
              }}
            />
            <Avatar
              variant="rounded"
              src={watchedInstitutionLogo}
              sx={{ width: 80, height: 80, bgcolor: "common.deutziaWhite", color: "common.gray3", border: "1px dashed #E2E8F0" }}
            >
              {!getValues("institution.logo") && <NoPhotoIcon />}
            </Avatar>
            <FormInput
              required
              control={control}
              name="position"
              label={type === "WORK" ? "Position" : "Degree"}
              error={!!errors?.position?.message}
              helperText={errors?.position?.message}
            />
            <FormInput
              required
              control={control}
              name="location"
              label="Location"
              error={!!errors?.location?.message}
              helperText={errors?.location?.message}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DateSelect label="Start from" initialDate={getValues("start")} onDataChange={date => { setValue("start", date); trigger("to") }} required />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateSelect
                  label="End in" showCurrentOption initialDate={getValues("to") || ""} onDataChange={date => { setValue("to", date); trigger("to") }}
                  error={!!errors?.to?.message}
                  helperText={errors?.to?.message}
                />
              </Grid>
            </Grid>
            <FormInput
              multiline
              minRows={5}
              maxRows={5}
              control={control}
              name="description"
              label="Description"
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ pt: 1.5, pb: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenUpsertDialog("");
              reset(defaultValues);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(handleSave)}
            disabled={!isValid}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmActionDialog
        title={`Delete ${type === "WORK" ? "work" : "education"} experience`}
        description="Are you sure you want to delete this work experience?"
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onSubmit={handleDelete}
        submitBtnLabel="Delete"
      />
    </Stack>
  )
}

export default UserExperiences;
