import { FC, ReactNode } from "react";

import {
  Button,
  SxProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton
} from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children?: ReactNode;
  sx?: SxProps;
}

const CommonDialog: FC<IProps> = ({
  isOpen,
  onClose,
  title,
  children,
  sx
}) => {

  return (
    <Dialog
      open={isOpen}
      // sx={{
      //   "& .MuiDialog-paper": {
      //     maxWidth: 540,
      //     m: 2,
      //   },
      // }}
      sx={{ "& .MuiDialog-paper": { width: { md: "80%" }, maxWidth: "80%" }, ...sx }}
    >
      <DialogTitle sx={{ fontSize: 20, fontWeight: 700, position: "relative" }}>
        {title}
        <IconButton sx={{ position: "absolute", right: 10 }} onClick={onClose}><CloseIcon /></IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 300 }}>
        <Box pt={3} pb={1}>{children}</Box>
      </DialogContent>

      {/* <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

export default CommonDialog;
