import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { AiOutlineCloseCircle, AiOutlineFileImage } from "react-icons/ai";

import { DOCUMENT_REGEX, IMG_REGEX } from "@constants/index";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Link,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

import CustomSnackbar, { ISnackbar } from "@components/Snackbar";

import { uploadImagesToServer } from "@utils/uploadImageToServer";

const ACCEPTED_FILE_TYPE = {
  "all": "image/*, .pdf, .doc, .docx, .ppt, .pptx",
  "image": "image/*",
  "document": ".pdf, .doc, .docx",
  "present": ".ppt, .pptx",
};

interface IProps {
  initialFiles?: string[];
  roundtableId?: string;
  userId?: string;
  acceptedFileTypes?: keyof typeof ACCEPTED_FILE_TYPE;
  setIsDirtyFiles?: Dispatch<SetStateAction<boolean>>;
  sx?: SxProps;
  suffixText?: string;
  isViewOnly?: boolean;
}

export default forwardRef<unknown, IProps>(function MultiFileUploader(
  {
    initialFiles,
    roundtableId,
    userId,
    acceptedFileTypes = "all",
    setIsDirtyFiles,
    sx,
    suffixText,
    isViewOnly,
  },
  ref
) {
  const [images, setImages] = useState<any[]>([]);
  const [documents, setDocuments] = useState<any[]>([]);
  const [files, setFiles] = useState<(File | string)[]>([]);
  const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false });

  const initialImages = useMemo(() => {
    return initialFiles?.filter((fileLink) => IMG_REGEX.test(fileLink));
  }, [initialFiles]);

  const initialDocuments = useMemo(() => {
    return initialFiles?.filter((fileLink) => DOCUMENT_REGEX.test(fileLink));
  }, [initialFiles]);

  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) {
      return;
    }

    const files = e.currentTarget.files;
    const promises = [];
    const newFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      /**
       * Validate file size must less than 5MB
       */
      if (file.size > 5 * 1000 * 1024) {
        setSnackbar({
          message: "File size exceeds 5MB!",
          open: true,
          severity: "error",
        });
        continue;
      }

      newFiles.push(file);

      const filePromise = new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => resolve(fileReader.result);
      });
      promises.push(filePromise);
    }

    Promise.all(promises).then((fileContents) => {
      const newImages: string[] = [];
      const newDocuments: string[] = [];

      fileContents.forEach((content) => {
        if (typeof content === "string") {
          if (content.startsWith("data:image")) {
            newImages.push(content);
          } else if (content.startsWith("data:application")) {
            newDocuments.push(content);
          }
        }
      });

      const newImageState = [...images, ...newImages];
      !!newImages.length && handleSetDirtyFiles("image", newImageState);

      const newDocumentState = [...documents, ...newDocuments];
      !!newDocuments.length && handleSetDirtyFiles("document", newDocumentState);

      setImages(newImageState);
      setDocuments(newDocumentState);
    });

    setFiles((files) => [...files, ...newFiles]);
  };

  const handleRemoveImage = (link: string) => {
    const newImageState = images.filter((img) => img !== link);
    handleSetDirtyFiles("image", newImageState);

    setImages(newImageState);
    setFiles((prevState) => prevState.filter((file) => file !== link));
  };

  const handleRemoveDocument = (link: string) => {
    const newDocumentState = documents.filter((doc) => doc !== link);
    handleSetDirtyFiles("document", newDocumentState);

    setDocuments((prevState) => prevState.filter((doc) => doc !== link));
    setFiles((prevState) => prevState.filter((file) => file !== link));
  };

  const processingImages = async () => {
    const oldImages = files?.filter((f) => !(f instanceof File));
    const newFiles = files?.filter((f) => f instanceof File);
    let newImages: string[] = [];

    if (newFiles.length) {
      newImages = await uploadImagesToServer(newFiles as File[]);
    }

    return [...(oldImages as string[]), ...newImages];
  };

  const processingDocFiles = async () => {
    const newFiles = files?.filter((f) => f instanceof File);
    let urlFiles: string[] = [];

    if (newFiles.length) {
      urlFiles = await uploadImagesToServer(newFiles as File[]);
    }
    return (newFiles as [Blob & File]).map((file, i) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      url: urlFiles[i],
    }));
  };

  const handleSetDirtyFiles = (
    type: Omit<keyof typeof ACCEPTED_FILE_TYPE, "all">,
    newFiles: string[]
  ) => {
    if (!setIsDirtyFiles) {
      return;
    }

    switch (type) {
      case "image":
        if (
          JSON.stringify(initialImages) === JSON.stringify(newFiles) &&
          JSON.stringify(initialDocuments) === JSON.stringify(documents)
        ) {
          return setIsDirtyFiles(false);
        } else {
          return setIsDirtyFiles(true);
        }
      case "document":
        if (
          JSON.stringify(initialImages) === JSON.stringify(images) &&
          JSON.stringify(initialDocuments) === JSON.stringify(newFiles)
        ) {
          return setIsDirtyFiles(false);
        } else {
          return setIsDirtyFiles(true);
        }
      default:
        break;
    }

    setIsDirtyFiles(false);
  };

  useImperativeHandle(ref, () => ({
    processingImages: processingImages,
    processingDocFiles,
    files,
  }));

  useEffect(() => {
    setImages(initialImages || []);
    setDocuments(initialDocuments || []);
    setFiles([...(initialDocuments || []), initialImages || []].flat() as any);
  }, [initialImages, initialDocuments]);

  return (
    <Stack spacing={2}>
      {acceptedFileTypes === "image" && !!images.length && (
        <FileGrid
          files={images}
          handleRemoveFile={(file) => {
            handleRemoveImage(file);
          }}
          isViewOnly={isViewOnly}
          acceptedFileTypes={acceptedFileTypes}
        />
      )}

      {acceptedFileTypes === "document" && !!documents.length && (
        <FileGrid
          files={documents}
          handleRemoveFile={(file) => {
            handleRemoveDocument(file);
          }}
          isViewOnly={isViewOnly}
          acceptedFileTypes={acceptedFileTypes}
        />
      )}

      {acceptedFileTypes === "all" && !![...documents, ...images].length && (
        <FileGrid
          files={[...documents, ...images]}
          handleRemoveFile={(file) => {
            handleRemoveImage(file);
            handleRemoveDocument(file);
          }}
          isViewOnly={isViewOnly}
          acceptedFileTypes={acceptedFileTypes}
        />
      )}

      <Stack
        spacing={2}
        sx={{
          ...sx,
          alignItems: "center",
          p: 3,
          border: (theme) => `1px dashed ${theme.palette.common.bellflowerBlue}`,
          display: isViewOnly ? "none" : "flex",
        }}
      >
        <AiOutlineFileImage style={{ width: 40, height: 40, color: "#e2e8f0" }} />

        <Stack
          direction="row"
          flexWrap={"wrap"}
          gap={0.5}
          sx={{ alignItems: "center", justifyContent: "center", fontSize: 14 }}
        >
          <Link
            component="label"
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography fontSize={{ xs: 12, md: 14 }}>Click here</Typography>
            <input
              hidden
              multiple
              type="file"
              accept={ACCEPTED_FILE_TYPE[acceptedFileTypes]}
              onChange={handleUploadImage}
              name="image"
              style={{ display: "none" }}
            />
          </Link>
          <Typography fontSize={{ xs: 13, md: 15 }}>to upload {suffixText}</Typography>
        </Stack>
      </Stack>

      <CustomSnackbar onClose={() => setSnackbar({ open: false })} {...snackbar} />
    </Stack>
  );
});

interface FileGridProps {
  files: string[];
  handleRemoveFile: (file: string) => void;
  isViewOnly?: boolean;
  acceptedFileTypes?: keyof typeof ACCEPTED_FILE_TYPE;
}

const FileGrid: React.FC<FileGridProps> = ({
  files,
  handleRemoveFile,
  isViewOnly,
  acceptedFileTypes = "all",
}) => (
  <Grid container spacing={1}>
    {files.map((file, index) => (
      <Grid item key={index} xs={3}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            onClick={() => handleRemoveFile(file)}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10,
              display: isViewOnly && "none",
            }}
            disabled={isViewOnly}
            color="primary"
          >
            <AiOutlineCloseCircle
              style={{
                backgroundColor: "white",
                color: "currentColor",
                borderRadius: "100%",
              }}
            />
          </IconButton>
          <Avatar
            variant="rounded"
            src={!file.startsWith("data:application/") && file}
            sx={{ width: "100%", height: 120 }}
          >
            <DocumentScannerIcon fontSize="large" />
          </Avatar>
        </Box>
      </Grid>
    ))}
  </Grid>
);
