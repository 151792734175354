import { FC, useCallback, useState } from "react";

import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { Box, Typography, Stack, Avatar, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";

import ViewImageDialog from "@components/Dialog/ViewImageDialog";
import EllipsisContent from "@components/EllipsisContent";

import { humanFileSize } from "@utils/format/fileSize";

interface IProps {
  name: string;
  size: number;
  date: Timestamp;
  type: string;
  url: string;
  onClickImage?: (url: string) => void;
}

const AttachmentItem: FC<IProps> = ({ name, size, date, url, type, onClickImage }) => {
  const formattedDate = dayjs(date?.toDate()).format("DD MMM, YYYY");
  const {
    palette: { common },
  } = useTheme();

  const onClickAttachment = () => {
    if (type.startsWith("image/")) {
      onClickImage(url);
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <Box
      onClick={onClickAttachment}
      display={"flex"}
      alignItems={"center"}
      sx={{
        margin: "0 12px 12px",
        padding: "10px 14px",
        cursor: "pointer",
        gap: 2,
        borderRadius: 2,
        border: common.border,
      }}
    >
      <Avatar
        variant="rounded"
        src={url ?? ""}
        sx={{ width: 40, height: 40, borderRadius: 1.5 }}
      >
        {type.startsWith("application/") && (
          <DocumentScannerIcon fontSize="large" width={18} />
        )}
      </Avatar>

      <Stack flex={1}>
        <EllipsisContent
          fontSize={16}
          lineHeight={1.5}
          fontWeight={"normal"}
          numberOfLines={1}
          sx={{ mt: 0, wordBreak: "break-word" }}
          content={name}
        />
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Typography>{humanFileSize(size)}</Typography>
          <Typography>{formattedDate}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AttachmentItem;
