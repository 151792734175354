import { useCallback, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { axios } from "@features/config";

import { EventLog } from "@dto/event-log";
import { ProposalStatus } from "@dto/proposal";

interface Response {
  data?: EventLog[];
}

export interface EventLogRequest {
  roundtableId: string;
  status?: ProposalStatus;
}

const getEventLogs = async ({
  roundtableId,
  status
}: EventLogRequest): Promise<Response> => {
  const response = await axios.get(`/round-tables/${roundtableId}/event-logs`, {
    params: {
      status
    }
  });

  return response.data;
};

export const useGetEventLogs = (initialParams: EventLogRequest) => {
  const [params, setParams] = useState(initialParams);

  const { data, isLoading, isFetching, isSuccess, isError, error, refetch } = useQuery(
    ["getEventLogs", params],
    () => getEventLogs({ ...params })
  );

  useEffect(() => {
    refetch();
  }, [params]);

  const handleChangeParams = useCallback((params: Partial<EventLogRequest>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  return {
    data: data?.data || [],
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    params,
    refetch,
    handleChangeParams
  };
};
