
import { FC } from "react";
import { Stack } from "@mui/material";

import EditRoundtableComponent from "@components/Dialog/AddEditRoundtableDialog/EditRoundtableDialog";

import { Roundtable } from "@dto/roundtable";
import { useSnackbar } from "@contexts/SnackbarContext";

interface IProps {
  roundtable: Roundtable;
  onClose: () => void;
}

const UpdateProjectManagement: FC<IProps> = ({
  roundtable,
  onClose,
}) => {
  const { setSnackbar } = useSnackbar();

  return (
    <Stack>
      <EditRoundtableComponent
        id={roundtable.id}
        initData={roundtable}
        onClose={onClose}
        setSnackbar={setSnackbar}
        removeDialog
      />
    </Stack>
  )
}

export default UpdateProjectManagement;
