import React, { FC } from "react";

import { Box, CircularProgress, BoxProps } from "@mui/material";

type CircleLoadingType = BoxProps;

export const CircleLoading: FC<CircleLoadingType> = ({ ...rest }) => {
  return (
    <Box
      height={rest.height || "100vh"}
      width={rest.width || "100vw"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <CircularProgress />
    </Box>
  );
};
