import { useQuery } from "@tanstack/react-query";
import { axios } from "@features/config";
import { Order } from "@dto/order";

type GetOrdersResponseData = {
  clientOrders: Order[];
  freelancerOrders: Order[];
};

const getOrders = async (): Promise<GetOrdersResponseData> => {
  const res = await axios.get(`orders`);
  return res?.data?.data;
};

export const useGetOrders = () =>
  useQuery(["getOrderLists"], () => getOrders());

const getOrderDetails = async (id: string): Promise<Order> => {
  const res = await axios.get(`orders/${id}`);
  return res?.data?.data;
};

export const useGetOrderDetails = (id: string) =>
  useQuery(["getOrderDetails", id], () => getOrderDetails(id), { enabled: !!id });

const getOrderInvoice = async (orderId: string): Promise<any> => {
  const res = await axios.get(`orders/${orderId}/invoice`);
  return res?.data?.data;
}

export const useGetOrderInvoice = (orderId: string) => 
  useQuery(["getOrderInvoice", orderId], () => getOrderInvoice(orderId), { enabled: !!orderId });
