import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { DATE_WITH_SLASH } from "@constants/datetime";
import { Proposal } from "@dto/proposal";
import EllipsisContent from "@components/EllipsisContent";

interface IProps {
  onClose: () => void;
  proposal: Proposal;
}

const VoteHistoryDialog: FC<IProps> = ({
  onClose,
  proposal,
}) => {
  const data = proposal?.voteOptions
    ?.reduce((accumulator, { label, votes }) => {
      return [...accumulator, ...votes.map(_ => ({ ..._, label }))]
    }, [])
    .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)

  return (
    <Dialog
      fullWidth
      open
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          m: 2,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
        Vote history
      </DialogTitle>

      <DialogContent>
        <TableContainer sx={{ maxHeight: 442, mb: 4 }}>
          <Table sx={{ '& td, & th': { border: 0 } }}>
            <TableHead sx={{
              '& th': {
                color: (theme) => theme.palette.common.gray5
              }
            }}>
              <TableRow>
                <TableCell>Member</TableCell>
                <TableCell>Option</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((vote) => (
                <TableRow
                  key={vote.attendeeId}
                  sx={{ '& td': { fontSize: 16 } }}
                >
                  <TableCell sx={{ fontWeight: 600 }}>
                    {vote.name}
                  </TableCell>
                  <TableCell>{vote.label}</TableCell>
                  <TableCell>
                    <EllipsisContent
                      fontSize={16}
                      lineHeight={"inherit"}
                      fontWeight={"normal"}
                      numberOfLines={"unset"}
                      sx={{ mt: 0, wordBreak: "break-word" }}
                      content={vote.reason}
                    />
                  </TableCell>
                  <TableCell>{dayjs(vote.createdAt).format(DATE_WITH_SLASH)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoteHistoryDialog;
