import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import useGetJobsByProject from "@api/job/useGetJobsByProject";
import { ToggleButtonGroup, ToggleButton, Typography, useTheme } from "@mui/material";

import { useAppDispatch, useAppSelector } from "@app/hooks";

import { sidebarActions } from "@features/sidebar/slice";

import DotNotification from "@components/Layout/PageLayout/components/DotNotification";

import { useQuestNotificationsManagement } from "@pages/jobs-management/QuestActivity/QuestNotificationManagement";
import { useGetUnreadRoundTables } from "@pages/messenger/ConversationList/hooks";

const RightBarToggleBtn = ({ projectId }: { projectId?: string }) => {
  const {
    palette: { common },
  } = useTheme();
  const dispatch = useAppDispatch();
  const projectContentView = useAppSelector((state) => state.sideBar.projectContentView);
  const [searchParams, setSearchParams] = useSearchParams();
  const jobId = searchParams.get("job");
  const { data } = useGetJobsByProject(projectId);
  const { unreadQuestList } = useQuestNotificationsManagement();
  const allQuest = data?.jobs;
  const unreadRoundtables = useGetUnreadRoundTables();

  const hasUnreadQuest = allQuest?.some((quest) => unreadQuestList.includes(quest.id));
  const hasUnreadChat = unreadRoundtables?.includes(projectId);
  useEffect(() => {
    if (jobId && projectContentView !== "list") {
      dispatch(sidebarActions.setProjectContentView({ view: "list" }));
      dispatch(sidebarActions.setRightBarOpen({ open: "project" }));
    }
  }, [jobId]);

  return (
    <ToggleButtonGroup
      value={projectContentView}
      exclusive
      onChange={(_e, val) => {
        if (!val) {
          return;
        }
        if (val === "chat") {
          setSearchParams({});
        }
        dispatch(sidebarActions.setProjectContentView({ view: val }));
        dispatch(sidebarActions.setRightBarOpen({ open: "project" }));
      }}
      sx={{
        height: 40,
        borderRadius: 2,
        border: common.border,
        ".Mui-selected": { bgcolor: `${common.deutziaWhite} !important` },
      }}
    >
      <ToggleButton
        value="chat"
        sx={{
          textTransform: "unset",
          gap: 1,
          width: 72,
          borderRadius: 2,
          border: common.border,
        }}
      >
        <Typography fontSize={14} fontWeight={600}>
          Chat
        </Typography>
        {hasUnreadChat && <DotNotification />}
      </ToggleButton>
      <ToggleButton
        value="list"
        sx={{
          textTransform: "unset",
          gap: 1,
          width: 72,
          borderRadius: 2,
          border: common.border,
        }}
      >
        <Typography fontSize={14} fontWeight={600}>
          List
        </Typography>
        {hasUnreadQuest && <DotNotification />}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default RightBarToggleBtn;
