import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

type Request = {
  id: string
};

const toggleRtNotification = async ({ id }: Request): Promise<any> => {
  const response = await axios.patch(`/round-table/${id}/notification-setting`);

  return response.data.data;
};

const useToggleNotification = () => {
  return useMutation(toggleRtNotification);
};

export default useToggleNotification;
