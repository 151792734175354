import { axios, handleApiError } from "@features/config";

import { Application } from "@dto/application";
import { User } from "@dto/user";

type DeliveryResponseType = {
  success: boolean;
  data?: any;
  message?: string;
};

type DeliveryRequestType = {
  jobId: string;
  body: {
    freelancerId: string;
    files: string[];
  };
};

export const submitDelivery = async ({ jobId, body }: DeliveryRequestType) => {
  try {
    const data: DeliveryResponseType = await axios.post(`/job/${jobId}/deliveries`, body);

    return {
      success: true,
      data: data?.data,
    };
  } catch (error) {
    return handleApiError(error);
  }
};
