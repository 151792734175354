import { FC, ReactNode } from "react";

import { Close as CloseIcon, KeyboardArrowLeft as KeyboardArrowLeftIcon } from "@mui/icons-material";
import { Stack, Typography, StackProps } from "@mui/material";

import useClientRect from "@hooks/useGetComponentSize";


type WrapperType = StackProps & {
  children: ReactNode;
  title?: string;
  onClose?: () => void;
  onBack?: () => void;
};

const ContentWrapper: FC<WrapperType> = ({
  children,
  title,
  onClose,
  onBack,
  ...rest
}) => {
  const [rect, ref] = useClientRect();

  return (
    <Stack {...rest}>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        px={2}
        py={1.5}
        component={'div'} ref={ref}
      >
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          {onBack && (
            <KeyboardArrowLeftIcon
              fontSize="medium"
              sx={{ cursor: "pointer" }}
              onClick={onBack}
            />
          )}
          <Typography fontSize={18} fontWeight={700}>
            {title}
          </Typography>
        </Stack>
        {onClose && (
          <CloseIcon
            fontSize="medium"
            sx={{ cursor: "pointer" }}
            onClick={onClose}
          />
        )}
      </Stack>
      <Stack pb={1.5} height={`calc(100% - ${rect?.height || 0})`} overflow={"auto"}>
        {children}
      </Stack>
    </Stack>
  )
}

export default ContentWrapper;
