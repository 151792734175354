import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import useCloseProject from "@api/roundtable/useCloseProject";
import useDeleteRoundtable from "@api/roundtable/useDeleteRoundtable";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
  debounce,
  useTheme,
} from "@mui/material";

import { handleApiError } from "@features/config";

import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";
import { ISnackbar } from "@components/Snackbar";

import queryClient from "@config/queryClient";

interface IProps {
  roundtableName: string;
  membersCount: number;
  onCancel: () => void;
  id: string;
  setSnackbar: Dispatch<SetStateAction<ISnackbar>>;
  isRoundtable?: boolean;
}

const ConfirmDeleteRoundtableDialog: FC<IProps> = ({
  roundtableName,
  membersCount,
  onCancel,
  id,
  setSnackbar,
  isRoundtable = true,
}) => {
  const {
    palette: { common },
  } = useTheme();

  const [confirm, setConfirm] = useState(false);
  const [reason, setReason] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const deleteRoundtable = useDeleteRoundtable();
  const closeProject = useCloseProject();

  const handleConfirm = useCallback(() => {
    setConfirm((preStatus) => !preStatus);
  }, []);

  const handleClickOnDelete = () => {
    if (isRoundtable) {
      return executeDeleteApi();
    }

    setOpenConfirmationDialog(true);
  };

  const _onSuccess = () => {
    setSnackbar({
      message:
        membersCount === 1
          ? isRoundtable
            ? "Your Teamspace was deleted successfully!"
            : "Your project was closed successfully!"
          : "Your proposal was created successfully!",
      open: true,
      severity: "success",
    });

    queryClient.invalidateQueries(["getEventLogs"]);
    onCancel();
  };

  const _onError = (message: string) => {
    setSnackbar({
      message,
      open: true,
      severity: "error",
    });
  };

  const executeDeleteApi = () => {
    isRoundtable
      ? deleteRoundtable.mutate(
          { id },
          {
            onSuccess: () => {
              _onSuccess();
            },
            onError: (error) => {
              _onError(handleApiError(error).message);
            },
          }
        )
      : closeProject.mutate(
          {
            id,
            reason,
          },
          {
            onSuccess: () => {
              _onSuccess();
            },
            onError: (error) => {
              _onError(handleApiError(error).message);
            },
          }
        );
  };

  const handleChangeReason = debounce(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e?.target?.value || "";
      setReason(value);
    },
    300
  );

  return (
    <>
      <Dialog fullWidth open maxWidth="sm">
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: 20, fontWeight: 700 }}>
          {isRoundtable ? "Delete Teamspace" : "Close Project"}
        </DialogTitle>

        <DialogContent>
          <Typography>
            Do you want to close the{" "}
            <Typography component="span" sx={{ fontWeight: 700 }}>
              {roundtableName}
            </Typography>{" "}
            that currently has{" "}
            <Typography component="span" sx={{ fontWeight: 700 }}>
              {membersCount}
            </Typography>{" "}
            members?
          </Typography>

          {isRoundtable && (
            <Typography sx={{ color: common.partyTime, mt: 2 }}>
              This {isRoundtable ? "Teamspace" : "project"} will be deleted. This action
              cannot be undone.
            </Typography>
          )}

          {isRoundtable ? (
            <FormControlLabel
              control={<Checkbox checked={confirm} onChange={handleConfirm} />}
              label="Yes, I want to delete this Teamspace."
              sx={{ mt: 2 }}
            />
          ) : (
            <TextField
              fullWidth
              multiline
              rows={5}
              name="reason"
              label="Message"
              onChange={handleChangeReason}
              sx={{ mt: 3 }}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            variant="contained"
            disabled={
              isRoundtable
                ? !confirm
                : deleteRoundtable.isLoading || closeProject.isLoading
            }
            onClick={handleClickOnDelete}
          >
            {isRoundtable ? "Delete" : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

      <CustomConfirmationDialog
        title="Are you sure to close this project?"
        buttons={[
          {
            variant: "outlined",
            label: "No",
          },
          {
            label: "Yes",
            handleOnClickButton: executeDeleteApi,
            //prevent unmount before the mutation finishes
            preventDialogClose: true,
          },
        ]}
        openDialog={openConfirmationDialog}
        setOpenDialog={setOpenConfirmationDialog}
      />
    </>
  );
};

export default ConfirmDeleteRoundtableDialog;
