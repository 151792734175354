import { FC } from "react"

import UpsertRoundtableComponent from "@components/Dialog/UpsertRoundtableDialog";
import { ROUNDTABLE_FORM_TYPE } from "@components/Dialog/UpsertRoundtableDialog/RountableFormDialog"

import { Roundtable as RoundtableType } from "@dto/roundtable";


interface IProps {
  data: RoundtableType;
  type?: (typeof ROUNDTABLE_FORM_TYPE)[keyof typeof ROUNDTABLE_FORM_TYPE];
  onClose?: () => void;
}

const UpsertRoundtable: FC<IProps> = ({
  data,
  type, 
  onClose
}) => {
  return (
    <UpsertRoundtableComponent
      data={data}
      type={type}
      onClose={onClose}
      removeDialog
    />
  )
}

export default UpsertRoundtable
