import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Divider, Stack, Typography } from "@mui/material";
import { SidebarKeys } from "@routers/const";

import { useNavItemsControl } from "@components/Layout/PageLayout/hooks/useNavItemsControl";

import { Roundtable } from "@dto/roundtable";

import { CollapseProvider } from "./CollapseContext";
import JobManagementLayout from "./JobManagementLayout";

type PropType = {
  isFreelancer?: boolean;
  roundtableData?: Roundtable;
};
const JobsManagement: FC<PropType> = ({ isFreelancer, roundtableData }) => {
  const { roundtableId, projectId } = useParams();
  const { handleFocusSidebarItem } = useNavItemsControl();

  useEffect(() => {
    const nodeId = `${SidebarKeys.roundtableItem}:${roundtableId}:${projectId}`;
    handleFocusSidebarItem(nodeId);
  }, []);

  return (
    <Stack
      px={3}
      py={4}
      sx={{
        maxHeight: "calc(100% - 10px)",
      }}
    >
      <Stack width={"100%"} mb={3}>
        <Typography fontWeight={700} fontSize={"24px"}>
          Quests management
        </Typography>
      </Stack>

      <CollapseProvider>
        <JobManagementLayout
          projectId={projectId || roundtableId}
          isFreelancer={isFreelancer}
          roundtableData={roundtableData}
        />
      </CollapseProvider>
    </Stack>
  );
};

export default JobsManagement;
