import { FC, useMemo } from "react";

import { Tooltip } from "@mui/material";

interface CustomTooltipProps {
  content?: string;
  maxLength?: number;
  placement?: string;
}

const CustomTooltip: FC<CustomTooltipProps> = ({ content = "", maxLength = 20 }) => {
  if (content.length <= maxLength) {
    return <span>{content}</span>;
  }

  const shortContent = content.substring(0, maxLength) + "...";

  return (
    <Tooltip title={content} style={{ cursor: "pointer" }}>
      <span>{shortContent}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
