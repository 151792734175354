import React, { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import useKickoutMember from "@api/roundtable/useKickoutMember";
import useProvidePermissionRoundtable from "@api/roundtable/useProvidePermissionRoundtable";
import { MemberManagementType } from "@constants/attendee";
import { yupResolver } from "@hookform/resolvers/yup";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import * as yup from "yup";

import { handleApiError } from "@features/config";

import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";
import FormInput from "@components/FormInput";

import { Attendee, ManagementEnum, Roundtable } from "@dto/roundtable";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

type MenuType = {
  text: string;
  sx?: SxProps<Theme>;
  title?: string;
  content?: string;
  onClick: () => void;
};

const formSchema = yup.object().shape({
  reason: yup.string().trim().nullable(),
});

export type KickoutMemberForm = {
  reason: string;
};

const defaultValues: KickoutMemberForm = {
  reason: "",
};

const MenuManagementList = ({
  member,
  roundtable,
  currentUser,
}: {
  member?: Attendee;
  roundtable: Roundtable;
  currentUser: Attendee;
}) => {
  const isTraditionalRoundtable =
    roundtable.managementStyle === ManagementEnum.TRADITIONAL;

  const _isTraditionalRoundtable = isTraditionalRoundtable ? !member.isSuperAdmin : true;
  const _isThisUser = member.userId !== currentUser.userId;
  const _isMember = member.type === MemberManagementType.MEMBER;
  const displayMenuCondition = _isTraditionalRoundtable && _isThisUser && _isMember;

  const isOwner = roundtable.attendees.some(
    (attendee) => attendee.isSuperAdmin && attendee.userId === currentUser.userId
  );
  const isAdmin = roundtable.attendees.some(
    (attendee) =>
      !attendee.isSuperAdmin &&
      +attendee.voteWeight > 0 &&
      attendee.userId === currentUser.userId
  );

  const roundTableId = roundtable.id;
  const targetAdmin = +member.voteWeight > 0 && !member.isSuperAdmin;
  const targetMember = !targetAdmin;
  const { setSnackbar } = useSnackbar();
  const [openWarningIndex, setOpenWarning] = useState<number | null>(null);
  const { mutate: providePermission, isLoading: providePermissionLoading } =
    useProvidePermissionRoundtable();
  const { mutate: kickoutMember, isLoading: kickoutLoading } = useKickoutMember();
  const menuItems: MenuType[] = [];

  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<KickoutMemberForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });
  const reason = watch("reason");

  if (isOwner) {
    menuItems.push(
      targetAdmin
        ? {
            text: "Remove admin",
            title: "Assign permission Teamspace",
            content: "Are you sure you want to remove this user from sub-admin?",
            sx: null,
            onClick: () => assignAdmin(member.userId),
          }
        : {
            text: "Assign admin",
            title: "Assign permission Teamspace",
            content: "Are you sure you want to assign this user to be sub-admin?",
            sx: null,
            onClick: () => assignAdmin(member.userId),
          }
    );
  }

  if (
    //display kickout traditional
    isOwner ||
    (isAdmin && targetMember) ||
    //display kickout decentralized
    (!isTraditionalRoundtable && +currentUser.voteWeight > 0)
  ) {
    menuItems.push({
      text: "Kick out",
      title: "Kick out member",
      content: "Are you sure to kick this member out?",
      onClick: () => handleKickout(member.userId),
    });
  }

  const handleKickout = async (memberId: string) => {
    kickoutMember(
      {
        targetMemberId: memberId,
        roundTableId,
        reason,
      },
      {
        onSuccess: () => {
          setSnackbar?.({
            message: isTraditionalRoundtable
              ? `Member has been removed from round table.`
              : "Your proposal was created successfully!",
            open: true,
            severity: "success",
          });

          queryClient.invalidateQueries(["getRoundTableDetail", roundTableId]);
          setOpenWarning(null);
        },
        onError: (error) => {
          setSnackbar?.({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  const assignAdmin = (memberId: string) => {
    providePermission(
      {
        assigneeId: memberId,
        roundTableId,
      },
      {
        onSuccess: () => {
          setSnackbar?.({
            message: `${isOwner ? "Remove" : "Assign"} this user successfully!`,
            open: true,
            severity: "success",
          });

          queryClient.invalidateQueries(["getRoundTableDetail", roundTableId]);
          setOpenWarning(null);
        },
        onError: (error) => {
          setSnackbar?.({
            message: handleApiError(error).message,
            open: true,
            severity: "error",
          });
        },
      }
    );
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleOpenWarning = useCallback(
    (index: number) => () => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }

      setOpenWarning(index);
    },
    []
  );

  const handleSelect = useCallback(
    (fn: () => void) => () => {
      setOpen(false);
      fn();
    },
    []
  );

  return (
    <Stack>
      {displayMenuCondition && menuItems.length ? (
        <IconButton
          ref={anchorRef}
          sx={{
            borderRadius: 0,
          }}
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>
      ) : null}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 5 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu">
                  {menuItems?.map(({ text, sx = {} }, index) => (
                    <MenuItem
                      key={`${text}-${index}`}
                      onClick={handleSelect(handleOpenWarning(index))}
                      sx={{ fontSize: 14, fontWeight: 400, ...sx }}
                    >
                      {text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <CustomConfirmationDialog
        title={menuItems[openWarningIndex]?.title}
        content={menuItems[openWarningIndex]?.content}
        buttons={[
          {
            variant: "outlined",
            handleOnClickButton: reset,
            label: "No",
          },
          {
            label: "Yes",
            handleOnClickButton: menuItems[openWarningIndex]?.onClick,
            isLoading: providePermissionLoading || kickoutLoading,
            //prevent unmount before the mutation finishes
            preventDialogClose: true,
          },
        ]}
        openDialog={openWarningIndex !== null}
        setOpenDialog={() => setOpenWarning(null)}
      >
        {!isTraditionalRoundtable && (
          <Box m={3}>
            <FormInput
              fullWidth
              multiline
              rows={5}
              control={control}
              name="reason"
              label="Reason"
              error={!!errors?.reason?.message}
              helperText={errors?.reason?.message}
              sx={{ mt: 1 }}
            />
          </Box>
        )}
      </CustomConfirmationDialog>
    </Stack>
  );
};
export default MenuManagementList;
