import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { deleteJob } from "@api/job/deleteJob";
import { editJob } from "@api/job/useEditJob";
import { UNREAD_REALTIME } from "@fb/const";
import { realtimeDb } from "@fb/db";
import { KeyboardArrowRight as KeyboardArrowRightIcon } from "@mui/icons-material";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Avatar,
  AvatarGroup,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { onValue, ref } from "firebase/database";

import { useCopyToClipboard } from "@hooks/useCopyToClipboard";
// import { useSelectJob } from "./SelectJobContext";
import useHandleJobMngByQuery from "@hooks/useHandleJobMngLayoutByQuery";

import { useAppSelector } from "@app/hooks";

import ConfirmDialog from "@components/Dialog/ConfirmDialog/ConfirmAction";
import CustomConfirmationDialog from "@components/Dialog/CustomConfirmationDialog";
import OrderDetailDialog from "@components/Dialog/OrderDetailDialog";
import DotNotification from "@components/Layout/PageLayout/components/DotNotification";

import { generateHSL, getAbbreviation } from "@utils/index";

import { Order } from "@dto/order";
import { Attendee } from "@dto/roundtable";

import { useSnackbar } from "@contexts/SnackbarContext";

import queryClient from "@config/queryClient";

import { useCollapseTable } from "./CollapseContext";
import { useQuestNotificationsManagement } from "./QuestActivity/QuestNotificationManagement";
import { useSelectJob } from "./SelectJobContext";
import { JOB_STATUS, VIEW, QuestVisibility } from "./constants";
import { TDataType, TRowType } from "./types";
import { formatPriorityStyle } from "./utils";

type Props = TDataType[keyof typeof JOB_STATUS] & {
  isFreelancer?: boolean;
};

type Column = {
  key: keyof TRowType;
  width?: string;
  display?: string;
  label?: string | ReactNode;
  render?: (record: TRowType) => ReactNode;
};

const JobsTable: FC<Props> = ({ title, color, status, data, isFreelancer }) => {
  const {
    palette: { common },
  } = useTheme();
  // const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const _clipboard = useCopyToClipboard();
  const { isCollapsed, toggleCollapse } = useCollapseTable();
  const { jobSelected, setJobSelected } = useSelectJob();
  const [confirmPause, setConfirmPause] = useState(false);

  const clickCollapse = (e: any) => {
    toggleCollapse(true);
  };
  const [selectedJobId, setSelectedJobId] = useState<string>("");
  const [selectedShortId, setSelectedShortId] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openInvoice, setOpenInvoice] = useState<string | null>("");

  const { updateUnreadQuestStatus, unreadQuestList } = useQuestNotificationsManagement();
  const { appendQuery, setQueries, regenerateLayoutQueries, allQueries } =
    useHandleJobMngByQuery();

  const { mutate: pauseJob } = useMutation({
    mutationFn: editJob,
  });

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleViewDetail = (event: React.MouseEvent<HTMLLIElement>) => {
    // jobId && navigate(ProtectedPaths.jobId.replace(":jobId", jobId));
    event.stopPropagation();
    appendQuery({
      ...regenerateLayoutQueries({ isFreelancer, status }),
      job: selectedShortId,
      l: VIEW[7].id,
    });
    setAnchorEl(null);
  };

  const handleEditQuest = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    appendQuery({
      ...regenerateLayoutQueries({ isFreelancer, status }),
      job: selectedShortId,
      m: VIEW[3].id,
      r: "",
    });
    setAnchorEl(null);
  };

  const onPauseJob = async () => {
    setAnchorEl(null);
    await pauseJob(
      { id: selectedJobId, status: JOB_STATUS.TODO },
      {
        onSuccess: () => {
          setJobSelected({ ...jobSelected, status: JOB_STATUS.TODO });
          queryClient.invalidateQueries(["getJobsByProject"]);
          appendQuery({ l: VIEW[1].id, m: VIEW[3].id, r: VIEW[2].id });
          setSnackbar({
            message: "Job paused successfully!",
            open: true,
            severity: "success",
          });
        },
      }
    );
  };

  const { mutate: removeJob, isLoading } = useMutation({
    mutationFn: deleteJob,
    onSuccess: ({ success }) => {
      setOpenConfirm(false);
      if (success) {
        setSelectedJobId("");
        setSelectedShortId("");
        queryClient.invalidateQueries(["getJobsByProject"]);
        setSnackbar({
          type: "success",
          message: "Successfully deleted!",
          open: true,
        });
      }
    },
  });

  const onClickRow = async (row: TRowType) => {
    setQueries({
      job: row.shortId,
      ...regenerateLayoutQueries({ isFreelancer, status: row.status }),
    } as any);
    updateUnreadQuestStatus(row.id);
  };

  const columns: Column[] = useMemo(
    () => [
      {
        key: "title",
        width: isCollapsed ? "100%" : "60%",
        label: (
          <Stack
            // component={'div'}
            // ref={ref}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <IconButton sx={{ p: 0 }}>
                <ArrowDropDownCircleIcon sx={{ color }} />
              </IconButton>
              <Typography fontSize={"24px"} fontWeight={600} color={color}>
                {title}
              </Typography>
              <Chip
                label={data.length}
                size="small"
                variant="outlined"
                sx={{
                  width: "fit-content",
                  borderRadius: "8px",
                }}
              />
            </Stack>
            {isCollapsed && (
              <IconButton sx={{ p: 0 }} onClick={(e) => toggleCollapse()}>
                <KeyboardArrowRightIcon sx={{ color }} />
              </IconButton>
            )}
          </Stack>
        ),
        render: (record) => {
          const assignee: any[] = record.orders?.map((order) => order.freelancer);
          const isSkeleton = record.id?.includes("Fake");
          const hasUnread = unreadQuestList.includes(record.id);

          return (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              pl={2}
              onClick={!isSkeleton ? () => onClickRow(record) : null}
            >
              <Stack direction={"row"} gap={1.5} alignItems={"center"}>
                <AvatarGroup max={2}>
                  {assignee?.length ? (
                    assignee.map((assignee, index) => (
                      <Tooltip key={index} title={assignee?.name}>
                        <Avatar
                          sx={{ width: 31, height: 31 }}
                          src={assignee?.avatar ?? ""}
                        />
                      </Tooltip>
                    ))
                  ) : (
                    <Avatar sx={{ width: 31, height: 31 }} src={""} />
                  )}
                </AvatarGroup>
                <Stack width={isCollapsed ? 164 : 440} gap={0.4}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "98%",
                      }}
                    >
                      {record.title}
                    </Typography>
                    {hasUnread && <DotNotification />}
                  </Stack>
                  {[JOB_STATUS.TODO, JOB_STATUS.HIRING].includes(record.status) &&
                    record.visibility === QuestVisibility.PLATFORM && (
                      <Typography
                        fontSize={10}
                        borderRadius={1.6}
                        px={0.8}
                        py={0.1}
                        color={"common.heirloomHydrangea"}
                        border={`1px solid ${common.heirloomHydrangea}`}
                        width={"fit-content"}
                      >
                        Public
                      </Typography>
                    )}
                  {[JOB_STATUS.IN_PROGRESS].includes(record.status) && isFreelancer && (
                    <Typography
                      fontSize={10}
                      borderRadius={1.6}
                      px={0.8}
                      py={0.1}
                      color={"common.heirloomHydrangea"}
                      border={`1px solid ${common.heirloomHydrangea}`}
                      width={"fit-content"}
                    >
                      Payment secured
                    </Typography>
                  )}
                </Stack>
              </Stack>
              {!isSkeleton && (
                <>
                  <IconButton
                    onClick={(e) => {
                      setSelectedJobId(record.id);
                      setSelectedShortId(record.shortId);
                      handleOpenMenu(e);
                    }}
                    sx={{ p: 0 }}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    sx={{
                      ".MuiPaper-root": {
                        boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.10)",
                      },
                    }}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        _clipboard.copy(
                          `${window?.location?.origin}${window?.location?.pathname}?job=${selectedShortId}`,
                          {
                            snackbar: {
                              enable: true,
                              message: "Copy share URL successfully!",
                            },
                          }
                        );
                        setAnchorEl(null);
                      }}
                    >
                      Copy Link
                    </MenuItem>
                    {!isFreelancer && record.status === JOB_STATUS.HIRING && (
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setConfirmPause(true);
                        }}
                      >
                        Pause job
                      </MenuItem>
                    )}
                    {(isFreelancer ||
                      [
                        JOB_STATUS.IN_PROGRESS,
                        JOB_STATUS.DELIVERED,
                        JOB_STATUS.DONE,
                      ].includes(record.status)) && (
                      <MenuItem onClick={(e) => handleViewDetail(e)}>
                        View Quest details
                      </MenuItem>
                    )}
                    {record.status === JOB_STATUS.HIRING && (
                      <MenuItem onClick={(e) => handleEditQuest(e)}>Edit Quest</MenuItem>
                    )}
                    {[
                      JOB_STATUS.IN_PROGRESS,
                      JOB_STATUS.DELIVERED,
                      JOB_STATUS.DONE,
                    ].includes(record.status) && (
                      <MenuItem
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setAnchorEl(null);
                          const currentJob = data.find(
                            (job) => job.shortId === selectedShortId
                          );
                          setOpenInvoice(currentJob.orders?.[0]?.id);
                        }}
                      >
                        View Order details
                      </MenuItem>
                    )}

                    {record.status === JOB_STATUS.TODO && (
                      <MenuItem
                        onClick={(e) => {
                          // handleClickItem(record.id);
                          e.stopPropagation();
                          setAnchorEl(null);
                          setOpenConfirm(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </Stack>
          );
        },
      },
      {
        key: "priority",
        width: "10%",
        display: isCollapsed ? "none" : "table-cell",
        label: "Priority",
        render: (record) => {
          return (
            <Typography sx={{ ...formatPriorityStyle(record.priority) }}>
              {record.priority}
            </Typography>
          );
        },
      },
      {
        key: "dueDate",
        width: "10%",
        display: isCollapsed ? "none" : "table-cell",
        label: "Due date",
        render: (record) =>
          !!record.dueDate ? (
            <Typography sx={{ wordBreak: "keep-all" }}>
              {dayjs(record.dueDate).format("MMM DD")}
            </Typography>
          ) : (
            "---"
          ),
      },
      {
        key: "budget",
        width: "10%",
        display: isCollapsed ? "none" : "table-cell",
        label: "Cost (USD)",
        render: (record) => {
          const order: Order[] = record.orders;
          return (
            <Typography>
              {Number(+order?.[0]?.price / 1.015 || record.budget).toFixed(2)}
            </Typography>
          );
        },
      },
      {
        key: "createdByUser",
        width: "10%",
        display: isCollapsed ? "none" : "table-cell",
        label: "Created by",
        render: (record) => (
          <Tooltip title={record.createdByUser.name}>
            <Avatar
              sx={{
                bgcolor: generateHSL(getAbbreviation(record.createdByUser.name)),
                width: 31,
                height: 31,
                fontSize: 16,
              }}
              alt={record.createdByUser.name}
              src={record.createdByUser.avatar}
            >
              {getAbbreviation(record.createdByUser.name)}
            </Avatar>
          </Tooltip>
        ),
      },
    ],
    [anchorEl, isCollapsed, unreadQuestList]
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <TableCell
                key={col.key}
                width={col.width || "unset"}
                sx={{
                  display: col?.display || "table-cell",
                  padding: "8px",
                  whiteSpace: "nowrap",
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#E2E8F0",
                  borderLeft: i === 0 ? "unset" : common.border,
                }}
              >
                {/* {col.key === 'title' && <Stack key={`title-width-${Number(isCollapsed)}`} component={'div'} ref={ref} height={2} width={"100%"} bgcolor={'red'} />} */}
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {!!data?.length &&
            data.map((item) => (
              <TableRow
                key={item.id}
                //open onclick to collapse table
                onClick={clickCollapse}
                sx={{
                  cursor: isCollapsed ? "default" : "pointer",
                  bgcolor:
                    [item.shortId, item.id].includes(allQueries.job) &&
                    common.deutziaWhite,
                  "&:hover": {
                    cursor: "pointer",
                    bgcolor: common.deutziaWhite,
                  },
                }}
              >
                {columns.map((col, i) => (
                  <TableCell
                    key={col.key}
                    width={col.width || "unset"}
                    sx={{
                      display: col?.display || "table-cell",
                      borderLeft: i === 0 ? "unset" : common.border,
                      padding: "8px",
                    }}
                  >
                    {!!col.render ? col.render(item) : item[col.key] || "---"}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <OrderDetailDialog
        open={!!openInvoice}
        onClose={() => setOpenInvoice("")}
        selectedOrder={openInvoice}
      />
      <ConfirmDialog
        title="Pause job"
        description="This job will be moved to To Do. Are you sure?"
        submitBtnLabel={"Pause it, please!"}
        cancelBtnLabel="No, leave it"
        isOpen={confirmPause}
        onSubmit={onPauseJob}
        onClose={() => setConfirmPause(false)}
      />
      <CustomConfirmationDialog
        title="Delete Quest?"
        content={"Are you sure you want to delete this quest?"}
        buttons={[
          {
            variant: "outlined",
            label: "Cancel",
            isLoading,
          },
          {
            label: "Delete",
            handleOnClickButton: () => removeJob({ jobId: selectedJobId }),
            preventDialogClose: true,
            isLoading,
          },
        ]}
        openDialog={openConfirm}
        setOpenDialog={setOpenConfirm}
      />
    </TableContainer>
  );
};

export default JobsTable;
